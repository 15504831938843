import axios from '@/axios';
import EntitiesService from '../entities/entities.service';
import { Transformation } from './transformation.class';

export default class TransformationsService extends EntitiesService<
Transformation
> {
  get endpoint() {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${localStorage.getItem('local_base_url')}/transformations`;
  }

  async bulk(transformations: Transformation[]) {
    const response = await axios.put(`${this.endpoint}/bulk`, transformations);
    return response;
  }

  async bulkCreate(transformations: Transformation[]) {
    const response = await axios.post(
      `${this.endpoint}/bulk-create`,
      transformations,
    );
    return response;
  }
}
