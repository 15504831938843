

































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { LoginRequest } from '@/services/api/auth/login-request.interface';

const usersModule = namespace('users');

@Component
export default class Login extends Vue {
  @usersModule.Action('LOGIN')
  userLogin!: (login: LoginRequest) => void;

  @usersModule.Action('REMOVE_TOKENS')
  userRemoveTokens!: () => Promise<void>;

  formData: LoginRequest = {
    username: '',
    password: '',
  };

  async created(): Promise<void> {
    await this.userRemoveTokens();
  }
}
