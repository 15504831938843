import { ShipmentType } from './shipment-type.class';
import EntitiesService from '../entities/entities.service';

export default class ShipmentTypesService extends EntitiesService<
ShipmentType
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) + '/shipment-types'
    );
  }
}
