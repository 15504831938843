


























































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ImportShipmentsResponse } from '@/services/api/shipments/import-shipments-response';
import { ShipmentImportStatus } from '@/types/import-status.enum';
import { ShipmentImport } from '@/services/api/shipment-imports/shipment-import.class';

const shipmentsModule = namespace('shipments');

@Component({
  components: {},
})
export default class ImportShipmentsDetail extends Vue {
  @shipmentsModule.Getter('IMPORT_RESULT')
  importResult!: ImportShipmentsResponse;

  @shipmentsModule.Action('FETCH_IMPORT')
  fetchImport!: (id: string) => Promise<ShipmentImport>;

  showResult = false;
  interval: any;
  importStatusEnum = ShipmentImportStatus;

  get percentageFetch(): number {
    return (
      ((this.importResult.success + this.importResult.failed) /
        this.importResult.totalRecords) *
      100
    );
  }

  @Watch('importResult')
  onShipmentImportDone(): void {
    if (this.importResult.status === ShipmentImportStatus.Done) {
      this.$emit('importDone', this.importResult);
    }
  }

  async created(): Promise<void> {
    await this.fetchImport(this.$route.params.id);
  }

  handleFailedImportResult(): number {
    this.$emit('failed', this.importResult);
    return this.importResult.failed;
  }
}
