import axios from '@/axios';
import { UserResponse } from './user-response.interface';
import { FetchAllParams } from '../fetch-all-params.interface';
import { PaginatedResponse } from '../paginated-response';
import { AxiosRequestConfig } from 'axios';
import { FilterParams } from '@/types/filter-params.interface';
import { isBlank } from '@/utils/is-blank.util';
import { UserRequest } from './user-request.interface';
import { User } from './user.class';

const endpoint = '/users';

export default {
  async fetchMe(): Promise<UserResponse> {
    const response = await axios.get<UserResponse>(`${endpoint}/me`);
    return response.data;
  },
  // async fetchAll(): Promise<UserResponse[]> {
  //   const response = await axios.get<UserResponse[]>(`${endpoint}`);

  //   return response.data;
  // },
  async create(user: UserRequest): Promise<UserResponse> {
    const response = await axios.post<UserResponse>('/auth/register', user);
    return response.data;
  },

  async update(id: string, user: UserRequest): Promise<UserResponse> {
    const response = await axios.put<UserResponse>(`${endpoint}/${id}`, user);
    return response.data;
  },

  async delete(id: string): Promise<UserResponse> {
    const response = await axios.delete(`${endpoint}/${id}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  },

  async fetchAll(
    params?: FetchAllParams,
  ): Promise<PaginatedResponse<UserResponse>> {
    const config: AxiosRequestConfig = {};
    if (params) {
      const { filters, ...rest } = params;
      const filterParams: FilterParams = {};
      if (filters) {
        for (const [field, filter] of Object.entries(filters)) {
          if (!isBlank(filter.value)) {
            if (Array.isArray(filter.value)) {
              filterParams[field] = filter.value.map(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                (valueItem: any) => `${filter.operation}${valueItem}`,
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              filterParams[field] = `${filter.operation}${filter.value}`;
            }
          }
        }
      }
      config.params = {
        ...rest,
        ...filterParams,
      };
    }
    const response = await axios.get<PaginatedResponse<UserResponse>>(
      endpoint,
      config,
    );
    return response.data;
  },

  async fetchOne(id: string): Promise<User> {
    const response = await axios.get<User>(`users/${id}`);
    return response.data;
  },
};
