



























































































import {
  Vue,
  Component,
  Prop,
  Model,
  Emit,
  Watch,
} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import draggable from 'vuedraggable';
import lowerCase from 'lodash.lowercase';
import FieldSelect from '../components/FieldSelect.vue';
import { TransformationOperation } from '../services/api/transformations/transformation-operation.interface';
import { TransformationOperator } from '../services/api/transformations/transformation-operator.enum';
import { TransformationCombinator } from '../services/api/transformations/transformation-combinator.enum';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { Transformation } from '@/services/api/transformations/transformation.class';

@Component({
  components: {
    Multiselect,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    draggable,
    FieldSelect,
  },
})
export default class TransformationOperationRow extends Vue {
  @Model('change', {
    type: Object,
  })
  operation!: TransformationOperation;

  @Prop({
    type: Array,
    required: true,
  })
  transformations!: Transformation[];
  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  importedFields: string[] = [];

  @Watch('transformations', { immediate: true })
  onOutputFieldChange(): string[] {
    this.transformations.map(trans =>
      trans.operations.map(operation =>
        operation.transformationFields.map(field =>
          this.importedFields.push(field),
        ),
      ),
    );
    return this.importedFields;
  }

  get operatorOptions(): TransformationOperator[] {
    return Object.values(TransformationOperator);
  }

  get combinatorOptions(): TransformationCombinator[] {
    return Object.values(TransformationCombinator);
  }

  get showCombinatorFieldSelect(): boolean {
    return this.operation.transformationFields.length > 1;
  }

  get showParamsRow(): boolean {
    return (
      this.operation.operator === TransformationOperator.SetValue ||
      this.operation.operator === TransformationOperator.ValidDateOrNull
    );
  }

  get paramsLabel(): string | undefined {
    switch (this.operation.operator) {
    case TransformationOperator.ValidDateOrNull:
      return 'In what format can we expect the date?';

    case TransformationOperator.SetValue:
      return 'Value:';
    }
  }

  @Watch('operation', { deep: true })
  onOperationChange(): void {
    if (!this.showCombinatorFieldSelect) {
      this.operation.combinator = undefined;
    }

    if (!this.showParamsRow) {
      this.operation.parameter = undefined;
    }
  }

  @Emit('selectApplicationField')
  onSelectApplicationField(value: string): string {
    return value;
  }

  @Emit('delete')
  deleteOperation(): boolean {
    return true;
  }

  operatorCustomLabel(label: string): string {
    return lowerCase(label);
  }

  combinatorCustomLabel(label: string): string {
    return lowerCase(label);
  }
}
