










































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import FieldDaySelect from '../components/FieldsDaySelect.vue';
import countries from 'i18n-iso-countries';
import { BModal } from 'bootstrap-vue';
import { CustomerDelivery as CustDelivery } from '@/types/customer/customer-delivery.class';
import TimeSlotSelect from './TimeSlotSelect.vue';
import { CustomerDeliverySchedule } from '@/types/customer/customer-delivery-schedule.class';
import { TimeSlot } from '@/types/customer/timeslot.class';
import { CustomerParent } from '@/types/customer/customer-parent.class';

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

@Component({
  components: {
    FieldDaySelect,
    BModal,
    TimeSlotSelect,
  },
})
export default class CustomerDelivery extends Vue {
  @Prop({ type: Array, required: true })
  deliveries!: CustDelivery[];

  @Prop({ type: Object })
  customer!: CustomerParent;

  @Prop({ type: Array })
  streetNames!: string[];

  get selectedStreetNames(): string[] {
    return [...this.streetNames];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  options = countries.getNames('en', { select: 'official' });

  selectedDelivery: CustDelivery = new CustDelivery();

  editing: boolean | undefined = false;

  activeWeekDays: number[] = [];

  counter = 0;

  listIndex!: number | undefined;

  $refs!: Vue['$refs'] & {
    customerDeliveryDetail: BModal;
    fieldDaySelect: FieldDaySelect;
  };

  toggleCollapses(id: string): void {
    this.$root.$emit('bv::toggle::collapse', id);
  }

  openCustomerDeliveryModal(
    selectedDel: CustDelivery,
    editing?: boolean,
    index?: number,
  ): void {
    this.editing = editing;
    this.listIndex = index;
    this.selectedDelivery = selectedDel;
    this.activeWeekDays = this.selectedDelivery.businessHours.map(
      item => item.weekday,
    );
    this.$refs.customerDeliveryDetail.show();
  }

  closeCustomerDeliveryDetail(): void {
    this.$refs.customerDeliveryDetail.hide();
  }

  async addDeliverySchedule(): Promise<void> {
    const res = await this.$validator.validateAll();
    if (!res) {
      return;
    }
    const custDelivery = this.deliveries.find(
      (item, index) =>
        item.address.street.split(' ').join('').toLowerCase() ===
          this.selectedDelivery.address.street
            .split(' ')
            .join('')
            .toLowerCase() && index !== this.listIndex,
    );
    const existingSchedule = this.deliveries.find(
      (item, index) => index === this.listIndex,
    );
    if (existingSchedule) {
      this.$refs.customerDeliveryDetail.hide();
      return;
    }
    this.$emit('addDelivery', this.selectedDelivery);
    this.$refs.customerDeliveryDetail.hide();
  }

  redirectToRemove(index: number): void {
    this.$emit('remove', index);
  }

  copyDefaultData(): void {
    this.selectedDelivery = {
      ...this.selectedDelivery,
      name: this.customer.name,
      address: {
        street: this.customer.address.street,
        city: this.customer.address.city,
        country: this.customer.address.country,
      },
    };
  }

  setWeekDays(weekday: number): void {
    const selectedWeekdays: number[] = this.selectedDelivery.businessHours.map(
      item => item.weekday,
    );
    if (selectedWeekdays.includes(weekday)) {
      const schedule = this.selectedDelivery.businessHours.findIndex(
        item => item.weekday === weekday,
      );
      this.selectedDelivery.businessHours.splice(schedule, 1);
      this.activeWeekDays = this.selectedDelivery.businessHours.map(
        item => item.weekday,
      );
      this.counter++;
      return;
    }
    this.selectedDelivery.businessHours.push({
      ...new CustomerDeliverySchedule(),
      weekday,
      openAllDay: false,
      timeSlots: [{ ...new TimeSlot(), startTime: '00:00', endTime: '00:00' }],
    });
    this.selectedDelivery.businessHours.sort((a, b) =>
      a.weekday > b.weekday ? 1 : -1,
    );
    this.activeWeekDays = this.selectedDelivery.businessHours.map(
      item => item.weekday,
    );
  }

  setOpenAllDay(open: boolean, businesshour: CustomerDeliverySchedule): void {
    this.selectedDelivery.businessHours.find(item => {
      if (item === businesshour) {
        item.openAllDay = open;
      }
    });
    this.deliveries.find(item => {
      if (item === this.selectedDelivery) {
        item = { ...this.selectedDelivery };
      }
    });
  }
}
