





















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { Company } from '../services/api/companies/company.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';

const companiesModule = namespace('companies');

@Component({
  components: {
    EntityList,
  },
})
export default class AllCompanies extends Vue {
  @companiesModule.Getter('ALL_PAGINATED')
  companies!: any;

  @companiesModule.Action('FETCH_ALL')
  fetchAll!: (filter?: FetchAllParams) => Promise<Company[]>;

  @companiesModule.Action('DELETE')
  deleteById!: (id?: string) => Promise<void>;

  filter: FetchAllParams = {};

  public columns: any[] = [
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<Company[]> {
    return this.fetchAll(filter);
  }

  redirectToDetail(company: Company): void {
    this.$router.push({
      name: 'Company',
      params: { id: company._id || '' },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateCompany',
    });
  }

  async redirectToDelete(company: Company): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + company.name + '?',
    );
    if (!result) {
      return;
    }
    await this.deleteById(company._id);
    this.$router.push({
      name: 'login',
    });
  }

  created(): void {
    this.fetchAll();
  }
}
