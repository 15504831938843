






























































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import appTransformationOperationRow from '../components/TransformationOperationRow.vue';
import appTransformationConditionRow from '../components/TransformationConditionRow.vue';
import { Transformation } from '../services/api/transformations/transformation.class';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { TransformationOperation } from '@/services/api/transformations/transformation-operation.interface';
import { TransformationCondition } from '../services/api/transformations/transformation-condition.interface';
// eslint-disable-next-line max-len
import { TransformationApplicationSelector } from '@/types/transformations/transformation-application-selector.interface';
import { DataObject } from '@/services/api/data-object.interface';

@Component({
  components: {
    appTransformationOperationRow,
    appTransformationConditionRow,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    draggable,
  },
})
export default class TransformationDetail extends Vue {
  @Model('change', {
    type: Object,
  })
  transformation!: Transformation;

  @Prop({
    type: Array,
    required: true,
  })
  headers!: string[];

  @Prop({
    type: Array,
    required: false,
  })
  options!: DataObject[];

  @Prop({
    type: Array,
    required: true,
  })
  transformations!: Transformation[];

  @Prop({
    type: Boolean,
    required: false,
  })
  outputFieldExist = false;

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  dragOptions: any = {
    animation: 200,
    ghostClass: 'ghost',
  };
  drag = false;

  $refs!: Vue['$refs'] & {
    draggable: Vue;
  };

  get errorFields(): string[] {
    const errorFields: string[] = [];

    this.transformation.operations.map(operation => {
      operation.transformationFields.map(field => {
        if (!this.headers.includes(field)) {
          errorFields.push(field);
        }
      });
    });

    this.transformation.conditions.map(condition => {
      if (condition.field && !this.headers.includes(condition.field)) {
        errorFields.push(condition.field);
      }
    });

    return [...new Set(errorFields)];
  }

  @Emit('input')
  selectApplicationField(value: string): TransformationApplicationSelector {
    let data = {} as TransformationApplicationSelector;
    const option = this.options.filter(opt => opt.value === value);
    if (option) {
      data = {
        value,
        type: option[0].dataType as string,
        required: option[0].required as boolean,
        transformation: this.transformation,
      };
    }
    return data;
  }

  addOperation(): void {
    const newOperation: TransformationOperation = {
      transformationFields: [],
      outputField: '',
      operator: undefined,
      combinator: undefined,
    };
    this.transformation.operations.push(newOperation);
  }

  removeOperation(operationIndex: number): void {
    this.transformation.operations.splice(operationIndex, 1);
  }

  addCondition(): void {
    const newCondition: TransformationCondition = {
      field: '',
      operator: undefined,
      parameters: [],
    };
    this.transformation.conditions.push(newCondition);
  }

  removeCondition(conditionIndex: number): void {
    this.transformation.conditions.splice(conditionIndex, 1);
  }

  async validate(): Promise<boolean> {
    let valid = true;

    const validationArray = [
      this.$validator.validateAll(),
      ...this.$children.map(child => child.$validator.validateAll()),
    ];
    if (this.$refs.draggable) {
      validationArray.push(
        ...this.$refs.draggable.$children.map(child =>
          child.$validator.validateAll(),
        ),
      );
    }

    await Promise.all(validationArray).then(result => {
      if (result.includes(false)) {
        valid = false;
      }
    });

    return valid;
  }
}
