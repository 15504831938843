import { ReportCondition } from '../../../services/api/reports/report-condition.class';

const checkSimilarConditionTypes = (
  condition: ReportCondition,
  reportConditions: ReportCondition[],
): boolean => {
  const usedConditions = reportConditions.map(repCond => repCond.conditionType);
  return usedConditions.includes(condition.conditionType);
};

export default checkSimilarConditionTypes;
