import axios from '@/axios';
import { VerifiedEmail } from './verified-email.class';
import EntitiesService from '../entities/entities.service';

export default class VerifiedEmailsService extends EntitiesService<
VerifiedEmail
> {
  get endpoint(): string {
    const url = localStorage.getItem('local_base_url');
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${url}/verified-emails`;
  }
  async fetchByToken(token: string): Promise<VerifiedEmail> {
    const response = await axios.get<VerifiedEmail>(
      `${this.endpoint}/token/${token}`,
    );
    return response.data;
  }
  async sendVerificationMail(id: string): Promise<VerifiedEmail> {
    const response = await axios.post<VerifiedEmail>(
      `${this.endpoint}/send-verification-mail/${id}`,
    );
    return response.data;
  }
  async verify(token: string): Promise<VerifiedEmail> {
    const response = await axios.post<VerifiedEmail>(
      `${this.endpoint}/verify/${token}`,
    );
    return response.data;
  }
}
