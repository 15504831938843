




















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { ShipmentType } from '@/services/api/shipment-types/shipment-type.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

const shipmentTypesModule = namespace('shipmentTypes');
const milestonesModule = namespace('milestones');

@Component({
  components: {
    EntityList,
  },
})
export default class AllShipmentTypes extends Vue {
  @milestonesModule.Getter('GET')
  milestone!: any;

  @shipmentTypesModule.Getter('ALL_PAGINATED')
  shipmentTypes!: any;

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => Promise<void>;

  @shipmentTypesModule.Action('DELETE')
  deleteById!: (id: string) => Promise<ShipmentType>;

  filter: FetchAllParams = {};

  public columns: any[] = [
    {
      field: 'name',
      label: 'Shipment type name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  redirectToDetail(shipmentType: ShipmentType): void {
    this.$router.push({
      name: 'ShipmentType',
      params: { id: shipmentType._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateShipmentType',
    });
  }

  async redirectToDelete(shipmentType: ShipmentType): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + shipmentType.name + '?',
    );
    if (!result) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.deleteById(shipmentType._id!);
    await this.fetchAll();
  }

  async created(): Promise<void> {
    await this.fetchAll();
  }
}
