import Vue from 'vue';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { SortOrder } from '@/services/api/sort-order.enum';
import CloneOfficesService from '@/services/api/office-duplications/office-duplications.service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { OfficeDuplication } from '@/services/api/office-duplications/office-duplication.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { RootState } from './types';

const cloneOfficesService = new CloneOfficesService();
const namespaced = true;

interface StoreState {
  one: OfficeDuplication;
  all: PaginatedResponse<OfficeDuplication>;
  apiParams: FetchAllParams;
}

const initialOfficeDuplicationState: StoreState = {
  one: new OfficeDuplication(),
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<StoreState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};
const mutations: MutationTree<StoreState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: OfficeDuplication[]) {
    state.all.docs = payload;
  },
  SET_PAGINATED(state, payload: PaginatedResponse<OfficeDuplication>) {
    Vue.set(state, 'all', payload);
  },
  SET_ONE(state: any, payload: OfficeDuplication) {
    Vue.set(state, 'one', payload);
  },
};

const actions: ActionTree<StoreState, RootState> = {
  async FETCH_ALL_PAGINATED(context, payload: Partial<any>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const params: FetchAllParams = Object.assign({}, payload);
      const response = await cloneOfficesService.fetch(params);
      context.commit('SET_PAGINATED', response);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const duplication = await cloneOfficesService.fetchOne(id);
      context.commit('SET_ONE', duplication);
      return duplication;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async CREATE(context, payload: OfficeDuplication) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response = await cloneOfficesService.create(payload);
      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  // eslint-disable-next-line camelcase
  socket_updateDuplication(context, payload: OfficeDuplication) {
    if (!payload.office) {
      return;
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.office !== context.rootGetters['users/GET_WORKING_OFFICE']._id ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload._id !== context.getters.GET._id
    ) {
      return;
    }
    context.dispatch('FETCH_ONE', payload._id);
  },
};
export default {
  namespaced,
  state: initialOfficeDuplicationState,
  getters,
  mutations,
  actions,
};
