import { ReportType } from '@/types/reports/report-type.enum';
import { ReportCondition } from './report-condition.class';
import { ReportField } from './report-field.class';
import { MailDetails } from './mail-details.class';
import { Office } from '../offices/office.class';
import { ReportArchiveType } from './report-archive.type';

export class Report {
  _id?: string;
  type: ReportType = ReportType.Timely;
  name = '';
  sheetName = '';
  conditions: ReportCondition[] = [];
  fields: ReportField[] = [];
  mail: MailDetails = new MailDetails();
  office?: Office | string;
  milestoneType = '';
  active = false;
  archiveType: ReportArchiveType = 'Active';
  linkedReports?: Report[];
  sortParam?: {
    sortField: string;
    sortOrder: string;
  };
}
