export class CustomerParent {
  name = '';
  address = {
    street: '',
    city: '',
    country: '',
  };
  contact = {
    name: '',
    phone: {
      office: '',
      mobile: '',
    },
    email: '',
  };
  credit = {
    amount: '',
  };
  vatID = '';
}
