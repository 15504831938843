


















import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';

import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

const shipmentTypesModule = namespace('shipmentTypes');

@Component({
  components: {
    Multiselect,
  },
})
export default class ShipmentTypeSelect extends Vue {
  @Model('change', {
    type: String,
  })
  value!: ShipmentTypeResponse[] | ShipmentTypeResponse | string;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: 'name',
  })
  mapOn!: string;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentTypeResponse[];

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchShipmentTypes!: (params?: FetchAllParams) => Promise<void>;

  get shipmentTypeValue(): ShipmentTypeResponse {
    return this.shipmentTypes.find(
      type =>
        type[`${this.mapOn}` as keyof ShipmentTypeResponse] === this.value,
    ) as ShipmentTypeResponse;
  }

  set shipmentTypeValue(shipmentType: ShipmentTypeResponse) {
    this.onChange(shipmentType);
  }

  @Emit('change')
  onChange(selectedValue: ShipmentTypeResponse): ShipmentTypeResponse {
    return selectedValue;
  }

  async created(): Promise<void> {
    await this.fetchShipmentTypes();
    this.shipmentTypes.push({
      name: 'ALL',
      requiredMilestones: [],
      _id: '',
    } as ShipmentTypeResponse);
  }
}
