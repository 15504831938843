
































































































































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import * as moment from 'moment-timezone';
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appShipmentArchiveButton from '../components/ShipmentArchiveButton.vue';
import appShipmentCheckbox from '../components/ShipmentCheckbox.vue';
import appShipmentMilestone from '../components/ShipmentMilestone.vue';
import appShipmentMilestoneCheckbox from '../components/ShipmentMilestoneCheckbox.vue';
import appShipmentCustomLink from '../components/ShipmentCustomLink.vue';
import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { ShipmentMilestoneStatus } from '../services/api/shipments/shipment-milestone-status.enum';
import { DateFormat } from '../services/api/users/date-format.enum';
import { RuleResultStatus } from '../types/rule-result-status.enum';
import { ShipmentMilestoneResponse } from '../services/api/shipments/shipment-milestone-response.interface';
import { UIField } from '../types/ui/ui-field.class';
import ShipmentMilestonePlaceholder from '../components/ShipmentMilestonePlaceholder.vue';
import ShipmentMilestoneCheckboxPlaceholder from '../components/ShipmentMilestoneCheckboxPlaceholder.vue';
import { ShipmentMilestoneStatusClass } from '../types/shipments/shipment-milestone-status-class.interface';
import { Milestone } from '../services/api/milestones/milestone.class';
import { Office } from '@/services/api/offices/office.class';
import ShipmentItemChecksMixin from '@/mixins/shipment-item-checks.mixin';
import { DeepPartial } from '@/types/deep-partial.type';
import { ShipmentRequest } from '@/services/api/shipments/shipment-request.interface';
import ShipmentItemFieldFunctionsMixin from '@/mixins/shipment-item-field-functions.mixin';

const shipmentsModule = namespace('shipments');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');

@Component({
  components: {
    appShipmentArchiveButton,
    appShipmentCheckbox,
    appShipmentMilestone,
    appShipmentMilestoneCheckbox,
    appShipmentCustomLink,
    ShipmentMilestonePlaceholder,
    ShipmentMilestoneCheckboxPlaceholder,
  },
})
export default class ShipmentItemScrollColumn extends Mixins(ShipmentItemChecksMixin, ShipmentItemFieldFunctionsMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  shipment!: ShipmentResponse;

  @Prop({
    type: Object,
    required: true,
  })
  field!: UIField;

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  })
  isActive!: boolean;

  @shipmentsModule.Getter('IS_LOADING')
  isLoadingDetail!: (key: string) => boolean;

  @shipmentsModule.Action('SET_FIELD')
  setShipmentField!: (obj: {
    id: string;
    data: DeepPartial<ShipmentRequest>;
  }) => void;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @usersModule.Getter('GET_TIMEZONE')
  userTimezone!: string;

  @usersModule.Getter('GET_DATE_FORMAT')
  userDateFormat!: DateFormat;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @usersModule.Getter('GET_IS_DISPLAY')
  isDisplay!: boolean;

  @Emit()
  shipmentMilestoneClicked(
    milestoneId: string,
    dateToggleClick: boolean,
  ): { milestoneId: string; milestoneCode: string; dateToggleClick: boolean } {
    const milestoneCode = (this.field.milestone as Milestone).code
      ? (this.field.milestone as Milestone).code
      : '';
    return {
      milestoneId,
      milestoneCode,
      dateToggleClick,
    };
  }

  get isLoading(): boolean {
    let key = `${this.shipment._id}.${this.field.field}`;
    if (this.field.fieldType === 'ms' && this.field.milestone) {
      key = `${this.shipment._id}.${
        (this.field.milestone as Milestone)._id as string
      }`;
    } else if (this.field.fieldType === 'ms') {
      key = `${this.shipment._id}.${this.field.milestone as string}`;
    } else if (this.field.fieldType === 'cb') {
      key = `${this.shipment._id}.${this.field.field}`;
    }
    return this.isLoadingDetail(key);
  }

  get priorityStar(): string[] {
    if (this.shipment.priority === 1) {
      return ['fas', 'star-half-alt'];
    } else if (this.shipment.priority === 2) {
      return ['fas', 'star'];
    }

    return ['far', 'star'];
  }

  emitActions(field: UIField, shipment: ShipmentResponse): void {
    if (field.openShipment) {
      this.$emit('openShipment', shipment);
    }
    if (field.openCustomer) {
      this.$emit('openCustomer', shipment);
    }
  }

  prepClasses(field: UIField): string {
    let classString = '';
    if (field.bold) {
      classString += 'font-weight-bold ';
    }
    if (field.truncate) {
      classString += 'text-truncate ';
    }
    if (this.isActive) {
      classString += 'active ';
    }
    return classString;
  }

  formattedDate(shipmentMilestone: ShipmentMilestoneResponse): string {
    if (
      !shipmentMilestone.value ||
      shipmentMilestone.value === null ||
      shipmentMilestone.value === ''
    ) {
      return '';
    }

    let format = 'DD/MM';
    if (this.userDateFormat !== DateFormat.DD_MM_YY) {
      format = 'MM/DD';
    }

    return moment.tz(shipmentMilestone.value, this.userTimezone).format(format);
  }

  cellClasses(
    shipmentMilestone?: ShipmentMilestoneResponse,
  ): ShipmentMilestoneStatusClass {
    const classList: ShipmentMilestoneStatusClass = {
      new: false,
      changed: false,
      registered: false,
      conflicted: false,
      confirmed: false,
      priority1: false,
      priority2: false,
      ruleWarning: false,
      ruleCritical: false,
      display: this.isDisplay,
      ['text-right']: this.isDisplay,
      ['text-center']: this.field.fieldType === 'cb',
    };

    if (!this.isDisplay || !shipmentMilestone) {
      return classList;
    }

    switch (shipmentMilestone.status) {
    case ShipmentMilestoneStatus.Changed:
      classList.changed = true;
      break;
    case ShipmentMilestoneStatus.Registered:
      classList.registered = true;
      break;
    case ShipmentMilestoneStatus.Conflicted:
      classList.conflicted = true;
      break;
    case ShipmentMilestoneStatus.Confirmed:
      if (!shipmentMilestone.value || shipmentMilestone.value === null) {
        classList.new = true;
      } else {
        classList.confirmed = true;
      }
      break;
    }

    switch (shipmentMilestone.priority) {
    case 1:
      classList.priority1 = true;
      break;
    case 2:
      classList.priority2 = true;
      break;
    }

    if (!shipmentMilestone.priority) {
      switch (shipmentMilestone.ruleResult.status) {
      case RuleResultStatus.Warning:
        classList.ruleWarning = true;
        break;
      case RuleResultStatus.Critical:
        classList.ruleCritical = true;
        break;
      }
    }

    return classList;
  }

  copyToClipboard(value: string): void {
    this.$copyText(value);
    this.addToast({
      title: 'Content copied!',
      message: `'${value}' has been copied to clipboard`,
      type: ToasterTypes.SUCCESS,
    });
  }

  updateField(val: string): void {
    const data: { [key: string]: any } = {};
    data[this.field.field] = val;
    this.setShipmentField({
      id: this.shipment._id,
      data,
    });
  }

  printCustomer(shipment: ShipmentResponse): string {
    if (shipment.customer) {
      return shipment.customer.parent.name;
    }
    return shipment.custom.customer;
  }

}
