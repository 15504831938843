import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import StatisticsService from '@/services/api/statistics/statistics.service';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from './types';

const statisticsService = new StatisticsService();
const namespaced = true;

interface StoreState {
  activeShipments: ShipmentResponse[];
}

const initialActiveShipmentState: StoreState = {
  activeShipments: [],
};

const getters = {
  ACTIVE_SHIPMENTS: (state: StoreState): ShipmentResponse[] =>
    state.activeShipments,
};
const mutations: MutationTree<StoreState> = {
  SET_ACTIVE_SHIPMENTS(state, payload: ShipmentResponse[]) {
    state.activeShipments = payload;
  },
};

const actions: ActionTree<StoreState, RootState> = {
  async FETCH_ACTIVE_SHIPMENTS(context, payload: Partial<any>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const params = Object.assign({}, payload);
      const response = await statisticsService.fetchActiveShipments(params);
      context.commit('SET_ACTIVE_SHIPMENTS', response);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};
export default {
  namespaced,
  state: initialActiveShipmentState,
  getters,
  mutations,
  actions,
};
