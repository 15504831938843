






































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone/moment-timezone';
import { OfficeDuplication } from '../services/api/office-duplications/office-duplication.class';
import EntityList from '../components/EntityList.vue';
import { EntityListColumn } from '../types/entity-list-column.interface';
import { User } from '../services/api/users/user.class';
import { OfficeDuplicationStatus } from '../services/api/office-duplications/office-duplication-status.class';

const officeDuplicationsModule = namespace('officeDuplications');
const usersModule = namespace('users');

@Component({ components: { EntityList } })
export default class OfficeDuplicationDetail extends Vue {
  @officeDuplicationsModule.Getter('GET')
  officeDuplication!: OfficeDuplication;

  @officeDuplicationsModule.Action('FETCH_ONE')
  fetchOfficeDuplicationById!: (id: string) => Promise<OfficeDuplication>;

  @usersModule.Action('FETCH_ONE')
  fetchCurrentUser!: (id: string) => Promise<User>;

  duplicationStatuses: OfficeDuplicationStatus[] = [];

  generalStatus = '';

  public columns: Array<EntityListColumn<OfficeDuplicationStatus>> = [
    { field: 'entity', label: 'Entity', sortable: true },
    { field: 'total', label: 'Imported', sortable: true },
    { field: 'completed', label: 'Done', sortable: true },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.statusLabel,
      label: 'Status',
      sortable: true,
      isHTML: true,
    },
  ];

  get isEditing(): string {
    return this.$route.params.id;
  }

  @Watch('officeDuplication')
  onOfficeDuplicationChanged(newValue: OfficeDuplication): void {
    this.duplicationStatuses = [];
    for (const [key, value] of Object.entries(newValue.statuses || {})) {
      this.duplicationStatuses.push({ ...value, entity: key });
    }
    this.getCurrentUser();
  }

  formatDate(date: string): string {
    return moment(date).format('YYYY/MM/DD - HH:mm');
  }

  generalStatusLabel(duplicationStatus: OfficeDuplicationStatus): string {
    const statusClass = this.$t(
      `status.${duplicationStatus.status}.class`,
    ) as string;
    const statusText = this.$t(`status.${duplicationStatus.status}`) as string;
    return `<p class="badge badge-${statusClass} badge-pill ml-1 mt-2">${statusText}</p>`;
  }

  statusLabel(item: OfficeDuplicationStatus): string {
    const statusClass = this.$t(`status.${item.status}.class`) as string;
    const statusText = this.$t(`status.${item.status}`) as string;
    return `<p class="badge badge-${statusClass} badge-pill ml-1 mt-2">${statusText}</p>`;
  }

  async getCurrentUser(): Promise<void> {
    const currentUser = await this.fetchCurrentUser(
      this.officeDuplication.user || '',
    );
    if (currentUser) {
      this.officeDuplication.user = currentUser.username;
    }
  }

  async created(): Promise<void> {
    await this.fetchOfficeDuplicationById(this.isEditing);
    this.getCurrentUser();
  }
}
