import axios from '@/axios';
import { ActiveShipments } from './active-shipments.class';
import { AxiosRequestConfig } from 'axios';
import { FilterParams } from '@/types/filter-params.interface';
import { FetchAllParams } from '../fetch-all-params.interface';

export default class StatisticsService {
  async fetchActiveShipments(
    params?: FetchAllParams,
  ): Promise<ActiveShipments> {
    const config: AxiosRequestConfig = {};

    if (params) {
      const { filters, ...rest } = params;
      const filterParams: FilterParams = {};

      config.params = {
        ...rest,
        ...filterParams,
      };
    }

    const response = await axios.get<ActiveShipments>('milestones', config);
    return response.data;
  }
}
