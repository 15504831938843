




































































import { Vue, Component, Prop, Model } from 'vue-property-decorator';
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';
import flatpickrTypes from 'flatpickr';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

import { getFlatPickrConfig } from '../utils/flatpickr';
import appShipmentMilestone from '../components/ShipmentMilestone.vue';
import { ShipmentMilestoneDetailInput } from '../types/shipments/shipment-milestone-detail-input.interface';

@Component({
  components: {
    appShipmentMilestone,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
  },
})
export default class ShipmentMilestoneDetail extends Vue {
  @Model('change', {
    type: Object,
  })
  shipmentMilestoneInput!: ShipmentMilestoneDetailInput;

  @Prop({
    type: String,
  })
  userTimezone!: string;

  $refs!: Vue['$refs'] & {
    flatPickr: any;
  };

  unchangedInput: ShipmentMilestoneDetailInput = {
    ...this.shipmentMilestoneInput,
  };

  priorityOptions = [
    { text: 0, value: 0 },
    { text: 1, value: 1 },
    { text: 2, value: 2 },
  ];

  public unFocus() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    this.changeValue([this.$refs.flatPickr.getElem().childNodes[0].value]);
  }

  get canUpdateShipmentPriority() {
    return this.$can('update', 'Shipment', 'priority');
  }

  get flatPickrConfig() {
    return {
      ...getFlatPickrConfig(),
      inline: true,
      plugins: [
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ShortcutButtonsPlugin({
          button: [
            {
              label: 'NOW',
            },
            {
              label: 'CLEAR',
            },
          ],
          onClick: (index: number, fp: flatpickrTypes.Instance) => {
            switch (index) {
            case 0:
              const date = new Date(Date.now());
              fp.setDate(date, true);
              break;
            case 1:
              fp.clear(true);
              break;
            }
          },
        }),
      ],
    };
  }

  get timezonedValue(): string {
    const date: moment.Moment = moment.tz(
      this.shipmentMilestoneInput.value,
      this.userTimezone,
    );

    if (!date.isValid()) {
      return '';
    }

    return date.format('YYYY-MM-DDTHH:mm:ss');
  }

  get notificationWarning() {
    if (
      this.unchangedInput.sendNotification &&
      !this.shipmentMilestoneInput.sendNotification
    ) {
      return 'You have disabled the notification for this update';
    }
    return 'A notification will be sent';
  }

  get notificationVariant() {
    if (
      this.unchangedInput.sendNotification &&
      !this.shipmentMilestoneInput.sendNotification
    ) {
      return 'warning';
    }
    return 'primary';
  }

  changeValue(date: Date[]) {
    if (date.length) {
      const timezonedValue = moment(date[0])
        .tz(this.userTimezone, true)
        .toISOString();

      this.shipmentMilestoneInput.value = timezonedValue;
    } else {
      this.shipmentMilestoneInput.value = null;
    }
  }
}
