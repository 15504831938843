import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { SortOrder } from '@/services/api/sort-order.enum';
import OfficesService from '@/services/api/offices/offices.service';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Office } from '@/services/api/offices/office.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { RootState } from './types';

const officesService = new OfficesService();
const namespaced = true;

interface StoreState {
  one: Office;
  allGlobal: Office[];
  allOptions: Option[];
  all: PaginatedResponse<Office>;
  apiParams: FetchAllParams;
}

const initialOfficeState: StoreState = {
  one: new Office(),
  allGlobal: [],
  all: new PaginatedResponseClass(),
  allOptions: [],
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<StoreState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_GLOBAL: state => state.allGlobal,
  ALL_OPTIONS: state => state.allOptions,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};
const mutations: MutationTree<StoreState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: Office[]) {
    state.all.docs = payload;
  },
  SET_ALL_GLOBAL(state, payload: Office[]) {
    state.allGlobal = payload;
  },
  SET_ALL_OPTIONS(state: StoreState, payload: PaginatedResponse<Office>) {
    const allNewOptions = payload.docs.map(
      (office: Office) =>
        ({
          value: office._id,
          text: office.name,
        } as Option),
    );

    state.allOptions = allNewOptions;
  },
  SET_PAGINATED(state, payload: PaginatedResponse<Office>) {
    state.all = payload;
  },
  SET_ONE(state, payload: Office) {
    state.one = payload;
  },
};

const actions: ActionTree<StoreState, RootState> = {
  async FETCH_ALL(context, payload: Partial<any>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const params = Object.assign({}, payload);
      const response = await officesService.fetchAll(params);
      context.commit('SET_ALL', response);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL_OPTIONS(context, payload: Partial<any>) {
    const params = Object.assign({}, payload);
    const response = await officesService.fetchAllOptions(params);
    context.commit('SET_ALL_OPTIONS', response);
  },
  async FETCH_ALL_GLOBAL(context, payload: Partial<any>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const params = Object.assign({}, payload);
      const response = await officesService.fetchAllGlobal(params);
      context.commit('SET_ALL_GLOBAL', response);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_PAGINATED(context, payload: Partial<any>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const params = Object.assign({}, payload);
      const response = await officesService.fetch(params);
      context.commit('SET_PAGINATED', response);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const office = await officesService.fetchOne(id);
      context.commit('SET_ONE', office);
      return office;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async CREATE(context, payload: any) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response = await officesService.create(payload);
      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; office: any }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response = await officesService.update(payload.id, payload.office);
      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response = await officesService.delete(payload);
      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};
export default {
  namespaced,
  state: initialOfficeState,
  getters,
  mutations,
  actions,
};
