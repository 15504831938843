



































import Vue from 'vue';
import { Prop, Component, Emit } from 'vue-property-decorator';
import { NavTabItem } from '../types/nav-tab-item.interface';

@Component({})
export default class NavTabs extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  items!: NavTabItem[];

  @Prop({
    type: String,
  })
  ModuleName!: string;

  saveButton = true;

  @Emit()
  save(): boolean {
    return true;
  }

  mounted(): void {
    this.fetchSaveButtonEnabled();
  }

  updated(): void {
    this.fetchSaveButtonEnabled();
  }

  fetchSaveButtonEnabled(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    this.saveButton = this.$router.currentRoute.meta.savable;
  }
}
