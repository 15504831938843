import { AxiosRequestConfig } from 'axios';
import { isBlank } from '@/utils/is-blank.util';

import axios from '@/axios';
import { PaginatedResponse } from '../paginated-response';
import { FetchAllParams } from '../fetch-all-params.interface';
import { FilterParams } from '@/types/filter-params.interface';
import { Filters } from '@/types/filters.interface';

export default class EntitiesService<T> {
  get endpoint(): string {
    return `${localStorage.getItem('local_base_url') || ''}/entities`;
  }
  static generateRequestConfig(params?: FetchAllParams): AxiosRequestConfig {
    const config: AxiosRequestConfig = {};
    if (params) {
      const { filters, ...rest } = params;

      const filterParams = EntitiesService.buildFilterParams(filters);

      config.params = {
        ...rest,
        ...filterParams,
      };
    }

    return config;
  }

  static buildFilterParams(filters?: Filters): FilterParams {
    const filterParams: FilterParams = {};

    if (filters) {
      for (const [field, filter] of Object.entries(filters)) {
        if (!isBlank(filter.value)) {
          if (Array.isArray(filter.value)) {
            filterParams[field] = filter.value.map(
              (valueItem: string) => `${filter.operation}${valueItem}`,
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            filterParams[field] = `${filter.operation}${filter.value}`;
          }
        }
      }
    }

    return filterParams;
  }
  async create(entity: T): Promise<T> {
    const response = await axios.post<T>(this.endpoint, entity);
    return response.data;
  }

  async fetch(
    params?: FetchAllParams,
    endpoint?: string,
  ): Promise<PaginatedResponse<T>> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );

    const response = await axios.get<PaginatedResponse<T>>(
      endpoint || this.endpoint,
      config,
    );

    return response.data;
  }

  async fetchAllOptions(
    params?: FetchAllParams,
    endpoint?: string,
  ): Promise<PaginatedResponse<T>> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );

    const response = await axios.get<PaginatedResponse<T>>(
      `${endpoint || this.endpoint}/options`,
      config,
    );

    return response.data;
  }

  async fetchAll(params?: FetchAllParams, endpoint?: string): Promise<T[]> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );

    const response = await axios.get<T[]>(
      `${endpoint || this.endpoint}/all`,
      config,
    );

    return response.data;
  }

  async fetchOne(id: string, endpoint?: string): Promise<T> {
    const response = await axios.get<T>(`${endpoint || this.endpoint}/${id}`);
    return response.data;
  }

  async update(id: string, entity: T): Promise<T> {
    const response = await axios.put<T>(`${this.endpoint}/${id}`, entity);
    return response.data;
  }

  async delete(id: string): Promise<T> {
    const response = await axios.delete(`${this.endpoint}/${id}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  }
}
