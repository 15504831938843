import { PartialShipmentResponse } from '@/services/api/shipments/partial-shipment-response';

export class PartialShipmentForm {
  public amount = 0;
  public date: string = new Date().toISOString();

  constructor(partialShipment?: PartialShipmentResponse) {
    if (partialShipment) {
      this.amount = partialShipment.amount;
      this.date = partialShipment.date;
    }
  }
}
