



















































































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import vuePassword from 'vue-password';
import { TokenUserInfo } from '@/types/token-user-info.interface';
import { ResetPassword } from '../services/api/auth/reset-password.interface';
import { LoginRequest } from '@/services/api/auth/login-request.interface';

const usersModule = namespace('users');
const appModule = namespace('application');
const tokenUserInfoModule = namespace('tokenUserInfo');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    vuePassword,
  },
})
export default class PasswordReset extends Vue {
  @appModule.Getter('GET_LOADING')
  loading!: boolean;

  @tokenUserInfoModule.Getter('GET_ONE')
  userInfo!: TokenUserInfo;

  @tokenUserInfoModule.Action('FETCH_ONE')
  fetchUserInfo!: (token: string) => Promise<TokenUserInfo>;

  @usersModule.Action('RESET_PASSWORD')
  resetPassword!: ({ token, password }: ResetPassword) => Promise<boolean>;

  @usersModule.Action('LOGIN')
  userLogin!: (login: LoginRequest) => void;

  STRENGTHTIPS = [
    'Compose a unique password',
    'Choose a password you do not use on other sites',
    'Names, birthdates are usually not secure',
  ];

  form = {
    email: '',
    password: '',
    confirmPassword: '',
  };

  strengthMessages = [
    'Very weak password',
    'Weak password',
    'Medium password',
    'Strong password',
    'Very strong password',
  ];

  extraAvoidingdictionary = [
    'Mileviewer',
    'star',
    'prioritize',
    'communicate',
    'perform',
  ];

  passwordScore = 0;

  strengthMessage = '';

  strengthTips = this.STRENGTHTIPS;

  tokenUser: TokenUserInfo = {
    email: '',
  };

  formFields!: FieldFlagsBag;

  formErrors!: ErrorBag;

  resetError = false;

  resethandeld = false;

  hintsVisible = false;

  async created(): Promise<void> {
    await this.fetchUserInfo(this.$route.params.token);
  }

  async updateStrength(password: string): Promise<void> {
    const passwordcheck = await import('zxcvbn').then(({ default: zxcvbn }) => {
      const passwordCheck = zxcvbn(password, this.extraAvoidingdictionary);

      return passwordCheck;
    });
    this.passwordScore = passwordcheck.score;
    this.passwordScore = passwordcheck.score;
    this.strengthMessage = this.strengthMessages[passwordcheck.score];
    this.strengthTips = this.STRENGTHTIPS;
    this.strengthTips = this.strengthTips.concat(
      passwordcheck.feedback.suggestions,
    );
  }

  toggleShowHints(): void {
    this.hintsVisible = !this.hintsVisible;
  }

  doResetPassword(): void {
    this.$validator.validateAll().then(async (validatedInput: boolean) => {
      if (validatedInput) {
        this.resethandeld = await this.resetPassword({
          token: this.$route.params.token,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,
        });
        this.userLogin({
          username: this.userInfo.email,
          password: this.form.password,
        });

        this.$validator.reset();
      } else {
        this.hintsVisible = true;
      }
    });
  }
}
