import { RuleType } from './rule-type.enum';
import { RuleResultStatus } from '../../../types/rule-result-status.enum';
import { RuleParams } from './rule-params.interface';

export class Rule {
  _id?: string;
  name = '';
  type: RuleType = RuleType.ChronologicOperationalMilestones;
  resultStatus: RuleResultStatus = 1;
  params?: RuleParams;
  active = true;
  office?: string;
}
