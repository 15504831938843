import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';

const namespaced = true;

interface StoreState {
  loading: boolean;
}

const globalState: StoreState = {
  loading: false,
};

const getters: GetterTree<StoreState, RootState> = {
  GET_LOADING: state => state.loading,
};
const mutations: MutationTree<StoreState> = {
  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },
};
const actions: ActionTree<StoreState, RootState> = {
  SET_LOADING(context) {
    context.commit('SET_LOADING', true);
  },
  UNSET_LOADING(context) {
    context.commit('SET_LOADING', false);
  },
};
export default {
  namespaced,
  state: globalState,
  getters,
  mutations,
  actions,
};
