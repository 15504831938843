




















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { EntityListColumn } from '@/types/entity-list-column.interface';

const milestoneTypesModule = namespace('milestoneTypes');

@Component({
  components: {
    EntityList,
  },
})
export default class AllMilestoneTypes extends Vue {
  @milestoneTypesModule.Getter('ALL_PAGINATED')
  milestoneTypes!: PaginatedResponse<MilestoneType>;

  @milestoneTypesModule.Action('FETCH_ALL_PAGINATED')
  fetchAllPaginated!: (
    filter?: FetchAllParams,
  ) => Promise<PaginatedResponse<MilestoneType>>;

  @milestoneTypesModule.Action('DELETE')
  deleteById!: (id: string) => Promise<MilestoneType>;

  filter: FetchAllParams = {};

  columns: [EntityListColumn<MilestoneType>] = [
    {
      field: 'name',
      label: 'Milestone type name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  redirectToDetail(milestoneType: MilestoneType): void {
    this.$router.push({
      name: 'MilestoneType',
      params: { id: milestoneType._id },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateMilestoneType',
    });
  }

  async redirectToDelete(milestoneType: MilestoneType): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + milestoneType.name + '?',
    );
    if (!result || !milestoneType._id) {
      return;
    }
    await this.deleteById(milestoneType._id);
    await this.fetchAllPaginated();
  }

  async created(): Promise<void> {
    await this.fetchAllPaginated();
  }
}
