import { ActionTree } from 'vuex';
import { RouteConfig } from 'vue-router';

import { UIState } from './types';
import { RootState } from '../types';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import router, { resetRouter } from '@/router';
import Shipments from '@/views/Shipments.vue';
import ShipmentsParent from '@/views/ShipmentsParent.vue';
import { Ui } from '@/services/api/ui/ui.class';
import UiService from '@/services/api/ui/ui.service';
import { DragEventOutput } from '@/types/drag-event-output.interface';
import { changeItemsSequence } from '@/utils/sequence.util';
import { AxiosError } from 'axios';
import { ToasterTypes } from '../toasters/types';

const uisService = new UiService();

export const actions: ActionTree<UIState, RootState> = {
  async CREATE(context, payload: Ui) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const ui = await uisService.create(payload);
      context.commit('SET_ONE', ui);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Screen created successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return ui;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async FETCH_UNPAGINATED(context, payload?: FetchAllParams) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response: Ui[] = await uisService.fetchAll(payload);
      context.commit('SET_UNPAGINATED', response);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const routes: RouteConfig[] = context.getters.ALL.map((ui: Ui) => {
        const routeConfig: RouteConfig = {
          path: ui.path,
          name: ui.name,
          component: Shipments,
          props: {
            ui,
          },
          meta: {
            requiresAuth: true,
          },
        };

        return routeConfig;
      });

      const defaultFound = routes.find(
        (route: RouteConfig) => route.path === '',
      );

      if (!defaultFound && routes.length > 0) {
        routes.unshift({ path: '', redirect: routes[0].path });
      }

      const shipmentsRoutes: RouteConfig[] = [
        {
          path: '/shipments',
          component: ShipmentsParent,
          meta: {
            requiresAuth: true,
          },
          children: routes,
        },
      ];

      resetRouter();
      router.addRoutes(shipmentsRoutes);
      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async FETCH_ALL(context, payload?: FetchAllParams) {
    try {
      const response: PaginatedResponse<Ui> = await uisService.fetch(payload);
      context.commit('SET_API_PARAMS', payload);
      context.commit('UIS_LOADED', response);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      const routes: RouteConfig[] = context.getters.UIS.map((ui: Ui) => {
        const routeConfig: RouteConfig = {
          path: ui.path,
          name: ui.name,
          component: Shipments,
          props: {
            ui,
          },
          meta: {
            requiresAuth: true,
          },
        };

        return routeConfig;
      });

      const defaultFound = routes.find(
        (route: RouteConfig) => route.path === '',
      );

      if (!defaultFound) {
        routes.unshift({ path: '', redirect: routes[0].path });
      }

      const shipmentsRoutes: RouteConfig[] = [
        {
          path: '/shipments',
          component: ShipmentsParent,
          meta: {
            requiresAuth: true,
          },
          children: routes,
        },
      ];

      resetRouter();
      router.addRoutes(shipmentsRoutes);
    } catch (error) {
      context.commit('UIS_ERROR');
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const ui = await uisService.fetchOne(id);
      context.commit('SET_ONE', ui);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; ui: Ui }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const ui = await uisService.update(payload.id, payload.ui);
      context.commit('SET_ONE', ui);
      context.dispatch(
        'toasters/ADD_TOASTER',
        { title: 'Save successfully', message: '', type: ToasterTypes.SUCCESS },
        { root: true },
      );
      return ui;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  UPDATE_LIST(context, dragEvent: DragEventOutput) {
    const uis = changeItemsSequence(context.state.all, dragEvent);
    context.commit('ALL_UIS_LOADED', uis);
  },
  async SAVE_LIST(context, ui: Ui[]) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      await uisService.bulk(ui);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const response = await uisService.delete(payload);
      const uiIndex = context.state.uis.docs.findIndex(
        (ui: Ui) => ui._id === payload,
      );
      const updatedUis = changeItemsSequence(
        context.state.uis.docs,
        { oldIndex: uiIndex, newIndex: uiIndex },
        true,
      );

      const paginated = {
        ...context.state.uis,
        docs: updatedUis,
      };
      context.commit('UIS_LOADED', paginated);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Screen deleted successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );

      return response;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DUPLICATE(
    context,
    payload: { ui: Ui },
  ): Promise<Ui | AxiosError<Ui> | undefined> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const ui = await uisService.duplicateUi(payload.ui);
      context.commit('SET_ONE', Ui);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Screen copied successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return ui;
    } catch (error) {
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Copying screen failed',
          message: (error as AxiosError).message,
          type: ToasterTypes.ERROR,
        },
        { root: true },
      );
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};
