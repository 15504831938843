// mixin.js
import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ShipmentItemChecksMixin extends Vue {
  isPartial(shipment: ShipmentResponse): boolean {
    return shipment.partialShipments && shipment.partialShipments.length
      ? true
      : false;
  }

  isCustomerLinked(shipment: ShipmentResponse): boolean {
    return !Boolean(shipment.customer);
  }
}
