import { Company } from '../companies/company.class';
import { DateFormat } from '../users/date-format.enum';

export class Office {
  public _id?: string;
  public name = '';
  public company: Company = new Company();
  public dateFormat: DateFormat = DateFormat.DD_MM_YY;
  public timezone = 'Europe/Amsterdam';
  public region = 'Europe/Amsterdam';
  public linkContainerNumber = false;
  public aggregationFields: string[] = [];
  public inboundEmailAddress = '';
  public allowCsvImport?: boolean;
}
