



























































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import EntityList from '../components/EntityList.vue';
import appTransformationDetail from '../components/TransformationDetail.vue';
import appTransformationRow from '../components/TransformationRow.vue';
import { Office } from '../services/api/offices/office.class';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { DragEventOutput } from '../types/drag-event-output.interface';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { FilterOperation } from '../types/filter-operation.enum';
import { SavableComponent } from '@/types/savable-component.class';
import { Transformation } from '@/services/api/transformations/transformation.class';
import api from '@/services/api/';
import { TransformationOperation } from '../services/api/transformations/transformation-operation.interface';
import { TransformationOperator } from '../services/api/transformations/transformation-operator.enum';
import { TransformationCondition } from '../services/api/transformations/transformation-condition.interface';
import { FileEvent } from '@/types/file-event.interface';
// eslint-disable-next-line max-len
import { TransformationApplicationSelector } from '@/types/transformations/transformation-application-selector.interface';
import { DataObject } from '@/services/api/data-object.interface';
import { Translation } from '@/services/api/translations/translation.class';

const officesModule = namespace('offices');
const transformationsModule = namespace('transformations');
const shipmentImportsModule = namespace('shipmentImports');
const utilityModule = namespace('utility');
const translationsModule = namespace('translations');

@Component({
  components: {
    EntityList,
    appTransformationDetail,
    appTransformationRow,
  },
})
export default class OfficeImports extends SavableComponent {
  @officesModule.Getter('GET')
  office!: Office;

  @shipmentImportsModule.Getter('GET_HEADERS')
  headers!: string[];

  @shipmentImportsModule.Action('GET_FILE_HEADERS')
  fetchFileHeaders!: (file: string) => Promise<string[]>;

  @transformationsModule.Action('FETCH_ALL')
  fetchTransformations!: (
    filter?: FetchAllParams,
  ) => Promise<PaginatedResponseClass<Transformation>>;

  @transformationsModule.Action('UPDATE_LIST')
  updateListSequence!: (list: any) => void;

  @transformationsModule.Action('UPDATE')
  updateTransformation!: (payload: {
    id: string;
    transformation: Transformation;
  }) => Promise<Transformation>;

  @transformationsModule.Action('BULK_CREATE')
  bulkcreateTransformations!: (list: Transformation[]) => Promise<void>;

  @transformationsModule.Action('BULK_UPDATE')
  bulkUpdateTransformations!: (list: Transformation[]) => Promise<void>;

  @transformationsModule.Action('CREATE')
  createTransformation!: (
    transformation: Transformation,
  ) => Promise<Transformation>;

  @transformationsModule.Action('DELETE')
  deleteById!: (transformationId: string) => Promise<Transformation>;

  @transformationsModule.Action('SAVE_LIST')
  saveList!: () => Promise<void>;

  @translationsModule.Action('FETCH_ALL')
  fetchTranslations!: () => Promise<Translation[]>;

  @translationsModule.Getter('ALL')
  translations!: Translation[];

  @transformationsModule.Getter('ALL')
  transformations!: PaginatedResponseClass<Transformation>;

  @transformationsModule.Getter('FILTER_LIST')
  transformationsFilterList!: PaginatedResponseClass<Transformation>;

  @utilityModule.Getter('GET_FIELD_SELECT_OPTIONS')
  fieldSelectOptions!: DataObject[];

  @utilityModule.Action('FETCH_FIELD_SELECT_OPTIONS')
  fetchFieldSelectOptions!: (options: {
    includeAttachments: boolean;
    includeMilestones: boolean;
  }) => Promise<void>;

  selectedTransformation: Transformation = new Transformation();
  localTransformations: Transformation[] = [];
  transformationModalTitle = '';
  key = '';
  showFilters = false;
  counter = 0;
  initialUploadExecuted = true;

  $refs!: Vue['$refs'] & {
    transformationModal: BModal;
    transformationDetail: appTransformationDetail;
  };

  @Watch('transformationsList', { immediate: true })
  onListChange(): Transformation[] {
    this.localTransformations = this.transformationsList.docs;
    return this.localTransformations;
  }

  @Watch('fieldSelectOptions', { immediate: true })
  onOptionsChange(): void {
    const values: string[] = [];
    this.localTransformations.map(transformation =>
      transformation.operations.map(operation =>
        values.push(operation.outputField),
      ),
    );
    this.fieldSelectOptions.map(option => {
      if (values.includes(option.value)) {
        option.$isDisabled = true;
      } else {
        option.$isDisabled = false;
      }
    });
  }

  get transformationsList(): PaginatedResponseClass<Transformation> {
    return { ...this.transformations };
  }

  get officeId(): string {
    return this.$route.params.id;
  }

  toggleFilters(): boolean {
    return (this.showFilters = !this.showFilters);
  }

  formatTransformationWarning(item: Transformation): string {
    let errorFields: string[] = [];

    item.operations.map(operation => {
      operation.transformationFields.map(field => {
        if (!this.headers.includes(field)) {
          errorFields.push(field);
        }
      });
    });

    item.conditions.map((condition: TransformationCondition) => {
      if (condition.field && !this.headers.includes(condition.field)) {
        errorFields.push(condition.field);
      }
    });

    errorFields = [...new Set(errorFields)];

    if (errorFields.length) {
      return `<span id="warning-${item.sequence}" class="badge badge-danger badge-pill">!</span>`;
    }
    return '';
  }

  save(): void {
    this.saveList();
  }

  dragEnded(event: DragEventOutput): void {
    this.updateListSequence(event);
  }

  async uploadDemoFile(e: FileEvent): Promise<void> {
    const file = await api.files.create(e.target.files[0]);
    await this.fetchFileHeaders(file[0]._id);
    this.transformHeadersToTransformations();
    this.initialUploadExecuted = true;
  }

  createNewTransformations(): Transformation[] {
    const newTransformations: Transformation[] = [];
    for (const header of this.headers) {
      const newTransformation: Transformation = new Transformation(
        0,
        this.officeId,
      );
      let operation: TransformationOperation = {} as TransformationOperation;
      operation = {
        transformationFields: [] as string[],
        outputField: ' ',
        operator: TransformationOperator.SetValue,
      };
      operation.transformationFields.push(header);
      newTransformation.operations.push(operation);
      newTransformations.push(newTransformation);
    }
    return newTransformations;
  }

  updateTransformations(): Transformation[] {
    const transformations: Transformation[] = [];
    const transformationsToAdd: Transformation[] = [];
    this.localTransformations.map(transformation =>
      transformations.push(transformation),
    );
    const operations: TransformationOperation[] = [] as TransformationOperation[];
    transformations.map(transformation =>
      transformation.operations.map(operation => operations.push(operation)),
    );
    const transformationFields: string[] = [];
    operations.map(operation =>
      operation.transformationFields.map(field =>
        transformationFields.push(field),
      ),
    );
    const newHeaders = this.headers.filter(
      header => !transformationFields.includes(header),
    );
    for (const header of newHeaders) {
      const newTransformation: Transformation = new Transformation(
        0,
        this.officeId,
      );
      let operation: TransformationOperation = {} as TransformationOperation;
      operation = {
        transformationFields: [] as string[],
        outputField: ' ',
        operator: TransformationOperator.SetValue,
      };
      operation.transformationFields.push(header);
      newTransformation.operations.push(operation);
      transformationsToAdd.push(newTransformation);
    }
    this.bulkcreateTransformations(transformationsToAdd);
    return transformations;
  }

  async transformHeadersToTransformations(): Promise<void> {
    let transformations: Transformation[] = [];
    if (this.localTransformations.length === 0) {
      transformations = this.createNewTransformations();
      await this.bulkcreateTransformations(transformations);
    } else {
      transformations = this.updateTransformations();
      await this.bulkUpdateTransformations(transformations);
    }
  }

  searchImportedField(s: string): void {
    debounce(() => {
      const searchResult: Transformation[] = [];
      this.localTransformations = [...this.transformationsList.docs];
      this.localTransformations = [...this.transformationsFilterList.docs];
      const list: Transformation[] = [...this.transformationsFilterList.docs];
      list.map(transformation =>
        transformation.operations.map(operation =>
          operation.transformationFields.filter(field => {
            if (field.toLowerCase().includes(s)) {
              searchResult.push(transformation);
            }
          }),
        ),
      );
      this.localTransformations = searchResult;
      this.counter += 1;
      return list;
    }, 100).bind(this)();
  }

  searchApplicationField(key: string): Transformation[] {
    const searchResult: Transformation[] = [];
    this.localTransformations = [...this.transformationsList.docs];
    this.localTransformations = [...this.transformationsFilterList.docs];
    const list: Transformation[] = [...this.transformationsFilterList.docs];
    list.map(transformation =>
      transformation.operations.filter(operation => {
        if (operation.outputField && operation.outputField.includes(key)) {
          searchResult.push(transformation);
        }
      }),
    );
    this.localTransformations = searchResult;
    this.counter += 1;
    return list;
  }

  findTranslation(s: string): string {
    debounce(() => {
      if (s !== '') {
        this.translations.filter(translation => {
          if (translation.value.toLowerCase().includes(s)) {
            this.key = translation.key;
          }
        });
      } else {
        this.key = '';
      }
      this.searchApplicationField(this.key);
      return this.key;
    }, 100).bind(this)();
    return '';
  }

  async setApplicationField(
    selected: TransformationApplicationSelector,
  ): Promise<void> {
    this.selectedTransformation = selected.transformation;
    const currentTransformationFields: string[] = [];
    this.selectedTransformation.operations.map(oper =>
      oper.transformationFields.map(field =>
        currentTransformationFields.push(field),
      ),
    );
    const operation: TransformationOperation = {
      outputField: selected.value,
      operator: TransformationOperator.SetValue,
      transformationFields: currentTransformationFields,
    };
    if (!this.selectedTransformation._id) {
      return;
    }
    if (selected.type === 'string' && selected.required) {
      operation.operator = TransformationOperator.StringOrEmpty;
      this.selectedTransformation.operations.splice(0, 1, operation);
    }
    if (selected.type === 'string' && !selected.required) {
      operation.operator = TransformationOperator.StringOrNull;
      this.selectedTransformation.operations.splice(0, 1, operation);
    }
    if (selected.type === 'number') {
      operation.operator = TransformationOperator.NumberValueOrNull;
      this.selectedTransformation.operations.splice(0, 1, operation);
    }
    if (selected.type === 'date') {
      operation.operator = TransformationOperator.ValidDateOrNull;
      this.selectedTransformation.operations.splice(0, 1, operation);
    }
    if (this.selectedTransformation._id) {
      if (typeof this.selectedTransformation.__v === 'number') {
        delete this.selectedTransformation.__v;
      }
    }
    await this.updateTransformation({
      id: this.selectedTransformation._id,
      transformation: this.selectedTransformation,
    });
    const values: string[] = [];
    this.localTransformations.map(transformation =>
      transformation.operations.map(oprt => values.push(oprt.outputField)),
    );
    this.fieldSelectOptions.map(option => {
      option.$isDisabled = values.includes(option.value);
    });
    return;
  }

  hideTransformationModal(): void {
    this.$refs.transformationModal.hide();
  }

  showTransformationModal(transformation?: Transformation): void {
    this.selectedTransformation = transformation
      ? cloneDeep(transformation)
      : new Transformation(this.localTransformations.length + 1, this.officeId);
    this.transformationModalTitle = this.selectedTransformation.operations
      .length
      ? (this.$t(
        `${this.selectedTransformation.operations[0].outputField}`,
      ) as string)
      : 'new transformation';

    this.$refs.transformationModal.show();
  }

  editTransformation(item: Transformation): void {
    this.showTransformationModal(item);
  }

  addTransformation(): void {
    this.showTransformationModal();
  }

  async saveTransformation(): Promise<void | false> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    const formValid = await this.$refs.transformationDetail.validate();
    if (!formValid) {
      return false;
    }
    if (this.selectedTransformation._id) {
      if (typeof this.selectedTransformation.__v === 'number') {
        delete this.selectedTransformation.__v;
      }
      await this.updateTransformation({
        id: this.selectedTransformation._id,
        transformation: this.selectedTransformation,
      });
      this.hideTransformationModal();
      this.counter += 1;
    }
  }

  deleteTransformation(transformation: Transformation): void {
    const result = confirm(
      'Are you sure you want to delete this transformation ?',
    );
    if (!result) {
      return;
    }
    if (transformation._id) {
      this.deleteById(transformation._id);
    }
  }
  async created(): Promise<void> {
    this.localTransformations = this.transformationsList.docs;
    await this.fetchTranslations();
    await this.fetchTransformations({
      filters: {
        office: {
          value: this.officeId,
          operation: FilterOperation.Equals,
        },
      },
    });

    await this.fetchFieldSelectOptions({
      includeAttachments: false,
      includeMilestones: true,
    });
    if (!this.localTransformations.length) {
      this.initialUploadExecuted = false;
    }
    return;
  }

  beforeDestroy(): void {
    this.fieldSelectOptions.map(option => {
      option.$isDisabled = false;
    });
  }
}
