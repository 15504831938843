import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import ShipmentAttachmentsService from '@/services/api/shipment-attachments/shipment-attachments.service';
import { ShipmentAttachment } from '@/services/api/shipment-attachments/shipment-attachment.class';
import { ActionContext } from 'vuex';
import { RootState } from './types';
import { Filter } from '@/types/filter.interface';

const shipmentAttachmentsService = new ShipmentAttachmentsService();
export interface ShipmentAttachmentState {
  allUnpaginated: ShipmentAttachment[];
  all: PaginatedResponse<ShipmentAttachment>;
  one: ShipmentAttachment;
  oneFile: File;
  apiParams: FetchAllParams;
}

export default {
  namespaced: true,
  state: {
    one: {},
    oneFile: {},
    allUnpaginated: [],
    all: { docs: [] },
    apiParams: {
      limit: 999,
      sort: [`sequence,${SortOrder.Asc}`],
    },
  },
  getters: {
    GET: (state: ShipmentAttachmentState): ShipmentAttachment => state.one,
    ALL: (state: ShipmentAttachmentState): ShipmentAttachment[] =>
      state.allUnpaginated,
    ALL_PAGINATED: (
      state: ShipmentAttachmentState,
    ): PaginatedResponse<ShipmentAttachment> => state.all,
    API_PARAMS: (state: ShipmentAttachmentState): FetchAllParams =>
      state.apiParams,
  },
  mutations: {
    SET_API_PARAMS(
      state: ShipmentAttachmentState,
      payload: FetchAllParams,
    ): void {
      state.apiParams = payload;
    },
    SET_ALL(
      state: ShipmentAttachmentState,
      payload: PaginatedResponse<ShipmentAttachment>,
    ): void {
      state.all = payload;
    },
    SET_UNPAGINATED(
      state: ShipmentAttachmentState,
      payload: ShipmentAttachment[],
    ): void {
      state.allUnpaginated = payload;
    },
    SET_ONE(state: ShipmentAttachmentState, payload: ShipmentAttachment): void {
      state.one = payload;
    },
  },
  actions: {
    async CREATE(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      payload: ShipmentAttachment,
    ): Promise<ShipmentAttachment> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const shipmentAttachment: ShipmentAttachment = await shipmentAttachmentsService.create(
          payload,
        );
        context.commit('SET_ONE', shipmentAttachment);
        return shipmentAttachment;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async FETCH_ONE(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      id: string,
    ): Promise<ShipmentAttachment> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const shipmentAttachment = await shipmentAttachmentsService.fetchOne(
          id,
        );
        context.commit('SET_ONE', shipmentAttachment);
        return shipmentAttachment;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async FETCH_ALL(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      filter?: Filter,
    ): Promise<PaginatedResponse<ShipmentAttachment>> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        context.commit('SET_API_PARAMS', filter);
        const shipmentAttachments = await shipmentAttachmentsService.fetch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.getters.API_PARAMS,
        );
        context.commit('SET_ALL', shipmentAttachments);
        return shipmentAttachments;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async FETCH_UNPAGINATED(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      filter?: Filter,
    ): Promise<ShipmentAttachment[]> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        context.commit('SET_API_PARAMS', filter);
        const shipmentAttachments = await shipmentAttachmentsService.fetchAll(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.getters.API_PARAMS,
        );
        context.commit('SET_UNPAGINATED', shipmentAttachments);
        return shipmentAttachments;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async UPDATE(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      payload: { _id: string; shipmentAttachment: ShipmentAttachment },
    ): Promise<ShipmentAttachment> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const shipmentAttachment: ShipmentAttachment = await shipmentAttachmentsService.update(
          payload._id,
          payload.shipmentAttachment,
        );
        context.commit('SET_ONE', shipmentAttachment);
        return shipmentAttachment;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async DELETE(
      context: ActionContext<ShipmentAttachmentState, RootState>,
      payload: string,
    ): Promise<ShipmentAttachment> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const shipmentAttachment = await shipmentAttachmentsService.delete(
          payload,
        );
        context.commit('SET_ONE', shipmentAttachment);
        return shipmentAttachment;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
  },
};
