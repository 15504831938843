import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { PartialShipmentResponse } from '@/services/api/shipments/partial-shipment-response';
import { ShipmentMilestoneForm } from './shipment-milestone-form.class';
import { PartialShipmentForm } from './partial-shipment-form.class';

export class ShipmentForm {
  public type: string | null = null;
  public ref = '';
  public mawb = '';
  public hawb = '';
  public flight: {
    origin: string;
    originCountry: string;
    destination: string;
    destinationCountry: string;
    destinationRegion: string;
    name: string;
  } = {
    origin: '',
    originCountry: '',
    destination: '',
    destinationCountry: '',
    destinationRegion: '',
    name: '',
  };
  public customer: CustomerResponse | null = null;
  public partialShipments: PartialShipmentForm[] = [];
  public remarks = '';
  public delayReasons = '';
  public priorDocument = '';
  public pieces: number | null = null;
  public grossWeight: number | null = null;
  public chargeableWeight: number | null = null;
  public volume: number | null = null;
  public freightTerm = '';
  public sku = '';
  public invoiceNumber = '';
  public cmr = false;
  public shipmentMilestones: {
    [key: string]: ShipmentMilestoneForm;
  } = {};
  public custom: {
    customer: string;
    consignee: string;
    shipper: string;
    wareHouseInstruction: string;
    truckerPU?: string;
    truckerDel?: string;
    terminal?: string;
    time?: string;
    isDraft?: boolean;
    docs?: boolean;
    cs?: boolean;
    customRef?: string;
    ticketNumber?: string;
    serialNumber?: string;
    loadingRef?: string;
    loadingLocation?: string;
    pic?: string;
    sellingProduct?: string;
    preAlert?: boolean;
    externalEDIReference?: string;
    wasManuallyUpdated?: boolean;
  } = {
    customer: '',
    consignee: '',
    shipper: '',
    wareHouseInstruction: '',
    sellingProduct: '',
    preAlert: false,
  };
  public service: {
    type: string;
    level: string;
  } = {
    type: '',
    level: '',
  };
  public archived: { [key: string]: boolean } = {};
  public trackingNumbers: string[] = [];
  public completed = false;
  public sortedPallets: number | null = null;
  public customField1 = '';
  public customField2 = '';
  public customField3 = '';
  public customerServiceReference1 = '';
  public customerServiceReference2 = '';
  public customerServiceReference3 = '';
  public customerServiceReference4 = '';

  constructor(shipmentOrMilestones: ShipmentResponse | MilestoneResponse[]) {
    if (Array.isArray(shipmentOrMilestones)) {
      const milestones: MilestoneResponse[] = shipmentOrMilestones;
      const shipmentMilestones: {
        [key: string]: ShipmentMilestoneForm;
      } = {};
      const noMilestones: boolean =
        !milestones.length || typeof milestones[0] === 'string';

      if (noMilestones) {
        this.shipmentMilestones = {};

        return;
      }

      for (const milestone of milestones) {
        shipmentMilestones[milestone._id] = new ShipmentMilestoneForm();
      }

      this.shipmentMilestones = shipmentMilestones;
    } else {
      const shipment: ShipmentResponse = shipmentOrMilestones;

      this.type = shipment.type;
      this.ref = shipment.ref;
      this.mawb = shipment.mawb;
      this.hawb = shipment.hawb;
      this.flight = {
        origin: shipment.flight.origin,
        originCountry: shipment.flight.originCountry,
        destination: shipment.flight.destination,
        destinationCountry: shipment.flight.destinationCountry,
        destinationRegion: shipment.flight.destinationRegion,
        name: shipment.flight.name,
      };
      this.customer = shipment.customer;
      this.partialShipments = shipment.partialShipments.map(
        (partialShipment: PartialShipmentResponse) =>
          new PartialShipmentForm(partialShipment),
      );
      this.remarks = shipment.remarks;
      this.delayReasons = shipment.delayReasons;
      this.priorDocument = shipment.priorDocument;
      this.pieces = shipment.pieces;
      this.grossWeight = shipment.grossWeight;
      this.chargeableWeight = shipment.chargeableWeight;
      this.volume = shipment.volume;
      this.freightTerm = shipment.freightTerm;
      this.sku = shipment.sku;
      this.cmr = shipment.cmr;
      this.invoiceNumber = shipment.invoiceNumber;
      this.shipmentMilestones = shipment.shipmentMilestones;
      this.trackingNumbers = shipment.trackingNumbers;
      this.custom = {
        customer: shipment.custom.customer,
        consignee: shipment.custom.consignee,
        shipper: shipment.custom.shipper,
        wareHouseInstruction: shipment.custom.wareHouseInstruction,
        truckerPU: shipment.custom.truckerPU,
        truckerDel: shipment.custom.truckerDel,
        terminal: shipment.custom.terminal,
        time: shipment.custom.time,
        isDraft: shipment.custom.isDraft,
        customRef: shipment.custom.customRef,
        ticketNumber: shipment.custom.ticketNumber,
        serialNumber: shipment.custom.serialNumber,
        loadingRef: shipment.custom.loadingRef,
        loadingLocation: shipment.custom.loadingLocation,
        pic: shipment.custom.pic,
        sellingProduct: shipment.custom.sellingProduct,
        preAlert: shipment.custom.preAlert,
        externalEDIReference: shipment.custom.externalEDIReference,
        wasManuallyUpdated: shipment.custom.wasManuallyUpdated,
      };
      this.service = {
        type: shipment.service ? shipment.service.type : '',
        level: shipment.service ? shipment.service.level : '',
      };
      this.archived = shipment.archived;
      this.completed = shipment.completed;
      this.sortedPallets = shipment.sortedPallets;
      this.customField1 = shipment.customField1;
      this.customField2 = shipment.customField2;
      this.customField3 = shipment.customField3;
    }
  }
}
