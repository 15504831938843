





























import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appShipmentItemStickyColumn from '../components/ShipmentItemStickyColumn.vue';
import appShipmentItemScrollColumn from '../components/ShipmentItemScrollColumn.vue';
import appShipmentCheckbox from '../components/ShipmentCheckbox.vue';
import appShipmentMilestone from '../components/ShipmentMilestone.vue';
import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { ShipmentMilestoneDetailInput } from '../types/shipments/shipment-milestone-detail-input.interface';
import { CustomerMilestoneResponse } from '../services/api/customers/customer-milestone-response.interface';
import { UIField } from '@/types/ui/ui-field.class';

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const shipmentsModule = namespace('shipments');

@Component({
  components: {
    appShipmentMilestone,
    appShipmentCheckbox,
    appShipmentItemScrollColumn,
    appShipmentItemStickyColumn,
  },
})
export default class ShipmentItem extends Mixins() {
  @Prop({
    type: Object,
    required: true,
  })
  shipment!: ShipmentResponse;

  @Prop({
    type: String,
    required: true,
  })
  milestoneType!: string;

  @Prop({
    type: Array,
    required: true,
  })
  scrollFields!: UIField[];

  @Prop({
    type: Array,
    required: true,
  })
  stickyFields!: UIField[];

  @shipmentsModule.Getter('ACTIVE')
  activeShipment!: string;

  @shipmentsModule.Action('SET_ACTIVE')
  setActive!: (shipmentId: string | null) => void;

  @shipmentsModule.Action('ARCHIVE')
  archiveShipment!: (payload: {
    id: string;
    milestoneType: string;
  }) => Promise<void>;

  @shipmentsModule.Action('UNARCHIVE')
  unarchiveShipment!: (payload: {
    id: string;
    milestoneType: string;
  }) => Promise<void>;

  @shipmentsModule.Action('SET_CMR')
  setCmr!: (payload: string) => void;

  @shipmentsModule.Action('UNSET_CMR')
  unsetCmr!: (payload: string) => void;

  @Emit()
  showDetail(shipment: any): string {
    return shipment._id;
  }

  @Emit()
  showCustomer(shipment: any): string {
    return shipment.customer._id;
  }

  @Emit()
  shipmentMilestoneClicked(input: {
    milestoneId: string;
    milestoneCode: string;
    dateToggleClick: boolean;
  }): ShipmentMilestoneDetailInput {
    const sendNotification = !!(
      this.shipment.customer &&
      this.shipment.customer.customerMilestones &&
      this.shipment.customer.customerMilestones.length > 0 &&
      this.shipment.customer.customerMilestones.find(
        (cm: CustomerMilestoneResponse) => {
          if (typeof cm.milestone !== 'string' && '_id' in cm.milestone) {
            return cm.milestone._id === input.milestoneId;
          }

          return cm.milestone === input.milestoneId;
        },
      )
    );

    return {
      shipmentId: this.shipment._id,
      shipmentRef: this.shipment.ref,
      ...input,
      value: this.shipment.shipmentMilestones[input.milestoneId].value,
      priority: this.shipment.shipmentMilestones[input.milestoneId].priority,
      status: this.shipment.shipmentMilestones[input.milestoneId].status,
      sendNotification,
    };
  }

  activateShipment(shipmentId: string): void {
    this.setActive(shipmentId);
  }

  get isActive(): boolean {
    return this.shipment._id === this.activeShipment;
  }

  toggleArchived(toArchive: boolean): void {
    if (toArchive) {
      this.archiveShipment({
        id: this.shipment._id,
        milestoneType: this.milestoneType,
      });
    } else {
      this.unarchiveShipment({
        id: this.shipment._id,
        milestoneType: this.milestoneType,
      });
    }
  }

  toggleCmr(newValue: boolean): void {
    if (newValue) {
      this.setCmr(this.shipment._id);
    } else {
      this.unsetCmr(this.shipment._id);
    }
  }
}
