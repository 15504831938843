



























import { Vue, Component, Prop, Emit, Inject } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';

import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';

const milestonesModule = namespace('milestones');

@Component({
  components: {
    Multiselect,
  },
})
export default class MilestoneSelect extends Vue {
  @Inject('validator') $validatorRef: any;
  @Prop({
    type: [Object, Array, String],
    required: false,
  })
  value!: MilestoneResponse[] | MilestoneResponse | string;

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Prop({
    type: String,
    default: 'Select milestone(s)',
  })
  placeholder!: string;

  @Prop({
    type: Number,
    default: 0,
  })
  msIndex!: number;

  @milestonesModule.Getter('ALL_BY_SEQUENCE')
  milestones!: MilestoneResponse[];

  @milestonesModule.Action('FETCH_ALL')
  fetchMilestones!: () => Promise<MilestoneResponse | MilestoneResponse[]>;

  @Emit('input')
  onChange(
    value: MilestoneResponse | MilestoneResponse[],
  ): MilestoneResponse | MilestoneResponse[] {
    return value;
  }

  async created(): Promise<void> {
    await this.fetchMilestones();
  }
}
