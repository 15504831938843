import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';
import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { Milestone } from '@/services/api/milestones/milestone.class';
import MilestonesService from '@/services/api/milestones/milestones.service';
import { DragEventOutput } from '@/types/drag-event-output.interface';
import { changeItemsSequence } from '@/utils/sequence.util';
import { RootState } from '@/store';
import { ActionTree, MutationTree, GetterTree } from 'vuex';

const milestonesService = new MilestonesService();
const namespaced = true;

export interface MilestoneState {
  milestones: { docs: Milestone[] };
  one: Milestone;
  maxSequence: number;
  apiParams: FetchAllParams;
}

const initialState: MilestoneState = {
  milestones: { docs: [] },
  one: new Milestone(),
  maxSequence: 0,
  apiParams: {
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const Getters: GetterTree<MilestoneState, RootState> = {
  ONE: state => state.one,
  MAX_SEQUENCE: state => state.maxSequence,
  API_PARAMS: state => state.apiParams,
  ALL: state => state.milestones.docs,
  ALL_PAGINATED: state => state.milestones,
  ALL_BY_SEQUENCE: (state, getters) =>
    // eslint-disable-next-line
    getters.ALL.sort(
      (a: MilestoneResponse, b: MilestoneResponse) => a.sequence - b.sequence,
    ),
};

const mutations: MutationTree<MilestoneState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: { docs: Milestone[] }) {
    state.milestones = payload;
  },
  SET_MAX_SEQUENCE(state, payload: number) {
    state.maxSequence = payload;
  },
  SET_ONE(state, payload: Milestone) {
    state.one = payload;
  },
  DELETE_ONE(state, payload: Milestone) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const milestoneIndex: number = state.milestones.docs.findIndex(
      (milestone: Milestone) => milestone._id === payload._id,
    );
    if (milestoneIndex > -1) {
      state.milestones.docs.splice(milestoneIndex, 1);
    }
  },
};

const actions: ActionTree<MilestoneState, RootState> = {
  async CREATE(context, payload: Milestone) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestone = await milestonesService.create(payload);
      context.commit('SET_ONE', milestone);
      return milestone;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async FETCH_ALL(context, filter?: FetchAllParams) {
    context.commit('SET_API_PARAMS', { ...filter, limit: 999 });
    const milestones = await milestonesService.fetch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.getters.API_PARAMS,
    );
    context.commit('SET_ALL', milestones);
    return milestones;
  },

  async FETCH_PAGINATED(context, filter?: FetchAllParams) {
    context.commit('SET_API_PARAMS', filter);
    const milestones = await milestonesService.fetch(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.getters.API_PARAMS,
    );
    context.commit('SET_ALL', milestones);
    return milestones;
  },

  async FETCH_MAX_SEQUENCE(context) {
    const filter = {
      sort: [`sequence,${SortOrder.Desc}`],
      limit: 1,
      select: 'sequence',
    };
    const milestone = await milestonesService.fetch(filter);
    context.commit('SET_MAX_SEQUENCE', milestone.docs[0].sequence);
    return milestone;
  },

  UPDATE_LIST(context, dragEvent: DragEventOutput) {
    const milestones = changeItemsSequence(
      context.state.milestones.docs,
      dragEvent,
    );
    const sortedMilestones = { ...context.state.milestones, docs: milestones };
    context.commit('SET_ALL', sortedMilestones);
  },

  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestone = await milestonesService.fetchOne(id);
      context.commit('SET_ONE', milestone);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async UPDATE(context, payload: { id: string; milestone: Milestone }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestone = await milestonesService.update(
        payload.id,
        payload.milestone,
      );
      context.commit('SET_ONE', milestone);
      return milestone;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async SAVE_LIST(context, milstoneList: Milestone[]) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      await milestonesService.bulk(milstoneList);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestone = await milestonesService.delete(payload);
      context.commit('DELETE_ONE', milestone);
      return milestone;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};
export default {
  namespaced,
  state: initialState,
  getters: Getters,
  mutations,
  actions,
};
