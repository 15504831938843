export enum Icon {
  Archive = 'archive',
  Circle = 'circle',
  ArrowFromLeft = 'arrow-from-left',
  ArrowFromRight = 'arrow-from-right',
  ChartBar = 'chart-bar',
  ChevronLeft = 'chevron-left',
  ChevornDown = 'chevron-down',
  ChevronUp = 'chevron-up',
  Download = 'download',
  Exchange = 'exchange',
  Globe = 'globe',
  Info = 'info',
  Save = 'save',
  Search = 'search',
  ShareSquare = 'share-square',
  SortAmountDown = 'sort-amount-down',
  SortAmountUpd = 'sort-amount-up',
  Spinner = 'spinner',
  Star = 'star',
  SyncAlt = 'sync-alt',
  Times = 'times',
  Tv = 'tv',
  User = 'user',
  UserCircle = 'user-circle',
  Upload = 'upload',
  Plus = 'plus',
  CalenderAlt = 'calendar-alt',
  Edit = 'edit',
  ClipBoard = 'clipboard',
  Copy = 'copy',
  ClipboardCheck = 'clipboard-check',
  History = 'history',
  MapMarkerAlt = 'map-marker-alt',
  TrashAlt = 'trash-alt',
  BarcodeRead = 'barcode-read',
  SlidersH = 'sliders-h',
  FileExel = 'file-excel',
  LayerGroup = 'layer-group',
  Warehouse = 'warehouse',
  BalanceScale = 'balance-scale',
  At = 'at',
  Users = 'users',
  QuestionCircle = 'question-circle',
}
