










































































































import {
  Vue,
  Component,
  Prop,
  Model,
  Emit,
  Watch,
} from 'vue-property-decorator';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import FieldSelect from './FieldSelect.vue';
import FilterOperationSelect from './FilterOperationSelect.vue';
import { UIFilter } from '../types/ui/ui-filter.interface';
import appShipmentTypeSelect from '../components/ShipmentTypeSelect.vue';
import {
  FilterOperation,
  humanReadableFilterOperation,
} from '@/types/filter-operation.enum';
import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import { first, isBoolean, isString } from 'lodash';

@Component({
  components: {
    FieldSelect,
    Multiselect,
    appShipmentTypeSelect,
    FilterOperationSelect,
  },
})
export default class UIFilterComponent extends Vue {
  @Model('change', {
    type: Object,
  })
  uiFilter!: UIFilter;

  @Prop({
    type: Number,
  })
  idKey!: number;

  @Prop({
    type: String,
  })
  milestoneType!: string;

  @Prop({
    type: Array,
  })
  shipmentTypes!: ShipmentTypeResponse[];

  @Prop({
    type: Array,
  })
  milestones!: MilestoneResponse[];

  @Prop({
    type: Array,
  })
  customers!: CustomerResponse[];

  visibleCustomers: string[] = [];

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  get isCustomer(): boolean {
    return this.uiFilter.field === 'customer';
  }

  get field(): string {
    if (this.uiFilter.field.includes('archived')) {
      return this.uiFilter.field.split('.')[0];
    }
    return this.uiFilter.field;
  }

  set field(field: string) {
    if (field === 'archived') {
      this.uiFilter.field = `${field}.${this.milestoneType}`;
    } else {
      this.uiFilter.field = field;
    }
  }

  @Watch('uiFilter.values', { immediate: true, deep: true })
  onFieldChange(values: string[]): void {
    if (!values) {
      return;
    }
    const filteredCustomers = this.customers.filter(item => {
      if (item._id) {
        return values.includes(item._id);
      }
    });
    if (filteredCustomers.length) {
      values = filteredCustomers.map(customer => {
        if (customer.aliases) {
          return customer.aliases[0];
        }
      }) as string[];
    }

    if (!values) {
      return;
    }

    this.visibleCustomers = [];
    if (values.length <= 2) {
      this.visibleCustomers = values;
      return;
    }
    for (let i = 0; i < 3; i++) {
      this.visibleCustomers.push(values[i]);
    }
    return;
  }

  @Emit('delete')
  deleteFilter(): boolean {
    return true;
  }

  @Emit('edit')
  editFilter(): boolean {
    return true;
  }

  @Emit('setActive')
  setActive(state: boolean): boolean {
    return state;
  }

  getFieldName(field: string): string {
    if (field.includes('shipmentMilestones')) {
      const selectedMilestone: MilestoneResponse[] = this.milestones.filter(
        milestone => milestone._id === field.split('.')[1],
      );
      return selectedMilestone[0].code;
    }
    if (field.includes('archived')) {
      const archivedField = first(field.split('.'));
      return archivedField ? archivedField : '';
    }
    return this.$t(field) as string;
  }

  getCustomerName(
    customerId: string,
    allCustomers: CustomerResponse[],
  ): string {
    const foundCustomer = allCustomers.find(custmr => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      custmr._id === customerId;
    });
    return foundCustomer ? foundCustomer.parent.name : '';
  }

  getReadableOperator(uiFilter: UIFilter): string {
    if (uiFilter.field === 'customer') {
      return 'contains following';
    }

    if (uiFilter.operation === FilterOperation.IsToday) {
      const returnString = 'date is ';
      return returnString.concat(
        this.transformValuesToDateStrings(parseInt(uiFilter.values[0], 10)),
      );
    }

    return humanReadableFilterOperation(uiFilter.operation);
  }

  transformValuesToDateStrings(value: number): string {
    if (value < -1) {
      return `${Math.abs(value)} days before today`;
    } else if (value >= -1 && value < 0) {
      return 'yesterday';
    } else if (value === 0) {
      return 'today';
    } else if (value <= 1 && value > 0) {
      return 'tomorrow';
    } else {
      return `${value} days after today`;
    }
  }

  returnFilterTypeString(uiFilterField: string): string {
    if (uiFilterField.includes('shipmentMilestones')) {
      return 'Milestone';
    }
    return 'The field';
  }

  remainingCustomers(uiFilterValues: string[]): string {
    const remainingCustomers = uiFilterValues.length - 3;
    return `+ ${remainingCustomers}`;
  }

  getUiFilterValue(values: string[], uiFilterField: string): string {
    const returnValue = [];
    returnValue.push(values[0]);
    if (uiFilterField === 'type') {
      const shipment: ShipmentTypeResponse[] = this.shipmentTypes.filter(
        ship => ship._id === values[0],
      );
      returnValue[0] = shipment[0].name;
      return returnValue[0].toLowerCase();
    }
    if (
      returnValue[0] &&
      isString(returnValue[0]) &&
      !isBoolean(returnValue[0])
    ) {
      let returnString = '';
      let iteration = 0;
      for (const stringValue of values) {
        if (iteration !== 0) {
          returnString += ', ';
        }
        returnString += stringValue;
        iteration++;
      }
      return returnString.toLowerCase();
    } else {
      return '';
    }
  }
}
