import { ReasonCode } from '@/types/reason-code.interface';

const maerskReasonCodes: ReasonCode[] = [
  {
    code: 'C1',
    description: 'Customer Requested to return the cargo to WSS warehouse',
  },
  {
    code: 'C7',
    description: 'Order/shipment cancelled',
  },
  {
    code: 'C8',
    description: 'Cargo On-Hold required by shipper (WSS)',
  },
  {
    code: 'C9',
    description: 'Missing/wrong documents supplied by shipper/customer.',
  },
  {
    code: 'C10',
    description: 'Late handover cargo at Pick-up location',
  },
  {
    code: 'C11',
    description: 'Wrong packed cargo',
  },
  {
    code: 'D1',
    description: 'Damaged cargo at arrival Maersk Warehouse',
  },
  {
    code: 'D2',
    description: 'Damaged in transit',
  },
  {
    code: 'D3',
    description: 'Lost cargo',
  },
  {
    code: 'L1',
    description: 'Delayed cargo due to re-routing by Maersk',
  },
  {
    code: 'L2',
    description:
      'Shipment delayed due to capacity/space/rolled by carrier/airline',
  },
  {
    code: 'M1',
    description: 'Mechanical breakdown',
  },
  {
    code: 'M2',
    description: 'Delay in flight (maintenance, flight cancellation)',
  },
  {
    code: 'S3',
    description: 'Late delivery to airport',
  },
  {
    code: 'S4',
    description: 'Shipment split by airline',
  },
  {
    code: 'S6',
    description: 'Delayed cargo release by airline',
  },
  {
    code: 'S7',
    description: 'Partial delivery',
  },
  {
    code: 'S10',
    description: 'Delays due to late approvals Draft AWB',
  },
  {
    code: 'T1',
    description: 'Customs delay',
  },
  {
    code: 'U1',
    description: 'Strike action',
  },
  {
    code: 'U2',
    description: 'Delays due to holidays',
  },
  {
    code: 'U3',
    description: 'Airline disaster',
  },
  {
    code: 'U5',
    description: 'Natural disaster (vulcano, eartquake, weather)',
  },
];

const asmlReasonCodes: ReasonCode[] = [
  {
    code: 'A01',
    description: 'Flight cancelled by airline',
  },
  {
    code: 'A02',
    description: 'Split shipment caused by airline',
  },
  {
    code: 'A03',
    description: 'Missing documents at arrival caused by airline',
  },
  {
    code: 'A04',
    description: 'Flight delay caused by the airline',
  },
  {
    code: 'A05',
    description: 'Pieces lost by airline',
  },
  {
    code: 'A06',
    description: 'Shipment cross labelled',
  },
  {
    code: 'A07',
    description: 'No daily passenger flight',
  },
  {
    code: 'A08',
    description: 'No daily freighter flight',
  },
  {
    code: 'A09',
    description: 'After Cut-off Time But Before Flight Departure',
  },
  {
    code: 'A10',
    description: 'Remote locations',
  },
  {
    code: 'B01',
    description: 'Late declaration',
  },
  {
    code: 'B02',
    description: 'Late notification of customs release',
  },
  {
    code: 'B03',
    description: 'Documents misplaced/lost',
  },
  {
    code: 'B04',
    description: 'Broker other',
  },
  {
    code: 'C01',
    description: 'Customs examination',
  },
  {
    code: 'C02',
    description: 'Customs other',
  },
  {
    code: 'C03',
    description: 'ASML customs system down',
  },
  {
    code: 'C04',
    description: '3PL customs system down',
  },
  {
    code: 'C05',
    description: 'Local authorities customs system down',
  },
  {
    code: 'C06',
    description: 'Unable to perform customs clearance due to ASML or shipper',
  },
  {
    code: 'C07',
    description: 'Unable to perform customs clearance due to consignee',
  },
  {
    code: 'F01',
    description: 'Flight delay due to bad weather',
  },
  {
    code: 'F02',
    description: 'Force Majeure',
  },
  {
    code: 'G01',
    description: 'Consignee refused the delivery of the shipment',
  },
  {
    code: 'G02',
    description: 'Damaged boxes ex Asml',
  },
  {
    code: 'G03',
    description: 'Over / under delivery from ASML',
  },
  {
    code: 'G04',
    description: 'Incomplete/wrong documentation set from ASML',
  },
  {
    code: 'G05',
    description: 'Pending destination approval for shipping',
  },
  {
    code: 'G06',
    description: 'ASML or consignee location closed',
  },
  {
    code: 'G07',
    description: 'Delivered as per customer request',
  },
  {
    code: 'G08',
    description: 'EDI message not received.',
  },
  {
    code: 'G09',
    description:
      'Pickup not possible - goods not ready at the moment of pick up.',
  },
  {
    code: 'G10',
    description: 'Dangerous goods',
  },
  {
    code: 'S01',
    description: 'Damage caused by LSP',
  },
  {
    code: 'S02',
    description: 'LSP Misrouted Cargo',
  },
  {
    code: 'S03',
    description: 'Missing / Lost Document ',
  },
  {
    code: 'S04',
    description: 'Incomplete documents',
  },
  {
    code: 'S05',
    description: 'Unable to book / board the goods on the flight',
  },
  {
    code: 'S06',
    description: 'Goods stolen from the cartons (claims follows)',
  },
  {
    code: 'S07',
    description: 'LSP other',
  },
  {
    code: 'S08',
    description: 'Overland Delay',
  },
  {
    code: 'S09',
    description: 'Warehouse Error',
  },
  {
    code: 'S10',
    description: 'Incorrectly processed',
  },
];

export { maerskReasonCodes, asmlReasonCodes };
