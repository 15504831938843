








































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../components/FieldSelect.vue';
import iconSelector from '../components/IconSelector.vue';
import appFieldCustomLinkForm from './FieldCustomLinkForm.vue';
import { UIField } from '../types/ui/ui-field.class';
import { Icon } from '../types/ui/icon.enum';
import {
  ITransformation,
  ITransformationOperator,
  ITransformationCombinator,
  ConditionOperator,
} from '../types/transformations';
import cloneDeep from 'lodash.clonedeep';

const utilityModule = namespace('utility');

@Component({
  components: {
    FieldSelect,
    iconSelector,
    appFieldCustomLinkForm,
  },
})
export default class FieldForm extends Vue {
  @Prop({
    type: Object,
  })
  field!: UIField;

  @utilityModule.Getter('GET_FIELD_SELECT_OPTIONS')
  options!: any[];

  @utilityModule.Action('FETCH_FIELD_SELECT_OPTIONS')
  fetchOptions!: (payload: {
    includeAttachments?: boolean;
    includeMilestones?: boolean;
    includeFilterOptions?: boolean;
  }) => Promise<any[]>;

  @Prop({})
  icon?: [];

  actionsForm = '';
  form: UIField = new UIField();
  boldClass = 'font-weight-bold';
  truncateClass = 'text-truncate';

  selected = 'field';
  fieldTypeOptions: Array<{ text: string; value: string }> = [
    { text: 'Field', value: 'field' },
    { text: 'Checkbox', value: 'cb' },
    { text: 'Icon', value: 'icon' },
  ];
  selectedAction = 'noAction';
  actions: Array<{ text: string; value: string }> = [
    { text: 'Do nothing', value: 'noAction' },
    { text: 'Open shipment', value: 'openShipment' },
    { text: 'Open customer', value: 'openCustomer' },
    { text: 'Open custom link', value: 'openCustomLink' },
  ];

  iconList: string[] = [];
  showLabelIconOptions = false;
  showFieldIconOptions = false;
  @Watch('selected')
  onFormChanged(): void {
    this.form.fieldType = this.selected;
  }

  @Watch('field', {
    immediate: true,
    deep: true,
  })
  onFieldChanged(field: UIField): void {
    this.prepIncomingField(field);
  }

  toggleFieldOptions(): void {
    this.showFieldIconOptions = !this.showFieldIconOptions;
  }

  toggleLabelOptions(): void {
    this.showLabelIconOptions = !this.showLabelIconOptions;
  }

  get iconOptions(): string[] {
    for (const item of Object.values(Icon)) {
      this.iconList.push(item);
    }
    return this.iconList;
  }

  get fieldIsFilterable(): boolean {
    return !!this.form.filterType;
  }

  set fieldIsFilterable(checked: boolean) {
    if (checked) {
      if (this.form.field === 'customer') {
        this.form.filterType = 'select';
      } else {
        this.form.filterType = 'txt';
      }
    } else {
      this.form.filterType = undefined;
    }
  }

  get isMilestoneOrAttachment(): boolean {
    if (this.isMilestone || this.isAttachment) {
      return true;
    }
    return false;
  }

  get isAttachment(): boolean {
    if (!this.form || !this.form.field) {
      return false;
    }
    if (this.form.field) {
      return this.form.field.startsWith('attachments.');
    }
    return this.form.field.startsWith('attachments.');
  }

  get isMilestone(): boolean {
    if (!this.form || !this.form.field) {
      return false;
    }
    return this.form.field.startsWith('shipmentMilestones.');
  }

  get milestoneId(): string | undefined {
    if (this.form.field) {
      return this.form.field
        .replace('shipmentMilestones.', '')
        .replace('.value', '');
    }
  }

  get isPartialField(): boolean {
    if (this.form.field) {
      const ret = this.form.field;
      return ret === 'partialShipments';
    }
    return false;
  }

  prepFormForSubmit(): void {
    const currentAction = this.selectedAction;
    delete this.form.func;
    switch (currentAction) {
    case 'openShipment':
      this.form.openShipment = true;
      break;
    case 'openCustomer':
      this.form.openCustomer = true;

      break;
    case 'openCustomLink':
      this.form.openCustomLink = true;
      break;
    default:
      this.form.openShipment = false;
      this.form.openCustomer = false;
      this.form.openCustomLink = false;
    }

    if (this.isPartialField) {
      this.form.func = 'isPartial';
    }

    if (this.isMilestoneOrAttachment || this.form.fieldType === 'cb') {
      this.form.openCustomLink = false;
    }
    if (this.form.field === 'customer') {
      this.form.func = 'printCustomer';
    }

    if (this.isMilestone) {
      this.form.milestone = this.milestoneId;
      if (this.form.fieldType !== 'cb') {
        this.form.fieldType = 'ms';
      } else {
        this.form.isDisabled = true;
      }
    }
  }

  prepIncomingField(field: UIField): void {
    this.form = cloneDeep(field);

    // set fieldType
    if (field.fieldIcon || field.labelIcon || field.icon) {
      this.form.fieldType = 'icon';
    }
    if (field.fieldType === 'cb') {
      this.form.fieldType = 'cb';
    }
    if (field.fieldType === 'ms' || !field.fieldType) {
      this.form.fieldType = 'field';
    }
    this.selected = this.form.fieldType ? this.form.fieldType : 'field';

    // set selectedAction
    const checkActions = [
      { value: this.form.openShipment, text: 'openShipment' },
      { value: this.form.openCustomer, text: 'openCustomer' },
      { value: this.form.openCustomLink, text: 'openCustomLink' },
    ];

    checkActions.map((action: { value: boolean; text: string }) => {
      if (action.value === true) {
        this.selectedAction = action.text;
      }
    });
  }

  async created(): Promise<void> {
    this.prepIncomingField(this.field);
    await this.fetchOptions({
      includeAttachments: false,
      includeMilestones: true,
    });
  }

  onSubmit(form: UIField): void {
    this.prepFormForSubmit();
    this.$emit('save', form);
  }

  handleFieldIconSelected(icon: string): void {
    this.form.icon = true;
    this.form.fieldIcon = icon;
    this.toggleFieldOptions();
  }
  handleLabelIconSelected(icon: string): void {
    this.form.icon = true;
    this.form.labelIcon = icon;
    this.toggleLabelOptions();
  }

  addCustomLink(): void {
    const newCustomLinkTransformation: ITransformation = {
      name: '',
      operations: [
        {
          transformationFields: ['ref'],
          outputField: 'url',
          operator: ITransformationOperator.SetValue,
          combinator: ITransformationCombinator.firstNotEmpty,
          parameter: '',
        },
      ],
      conditions: [
        {
          field: '',
          operator: ConditionOperator.Equal,
          parameters: '',
        },
      ],
      sequence: 0,
    };

    this.form.customLinkTransformations.push(newCustomLinkTransformation);
  }

  removeCustomLink(index: number): void {
    this.form.customLinkTransformations.splice(index, 1);
  }
}
