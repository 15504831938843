import { AddressRequest } from '@/services/api/address-request.interface';

export class CustomerInvoice {
  name = '';
  address = {} as AddressRequest;
  contact = {
    name: '',
    email: '',
    phone: {
      mobile: '',
      office: '',
    },
  };
  currency = {
    one: '',
    two: '',
  };
  ref = '';
}
