


































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Report } from '../services/api/reports/report.class';
import EntityList from '../components/EntityList.vue';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponseClass } from '@/services/api/paginated-response';
import { BModal } from 'bootstrap-vue';
import { TestIds } from '../types/test-ids.enum';
import { CustomButton } from '@/types/custom-button.interface';

const reportsModule = namespace('reports');

@Component({
  components: { EntityList },
})
export default class AllReports extends Vue {
  @reportsModule.Getter('ALL_PAGINATED')
  reports!: PaginatedResponseClass<Report>;

  @reportsModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => Promise<Report>;

  @reportsModule.Action('DELETE')
  deleteById!: (id: string) => Promise<Report>;

  @reportsModule.Action('DUPLICATE')
  duplicateReport!: (payload: { report: Report }) => Promise<Report>;

  selectedReport = new Report();

  filter: FetchAllParams = { sort: ['name,asc'] };

  $refs!: Vue['$refs'] & {
    cloneModal: BModal;
  };

  TestIds: { [key: string]: number | string } = TestIds;

  public columns: any[] = [
    {
      field: 'name',
      label: 'Report Name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];
  customButtons: CustomButton[] = [
    {
      variant: 'light',
      icon: 'copy',
      event: 'openCloneModal',
      show: this.$can('create', 'Report'),
      id: TestIds.COPY_REPORT_BTN,
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  redirectToDetail(report: Report): void {
    this.$router.push({
      name: 'Report',
      params: { id: report._id || '' },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateReport',
    });
  }

  async redirectToDelete(report: Report): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + report.name + '?',
    );
    if (!result) {
      return;
    }
    await this.deleteById(report._id || '');
    await this.fetchAll();
  }

  openCloneModal(item: Report): void {
    this.$refs.cloneModal.show();
    this.selectedReport = { ...item };
  }

  async copyReport(): Promise<void> {
    if (this.selectedReport) {
      this.selectedReport.name = `Copy of ${this.selectedReport.name}`;
      const report = await this.duplicateReport({
        report: this.selectedReport,
      });
      if (report && report._id) {
        await this.$router.replace({
          name: 'Report',
          params: { id: report._id },
        });
      }
    }
  }

  async created(): Promise<void> {
    await this.fetchAll();
  }
}
