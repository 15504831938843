
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { Office } from '@/services/api/offices/office.class';
import { OfficeEntity } from '@/services/api/offices/office-entity.class';

const usersModule = namespace('users');

@Component({
  components: { Multiselect },
})
export default class Environments extends Vue {
  @usersModule.Getter('GET_OFFICE_ROLES')
  officeRoles!: OfficeEntity[];

  @usersModule.Getter('GET_FIRST_NAME')
  firstName!: string;

  @usersModule.Action('SELECT_OFFICE')
  authenticateForOffice!: (office: Office) => Promise<Office>;

  selectedOfficeRole: OfficeEntity = new OfficeEntity();

  lastOfficeSelected!: OfficeEntity | undefined;

  customLabel(officeRole: OfficeEntity): string {
    return officeRole.office.name;
  }

  enterOffice(): void {
    localStorage.setItem('officeName', this.selectedOfficeRole.office.name);
    this.authenticateForOffice(this.selectedOfficeRole.office);
  }

  created(): void {
    const officeName = localStorage.getItem('officeName');
    this.lastOfficeSelected = this.officeRoles.find(
      item => item.office.name === officeName,
    );
    if (this.lastOfficeSelected) {
      this.selectedOfficeRole = this.lastOfficeSelected;
      return;
    }
    this.selectedOfficeRole = this.officeRoles[0];
  }
}
