









































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone';

import { ShipmentMilestoneStatus } from '../services/api/shipments/shipment-milestone-status.enum';
import { DateFormat } from '../services/api/users/date-format.enum';
import { RuleResult } from '../services/api/shipments/rule-result.interface';
import { RuleResultStatus } from '../types/rule-result-status.enum';
import { Role } from '../services/api/users/role.enum';

const usersModule = namespace('users');

@Component({})
export default class ShipmentMilestone extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  shipmentId!: string;

  @Prop({
    type: String,
    required: true,
  })
  milestoneId!: string;

  @Prop({
    type: String,
    default: null,
  })
  value!: string;

  @Prop({
    type: String,
    default: ShipmentMilestoneStatus.Confirmed,
  })
  status!: ShipmentMilestoneStatus;

  @Prop({
    type: Number,
    default: 0,
  })
  priority!: number;

  @Prop({
    type: Object,
  })
  ruleResult!: RuleResult;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @usersModule.Getter('GET_TIMEZONE')
  userTimezone!: string;

  @usersModule.Getter('GET_DATE_FORMAT')
  userDateFormat!: DateFormat;

  @usersModule.Getter('GET_ROLE')
  userRole!: Role;

  get clickableClass(): { clickable: boolean } {
    if (!this.canUpdateShipmentValue) {
      return { clickable: true };
    }
    return { clickable: false };
  }

  get validationClass(): {
    new: boolean;
    changed: boolean;
    registered: boolean;
    conflicted: boolean;
    confirmed: boolean;
    priority1: boolean;
    priority2: boolean;
    ruleWarning: boolean;
    ruleCritical: boolean;
  } {
    const classList = {
      new: false,
      changed: false,
      registered: false,
      conflicted: false,
      confirmed: false,
      priority1: false,
      priority2: false,
      ruleWarning: false,
      ruleCritical: false,
    };

    switch (this.status) {
    case ShipmentMilestoneStatus.Changed:
      classList.changed = true;
      break;
    case ShipmentMilestoneStatus.Registered:
      classList.registered = true;
      break;
    case ShipmentMilestoneStatus.Conflicted:
      classList.conflicted = true;
      break;
    case ShipmentMilestoneStatus.Confirmed:
      if (this.value === null) {
        classList.new = true;
      } else {
        classList.confirmed = true;
      }
      break;
    }

    switch (this.priority) {
    case 1:
      classList.priority1 = true;
      break;
    case 2:
      classList.priority2 = true;
      break;
    }

    if (!this.priority && this.ruleResult) {
      switch (this.ruleResult.status) {
      case RuleResultStatus.Warning:
        classList.ruleWarning = true;
        break;
      case RuleResultStatus.Critical:
        classList.ruleCritical = true;
        break;
      }
    }

    return classList;
  }

  get formattedDate(): string {
    if (this.value === null || this.value === '') {
      return '';
    }

    return moment.tz(this.value, this.userTimezone).format(this.userDateFormat);
  }

  get formattedTime(): string {
    if (this.value === null || this.value === '') {
      return '';
    }

    return moment.tz(this.value, this.userTimezone).format('HH:mm');
  }

  get canUpdateShipmentValue(): boolean {
    return this.$can('update', 'Shipment', 'value');
  }

  @Emit()
  shipmentMilestoneClicked(): string {
    return this.milestoneId;
  }
}
