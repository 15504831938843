import { FilterOperation } from '@/types/filter-operation.enum';

export const initialFilters = {
  'ref': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.origin': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.voyage': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.originCountry': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.destination': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.destinationCountry': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.destinationRegion': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'mawb': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'hawb': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'remarks': {
    value: '',
    operation: FilterOperation.Equals,
  },
  'delayReasons': {
    value: '',
    operation: FilterOperation.Equals,
  },
  'customer': {
    value: [],
    operation: FilterOperation.Equals,
  },
  'trackingNumbers': {
    value: [],
    operation: FilterOperation.Equals,
  },
  'sku': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.realConsignee': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.firstVesselName': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.carrierCode': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.consignee': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'priorDocument': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.customer': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.customRef': {
    value: '',
    operaton: FilterOperation.Contains,
  },
  'custom.firsVesselName': {
    value: '',
    operaton: FilterOperation.Contains,
  },
  'custom.firstVesselVoyage': {
    value: '',
    operaton: FilterOperation.Contains,
  },
  'custom.wareHouseInstruction': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.secondVesselVoyage': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.loadingRef': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.loadingLocation': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.pickupHours': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.secondVesselName': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.operatorid': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.description': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.thirdVesselName': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.thirdVesselVoyage': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.ticketNumber': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.terminal': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.sono': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.truckerPU': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.truckerDel': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.time': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.sellingProduct': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'containerNumber': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'containerType': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'flight.name': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'service.type': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'service.level': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'typeName': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'freightTerm': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.loadTerm': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.vesselId': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.officeCode': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.shipper': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.shipperCity': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.shipperRef': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'pieces': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'sortedPallets': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'grossWeight': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'chargeableWeight': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'volume': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'invoiceNumber': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.pic': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.preAlert': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'updatedAt': {
    value: '',
    operation: FilterOperation.Contains,
  },
  'custom.externalEDIReference': {
    value: '',
    operation: FilterOperation.Contains,
  },
};
