








































































































































































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { Office } from '../services/api/offices/office.class';
import { DateFormat } from '../services/api/users/date-format.enum';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { SavableComponent } from '../types/savable-component.class';
import FieldSelect from '../components/FieldSelect.vue';
import { Company } from '../services/api/companies/company.class';
import env from '../../env.config';

const officesModule = namespace('offices');
const companiesModule = namespace('companies');
const toastersModule = namespace('toasters');
const utilityModule = namespace('utility');

@Component({
  components: { FieldSelect },
})
export default class OfficeGeneral extends SavableComponent {
  @officesModule.Action('FETCH_ONE')
  fetchOffice!: (id: string) => Promise<Office>;

  @officesModule.Getter('GET')
  office!: Office;

  @companiesModule.Getter('ALL')
  companies!: any;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @officesModule.Action('CREATE')
  createOffice!: (office: Office) => Promise<Office>;

  @officesModule.Action('UPDATE')
  updateOffice!: (payload: {
    id: string;
    office: Partial<Office>;
  }) => Promise<Office>;

  @utilityModule.Getter('GET_FIELD_SELECT_OPTIONS')
  options!: any[];

  @utilityModule.Action('FETCH_FIELD_SELECT_OPTIONS')
  fetchOptions!: (options: {
    includeAttachments: boolean;
    includeMilestones: boolean;
  }) => Promise<void>;

  form: Office = new Office();

  dateformats: any[] = Object.values(DateFormat).map((x: string) => ({
    text: x,
    value: x,
  }));

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  @Watch('office', { deep: true })
  onTypeChange(newVal: Office): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.form = { ...newVal };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.form.company = (this.form.company._id! as unknown) as Company;
    this.$validator.reset();
  }

  get isEditing(): boolean {
    return !!this.$route.params.id;
  }

  async save(): Promise<void> {
    await this.$validator.validate().then(async (result: any) => {
      if (result) {
        const office = {
          ...this.form,
        };
        let officeResult: Office = new Office();
        if (this.form._id) {
          officeResult = await this.updateOffice({ id: this.form._id, office });
        } else {
          officeResult = await this.createOffice(office);
        }

        if (!this.isEditing) {
          this.$router.replace({
            name: 'Office',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            params: { id: officeResult._id! },
          });
        }
      }
    });
  }

  addAggregationFields(aggregationFields: string[]): void {
    this.form.aggregationFields = aggregationFields;
  }

  removeAggregationFields(aggregationFields: string[]): void {
    this.form.aggregationFields = aggregationFields;
  }

  created(): void {
    if (this.isEditing) {
      this.fetchOffice(this.$route.params.id);
      this.fetchOptions({
        includeAttachments: false,
        includeMilestones: false,
      });
    }
    if (!this.isEditing) {
      this.form.aggregationFields = [];
      this.form.aggregationFields.push('ref');
    }
  }

  copyToClipboard(): void {
    this.$copyText(this.office.inboundEmailAddress);
    this.addToast({
      title: 'Copied to clipboard',
      message: '',
      type: ToasterTypes.SUCCESS,
    });
  }

  async refreshInboundEmail(): Promise<void> {
    const id = this.office._id;

    const randomNumber = Math.random().toString(36).substring(2, 11);

    const environment = (env.ENV as string).toLowerCase();
    let domain = 'inbound.mileviewer.com';
    if (environment !== 'PRODUCTION') {
      domain = `inbound.${environment}.mileviewer.com`;
    }
    let email;
    if (id) {
      email = `${randomNumber}.${id}@${domain}`;
    }

    await this.updateOffice({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: this.office._id!,
      office: { inboundEmailAddress: email },
    });

    await this.fetchOffice(this.$route.params.id);

    this.addToast({
      title: 'New inbound emailAddress generated',
      message: '',
      type: ToasterTypes.SUCCESS,
    });
  }
}
