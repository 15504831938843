


























































import { Vue, Component } from 'vue-property-decorator';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { ReportLog } from '@/services/api/reports/report-log.class';
import { FilterParams } from '@/types/filter-params.interface';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import api from '@/services/api';
import { Report } from '@/services/api/reports/report.class';
import moment from 'moment';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { Sort } from '@/utils/sort.class';
import { BModal } from 'bootstrap-vue';

const reportsModule = namespace('reports');

@Component({
  components: {
    EntityList,
  },
})
export default class ReportLogs extends Vue {
  @reportsModule.Action('FETCH_REPORT_LOGS')
  fetchLogs!: (params: FilterParams) => Promise<PaginatedResponse<ReportLog[]>>;

  @reportsModule.Getter('LOGS')
  reportLogs!: PaginatedResponse<ReportLog>;

  @Prop({
    required: false,
  })
  filter!: FilterParams;

  @Prop({
    required: false,
    type: Object,
  })
  report!: Report;

  $refs!: Vue['$refs'] & {
    emailToListModal: BModal;
  };

  sortDirection = 'desc';
  dateFormat = 'ddd, MMM DD, YYYY H:mm';

  selectedReportLog = new ReportLog();

  get params(): FilterParams {
    this.filter.sort = ['createdAt,desc'];
    this.filter.limit = 5;
    return { ...this.filter };
  }

  columns: Array<EntityListColumn<ReportLog>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.createdAt,
      label: 'Sent at',
      isHTML: true,
      showSortable: true,
      sortField: 'createdAt',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.getFileSize,
      label: 'File size',
      isHTML: true,
    },
  ];

  customButtons = [
    { variant: 'primary', icon: 'envelope', event: 'openEmailToList' },
    {
      variant: 'light',
      icon: 'download',
      event: 'downloadReport',
      margin: 'mr-0',
    },
  ];

  async downloadReport(item: ReportLog): Promise<void> {
    const file = await api.files.getFile(item.file.url);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${this.report.name}
    -${moment(item.createdAt).format(this.dateFormat)}.xlsx`,
    ); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  openEmailToList(item: ReportLog): void {
    this.selectedReportLog = item;
    this.$refs.emailToListModal.show();
  }

  createdAt(item: ReportLog): string {
    return moment(item.createdAt).format(this.dateFormat);
  }

  getFileSize(item: ReportLog): string {
    const Mb = (item.file.size / (1024 * 1024)).toFixed(2);
    return `${Mb.toString()}MB`;
  }

  async doSort(item: Sort): Promise<void | string> {
    if (this.sortDirection === 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }
    this.params.sort = [`${item.column},${this.sortDirection}`];
    await this.fetchLogs(this.params);
  }

  handleFilterChange(
    filter: FetchAllParams,
  ): Promise<PaginatedResponse<ReportLog[]>> {
    return this.fetchLogs(filter);
  }

  async created(): Promise<void> {
    await this.fetchLogs(this.params);
  }
}
