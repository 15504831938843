import { Report } from '@/services/api/reports/report.class';
import ReportsService from '@/services/api/reports/reports.service';
import { ToasterTypes } from './toasters/types';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { SortOrder } from '@/services/api/sort-order.enum';
import { RootState } from './types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Filter } from '@/types/filter.interface';
import { ReportLog } from '@/services/api/reports/report-log.class';
import { AxiosError } from 'axios';
import { Office } from '@/services/api/offices/office.class';

const reportsService = new ReportsService();

interface ReportState {
  one: Report;
  all: PaginatedResponse<Report>;
  allOptions: Option[];
  allCompanyReports: Report[];
  apiParams: FetchAllParams;
  logs: PaginatedResponse<ReportLog>;
}

const initialReportState: ReportState = {
  one: new Report(),
  all: new PaginatedResponseClass(),
  allOptions: [],
  allCompanyReports: [],
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
  logs: new PaginatedResponseClass(),
};

const getters: GetterTree<ReportState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  ALL_OPTIONS: state => state.allOptions,
  API_PARAMS: state => state.apiParams,
  LOGS: state => state.logs,
};

const mutations: MutationTree<ReportState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<Report>) {
    state.all = payload;
  },
  SET_ALL_COMPANY_REPORTS(state, payload: Report[]) {
    state.allCompanyReports = payload;
  },
  SET_ONE(state, payload: Report) {
    state.one = payload;
  },
  SET_LOGS(state, payload: PaginatedResponse<ReportLog>) {
    state.logs = payload;
  },
  SET_ALL_SELECT_OPTIONS(state, payload: Report[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const allNewOptions = payload.map((report: Report) => {
      const office = report.office as Office;
      const officeNameStr =
        office && office.name ? office.name + ' - ' + report.name : report.name;
      return {
        value: report._id,
        text: officeNameStr,
      } as Option;
    });

    state.allOptions = allNewOptions;
  },
};

const actions: ActionTree<ReportState, RootState> = {
  async CREATE(context, payload: Report): Promise<Report> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportsService.create(payload);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Report created successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string): Promise<Report> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportsService.fetchOne(id);
      context.commit('SET_ONE', report);
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context,
    filter?: Filter,
  ): Promise<PaginatedResponse<Report>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const reports = await reportsService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', reports);
      return reports;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL_COMPANY_REPORTS(context, filter?: Filter) {
    context.commit('SET_API_PARAMS', filter);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const reports = await reportsService.fetchAllCompanyReports(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      context.getters.API_PARAMS,
    );
    context.commit('SET_ALL_COMPANY_REPORTS', reports);
    context.commit('SET_ALL_SELECT_OPTIONS', reports);
    return reports;
  },
  async UPDATE(
    context,
    payload: { id: string; report: Report },
  ): Promise<Report> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportsService.update(payload.id, payload.report);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        { title: 'Save successfully', message: '', type: ToasterTypes.SUCCESS },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DUPLICATE(
    context,
    payload: { report: Report },
  ): Promise<Report | AxiosError<Report> | undefined> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportsService.duplicateReport(payload.report);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Report copied successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return report;
    } catch (error) {
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Copying report failed',
          message: (error as AxiosError).message,
          type: ToasterTypes.ERROR,
        },
        { root: true },
      );
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string): Promise<Report> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportsService.delete(payload);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Report removed successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_REPORT_LOGS(
    context,
    params?: FetchAllParams,
  ): Promise<PaginatedResponse<ReportLog>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', params);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const logs = await reportsService.fetchLogs(context.getters.API_PARAMS);
      context.commit('SET_LOGS', logs);
      return logs;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialReportState,
  getters,
  mutations,
  actions,
};
