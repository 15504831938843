import EntitiesService from '../entities/entities.service';
import { OfficeDuplication } from './office-duplication.class';

export default class OfficeDuplicationsService extends EntitiesService<
OfficeDuplication
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) +
      '/office-duplications'
    );
  }
}
