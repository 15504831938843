





















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DaySelect extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  value!: number[];

  get selectedValues(): number[] {
    return this.value;
  }

  set selectedValues(selection: number[]) {
    this.onChange(selection);
  }
  weekdays = [
    { text: 'Mon', value: 1, pressed: false },
    { text: 'Tue', value: 2, pressed: false },
    { text: 'Wed', value: 3, pressed: false },
    { text: 'Thu', value: 4, pressed: false },
    { text: 'Fri', value: 5, pressed: false },
    { text: 'Sat', value: 6, pressed: false },
    { text: 'Sun', value: 7, pressed: false },
  ];

  @Emit('input')
  onChange(value: number[]): number[] {
    return value;
  }

  updateValue(dayValue: number): void {
    if (this.selectedValues) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.selectedValues.includes(dayValue)
        ? this.selectedValues.splice(this.selectedValues.indexOf(dayValue), 1)
        : this.selectedValues.push(dayValue);
    }
    this.checkActiveDays();
    this.$emit('selectedDay', dayValue);
  }
  checkActiveDays(): void {
    for (const [index, day] of this.weekdays.entries()) {
      // eslint-disable-next-line
      this.selectedValues.includes(day.value)
        ? (this.weekdays[index].pressed = true)
        : (this.weekdays[index].pressed = false);
    }
  }
  created(): void {
    this.checkActiveDays();
  }
}
