import axios from '@/axios';
import { FileResponse } from './file-response.interface';
import { AxiosResponse } from 'axios';

export default {
  get endpoint(): string {
    return (localStorage.getItem('local_base_url') as string) + '/files';
  },
  async create(file: File, endpointOverride?: string): Promise<FileResponse[]> {
    const formData = new FormData();

    /*
        Add the form data we need to submit
    */
    formData.append('files', file);
    const response = await axios.post<any>(
      (endpointOverride ? endpointOverride : this.endpoint) + '/upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  },
  async getFile(fileUrl: string): Promise<Blob> {
    const response = (await axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // important
    })) as AxiosResponse<Blob>;
    return response.data;
  },
};
