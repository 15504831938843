import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import MilestoneTypesService from '@/services/api/milestone-types/milestone-types.service';
import { MilestoneType } from '@/services/api/milestone-types/milestone-type.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { Filter } from '@/types/filter.interface';

const milestoneTypesService = new MilestoneTypesService();
const namespaced = true;

interface StoreState {
  one: MilestoneType;
  all: MilestoneType[];
  allPaginated: PaginatedResponse<MilestoneType>;
  apiParams: FetchAllParams;
}

const initialMilestoneTypeState: StoreState = {
  one: new MilestoneType(),
  all: [],
  allPaginated: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<StoreState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all,
  ALL_PAGINATED: state => state.allPaginated,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<StoreState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: MilestoneType[]) {
    state.all = payload;
  },
  SET_ALL_PAGINATED(state, payload: PaginatedResponse<MilestoneType>) {
    state.allPaginated = payload;
  },
  SET_ONE(state, payload: MilestoneType) {
    state.one = payload;
  },
};
const actions: ActionTree<StoreState, RootState> = {
  async CREATE(context, payload: MilestoneType) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestoneType = await milestoneTypesService.create(payload);
      context.commit('SET_ONE', milestoneType);
      return milestoneType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestoneType = await milestoneTypesService.fetchOne(id);
      context.commit('SET_ONE', milestoneType);
      return milestoneType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const milestoneTypes = await milestoneTypesService.fetchAll(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', milestoneTypes);
      return milestoneTypes;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL_PAGINATED(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const milestoneTypes = await milestoneTypesService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL_PAGINATED', milestoneTypes);
      return milestoneTypes;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; milestoneType: MilestoneType }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestoneType = await milestoneTypesService.update(
        payload.id,
        payload.milestoneType,
      );
      context.commit('SET_ONE', milestoneType);
      return milestoneType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const milestoneType = await milestoneTypesService.delete(payload);
      context.commit('SET_ONE', milestoneType);
      return milestoneType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced,
  getters,
  mutations,
  actions,
  state: initialMilestoneTypeState,
};
