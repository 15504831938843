import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import env from '../../../../env.config';
import { FetchAllParams } from '../fetch-all-params.interface';

export default class LogsService extends EntitiesService<unknown> {
  get endpoint(): string {
    if (env.USE_SEPERATE_LOGS_API === 'true') {
      return env.LOG_URL;
    }

    return (localStorage.getItem('local_base_url') as string) + '/logging';
  }

  async mailReport(params: FetchAllParams): Promise<any> {
    return axios.post<any>(`${this.endpoint}/report`, params);
  }

  async downloadReport(params: FetchAllParams): Promise<any> {
    return axios.get<any>(`${this.endpoint}/report`, { params });
  }
}
