


























































































































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { Milestone } from '../services/api/milestones/milestone.class';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { DragEventOutput } from '../types/drag-event-output.interface';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { BModal } from 'bootstrap-vue';
import { Office } from '@/services/api/offices/office.class';
import { Report } from '@/services/api/reports/report.class';
import { FilterOperation } from '@/types/filter-operation.enum';
import { CustomerForm } from '@/types/customer/customer-form.interface';
import { CustomerMilestone } from '@/types/customer/customer-milestone.class';
import { Rule } from '@/services/api/rules/rule.class';
import { RuleParams } from '@/services/api/rules/rule-params.interface';
import { ShipmentType } from '@/services/api/shipment-types/shipment-type.class';

const milestonesModule = namespace('milestones');
const milestoneTypesModule = namespace('milestoneTypes');
const usersModule = namespace('users');
const reportsModule = namespace('reports');
const customersModule = namespace('customers');
const rulesModule = namespace('rules');
const shipmentTypesModule = namespace('shipmentTypes');

@Component({
  components: {
    EntityList,
  },
})
export default class AllMilestones extends Vue {
  @milestonesModule.Getter('ALL_PAGINATED')
  milestones!: PaginatedResponseClass<Milestone>;

  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @milestonesModule.Action('FETCH_PAGINATED')
  fetchAll!: (filter?: any) => Promise<void>;

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchAllMilestoneTypes!: (filter?: any) => void;

  @milestonesModule.Action('UPDATE_LIST')
  updateListSequence!: (dragEvent: DragEventOutput) => Promise<void>;

  @milestonesModule.Action('DELETE')
  deleteById!: (id: string) => Promise<void>;

  @milestonesModule.Action('SAVE_LIST')
  saveMilestoneList!: (milestone: Milestone[]) => Promise<Milestone[]>;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @reportsModule.Action('FETCH_ALL')
  fetchAllReports!: (params: FetchAllParams) => Promise<Report[]>;

  @reportsModule.Getter('ALL')
  reports!: Report[];

  @customersModule.Action('FETCH_ALL')
  fetchAllCustomers!: (
    params: FetchAllParams,
  ) => Promise<PaginatedResponseClass<CustomerForm[]>>;

  @customersModule.Getter('ALL')
  customers!: PaginatedResponseClass<CustomerForm>;

  @rulesModule.Action('FETCH_ALL')
  fetchAllRules!: (params: FetchAllParams) => Promise<Rule[]>;

  @rulesModule.Getter('ALL')
  rules!: Rule[];

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchAllShipmentTypes!: (params: FetchAllParams) => Promise<ShipmentType[]>;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentType[];

  filter: FetchAllParams = {};
  affectedReports: Report[] = [];
  affectedCustomerNotifications: CustomerForm[] = [];
  affectedRules: Rule[] = [];
  affectedShipmentTypes: ShipmentType[] = [];
  counter = 0;
  currentMilestone = new Milestone();
  interval!: any;
  disabled = true;

  $refs!: Vue['$refs'] & {
    confirmationModal: BModal;
  };

  public columns: any[] = [
    {
      field: 'sequence',
      label: 'Order',
      sortable: true,
      sortDirection: 'desc',
    },
    {
      field: 'name',
      label: 'Milestone Name',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.printMilestoneType,
      params: 'type',
      label: 'Milestone Type',
    },
    { field: 'code', label: 'Code' },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { field: this.formatBoolean, param: 'compareTime', label: 'Compare time' },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { field: this.formatBoolean, param: 'isEstimate', label: 'Is estimate' },
  ];

  printMilestoneType(milestone: MilestoneResponse): string {
    const msType: MilestoneType | undefined = this.milestoneTypes.find(
      x => x._id === milestone.type,
    );
    return msType ? msType.name : '';
  }

  formatBoolean(prop: boolean): string {
    if (prop === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  async handleDrag(event: DragEventOutput): Promise<void> {
    await this.updateListSequence(event);
    await this.saveMilestoneList(this.milestones.docs);
    this.filter.sort = ['sequence,ASC'];
    await this.fetchAll(this.filter);
  }

  redirectToDetail(milestone: Milestone): void {
    this.$router.push({
      name: 'Milestone',
      params: { id: milestone._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateMilestone',
    });
  }

  findMilestoneInReports(milestone: Milestone): void {
    this.affectedReports = [];
    this.reports.map(report =>
      report.conditions.map(condition => {
        if (condition.data && condition.data.milestones) {
          condition.data.milestones.filter(mlst => {
            if (mlst._id === milestone._id) {
              this.affectedReports.push(report);
            }
          });
          if (
            condition.data.milestone &&
            condition.data.milestone._id === milestone._id
          ) {
            this.affectedReports.push(report);
          }
        }
      }),
    );
  }

  findMilestoneInCustomerNotifications(milestone: Milestone): void {
    this.affectedCustomerNotifications = [];
    this.customers.docs.map(customer => {
      (customer.customerMilestones as CustomerMilestone[]).filter(
        mlstnotfication => {
          if (mlstnotfication.milestone._id === milestone._id) {
            this.affectedCustomerNotifications.push(customer);
          }
        },
      );
    });
  }

  findMilestoneInRules(milestone: Milestone): void {
    this.affectedRules = [];
    this.rules.filter(rule => {
      if (
        ((rule.params as RuleParams).milestone1 as string) === milestone._id ||
        ((rule.params as RuleParams).milestone2 as string) === milestone._id
      ) {
        this.affectedRules.push(rule);
      }
    });
  }

  findMilestoneInShipmentTypes(milestone: Milestone): void {
    this.affectedShipmentTypes = [];
    const shipmentMilestone = this.milestoneTypes[0]._id;
    this.shipmentTypes.map(type =>
      type.requiredMilestones[shipmentMilestone].filter(mlst => {
        if (mlst === milestone._id) {
          this.affectedShipmentTypes.push(type);
        }
      }),
    );
  }

  async checkRemainingIssues(): Promise<void> {
    await this.fetchEntities();
    this.findMilestoneInRelatedEntitities(this.currentMilestone);
    this.checkIfIssuesAreResolved();
  }

  checkIfIssuesAreResolved(): void {
    if (
      this.affectedReports.length === 0 &&
      this.affectedCustomerNotifications.length === 0 &&
      this.affectedRules.length === 0 &&
      this.affectedShipmentTypes.length === 0
    ) {
      this.disabled = false;
    }
  }

  findMilestoneInRelatedEntitities(milestone: Milestone): void {
    this.findMilestoneInReports(milestone);
    this.findMilestoneInCustomerNotifications(milestone);
    this.findMilestoneInRules(milestone);
    this.findMilestoneInShipmentTypes(milestone);
    this.counter = +1;
  }

  redirectToDelete(milestone: Milestone): void {
    this.disabled = true;
    this.currentMilestone = milestone;
    if (milestone._id) {
      this.findMilestoneInRelatedEntitities(milestone);
    }
    this.checkIfIssuesAreResolved();
    this.interval = setInterval(() => {
      this.checkRemainingIssues();
    }, 30000);
    if (this.disabled) {
      this.$refs.confirmationModal.show();
      return;
    }
    const confirmation = confirm(
      `Are you sure you want to delete ${milestone.code}?`,
    );
    if (confirmation) {
      this.deleteMilestone();
    }
  }

  async deleteMilestone(): Promise<void> {
    if (this.currentMilestone._id) {
      await this.deleteById(this.currentMilestone._id);
    }
    this.fetchAll(this.filter);
    this.$refs.confirmationModal.hide();
    clearInterval(this.interval);
  }

  closeConfirmationModal(): void {
    this.$refs.confirmationModal.hide();
    clearInterval(this.interval);
  }

  async fetchEntities(): Promise<void> {
    if (this.office._id) {
      await this.fetchAllReports({
        limit: 999,
        filters: {
          office: {
            value: this.office._id,
            operation: FilterOperation.Equals,
          },
        },
      });
      await this.fetchAllCustomers({
        filters: {
          office: {
            value: this.office._id,
            operation: FilterOperation.Equals,
          },
        },
      });
      await this.fetchAllRules({
        filters: {
          office: {
            value: this.office._id,
            operation: FilterOperation.Equals,
          },
        },
      });
      await this.fetchAllShipmentTypes({
        filters: {
          office: {
            value: this.office._id,
            operation: FilterOperation.Equals,
          },
        },
      });
    }
  }
  beforeDestroy(): void {
    clearInterval(this.interval);
  }
  async created(): Promise<void> {
    this.fetchEntities();
    this.filter.sort = ['sequence,ASC'];
    await this.fetchAll(this.filter);
    this.fetchAllMilestoneTypes();
  }
}
