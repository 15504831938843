




















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '@/services/api/users/user.class';
import EntityList from '../components/EntityList.vue';
import { PaginatedResponseClass } from '@/services/api/paginated-response';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

const usersModule = namespace('users');

@Component({
  components: {
    EntityList,
  },
})
export default class AllUsers extends Vue {
  @usersModule.Getter('ALL_PAGINATED')
  users!: PaginatedResponseClass<User>;

  @usersModule.Getter('GET_ID')
  userId!: string;

  @usersModule.Action('FETCH_ALL')
  fetchSortedUsers!: (payload: any) => Promise<void>;

  @usersModule.Action('DELETE')
  deleteUserById!: (id: string) => Promise<User>;

  filter: FetchAllParams = {};

  public columns: any[] = [
    {
      field: 'username',
      label: 'Username',
      sortable: true,
      sortDirection: 'asc',
    },
    { field: 'firstName', label: 'Firstname', sortable: true },
    { field: 'lastName', label: 'Lastname', sortable: true },
    { field: 'email', label: 'Email', sortable: true },
    { field: 'actions', label: '' },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchSortedUsers(filter);
  }

  redirectToDetail(user: User): void {
    this.$router.push({
      name: 'User',
      params: { id: user._id || '' },
    });
  }

  deleteUser(user: User): boolean {
    if (user._id !== this.userId) {
      return false;
    }
    return true;
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateUser',
    });
  }

  async redirectToDelete(user: User): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + user.username + '?',
    );
    if (!result) {
      return;
    }
    await this.deleteUserById(user._id as string);
    await this.fetchSortedUsers(this.filter);
  }

  async created(): Promise<void> {
    this.filter.sort = ['username,asc'];
    this.filter.limit = 10;
    await this.fetchSortedUsers(this.filter);
  }
}
