/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ShipmentsState } from './types';

export const getters: GetterTree<ShipmentsState, RootState> = {
  ALL: state => state.shipments,
  ALL_ARCHIVED: (state, localGetters: any) =>
    localGetters.ALL.filter((shipment: ShipmentResponse) => shipment.archived),
  ALL_UNARCHIVED: (state: any, localGetters: any) =>
    localGetters.ALL.filter((shipment: ShipmentResponse) => !shipment.archived),
  ACTIVE: state => state.activeShipment,
  API_PARAMS: state => state.apiParams,
  NEW_SHIPMENTS: state => state.newShipments,
  IMPORT_RESULT: state => state.import.result,
  SELECTED: state =>
    state.shipments.find(
      (shipment: ShipmentResponse) => shipment._id === state.selected,
    ),
  GET_INFINITE_LOADING_IDENTIFIER: (state: ShipmentsState) =>
    state.infiniteLoadingIdentifier,
  COUNT: state => state.count,
  BREACHED_JOBS: state => state.breachedJobs,
  PER_MILESTONE: state => state.perMilestone,
  IS_LOADING: state => (key: string) => state.loadingDetail[key],
  ALL_CUSTOMERS: state => {
    const customerList = state.shipments.map(shipment => shipment.customer);
    const uniqueCustomerNames: string[] = [];

    const uniqueCustomers = customerList.filter(customer => {
      const customerNotAdded =
        customer && !uniqueCustomerNames.includes(customer.parent.name);

      if (
        customerNotAdded &&
        customer &&
        customer.parent &&
        customer.parent.name
      ) {
        uniqueCustomerNames.push(customer.parent.name);
        return customer;
      }
    });

    return uniqueCustomers;
  },
};
