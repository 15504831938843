const formatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const formatDate = (
  value: Date,
  options: { [key: string]: string } = formatOptions,
): string => {
  const selectedDate = value ? new Date(value) : new Date();
  const formatedDate = selectedDate.toLocaleString('en-US', options);

  return formatedDate || '';
};

export default formatDate;
