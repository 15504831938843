var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{class:_vm.cellClasses(
      _vm.field.milestone
        ? _vm.shipment.shipmentMilestones[_vm.field.milestone._id]
        : null
    )},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],class:("px-2 " + (_vm.prepClasses(_vm.field))),style:(("width: " + (_vm.field.width) + "px;")),attrs:{"title":_vm.field.showFullContent ? _vm.getField(_vm.field, _vm.shipment, _vm.office) : ''}},[(_vm.field.copy)?_c('a',{attrs:{"href":"#","size":"sm","variant":"outline"},on:{"click":function($event){_vm.copyToClipboard(_vm.getField(_vm.field, _vm.shipment, _vm.office))}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'copy']}})],1):_vm._e(),(_vm.field.fieldType === 'ms')?[(_vm.isDisplay)?_c('div',{staticClass:"input-wrapper"},[_c('input',{staticClass:"form-control form-control-sm test",attrs:{"disabled":""},domProps:{"value":_vm.formattedDate(_vm.shipment.shipmentMilestones[_vm.field.milestone._id])}})]):(
          _vm.field.milestone && _vm.shipment.shipmentMilestones[_vm.field.milestone._id]
        )?_c('app-shipment-milestone',{attrs:{"shipment-id":_vm.shipment._id,"milestone-id":_vm.field.milestone._id,"value":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].value,"priority":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].priority,"status":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].status,"rule-result":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].ruleResult,"is-loading":_vm.isLoading,"disabled":_vm.field.milestone.isDisabled},on:{"shipment-milestone-clicked":function($event){return _vm.shipmentMilestoneClicked($event, false)}}}):(_vm.field.milestone)?_c('shipment-milestone-placeholder'):_vm._e()]:[(_vm.field.fieldType === 'cb' && !_vm.field.milestone)?_c('app-shipment-checkbox',{attrs:{"loading-key":((_vm.shipment._id) + "." + (_vm.field.field)),"checked":Boolean(_vm.getField(_vm.field, _vm.shipment, _vm.office)),"disabled":false,"is-loading":_vm.isLoading},on:{"toggle":_vm.updateField}}):(
          _vm.field.fieldType === 'cb' &&
            _vm.field.milestone &&
            _vm.shipment.shipmentMilestones[_vm.field.milestone._id]
        )?_c('app-shipment-milestone-checkbox',{attrs:{"shipment-id":_vm.shipment._id,"milestone-id":_vm.field.milestone._id,"value":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].value,"priority":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].priority,"status":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].status,"rule-result":_vm.shipment.shipmentMilestones[_vm.field.milestone._id].ruleResult,"loading-key":((_vm.shipment._id) + "." + (_vm.field.field)),"checked":Boolean(_vm.getField(_vm.field, _vm.shipment, _vm.office)),"disabled":_vm.field.milestone.isDisabled,"is-loading":_vm.isLoading},on:{"toggle":function($event){return _vm.shipmentMilestoneClicked($event, true)}}}):(_vm.field.fieldType === 'cb')?_c('shipment-milestone-checkbox-placeholder'):(_vm.field.openCustomLink)?_c('app-shipment-custom-link',{attrs:{"shipment":_vm.shipment,"field":_vm.field}}):(_vm.checkForActions(_vm.field) && _vm.field.html)?_c('b-button',{class:((_vm.prepClasses(_vm.field)) + " p-0"),attrs:{"variant":"link","disabled":_vm.runFromString(_vm.field.isDisabled, _vm.shipment)},domProps:{"innerHTML":_vm._s(_vm.getField(_vm.field, _vm.shipment, _vm.office))},on:{"click":function($event){return _vm.emitActions(_vm.field, _vm.shipment)}}}):(_vm.checkForActions(_vm.field))?_c('b-button',{class:((_vm.prepClasses(_vm.field)) + " p-0"),attrs:{"variant":"link"},on:{"click":function($event){return _vm.emitActions(_vm.field, _vm.shipment)}}},[_vm._v(" "+_vm._s(_vm.getField(_vm.field, _vm.shipment, _vm.office))+" ")]):(_vm.field.html)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getField(_vm.field, _vm.shipment, _vm.office))}}):[(_vm.field.fieldIcon)?_c('font-awesome-icon',{attrs:{"icon":['far', _vm.field.fieldIcon]}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getField(_vm.field, _vm.shipment, _vm.office))+" ")]]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }