import { Milestone } from '@/services/api/milestones/milestone.class';
import { ITransformation } from '../../types/transformations';

export class UIField {
  _id?: string;
  milestone?: string | Milestone;
  fieldType?: string = '';
  func?: string;
  field = '';
  label?: string;
  filterType?: string;
  html?: boolean;
  width?: number;
  isDisabled?: boolean | string;
  icon = false;
  filterable = false;
  fieldIcon?: string;
  labelIcon?: string;
  showAsCheckbox = false;
  copy = false;
  truncate = false;
  bold = false;
  formatDatetime = false;
  openShipment = false;
  openCustomer = false;
  openCustomLink = false;
  showFullContent = false;
  customLinkTransformations: ITransformation[] = [];
}
