import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import IntegrationLogsService from '@/services/api/integration-logs/integration-logs.service';
import { IntegrationLog } from '@/services/api/integration-logs/integration-log.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { Filter } from '@/types/filter.interface';

const integrationLogsService = new IntegrationLogsService();
const namespaced = true;

interface StoreState {
  allPaginated: PaginatedResponse<IntegrationLog>;
  apiParams: FetchAllParams;
}

const initialIntegrationLogState: StoreState = {
  allPaginated: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`createdAt,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<StoreState, RootState> = {
  ALL_PAGINATED: state => state.allPaginated,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<StoreState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL_PAGINATED(state, payload: PaginatedResponse<IntegrationLog>) {
    state.allPaginated = payload;
  },
};
const actions: ActionTree<StoreState, RootState> = {
  async FETCH_ALL_PAGINATED(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const integrationLogs = await integrationLogsService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL_PAGINATED', integrationLogs);
      return integrationLogs;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced,
  getters,
  mutations,
  actions,
  state: initialIntegrationLogState,
};
