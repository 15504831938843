import { ReportCondition } from '../../../services/api/reports/report-condition.class';

const getDateRangeCondition = (
  conditions: ReportCondition[],
): ReportCondition | undefined => {
  const dateRangeConditionType = 'DATE_RANGE';

  if (conditions.length === 0) {
    return undefined;
  }

  const isDateRangeSelected = conditions.find(condition => {
    const conditionType = condition.conditionType;

    return conditionType === dateRangeConditionType;
  });

  return isDateRangeSelected;
};

export default getDateRangeCondition;
