



























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class IconSelector extends Vue {
  @Prop() iconList!: string[];

  @Prop({ default: false }) small!: boolean;

  @Prop() selectedIcon!: string;

  inputSearch = '';
  filteredIcons: string[] = [];

  onInput(val: string): void {
    this.filteredIcons = this.iconList.filter((icon: string) =>
      icon.includes(val),
    );
  }

  emitIcon(icon: string): void {
    this.$emit('selectedIcon', icon);
  }

  created(): void {
    this.filteredIcons = [...this.iconList];
  }
}
