export interface ToastersState {
  toasters: Toaster[];
}

export class Toaster {
  title = '';
  message = '';
  show?: number | boolean;
  type = ToasterTypes.SUCCESS;
}

export enum ToasterTypes {
  ERROR = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}
