






























































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { FileType } from '../services/api/attachments/file-type.enum';
import { Attachment } from '../services/api/attachments/attachment.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { BModal } from 'bootstrap-vue';

const attachmentsModule = namespace('attachments');

@Component({
  components: {
    EntityList,
  },
})
export default class Attachments extends Vue {
  @attachmentsModule.Getter('ALL_PAGINATED')
  attachments!: Attachment[];

  @attachmentsModule.Action('FETCH_ALL')
  fetchAll!: (
    filter?: FetchAllParams,
  ) => Promise<PaginatedResponse<Attachment>>;

  @attachmentsModule.Action('UPDATE')
  updateById!: (attachment: Attachment) => Promise<Attachment>;

  @attachmentsModule.Action('CREATE')
  createAttachment!: (attachment: Attachment) => Promise<Attachment>;

  @attachmentsModule.Action('DELETE')
  deleteAttachment!: (attachment: string) => Promise<Attachment>;

  public columns: any[] = [
    {
      field: 'name',
      label: 'Name',
    },
    {
      field: 'description',
      label: 'Description',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatBool,
      label: 'Private?',
      param: 'isPrivate',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatBool,
      label: 'Multiple?',
      param: 'multiple',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatFileTypes,
      label: 'Allowed Types',
      param: 'fileTypes',
    },
  ];
  searchCopy = '';
  status = false;
  localAttachment: Attachment = new Attachment();
  test: Attachment = new Attachment();
  filter: FetchAllParams = {
    limit: 10,
  };
  $refs!: Vue['$refs'] & {
    attachmentModal: InstanceType<typeof BModal>;
  };

  get fileTypes(): string[] {
    return Object.values(FileType);
  }

  openAttachmentModal(rowData?: Attachment): void {
    if (rowData) {
      this.localAttachment = { ...rowData };
    } else {
      this.localAttachment = new Attachment();
    }
    this.$refs.attachmentModal.show();
  }

  async handleAttachment(): Promise<void> {
    this.localAttachment.company = this.$route.params.id;
    if (!this.localAttachment._id) {
      await this.createAttachment(this.localAttachment);
    } else {
      await this.updateById(this.localAttachment);
    }
    this.localAttachment = new Attachment();
    await this.fetchAll(this.filter);
  }

  async handleDelete(rowData: Attachment): Promise<void> {
    if (rowData._id) {
      await this.deleteAttachment(rowData._id);
      await this.fetchAll(this.filter);
    }
  }

  async created(): Promise<void> {
    await this.fetchAll(this.filter);
  }

  formatBool(input?: boolean): string {
    switch (input) {
    case true:
      return '✔';
    case false:
      return '✘';
    default:
      return '';
    }
  }

  formatFileTypes(fileTypes: string[]): string {
    let output = '';
    if (fileTypes.length > 0) {
      for (const filetype of fileTypes) {
        if (!output) {
          output = filetype;
        } else {
          output = output.concat(', ', filetype);
        }
      }
    }
    return output;
  }
}
