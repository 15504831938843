















import { namespace } from 'vuex-class';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EntityList from '@/components/EntityList.vue';
import { ReportSchedule } from '@/services/api/reports/report-schedule.class';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { format, parseISO } from 'date-fns';
import getOfficeLocalTimeToSend from '../utils/getOfficeLocalTimeToSend';
import { Office } from '@/services/api/offices/office.class';

const usersModule = namespace('users');

@Component({
  components: {
    EntityList,
  },
})
export default class ReportScheduleList extends Vue {
  @Prop({ type: Array, required: true })
  reportSchedules!: ReportSchedule[];

  @usersModule.Getter('GET_WORKING_OFFICE')
  userOffice!: Office;

  public columns: Array<EntityListColumn<ReportSchedule>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.getTime,
      label: 'Send time',
      isHTML: true,
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.getRecurrence,
      label: 'Recurrence',
      isHTML: true,
    },
  ];

  dateFormat = 'EEE, MMM dd, yyyy HH:mm';

  getTime(schedule: ReportSchedule): string {
    const { timeToSend } = schedule;
    const { timezone } = this.userOffice;

    const officeLocalDateToSend = getOfficeLocalTimeToSend(
      timeToSend,
      timezone,
    );

    const officeLocalTimeToSend = format(
      parseISO(officeLocalDateToSend),
      this.dateFormat,
    );

    return `<p class="m-0">${officeLocalTimeToSend}</p>`;
  }

  getRecurrence(schedule: ReportSchedule): string {
    return `<p class="m-0">Repeat every ${
      schedule.repeatEvery !== 1 ? schedule.repeatEvery : ''
    } 
      ${
  schedule.repeatEvery !== 1
    ? schedule.recurrenceType.toLowerCase()
    : schedule.recurrenceType.toLowerCase().slice(0, -1)
} </br><span>
      ${schedule.recurrenceType === 'WEEKS' ? 'on' : ''} 
      ${
  schedule.recurrenceType === 'WEEKS'
    ? this.getWeekDays(schedule.weekdays)
    : ''
}
      ${
  schedule.recurrenceType === 'MONTHS'
    ? schedule.day
      ? `on the ${this.getNth(schedule.day)} 
      of the month`
      : ''
    : ''
} 
      </span></p>`;
  }
  getNth(day: number) {
    if (day > 3 && day < 21) {
      return `${day}th`;
    }
    switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
    }
  }

  redirectToDetail(schedule: ReportSchedule): void {
    this.$emit('update', schedule);
  }
  redirectToRemove(schedule: ReportSchedule): void {
    this.$emit('remove', schedule);
  }

  getWeekDays(weekdays: number[]): string {
    let days = '';
    weekdays.map(day => {
      if (day === 1) {
        days = days + 'mon ';
      }
      if (day === 2) {
        days = days + 'tue ';
      }
      if (day === 3) {
        days = days + 'wed ';
      }
      if (day === 4) {
        days = days + 'thu ';
      }
      if (day === 5) {
        days = days + 'fri ';
      }
      if (day === 6) {
        days = days + 'sat ';
      }
      if (day === 7) {
        days = days + 'sun ';
      }
    });
    const seperatedDays = days.split(' ').join(', ').slice(0, -2);
    return seperatedDays;
  }
}
