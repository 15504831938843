















import { namespace } from 'vuex-class';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EntityList from '@/components/EntityList.vue';
import { Report } from '@/services/api/reports/report.class';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { Office } from '@/services/api/offices/office.class';

const usersModule = namespace('users');

@Component({
  components: {
    EntityList,
  },
})
export default class ReportScheduleList extends Vue {
  @Prop({ type: Array, required: true })
  reportSchedules!: Array<{ office: string; report: string }>;

  @usersModule.Getter('GET_WORKING_OFFICE')
  userOffice!: Office;

  public columns: Array<EntityListColumn<Report>> = [
    {
      field: 'office',
      label: 'Office',
      isHTML: true,
    },
    {
      field: 'report',
      label: 'Report',
      isHTML: true,
    },
  ];

  redirectToRemove(report: Report): void {
    this.$emit('remove', report);
  }
}
