





















































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { Translation } from '@/services/api/translations/translation.class';

const utilityModule = namespace('utility');
const translationsModule = namespace('translations');

@Component({
  components: {
    Multiselect,
  },
})
export default class FieldSelect extends Vue {
  @Prop() value!: {};
  @Prop({ default: true })
  includeMilestones!: boolean;

  @Prop({ default: true })
  includeAttachments!: boolean;

  @Prop({ default: false })
  includeFilterOptions!: boolean;

  @Prop({ default: false })
  multipleOptions!: boolean;

  @utilityModule.Getter('GET_FIELD_SELECT_OPTIONS')
  options!: Option[];

  @utilityModule.Action('FETCH_FIELD_SELECT_OPTIONS')
  fetchOptions!: (options: {
    includeAttachments: boolean;
    includeMilestones: boolean;
    includeFilterOptions: boolean;
  }) => Promise<void>;

  @translationsModule.Action('FETCH_ALL')
  fetchTranslations!: (filter?: string) => Promise<Translation[]>;

  @translationsModule.Getter('ALL')
  translations!: Translation[];

  searchQuery: string[] = [];

  modelValue: any[] = [];

  $refs!: Vue['$refs'] & {
    customFieldSelect: Multiselect;
  };

  @Watch('value', {
    immediate: true,
  })
  onValueChange(value: any): void {
    if (value) {
      if (this.multipleOptions) {
        this.modelValue = this.options.filter(option =>
          value.includes(option.value),
        );
      } else if (this.isStringInput) {
        const search = this.options.find(option => option.value === value);
        if (search) {
          this.modelValue = search as any;
        }
      } else {
        this.modelValue = value;
      }
    }
  }

  @Emit('input')
  onChange(selectedValue: any): any[] {
    if (this.multipleOptions) {
      const result = this.modelValue.map(x => x.value);
      return [...this.modelValue.map(x => x.value)];
    } else if (this.isStringInput) {
      return selectedValue && selectedValue.value
        ? selectedValue.value
        : undefined;
    } else {
      return selectedValue;
    }
  }

  async created(): Promise<void> {
    await this.fetchOptions({
      includeAttachments: this.includeAttachments,
      includeMilestones: this.includeMilestones,
      includeFilterOptions: this.includeFilterOptions,
    });
    await this.searchTranslations('');
    this.onValueChange(this.value);
  }

  get filteredOptions(): Option[] {
    const filteredOptionsArray = this.options.filter(option =>
      this.searchQuery.includes(this.$t(option.text) as string),
    );
    const sortedArray = filteredOptionsArray.sort(
      (referenceOption: Option, compareOption: Option) => {
        const referenceString = this.$t(referenceOption.text)
          ? (this.$t(referenceOption.text) as string)
          : referenceOption.text;
        const compareString = this.$t(compareOption.text)
          ? (this.$t(compareOption.text) as string)
          : compareOption.text;
        return referenceString.localeCompare(compareString, this.$i18n.locale);
      },
    );
    return sortedArray;
  }

  get isStringInput(): boolean {
    return typeof this.value === 'string';
  }

  async searchTranslations(s: string): Promise<void> {
    await this.fetchTranslations(s);
    this.searchQuery = this.translations.map(item => item.value);
  }
}
