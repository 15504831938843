import { CustomerDeliverySchedule } from './customer-delivery-schedule.class';

export class CustomerDelivery {
  name = '';
  address = {
    street: '',
    city: '',
    country: '',
  };
  contact = {
    name: '',
    phone: {
      office: '',
      mobile: '',
    },
    email: '',
  };
  businessHours: CustomerDeliverySchedule[] = [];
  appointment = '';
  sortInstruction = '';
  customsHandling = '';
}
