














import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Toaster } from '@/store/modules/toasters/types';

const toastersModule = namespace('toasters');

@Component({})
export default class ToasterComponent extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  toaster!: Toaster;

  @toastersModule.Action('REMOVE_TOASTER')
  removeToaster!: (toaster: Toaster) => Promise<void>;
}
