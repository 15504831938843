var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('div',{staticClass:"d-flex justify-content-start align-items-center",class:{ 'bg-secondary': _vm.showFilters, 'search-bar': _vm.showFilters }},[_c('div',{staticStyle:{"width":"45px"}},[_c('div',{staticClass:"bg-secondary rounded-circle d-flex justify-content-center align-items-center search-filter mx-auto",attrs:{"data-testid":"sticky-search-shipment"},on:{"click":_vm.toggleFilters}},[(_vm.showFilters)?_c('font-awesome-icon',{attrs:{"icon":['far', 'times'],"fixed-width":"","size":"sm"}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search'],"fixed-width":"","size":"sm"}})],1)]),_vm._l((_vm.stickyFields),function(field,index){return _c('div',{key:index,staticClass:"px-2",style:(("width:" + (field.width) + "px")),attrs:{"data-testid":("column-" + (field.field))}},[(field.filterType && _vm.showFilters)?[(field.filterType === 'txt' && _vm.filters[field.field])?_c('input',{staticClass:"form-control form-control-sm border-top-0 border-right-0 border-left-0 rounded-0",attrs:{"type":"text","placeholder":field.label},domProps:{"value":_vm.checkIfFilterExists(field.field)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filter(
              field.field,
              $event.target.value,
              _vm.filters[field.field].operation
            )}}}):(!_vm.filters[field.field] || !field.filterType === 'select')?_c('p',{staticClass:"m-0 not-searchable",attrs:{"id":("" + (field.label))}},[_vm._v(" "+_vm._s(field.label)+" ")]):_vm._e(),_c('b-tooltip',{attrs:{"target":("" + (field.label)),"placement":"bottom"}},[_vm._v(" Not searchable ")]),(field.filterType === 'select')?_c('app-customer-select',{attrs:{"value":_vm.filters[field.field].value},on:{"close":function($event){_vm.filter(
              'customer',
              $event.map(function (customer) { return (customer ? customer._id : null); }),
              _vm.filters.customer.operation
            )}}}):_vm._e()]:(field.fieldType === 'ms')?[(field.label)?_c('a',{staticClass:"sort font-weight-bold"},[_vm._v(_vm._s(field.label))]):_vm._e(),(field.milestone)?_c('a',{staticClass:"sort font-weight-bold",on:{"click":function($event){return _vm.doSort(("shipmentMilestones[" + (field.milestone._id) + "].value"))}}},[_vm._v(" "+_vm._s(field.milestone.code)+" "),(
              _vm.getSortLevel(
                ("shipmentMilestones[" + (field.milestone._id) + "].value")
              ) === 0
            )?_c('span',{staticClass:"sort-span"},[_c('font-awesome-icon',{attrs:{"icon":_vm.sortIcon(_vm.sort[0].asc)}})],1):_vm._e(),(
              _vm.getSortLevel(
                ("shipmentMilestones[" + (field.milestone._id) + "].value")
              ) === 1
            )?_c('span',{staticClass:"sort-span sort-level-2"},[_c('font-awesome-icon',{attrs:{"icon":_vm.sortIcon(_vm.sort[1].asc)}})],1):_vm._e()]):_vm._e()]:[(field.labelIcon)?_c('font-awesome-icon',{attrs:{"icon":['far', field.labelIcon]}}):_vm._e(),_c('a',{staticClass:"sort",on:{"click":function($event){return _vm.doSort(field.field)}}},[_vm._v(_vm._s(field.label))]),(_vm.getSortLevel(field.field) === 0)?_c('span',{staticClass:"sort-span"},[_c('font-awesome-icon',{attrs:{"icon":_vm.sortIcon(_vm.sort[0].asc)}})],1):_vm._e(),(_vm.getSortLevel(field.field) === 1)?_c('span',{staticClass:"sort-span sort-level-2"},[_c('font-awesome-icon',{attrs:{"icon":_vm.sortIcon(_vm.sort[1].asc)}})],1):_vm._e()]],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }