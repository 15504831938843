import { Transformation } from './../../services/api/transformations/transformation.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import TransformationsService from '@/services/api/transformations/transformations.service';
import { DragEventOutput } from '@/types/drag-event-output.interface';
import { ActionContext, Module } from 'vuex';
import { RootState } from './types';
import { ConditionOperator } from '@/services/api/transformations/condition-operator.enum';
import { changeItemsSequence } from '@/utils/sequence.util';
import { SortOrder } from '@/services/api/sort-order.enum';

const transformationsService = new TransformationsService();

export interface TransformationState {
  all: PaginatedResponse<Transformation>;
  apiParams: FetchAllParams;
  filterList: PaginatedResponse<Transformation>;
}

const transformationsModule: Module<TransformationState, RootState> = {
  namespaced: true,
  state: {
    all: {
      docs: [],
      total: 0,
      limit: 0,
      page: 0,
      pages: 0,
    },
    apiParams: {
      limit: 9999,
    },
    filterList: {
      docs: [],
      total: 0,
      limit: 0,
      page: 0,
      pages: 0,
    },
  },
  getters: {
    ALL: (state: TransformationState) => state.all,
    API_PARAMS: (state: TransformationState) => state.apiParams,
    FILTER_LIST: (state: TransformationState) => state.filterList,
  },
  mutations: {
    SET_API_PARAMS(state, payload: FetchAllParams) {
      state.apiParams = {
        ...payload,
        limit: 9999,
        sort: [`sequence, ${SortOrder.Asc}`],
      };
    },
    SET_ALL(state, payload: PaginatedResponse<Transformation>) {
      state.all = payload;
      state.filterList = payload;
    },
    UPDATE_ONE(state, payload: Transformation) {
      state.all = {
        ...state.all,
        docs: [
          ...state.all.docs.map(transformation =>
            transformation._id === payload._id ? payload : transformation,
          ),
        ],
      };
    },
    ADD_ONE(state, payload: Transformation) {
      state.all = {
        ...state.all,
        docs: [...state.all.docs, payload],
      };
    },
  },
  actions: {
    async FETCH_ALL(
      context: ActionContext<TransformationState, RootState>,
      filter?: any,
    ) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        if (filter) {
          context.commit('SET_API_PARAMS', filter);
        }

        const transformations = await transformationsService.fetch(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          context.getters.API_PARAMS,
        );
        context.commit('SET_ALL', transformations);

        return transformations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    UPDATE_LIST(context, dragEvent: DragEventOutput) {
      const transformations = changeItemsSequence(
        context.state.all.docs,
        dragEvent,
      );
      const paginated = { ...context.state.all, docs: transformations };
      context.commit('SET_ALL', paginated);
    },
    async SAVE_LIST(context) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        let transformations = context.state.all.docs;
        transformations = [
          ...transformations.map(transformation =>
            cleanEmptyOperationsAndConditions(transformation),
          ),
        ];

        await transformationsService.bulk(transformations);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },

    async BULK_UPDATE(
      context: ActionContext<TransformationState, RootState>,
      payload: Transformation[],
    ): Promise<void> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        await transformationsService.bulk(payload);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async CREATE(context, payload: Transformation) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });

        const cleanedTransformation = cleanEmptyOperationsAndConditions(
          payload,
        );
        const response = await transformationsService.create(
          cleanedTransformation,
        );

        context.commit('ADD_ONE', response);
        return response;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async BULK_CREATE(
      context: ActionContext<TransformationState, RootState>,
      payload: Transformation[],
    ): Promise<void> {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        await transformationsService.bulkCreate(payload);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async UPDATE(
      context,
      payload: { id: string; transformation: Transformation },
    ) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });

        const cleanedTransformation = cleanEmptyOperationsAndConditions(
          payload.transformation,
        );
        const response = await transformationsService.update(
          payload.id,
          cleanedTransformation,
        );

        context.commit('UPDATE_ONE', response);
        return response;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async DELETE(context, payload: string) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const response = await transformationsService.delete(payload);

        const transformationIndex = context.state.all.docs.findIndex(
          trans => trans._id === payload,
        );
        const updatedTransformations = changeItemsSequence(
          context.state.all.docs,
          { oldIndex: transformationIndex, newIndex: transformationIndex },
          true,
        );
        const paginated = {
          ...context.state.all,
          docs: updatedTransformations,
        };
        context.commit('SET_ALL', paginated);
        context.dispatch('SAVE_LIST');
        return response;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
  },
};

export default transformationsModule;

function cleanEmptyOperationsAndConditions(
  transformation: Transformation,
): Transformation {
  const cleanedTransformation: Transformation = {
    ...transformation,
    operations: transformation.operations.filter(
      operation =>
        operation.operator &&
        operation.transformationFields &&
        operation.outputField,
    ),
    conditions: transformation.conditions.filter(
      condition =>
        condition.field &&
        (condition.operator === ConditionOperator.Exists ||
          (condition.operator &&
            condition.parameters &&
            condition.parameters.length)),
    ),
  };

  return cleanedTransformation;
}
