





























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ShipmentCheckbox extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  checked!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;

  @Emit()
  toggle(checked: boolean): boolean {
    return !checked;
  }
}
