






















































































































import { ReportSchedule } from '@/services/api/reports/report-schedule.class';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { RecurrenceType } from '@/types/reports/recurrence-type.enum';
import { DataObject } from '@/services/api/data-object.interface';
import FieldsDaySelect from '@/components/FieldsDaySelect.vue';

@Component({
  components: {
    FieldsDaySelect,
  },
})
export default class ReportScheduleForm extends Vue {
  @Prop({ type: Object, required: true })
  reportSchedule!: ReportSchedule;

  @Prop({ type: String })
  reportId!: string;

  recurrenceTypes: DataObject[] = Object.values(RecurrenceType).map(
    (item: string) => ({ text: item, value: item } as DataObject),
  );

  $refs!: {
    reportScheduleModal: BModal;
  };

  @Watch('reportSchedule.recurrenceType')
  onFormTypeChange(newType: RecurrenceType): RecurrenceType {
    this.reportSchedule.recurrenceType = newType;
    return newType;
  }

  closeReportScheduleModal(): void {
    this.$refs.reportScheduleModal.hide();
  }

  async createReportScheduleModal(): Promise<void> {
    await this.$validator.validate().then(result => {
      if (!result) {
        return;
      }
      if (
        this.reportSchedule.recurrenceType === 'WEEKS' &&
        !this.reportSchedule.weekdays.length
      ) {
        window.alert('Please select at least 1 day');
        return;
      }
      this.reportSchedule.report = this.reportId;
      this.$emit('save', this.reportSchedule);
      this.$refs.reportScheduleModal.hide();
    });
  }
}
