





















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Dropzone from 'dropzone';
import vue2Dropzone from 'vue2-dropzone';

import { ImportShipmentsResponse } from '@/services/api/shipments/import-shipments-response';

const shipmentsModule = namespace('shipments');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    vue2Dropzone,
  },
})
export default class ImportShipments extends Vue {
  @shipmentsModule.Getter('IMPORT_RESULT')
  importResult!: ImportShipmentsResponse;

  @shipmentsModule.Action('IMPORT')
  importShipments!: (arg0: Dropzone.DropzoneFile) => Promise<ImportShipmentsResponse>;

  $refs!: Vue['$refs'] & {
    dropzone: Dropzone;
  };

  get dropzoneOptions(): {
    url: string;
    autoProcessQueue: boolean;
    thumbnailWidth: number;
    maxFilesize: number;
    timeout: null;
  } {
    return {
      url: '/',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 512,
      timeout: null,
    };
  }

  async startImportShipments(file: Dropzone.DropzoneFile): Promise<void> {
    await this.importShipments(file);
    this.showImportDetail();
  }

  showImportDetail(): void {
    this.$router.push({
      name: 'ShipmentImport',
      params: { id: this.importResult._id },
    });
  }
}
