import { Customer } from '@/services/api/customers/customer-interface';
import { CustomerUploadPdfResponse } from '@/services/api/customers/customer-upload-pdf-response';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { CustomersApiParams, CustomersState } from './types';

export const getters = {
  PAGINATED: (state: CustomersState): PaginatedResponse<Customer> =>
    state.customers,
  ONE: (state: CustomersState): Customer => state.one,
  API_PARAMS: (state: CustomersState): CustomersApiParams => state.apiParams,
  UPLOAD_RESULT: (state: CustomersState): CustomerUploadPdfResponse =>
    state.pdfUpload as CustomerUploadPdfResponse,
  ALL: (state: CustomersState): Customer[] => state.allCustomers,
};

export default getters;
