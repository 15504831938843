import { Ui } from './ui.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import { AxiosResponse } from 'axios';

export default class UiService extends EntitiesService<Ui> {
  get endpoint(): string {
    return (localStorage.getItem('local_base_url') as string) + '/ui';
  }

  async bulk(uis: Ui[]): Promise<AxiosResponse> {
    const response = await axios.put(`${this.endpoint}/bulk`, uis);
    return response;
  }

  async duplicateUi(ui: Ui): Promise<Ui> {
    const response = await axios.post<Ui>(`${this.endpoint}/duplicate`, ui);
    return response.data;
  }
}
