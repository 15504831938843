



































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';

import appNavTabs from '../components/NavTabs.vue';
import { NavTabItem } from '../types/nav-tab-item.interface';
import { SavableComponent } from '../types/savable-component.class';

const companiesModule = namespace('companies');

@Component({
  components: {
    appNavTabs,
  },
})
export default class Company extends Vue {
  @companiesModule.Getter('GET')
  company!: Company;

  $refs!: Vue['$refs'] & {
    child: SavableComponent;
  };

  get isEditing(): boolean {
    return !!this.$route.params.id;
  }

  get navTabs(): NavTabItem[] {
    return this.isEditing
      ? [
        {
          label: 'General',
          routeName: 'CompanyGeneral',
        },
        {
          label: 'Translations',
          routeName: 'CompanyTranslations',
        },
        {
          label: 'Attachments',
          routeName: 'Attachments',
        },
      ]
      : [
        {
          label: 'General',
          routeName: 'CreateCompany',
        },
      ];
  }

  save(): void {
    const child = this.$refs.child;
    if (child instanceof SavableComponent) {
      child.save();
    }
  }
}
