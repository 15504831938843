import { RecurrenceType } from '@/types/reports/recurrence-type.enum';

export class ReportSchedule {
  _id?: string;
  time = '';
  recurrenceType = RecurrenceType.Day;
  repeatEvery!: number;
  day?: number;
  weekdays: number[] = [];
  report = '';
  reports?: string[];
  timeToSend!: string;
  lastDateSent!: Date;
  createdAt!: Date;
  office!: string;
  sortOrder!: number;
}
