

































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../components/FieldSelect.vue';
import MilestoneSelect from '../components/MilestoneSelect.vue';
import condition from '../components/TransformationConditionRow.vue';
import flatPickr from 'vue-flatpickr-component';
import { Toaster } from '../store/modules/toasters/types';
import { ShipmentType } from '../services/api/shipment-types/shipment-type.class';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { convertIdToObject } from '../utils/convert-id-to-object.util';
import { Office } from '../services/api/offices/office.class';
import { TransformationCondition } from '@/services/api/transformations/transformation-condition.interface';

const shipmentTypesModule = namespace('shipmentTypes');
const milestoneTypesModule = namespace('milestoneTypes');
const milestonesModule = namespace('milestones');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
    FieldSelect,
    MilestoneSelect,
    condition,
  },
})
export default class ShipmentTypeDetail extends Vue {
  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @milestonesModule.Getter('ALL')
  milestones!: MilestoneResponse[];

  @milestonesModule.Action('FETCH_ALL')
  fetchMilestones!: () => Promise<MilestoneResponse[]>;

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchMilestoneTypes!: () => Promise<MilestoneType[]>;

  @shipmentTypesModule.Getter('GET')
  shipmentType!: ShipmentType;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @shipmentTypesModule.Action('CREATE')
  createShipmentType!: (shipmentType: ShipmentType) => Promise<ShipmentType>;

  @shipmentTypesModule.Action('UPDATE')
  updateShipmentType!: (payload: {
    id: string;
    shipmentType: ShipmentType;
  }) => Promise<ShipmentType>;

  @shipmentTypesModule.Action('FETCH_ONE')
  fetchShipmentType!: (id: string) => Promise<ShipmentType>;

  form: ShipmentType = new ShipmentType();

  renderKey = 0;

  @Watch('shipmentType')
  onTypeChange(): void {
    this.form = { ...this.shipmentType };
    this.form.office = this.office._id;

    for (const milestoneType of this.milestoneTypes) {
      const key = milestoneType._id || '';
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.form.requiredMilestones[key] = convertIdToObject(
        this.form.requiredMilestones[key] || [],
        this.milestones,
      );
    }
  }

  forceRerender(): void {
    // Using key attribute to forceUpdate component
    ++this.renderKey;
  }

  async onSubmit(): Promise<void> {
    const shipmentType = { ...this.form };
    if (shipmentType.requiredMilestones) {
      for (const key of Object.keys(shipmentType.requiredMilestones)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        shipmentType.requiredMilestones[key] = shipmentType.requiredMilestones[
          key
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        ].map((x: any) => x._id);
      }
    }
    if (!shipmentType.office) {
      shipmentType.office = this.office._id;
    }
    if (shipmentType._id) {
      await this.updateShipmentType({
        id: shipmentType._id,
        shipmentType,
      });
    } else {
      const resp: ShipmentType = await this.createShipmentType(shipmentType);
      if (resp && resp._id) {
        this.$router.replace({
          name: 'ShipmentType',
          params: { id: resp._id },
        });
      }
    }
  }

  addCondition(): void {
    const newCondition: TransformationCondition = {
      field: '',
      operator: undefined,
      parameters: [],
    };

    if (this.form.conditions) {
      this.form.conditions.push(newCondition);
    } else {
      this.form.conditions = [newCondition];
    }
  }

  removeCondition(conditionIndex: number): void {
    this.form.conditions.splice(conditionIndex, 1);
  }

  async created(): Promise<void> {
    await this.fetchMilestoneTypes();
    await this.fetchMilestones();
    if (this.$route.params.id) {
      await this.fetchShipmentType(this.$route.params.id);
    }
  }
}
