import { ShipmentMilestoneStatus } from '@/services/api/shipments/shipment-milestone-status.enum';
import { ShipmentMilestoneResponse } from '@/services/api/shipments/shipment-milestone-response.interface';

export class ShipmentMilestoneForm {
  public milestone = '';
  public value = '';
  public status: ShipmentMilestoneStatus = ShipmentMilestoneStatus.Confirmed;
  public priority = 0;

  constructor(shipmentMilestone?: ShipmentMilestoneResponse) {
    if (shipmentMilestone) {
      this.milestone = shipmentMilestone.milestone;
      this.value = shipmentMilestone.value;
      this.status = shipmentMilestone.status;
      this.priority = shipmentMilestone.priority;
    }
  }
}
