import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { DataObject } from '@/services/api/data-object.interface';

export class UiFilterForm {
  title = '';
  field = {
    text: 'select a field',
    dataType: 'string',
  } as DataObject;
  operation = '';
  value = '';
  values = [] as string[];
  customersValues = [] as CustomerResponse[];
}
