




















































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { Translation } from '@/services/api/translations/translation.class';

const attachmentsModule = namespace('attachments');
const translationsModule = namespace('translations');

@Component({
  components: {
    Multiselect,
  },
})
export default class AttachmentSelect extends Vue {
  @Prop()
  value!: {};
  @attachmentsModule.Getter('GET_ALL_OPTIONS')
  options!: Option[];

  @attachmentsModule.Action('FETCH_ALL_OPTIONS')
  fetchOptions!: () => Promise<void>;

  @translationsModule.Action('FETCH_ALL')
  fetchTranslations!: (filter?: string) => Promise<Translation[]>;

  @translationsModule.Getter('ALL')
  translations!: Translation[];

  searchQuery: string[] = [];

  modelValue: any[] = [];

  $refs!: Vue['$refs'] & {
    customAttachmentSelect: Multiselect;
  };

  @Watch('value', {
    immediate: true,
  })
  onValueChange(value: any): void {
    if (value) {
      if (this.isStringInput) {
        const search = this.options.find(option => option.value === value);
        if (search) {
          this.modelValue = search as any;
        }
      } else {
        this.modelValue = value;
      }
    }
  }

  @Emit('input')
  onChange(selectedValue: any): any[] {
    if (this.isStringInput) {
      return selectedValue && selectedValue.value
        ? selectedValue.value
        : undefined;
    } else {
      return selectedValue;
    }
  }

  async created(): Promise<void> {
    await this.fetchOptions();
    await this.searchTranslations('');
    this.onValueChange(this.value);
  }

  get isStringInput(): boolean {
    return typeof this.value === 'string';
  }

  async searchTranslations(s: string): Promise<void> {
    await this.fetchTranslations(s);
    this.searchQuery = this.translations.map(item => item.value);
  }
}
