import api from '@/services/api/';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { ShipmentMilestoneResponse } from '@/services/api/shipments/shipment-milestone-response.interface';
import { ShipmentMilestoneStatus } from '@/services/api/shipments/shipment-milestone-status.enum';
import { ShipmentRequest } from '@/services/api/shipments/shipment-request.interface';
import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { Role } from '@/services/api/users/role.enum';
import { DeepPartial } from '@/types/deep-partial.type';
import { FilterOperation } from '@/types/filter-operation.enum';
import { Filters } from '@/types/filters.interface';
import { ActionContext } from 'vuex';
import { RootState } from '../types';
import { initialFilters } from './filters';
import { ShipmentsState } from './types';
import { ToasterTypes } from '../toasters/types';
import { transformUiFilter } from '@/utils/transformBaseFilter.util';
import shipmentsService from '@/services/api/shipments/shipments.service';
import { ShipmentImportStatus } from '@/types/import-status.enum';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { ImportShipmentsResponse } from '@/services/api/shipments/import-shipments-response';
import { Office } from '@/services/api/offices/office.class';

type AC = ActionContext<ShipmentsState, RootState>;

const fetchAll = async (context: AC, payload?: FetchAllParams) => {
  try {
    context.dispatch('application/SET_LOADING', null, { root: true });

    const allShipments = await api.shipments.fetchAll(payload);

    if (!payload || !payload.page || payload.page === 1) {
      context.commit('SET', allShipments.docs);
      context.commit('RESET_NEW_SHIPMENTS');
    } else {
      context.commit('ADD_SHIPMENTS', allShipments.docs);
    }
    return allShipments;
  } finally {
    context.dispatch('application/UNSET_LOADING', null, { root: true });
  }
};

export const actions = {
  SET_ACTIVE(context: AC, shipmentId: string | null): void {
    context.commit('SET_ACTIVE', shipmentId);
  },
  SET_SORT(context: AC, payload: string[]): void {
    context.commit('SET_SORT', payload);
  },

  SET_FILTERS(context: AC, payload: Filters): void {
    context.commit('SET_FILTERS', payload);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    context.dispatch('FETCH_ALL', context.getters.API_PARAMS);
  },
  async ARCHIVE(
    context: AC,
    payload: { id: string; milestoneType: string },
  ): Promise<void> {
    try {
      context.commit('SET_LOADSTATE', `${payload.id}.archived`);
      const shipment = await api.shipments.partialUpdate(payload.id, {
        archived: { [payload.milestoneType]: true },
      });
      const shipmentIndex: number = context.state.shipments.findIndex(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      if (shipmentIndex > -1) {
        context.commit('UPDATE_SHIPMENT', {
          shipmentIndex,
          shipment,
        });
      }
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit('UNSET_LOADSTATE', `${payload.id}.archived`);
    }
  },
  async UNARCHIVE(
    context: AC,
    payload: { id: string; milestoneType: string },
  ): Promise<void> {
    try {
      context.commit('SET_LOADSTATE', `${payload.id}.archived`);
      const shipment = await api.shipments.partialUpdate(payload.id, {
        archived: { [payload.milestoneType]: false },
      });
      const shipmentIndex: number = context.state.shipments.findIndex(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      if (shipmentIndex > -1) {
        context.commit('UPDATE_SHIPMENT', {
          shipmentIndex,
          shipment,
        });
      }
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit('UNSET_LOADSTATE', `${payload.id}.archived`);
    }
  },

  async SET_FIELD(
    context: AC,
    payload: { id: string; data: DeepPartial<ShipmentRequest> },
  ): Promise<void> {
    try {
      context.commit(
        'SET_LOADSTATE',
        `${payload.id}.${Object.keys(payload.data)[0]}`,
      );
      const shipment = await api.shipments.partialUpdate(
        payload.id,
        payload.data,
      );
      const shipmentIndex: number = context.state.shipments.findIndex(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      if (shipmentIndex > -1) {
        context.commit('UPDATE_SHIPMENT', {
          shipmentIndex,
          shipment,
        });
      }
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit(
        'UNSET_LOADSTATE',
        `${payload.id}.${Object.keys(payload.data)[0]}`,
      );
    }
  },

  SET_CMR(context: AC, payload: string): void {
    api.shipments.partialUpdate(payload, {
      cmr: true,
    });
  },
  UNSET_CMR(context: AC, payload: string): void {
    api.shipments.partialUpdate(payload, {
      cmr: false,
    });
  },
  async SET_SHIPMENT_MILESTONE(
    context: AC,
    payload: {
      shipmentId: string;
      milestoneId: string;
      value: string;
      priority?: number;
      sendNotification: boolean;
    },
  ): Promise<ShipmentResponse | undefined> {
    const { shipmentId, milestoneId, sendNotification, ...rest } = payload;
    try {
      context.commit(
        'SET_LOADSTATE',
        `${payload.shipmentId}.${payload.milestoneId}`,
      );
      const shipment = await api.shipments.partialUpdate(shipmentId, {
        shipmentMilestones: {
          [milestoneId]: {
            ...rest,
          },
        },
        sendNotification,
      });
      const shipmentIndex: number = context.state.shipments.findIndex(
        (sh: ShipmentResponse) => sh._id === shipmentId,
      );
      if (shipmentIndex > -1) {
        context.commit('UPDATE_SHIPMENT', {
          shipmentIndex,
          shipment,
        });
      }
      return shipment;
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.shipmentId,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit(
        'UNSET_LOADSTATE',
        `${payload.shipmentId}.${payload.milestoneId}`,
      );
    }
  },
  async SET_MULTIPLE_SHIPMENT_MILESTONES(
    context: AC,
    payload: {
      shipmentId: string;
      milestoneId: string;
      value: string;
      priority?: number;
      sendNotification: boolean;
      query: Filters;
    },
  ): Promise<ShipmentResponse[] | undefined> {
    try {
      const {
        shipmentId,
        milestoneId,
        sendNotification,
        query,
        ...rest
      } = payload;
      context.commit('SET_LOADSTATE', `${shipmentId}.${milestoneId}`);
      const shipments = await shipmentsService.bulk(query, {
        shipmentMilestones: {
          [milestoneId]: {
            ...rest,
          },
        },
        sendNotification,
      });

      for (const shipment of shipments) {
        const shipmentIndex: number = context.state.shipments.findIndex(
          (sh: ShipmentResponse) => sh._id === shipment._id,
        );
        if (shipmentIndex > -1) {
          context.commit('UPDATE_SHIPMENT', {
            shipmentIndex,
            shipment,
          });
        }
      }
      return shipments;
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.shipmentId,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit(
        'UNSET_LOADSTATE',
        `${payload.shipmentId}.${payload.milestoneId}`,
      );
    }
  },
  async SET_SHIPMENT_MILESTONE_STATUS(
    context: AC,
    payload: {
      shipmentId: string;
      milestoneId: string;
      status: ShipmentMilestoneStatus;
    },
  ): Promise<void> {
    const { shipmentId, milestoneId, status } = payload;

    const shipmentIndex: number = context.state.shipments.findIndex(
      (sh: ShipmentResponse) => sh._id === shipmentId,
    );
    const shipment = await api.shipments.partialUpdate(shipmentId, {
      shipmentMilestones: {
        [milestoneId]: {
          status,
        },
      },
    });
    if (shipmentIndex > -1) {
      context.commit('UPDATE_SHIPMENT', {
        shipmentIndex,
        shipment,
      });
    }
  },
  // Used by vue-socket.io
  // eslint-disable-next-line camelcase
  async socket_updateShipment(
    context: AC,
    payload: { shipment: string; office: string },
  ): Promise<void> {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.office !== context.rootGetters['users/GET_WORKING_OFFICE']._id
    ) {
      return;
    }
    const baseFilter = context.state.currentUiBaseFilters;
    const apiParams = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      filters: {
        _id: {
          value: payload.shipment,
          operation: FilterOperation.Equals,
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ...transformUiFilter(baseFilter),
      },
    };
    const shipmentsWrapper = await api.shipments.fetchAll(apiParams);
    const shipment =
      shipmentsWrapper.total > 0 ? shipmentsWrapper.docs[0] : null;
    const shipmentIndex: number = context.state.shipments.findIndex(
      (sh: ShipmentResponse) => sh._id === payload.shipment,
    );
    if (!shipment) {
      if (shipmentIndex > -1) {
        context.commit('REMOVE_SHIPMENT', shipmentIndex);
      }
      return;
    }

    if (shipmentIndex > -1) {
      context.commit('UPDATE_SHIPMENT', {
        shipmentIndex,
        shipment,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (context.rootGetters['users/GET_ROLE'] === Role.Synchronizer) {
        if (shipment.shipmentMilestones) {
          for (const shipmentMilestoneKey of Object.keys(
            shipment.shipmentMilestones,
          )) {
            const shipmentMilestone: ShipmentMilestoneResponse =
              shipment.shipmentMilestones[shipmentMilestoneKey];

            if (
              shipmentMilestone.status === ShipmentMilestoneStatus.Changed ||
              shipmentMilestone.status === ShipmentMilestoneStatus.Conflicted
            ) {
              context.commit('INCREASE_NEW_SHIPMENTS');
              break;
            }
          }
        }
      } else {
        context.commit('INCREASE_NEW_SHIPMENTS');
      }
    }
  },
  // Used by vue-socket.io
  // eslint-disable-next-line camelcase
  socket_updateImport(
    context: AC,
    payload: { office: Office; status: ShipmentImportStatus },
  ): void {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      payload.office._id !== context.rootGetters['users/GET_WORKING_OFFICE']._id
    ) {
      return;
    }

    context.commit('SET_IMPORT_RESULT', payload);

    if (payload.status === ShipmentImportStatus.Done) {
      context.commit('shipmentImports/FINISH_IMPORT', payload, { root: true });
    }
  },
  async FETCH_IMPORT(context: AC, payload: string): Promise<void> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const result = await api.shipments.fetchImport(payload);

      context.commit('SET_IMPORT_RESULT', result);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async IMPORT(context: AC, payload: File): Promise<ImportShipmentsResponse> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const file = await api.files.create(payload);
      const result = await api.shipments.import({ file: file[0]._id });

      context.commit('SET_IMPORT_RESULT', result);
      return result;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context: AC,
    payload?: FetchAllParams,
  ): Promise<PaginatedResponse<ShipmentResponse>> {
    return fetchAll(context, payload);
  },
  async FETCH_ONE(context: AC, payload: string): Promise<ShipmentResponse> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipment = await api.shipments.fetchOne(payload);
      context.commit('SET_ONE', shipment);
      context.commit('SET_SELECTED', payload);
      return shipment;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async RELOAD(context: AC): Promise<void> {
    context.commit('SET_PAGE', 1);
    context.commit('INCREMENT_INFINITE_LOADING_IDENTIFIER');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    await context.dispatch('FETCH_ALL', { ...context.getters.API_PARAMS });
  },
  async PARTIAL_UPDATE(
    context: AC,
    payload: { id: string; shipment: DeepPartial<ShipmentRequest> },
  ): Promise<ShipmentResponse | undefined> {
    context.commit('SET_LOADSTATE', `${payload.id}`);
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipment = await api.shipments.partialUpdate(
        payload.id,
        payload.shipment,
      );

      const shipmentIndex: number = context.state.shipments.findIndex(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      if (shipmentIndex > -1) {
        context.commit('SET_ONE', {
          shipment,
        });
      }
      return shipment;
    } catch (e) {
      const shipment:
      | ShipmentResponse
      | undefined = context.state.shipments.find(
        (sh: ShipmentResponse) => sh._id === payload.id,
      );
      const ref = shipment ? shipment.ref : 'unknown';
      context.dispatch('toasters/ADD_TOASTER', {
        title: 'Could not update shipment',
        message: `Shipment "${ref}"`,
        show: true,
        type: ToasterTypes.ERROR,
      });
    } finally {
      context.commit('UNSET_LOADSTATE', `${payload.id}`);
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  CLEAR_FILTERS_AND_SORT(
    context: AC,
    payload?: {
      filters?: Filters;
      sort?: string[];
    },
  ): void {
    context.commit(
      'SET_FILTERS',
      payload && payload.filters ? payload.filters : initialFilters,
    );
    context.commit('SET_SORT', payload && payload.sort ? payload.sort : []);
  },
  async FETCH_BREACHED_JOBS(
    context: AC,
    payload: FetchAllParams,
  ): Promise<number> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const count = await api.shipments.fetchBreachedJobs(payload);
      context.commit('SET_BREACHED_JOBS', count);
      return count;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_PER_MILESTONE(
    context: AC,
    payload: FetchAllParams,
  ): Promise<number[]> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const counts = await api.shipments.fetchPerMilestone(payload);
      context.commit('SET_PER_MILESTONE', counts);
      return counts;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_COUNT(context: AC, payload: FetchAllParams): Promise<number> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const count = await api.shipments.fetchCount(payload);
      context.commit('SET_COUNT', count);
      return count;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_FILTER_COUNT(
    context: AC,
    payload: FetchAllParams,
  ): Promise<number | undefined> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const count = await api.shipments.fetchFilterCount(payload);
      context.commit('SET_COUNT', count);
      return count;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  SET_CURRENT_UI_BASE_FILTERS(context: AC, payload: FetchAllParams): void {
    context.commit('SET_CURRENT_UI_BASE_FILTERS', payload);
  },
  async DELETE(context: AC, payload: string): Promise<void> {
    if (!payload) {
      return;
    }

    context.commit('REMOVE_SHIPMENT_BY_ID', payload);

    try {
      await api.shipments.delete(payload);

      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Shipment has been succesfully deleted',
          message: `Shipment "${payload}"`,
          show: true,
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
    } catch (e) {
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Could not delete shipment',
          message: `Shipment "${payload}"`,
          show: true,
          type: ToasterTypes.ERROR,
        },
        { root: true },
      );
    }
  },

  async RESTORE(context: AC, payload: string): Promise<void> {
    context.commit('REMOVE_SHIPMENT_BY_ID', payload);

    try {
      await api.shipments.restore(payload);

      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Shipment has been succesfully restored',
          message: `Shipment "${payload}"`,
          show: true,
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
    } catch (e) {
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Could not restore shipment',
          message: `Shipment "${payload}"`,
          show: true,
          type: ToasterTypes.ERROR,
        },
        { root: true },
      );
    }
  },
};
