/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from '@/services/api/';
import { Customer } from '@/services/api/customers/customer-interface';
import { CustomersState, CustomersApiParams } from './types';
import { RootState } from '../types';
import { ActionContext } from 'vuex';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { CustomerUploadPdfResponse } from '@/services/api/customers/customer-upload-pdf-response';
import { ToasterTypes } from '../toasters/types';

type AC = ActionContext<CustomersState, RootState>;

export const actions = {
  /**
   * Save limit & sorting options for the customers on the dashboards
   */
  SET_SORT(context: AC, payload: string[]): void {
    context.commit('SET_SORT', payload);
    context.dispatch('FETCH_ALL');
  },
  async FETCH_ONE(context: AC, payload: string): Promise<Customer> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const customer = await api.customers.fetchOne(payload);
      context.commit('SET_ONE', customer);
      return customer;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(
    context: AC,
    payload: { id: string; customer: Customer },
  ): Promise<Customer> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const customer = await api.customers.update(payload.id, payload.customer);
      context.commit('SET_ONE', customer);
      context.dispatch(
        'toasters/ADD_TOASTER',
        { title: 'Customer updated', message: '', type: ToasterTypes.SUCCESS },
        { root: true },
      );
      return customer;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async CREATE(context: AC, payload: Customer): Promise<Customer> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const customer = await api.customers.create(payload);
      context.commit('SET_ONE', customer);
      context.dispatch(
        'toasters/ADD_TOASTER',
        { title: 'Customer created', message: '', type: ToasterTypes.SUCCESS },
        { root: true },
      );
      return customer;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  /**
   * Fetch all customers based on the ApiParams
   * The default store ApiParams can be overridden through the payload
   */
  async FETCH(
    context: AC,
    payload: Partial<CustomersApiParams>,
  ): Promise<PaginatedResponse<Customer>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const params = Object.assign({}, context.getters.API_PARAMS, payload);
      const customers = await api.customers.fetch(params);
      context.commit('SET_PAGINATED', customers);
      return customers;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context: AC,
    payload: Partial<CustomersApiParams>,
  ): Promise<Customer[]> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const params = Object.assign({}, context.getters.API_PARAMS, payload);
      const customers = await api.customers.fetchAll(params);
      context.commit('SET_ALL', customers);
      return customers;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context: AC, id: string): Promise<Customer> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const customer = await api.customers.delete(id);
      return customer;
      context.dispatch('FETCH_ALL', {});
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPLOAD_PDF(
    context: AC,
    payload: File,
  ): Promise<CustomerUploadPdfResponse> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });

      const result = await api.files.create(payload);
      context.commit('SET_UPLOAD_RESULT', result[0]);

      return result[0];
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};
