




















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { Rule } from '../services/api/rules/rule.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

const rulesModule = namespace('rules');
const milestonesModule = namespace('milestones');

@Component({
  components: {
    EntityList,
  },
})
export default class AllRules extends Vue {
  @milestonesModule.Getter('GET')
  milestone!: any;

  @rulesModule.Getter('ALL_PAGINATED')
  rules!: any;

  @rulesModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => Promise<void>;

  @rulesModule.Action('DELETE')
  deleteById!: (id: string) => Promise<Rule>;

  filter: FetchAllParams = {};

  public columns: any[] = [
    {
      field: 'name',
      label: 'Rule name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  redirectToDetail(rule: Rule): void {
    this.$router.push({
      name: 'Rule',
      params: { id: rule._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateRule',
    });
  }

  async redirectToDelete(rule: Rule): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + rule.name + '?',
    );
    if (!result) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.deleteById(rule._id!);
    this.fetchAll();
  }

  async created(): Promise<void> {
    await this.fetchAll();
  }
}
