
/* eslint-disable vue/no-v-html */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { CustomButton } from '@/types/custom-button.interface';

@Component
export default class EntityListRow extends Vue {
  @Prop()
  item!: any;

  @Prop()
  columns!: {};

  @Prop({ default: false })
  draggable?: boolean;

  @Prop()
  showCustomActions!: boolean;

  @Prop()
  editValidator?: boolean;

  @Prop()
  deleteValidator?: boolean;

  @Prop()
  customValidator?: boolean;

  @Prop()
  customButtons?: CustomButton[];

  @Prop()
  showEdit!: boolean;

  @Prop()
  showDelete!: boolean;

  @Prop()
  showActionColumn!: boolean;

  @Prop()
  editLabel?: string;

  @Prop()
  deleteLabel?: string;

  getValue(column: EntityListColumn<unknown>, item: any): string {
    if (!column.field) {
      return '';
    }

    if (typeof column.field === 'function') {
      if (column.param) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return column.field(item[column.param]);
      }
      return column.field(item);
    }

    // this is to access enclosed data structures
    const fields = column.field.split('.');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let currentItem = item;
    for (const field of fields) {
      if (!currentItem) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return currentItem;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      currentItem = currentItem[field];
    }

    if (currentItem instanceof Array) {
      return currentItem.join('<br/>');
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return currentItem;
  }
}
