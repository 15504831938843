import { MutationTree } from 'vuex';
import Vue from 'vue';
import { UIState } from './types';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { Ui } from '@/services/api/ui/ui.class';
import { PaginatedResponse } from '@/services/api/paginated-response';

export const mutations: MutationTree<UIState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  ALL_UIS_LOADED(state, payload: Ui[]) {
    state.all = payload;
  },
  UIS_LOADED(state, payload: PaginatedResponse<Ui>) {
    state.uis = payload;
  },
  SET_UNPAGINATED(state, payload: Ui[]) {
    state.all = payload;
  },
  UIS_ERROR(state) {
    state.error = true;
    state.uis = {
      total: 0,
      page: 0,
      pages: 0,
      limit: 0,
      docs: [] as Ui[],
    };
    state.loading = false;
  },
  SET_ONE(state: any, payload: any) {
    Vue.set(state, 'one', payload);
  },
};
