import { ReportCondition } from '../../../services/api/reports/report-condition.class';

const checkIsFromLessThanTo = (dateRange: ReportCondition): boolean => {
  if (!dateRange.data.dateStart || !dateRange.data.dateEnd) {
    return false;
  }

  const { dateStart, dateEnd } = dateRange.data;

  const dateFromInSec = new Date(dateStart).getTime();
  const dateToInSec = new Date(dateEnd).getTime();

  const isFromLessThanTo = dateToInSec > dateFromInSec;

  return isFromLessThanTo;
};

export default checkIsFromLessThanTo;
