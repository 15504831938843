import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import VerifiedEmailsService from '@/services/api/verified-emails/verified-emails.service';
import { VerifiedEmail } from '@/services/api/verified-emails/verified-email.class';
import env from '../../../env.config';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';

const verifiedEmailsService = new VerifiedEmailsService();

interface VerifiedEmailState {
  one: VerifiedEmail;
  all: PaginatedResponse<VerifiedEmail>;
  apiParams: FetchAllParams;
}

const initialVerifiedEmailState: VerifiedEmailState = {
  one: new VerifiedEmail(),
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<VerifiedEmailState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<VerifiedEmailState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<VerifiedEmail>) {
    state.all = payload;
  },
  SET_ONE(state, payload: VerifiedEmail) {
    state.one = payload;
  },
};

const actions: ActionTree<VerifiedEmailState, RootState> = {
  async CREATE(context, payload: any) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.create(payload);
      context.commit('SET_ONE', verifiedEmail);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async SEND_VERIFICATION(context, payload: any) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.sendVerificationMail(
        payload,
      );
      context.commit('SET_ONE', verifiedEmail);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.fetchOne(id);
      context.commit('SET_ONE', verifiedEmail);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_BY_TOKEN(
    context,
    payload: {
      token: string;
      region: string;
    },
  ) {
    const token = payload.token;
    await context.dispatch('regions/FETCH_ALL', null, { root: true });
    const originalUrl = localStorage.getItem('local_base_url') || env.API_URL;
    localStorage.setItem('local_base_url', env.API_URL);
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.fetchByToken(token);
      context.commit('SET_ONE', verifiedEmail);
      localStorage.setItem('local_base_url', originalUrl);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: any) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const verifiedEmails = await verifiedEmailsService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', verifiedEmails);
      return verifiedEmails;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; verifiedEmail: VerifiedEmail }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.update(
        payload.id,
        payload.verifiedEmail,
      );
      context.commit('SET_ONE', verifiedEmail);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const verifiedEmail = await verifiedEmailsService.delete(payload);
      context.commit('SET_ONE', verifiedEmail);
      return verifiedEmail;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async VERIFY(
    context,
    payload: {
      token: string;
      region: string;
    },
  ) {
    await context.dispatch('regions/FETCH_ALL', null, { root: true });
    const originalUrl = localStorage.getItem('local_base_url') || env.API_URL;
    localStorage.setItem('local_base_url', originalUrl);
    const verifiedEmail = await verifiedEmailsService.verify(payload.token);
    return verifiedEmail;
  },
};

export default {
  namespaced: true,
  state: initialVerifiedEmailState,
  getters,
  mutations,
  actions,
};
