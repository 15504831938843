







































import { IList } from '@/services/api/ui/ui-list.interface';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NavBarUserMenu extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  userName!: string;

  @Prop({
    type: Array,
    required: true,
  })
  uiList!: IList[];

  icon(icon: [string, string]): [string, string] {
    return icon;
  }
}
