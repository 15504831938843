









import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Office } from './services/api/offices/office.class';
import io from 'socket.io-client';

import appLoader from './components/TheLoader.vue';
import appNavBar from './components/TheNavBar.vue';
import appToasters from './components/Toasters.vue';
import { LocaleMessageObject } from 'vue-i18n';
import { ShipmentImportStatus } from './types/import-status.enum';

const appModule = namespace('application');
const usersModule = namespace('users');
const shipmentsModule = namespace('shipments');
const translationsModule = namespace('translations');
const officeDuplicationsModule = namespace('officeDuplications');

@Component({
  components: {
    appLoader,
    appNavBar,
    appToasters,
  },
})
export default class App extends Vue {
  @appModule.Getter('GET_LOADING')
  loading!: boolean;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @shipmentsModule.Action('socket_updateShipment')
  websocketUpdateShipment!: (data: any) => void;

  @shipmentsModule.Action('socket_updateImport')
  websocketUpdateImport!: (data: any) => void;

  @officeDuplicationsModule.Action('socket_updateDuplication')
  websocketUpdateDuplication!: (data: any) => void;

  @translationsModule.Action('LOAD_DEFAULT')
  loadDefaultTranslations!: () => Promise<LocaleMessageObject>;

  @translationsModule.Action('LOAD_SPECIFIC')
  loadSpecificTranslations!: () => Promise<LocaleMessageObject>;

  socket?: SocketIOClient.Socket;
  tickLimitCounter = 0;

  @Watch('office', { deep: true })
  onOfficeChange(newOffice: Office, oldOffice: Office): void {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
    if (!localStorage.getItem('local_base_url')) {
      return;
    }
    if (!localStorage.getItem('token')) {
      return;
    }
    this.socket = io(localStorage.getItem('local_base_url') as string);
    this.socket.on('updateShipment', (data: any) => {
      this.websocketUpdateShipment(data);
    });
    this.socket.on('updateImport', (data: any) => {
      this.checkUpdate(data);
    });
    this.socket.on('updateDuplication', (data: any) => {
      this.websocketUpdateDuplication(data);
    });

    if (
      (!oldOffice && newOffice) ||
      (oldOffice && oldOffice._id !== newOffice._id)
    ) {
      this.loadSpecificTranslations().then(translations => {
        this.$i18n.setLocaleMessage(this.$i18n.locale, translations);
      });
    }
  }

  created(): void {
    this.loadDefaultTranslations().then(translations => {
      this.$i18n.setLocaleMessage(this.$i18n.locale, translations);
    });
  }

  checkUpdate(data: Record<string, unknown>): void {
    if (data.status === ShipmentImportStatus.Done) {
      this.websocketUpdateImport(data);
      return;
    }
    ++this.tickLimitCounter;
    const proportionalProgressIncrement = (data.totalRecords as number) / 100;
    if (this.tickLimitCounter < proportionalProgressIncrement) {
      return;
    }
    this.websocketUpdateImport(data);
    this.tickLimitCounter = 0;
  }
}
