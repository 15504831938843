
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { Office } from '../services/api/offices/office.class';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { BModal } from 'bootstrap-vue';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { OfficeDuplication } from '../services/api/office-duplications/office-duplication.class';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { TestIds } from '@/types/test-ids.enum';
import { CustomButton } from '@/types/custom-button.interface';

const officesModule = namespace('offices');
const officeDuplicationsModule = namespace('officeDuplications');
const usersModule = namespace('users');
const toastersModule = namespace('toasters');

@Component({
  components: {
    EntityList,
  },
})
export default class AllOffices extends Vue {
  @officesModule.Getter('ALL_PAGINATED')
  offices!: PaginatedResponseClass<Office>;

  @officesModule.Action('FETCH_PAGINATED')
  fetchAll!: (filter: FetchAllParams) => Promise<Office[]>;

  @officesModule.Action('DELETE')
  deleteById!: (id: string) => Promise<Office>;

  @usersModule.Getter('GET_WORKING_OFFICE')
  workingOffice!: Office;

  @officeDuplicationsModule.Action('CREATE')
  createCloneOffice!: (
    cloneOffice: OfficeDuplication,
  ) => Promise<OfficeDuplication>;

  @usersModule.Action('LOGOUT')
  logOut!: () => Promise<void>;
  @toastersModule.Action('ADD_TOASTER')
  addToaster!: (toast: Toaster) => Promise<void>;

  customButtons: CustomButton[] = [
    {
      variant: 'light',
      icon: 'copy',
      event: 'openCloneModal',
      id: TestIds.CLONE_OFFICE,
      show: true,
    },
  ];
  selectedOffice: Office = new Office();
  newOffice: OfficeDuplication = new OfficeDuplication();

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  cloneOptions = [
    { text: 'Shipments', value: 'Shipment' },
    { text: 'Customers', value: 'Customer' },
    { text: 'Milestone notifications', value: 'MilestoneNotification' },
    { text: 'Reports', value: ['Report', 'ReportSchedule'] },
    { text: 'Verified e-mails', value: 'VerifiedEmail' },
    { text: 'Users', value: 'User' },
  ];
  defaultOptions = [
    'Office',
    'Transformation',
    'MilestoneType',
    'Milestone',
    'Ui',
    'ShipmentType',
    'Rule',
  ];

  additionalOptions = [];

  $refs!: Vue['$refs'] & {
    cloneModal: BModal;
  };

  public columns: [EntityListColumn<Office>] = [
    {
      field: 'name',
      label: 'Name',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  search(searchString: string): void {
    this.fetchAll({ name: '~' + searchString });
  }

  redirectToDetail(office: Office): void {
    this.$router.push({
      name: 'Office',
      params: { id: office._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateOffice',
    });
  }

  async redirectToDelete(office: Office): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + office.name + '?',
    );
    if (!result) {
      return;
    }
    if (office._id) {
      await this.deleteById(office._id);
      this.addToaster({
        title: 'Office deleted!',
        message:
          'The requested office was deleted successfully, please sign in again.',
        type: ToasterTypes.SUCCESS,
      });
      await this.logOut();
    }
  }

  openCloneModal(item: Office): void {
    this.$refs.cloneModal.show();
    this.selectedOffice = { ...item };
    this.newOffice.office = item._id || '';
  }

  onCloseModal(): void {
    this.newOffice = new OfficeDuplication();
  }

  async onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    const result = await this.$validator.validate();
    if (result) {
      if (!this.newOffice.selectedCopies) {
        this.newOffice.selectedCopies = this.defaultOptions;
      }
      const cloneOffice = await this.createCloneOffice(this.newOffice);
      this.$refs.cloneModal.hide();
      this.$validator.reset();
      this.newOffice = new OfficeDuplication();
      this.$router.push({
        name: 'OfficeDuplicationDetail',
        params: { id: cloneOffice._id as string },
      });
    }
  }

  showDuplicationButton(input: Office): boolean {
    const isWorkingOffice = input._id === this.workingOffice._id;

    return isWorkingOffice;
  }

  prepNewOfficeSelectedCopies(): void {
    const selectedcopies = this.defaultOptions.concat(this.additionalOptions);
    this.newOffice.selectedCopies = selectedcopies;
  }

  async created(): Promise<void> {
    await this.fetchAll({ sort: ['name,asc'], limit: 99999 });
  }
}
