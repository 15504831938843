import { Office } from './office.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import { FetchAllParams } from '../fetch-all-params.interface';
import { AxiosRequestConfig } from 'axios';
import store from '@/store';

export default class OfficesService extends EntitiesService<Office> {
  get endpoint() {
    return '/offices';
  }

  async fetchAllGlobal(
    params?: FetchAllParams,
    endpoint?: string,
  ): Promise<Office[]> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const token = store.getters['users/GET_TOKEN'] as string;
    if (!config.headers) {
      config.headers = {};
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    config.headers.Authorization = `Bearer ${token}`;

    const response = await axios.get<Office[]>(
      `${endpoint || this.endpoint}/all`,
      config,
    );

    return response.data;
  }
}
