






import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appCustomerList from '@/components/CustomerList.vue';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { CustomersApiParams } from '@/store/modules/customers/types';
import { PaginatedResponseClass } from '../services/api/paginated-response';

const customersModule = namespace('customers');

@Component({
  components: {
    appCustomerList,
  },
})
export default class AllCustomers extends Vue {
  @customersModule.Getter('PAGE')
  customers!: PaginatedResponseClass<CustomerResponse>;

  @customersModule.Action('FETCH')
  fetchSortedCustomers!: (
    payload: Partial<CustomersApiParams>,
  ) => Promise<void>;

  async created(): Promise<void> {
    await this.fetchSortedCustomers({ sort: ['parent.name,asc'] });
  }
}
