export class Log {
  level?: number;
  action?: string;
  objectType?: string;
  object?: string;
  user?: string;
  office?: string;
  content?: {
    data?: {};
    message?: string;
    shipmentMilestones?: { [key: string]: any };
  };
  createdAt?: string;
}

export interface ShipmentMilestoneLog {
  value?: string;
  ruleResults?: [];
  priority?: number;
  overridden?: boolean;
  ruleResult?: {
    status: string;
  };
}
