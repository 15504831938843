var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-form-input',{attrs:{"name":"ui filter name","type":"text","placeholder":"Enter a filter name"},on:{"input":function($event){return _vm.setFilterTitle(_vm.uiFilterForm.title)}},model:{value:(_vm.uiFilterForm.title),callback:function ($$v) {_vm.$set(_vm.uiFilterForm, "title", $$v)},expression:"uiFilterForm.title"}})],1),_c('div',{staticClass:"divider"}),_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('field'),"state":!_vm.formErrors.has('field') ? null : false}},[_c('field-select',{directives:[{name:"validate",rawName:"v-validate",value:('required|dataObject'),expression:"'required|dataObject'"}],staticClass:"mb-2 mt-3",attrs:{"state":!_vm.formErrors.has('field') ? null : false,"include-attachments":false,"include-filter-options":true,"name":"field"},on:{"input":function($event){return _vm.determineFilterOnFieldType($event)}},model:{value:(_vm.uiFilterForm.field),callback:function ($$v) {_vm.$set(_vm.uiFilterForm, "field", $$v)},expression:"uiFilterForm.field"}})],1),(
      _vm.uiFilterForm.field.value &&
        (_vm.uiFilterForm.field.dataType === 'boolean' ||
          _vm.uiFilterForm.field.text === 'archived')
    )?_c('multiselect',{staticClass:"mb-2",attrs:{"value":_vm.inputFormBoolPropAlignment(_vm.uiFilterForm.value),"placeholder":"Select a value","options":_vm.booleanOptions,"multiple":false,"allow-empty":false,"custom-label":_vm.returnOptionText,"close-on-select":true,"searchable":false,"show-labels":false,"track-by":"value"},on:{"input":function($event){return _vm.setUiFilterValueBoolean($event)}}}):_vm._e(),(
      _vm.uiFilterForm.field.value && _vm.uiFilterForm.field.type === 'shipmentType'
    )?_c('shipment-type-select',{attrs:{"map-on":"_id"},on:{"change":function($event){return _vm.setUiFilterValueShipmentType($event)}},model:{value:(_vm.uiFilterForm.value),callback:function ($$v) {_vm.$set(_vm.uiFilterForm, "value", $$v)},expression:"uiFilterForm.value"}}):_vm._e(),(_vm.uiFilterForm.field.value && _vm.uiFilterForm.field.type === 'milestone')?_c('b-form',{staticClass:"mt-4"},[_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('milestone value'),"state":!_vm.formErrors.has('milestone value') ? null : false,"label-for":"ui value"}},[_c('b-row',{staticClass:"d-flex align-content-center border p-2 ml-2 mr-2"},[_c('b-col',{attrs:{"md":"3","lg":"2"}},[_c('label',{staticClass:"ml-3 mt-2",staticStyle:{"font-size":"15px"}},[_vm._v("Today ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"text-center",attrs:{"name":"milestone value","state":!_vm.formErrors.has('milestone value') ? null : false,"type":"number"},on:{"input":function($event){return _vm.setUiFilterValueMilestone(_vm.milestoneDayOffset)}},model:{value:(_vm.milestoneDayOffset),callback:function ($$v) {_vm.milestoneDayOffset=$$v},expression:"milestoneDayOffset"}})],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"mt-2",staticStyle:{"font-size":"15px"}},[_vm._v("days ")])])],1)],1)],1):_vm._e(),(
      (_vm.uiFilterForm.field.value &&
        !_vm.uiFilterForm.field.type &&
        _vm.uiFilterForm.field.dataType === 'string') ||
        _vm.uiFilterForm.field.dataType === 'number'
    )?_c('filter-operation-select',{attrs:{"data-type":_vm.uiFilterForm.field.dataType},on:{"input":function($event){return _vm.setUiFilterOperation($event)}},model:{value:(_vm.uiFilterForm.operation),callback:function ($$v) {_vm.$set(_vm.uiFilterForm, "operation", $$v)},expression:"uiFilterForm.operation"}}):_vm._e(),(
      _vm.uiFilterForm.field.value &&
        _vm.uiFilterForm.field.dataType === 'string' &&
        !_vm.uiFilterForm.field.type
    )?_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(" Press enter, comma, or ; to enter your filter criteria "),_c('multi-text-input',{ref:"multitext",attrs:{"input-values":_vm.uiFilterForm.values},on:{"removeValue":function () {
        var args = [], len = arguments.length;
        while ( len-- ) args[ len ] = arguments[ len ];

        return _vm.setUiFilterValueTextRemove.apply(void 0, args);
},"addValue":function () {
        var args = [], len = arguments.length;
        while ( len-- ) args[ len ] = arguments[ len ];

        return _vm.setUiFilterValueTextAdd.apply(void 0, args);
}}})],1):_vm._e(),(
      _vm.uiFilterForm.field.value && _vm.uiFilterForm.field.dataType === 'number'
    )?_c('b-form',{staticClass:"mt-2"},[_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('value'),"state":!_vm.formErrors.has('value') ? null : false,"label-for":"ui value"}},[(_vm.uiFilterForm.values)?_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"value","placeholder":"enter a value","state":!_vm.formErrors.has('value') ? null : false,"type":"number"},on:{"input":function($event){return _vm.setUiFilterValue($event)}},model:{value:(_vm.uiFilterForm.values[0]),callback:function ($$v) {_vm.$set(_vm.uiFilterForm.values, 0, $$v)},expression:"uiFilterForm.values[0]"}}):_vm._e()],1)],1):_vm._e(),(_vm.uiFilterForm.field.value && _vm.uiFilterForm.field.value === 'customer')?_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mb-2",attrs:{"name":"customer","state":!_vm.formErrors.has('customer') ? null : false,"options":_vm.customers,"multiple":true,"allow-empty":true,"custom-label":_vm.valueCustomerLabel,"close-on-select":true,"searchable":true,"show-labels":false,"track-by":"_id","value":"_id","placeholder":"select a customer"},on:{"close":function($event){return _vm.setUiFilterValuesCustomers()}},model:{value:(_vm.uiFilterForm.customersValues),callback:function ($$v) {_vm.$set(_vm.uiFilterForm, "customersValues", $$v)},expression:"uiFilterForm.customersValues"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }