const adminListConditionDataSets = [
  {
    action: 'read',
    subject: 'Rule',
    data: {
      name: 'Rules',
      icon: ['far', 'balance-scale'],
      path: '/rules',
    },
  },
  {
    action: 'manage',
    subject: 'ShipmentImport',
    data: {
      name: 'Shipment Imports',
      icon: ['far', 'upload'],
      path: '/shipment-imports',
    },
  },
  {
    action: 'read',
    subject: 'Log',
    data: {
      name: 'Logs',
      icon: ['far', 'history'],
      path: '/logs',
    },
  },
  {
    action: 'manage',
    subject: 'Milestone',
    data: {
      name: 'Milestones',
      icon: ['far', 'clipboard-check'],
      path: '/milestones',
    },
  },
  {
    action: 'read',
    subject: 'ShipmentType',
    data: {
      name: 'Shipment Types',
      icon: ['far', 'barcode-read'],
      path: '/shipment-types',
    },
  },
  {
    action: 'read',
    subject: 'MilestoneType',
    data: {
      name: 'Milestone Types',
      icon: ['far', 'layer-group'],
      path: '/milestone-types',
    },
  },
  {
    action: 'read',
    subject: 'UI',
    data: {
      name: 'Screens',
      icon: ['far', 'sliders-h'],
      path: '/screens',
    },
  },
  {
    action: 'manage',
    subject: 'User',
    data: {
      name: 'Users',
      icon: ['far', 'users'],
      path: '/users',
    },
  },
  {
    action: 'read',
    subject: 'VerifiedEmail',
    data: {
      name: 'Verified Emails',
      icon: ['far', 'at'],
      path: '/verified-emails',
    },
  },
  {
    action: 'read',
    subject: 'Office',
    data: {
      name: 'Offices',
      icon: ['far', 'warehouse'],
      path: '/offices',
    },
  },
  {
    action: 'read',
    subject: 'Company',
    data: {
      name: 'Companies',
      icon: ['fas', 'building'],
      path: '/companies',
    },
  },
];

export default adminListConditionDataSets;
