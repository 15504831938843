import axios from '@/axios';
import { AxiosResponse } from 'axios';
import { LocaleMessageObject } from 'vue-i18n';
import EntitiesService from '../entities/entities.service';
import { Translation } from './translation.class';

export default class TranslationsService extends EntitiesService<Translation> {
  get endpoint(): string {
    return localStorage.getItem('local_base_url')
      ? `${localStorage.getItem('local_base_url') as string}/translations`
      : '/translations';
  }

  async createByCompanyId(
    companyId: string,
    translations: { [key: string]: string },
  ): Promise<AxiosResponse> {
    const response = await axios.post(
      `${this.endpoint}/company-translations/${companyId}`,
      translations,
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  }

  async loadDefaultTranslations(): Promise<LocaleMessageObject> {
    const response = await axios.get(`${this.endpoint}/default`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  }

  async loadSpecificTranslations(): Promise<LocaleMessageObject> {
    const response = await axios.get(`${this.endpoint}/i18n`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  }
}
