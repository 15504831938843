import auth from './auth/auth.service';
import users from './users/users.service';
import milestones from './milestones/milestones.service';
import milestoneTypes from './milestone-types/milestone-types.service';
import shipments from './shipments/shipments.service';
import shipmentTypes from './shipment-types/shipment-types.service';
import customers from './customers/customers.service';
import ui from './ui/ui.service';
import files from './files/files.service';
import reports from './reports/reports.service';
import offices from './offices/offices.service';
import companies from './companies/companies.service';
import attachments from './attachments/attachments.service';
import shipmentAttachments from './shipment-attachments/shipment-attachments.service';
import officeDuplication from './office-duplications/office-duplications.service';

export default {
  auth,
  users,
  milestones,
  milestoneTypes,
  shipments,
  shipmentTypes,
  customers,
  ui,
  files,
  reports,
  offices,
  companies,
  attachments,
  shipmentAttachments,
  officeDuplication,
};
