import { AxiosResponse } from 'axios';
import { isBlank } from '@/utils/is-blank.util';

import axios from '@/axios';
import { DeepPartial } from '@/types/deep-partial.type';
import { PaginatedResponse } from '../paginated-response';
import { ShipmentResponse } from './shipment-response.interface';
import { FetchAllParams } from '../fetch-all-params.interface';
import { ShipmentRequest } from './shipment-request.interface';
import { ImportShipmentsResponse } from './import-shipments-response';
import { FilterParams } from '@/types/filter-params.interface';
import { ImportShipmentsRequest } from './import-shipments-request';
import { Filters } from '@/types/filters.interface';
import { Filter } from '@/types/filter.interface';
import EntitiesService from '@/services/api/entities/entities.service';

export default {
  get endpoint(): string {
    return `${localStorage.getItem('local_base_url') || ''}/shipments`;
  },
  get: async function get<T>(endpoint: string): Promise<T> {
    const response = await axios.get<T>(endpoint);

    return response.data;
  },
  async fetchAll(
    params?: FetchAllParams,
  ): Promise<PaginatedResponse<ShipmentResponse>> {
    const data = EntitiesService.generateRequestConfig(params);

    const response = await axios.post<PaginatedResponse<ShipmentResponse>>(
      this.endpoint + '/search',
      data.params,
    );

    return response.data;
  },

  async fetchOne(id: string): Promise<ShipmentResponse> {
    return this.get<ShipmentResponse>(`${this.endpoint}/${id}`);
  },

  async update(
    id: string,
    shipment: ShipmentRequest,
  ): Promise<ShipmentResponse> {
    const response = await axios.put<ShipmentResponse>(
      `${this.endpoint}/${id}`,
      shipment,
    );

    return response.data;
  },

  async partialUpdate(
    id: string,
    shipment: DeepPartial<ShipmentRequest>,
  ): Promise<ShipmentResponse> {
    const response = await axios.patch<ShipmentResponse>(
      `${this.endpoint}/${id}`,
      shipment,
    );

    return response.data;
  },

  async bulk(
    params: Filters,
    update: { [key: string]: any },
  ): Promise<ShipmentResponse[]> {
    const filterParams = EntitiesService.buildFilterParams(params);

    const response = await axios.post<ShipmentResponse[]>(
      `${this.endpoint}/update`,
      { query: filterParams, update },
    );
    return response.data;
  },

  async import(
    payload: ImportShipmentsRequest,
  ): Promise<ImportShipmentsResponse> {
    const response = await axios.post<ImportShipmentsResponse>(
      `${localStorage.getItem('local_base_url') || ''}/shipment-imports`,
      payload,
    );
    return response.data;
  },

  async fetchImport(id: string): Promise<ImportShipmentsResponse> {
    const response = await axios.get<ImportShipmentsResponse>(
      `${localStorage.getItem('local_base_url') || ''}/shipment-imports/${id}`,
    );
    return response.data;
  },

  async fetchPerMilestone(params: FetchAllParams): Promise<number[]> {
    const data = EntitiesService.generateRequestConfig(params);

    const response = await axios.post<number[]>(
      `${this.endpoint}/statistics/per-milestone`,
      data.params,
    );
    return response.data;
  },

  async fetchCount(params: FetchAllParams): Promise<number> {
    const data = EntitiesService.generateRequestConfig(params);

    const response = await axios.post<number>(
      `${this.endpoint}/count`,
      data.params,
    );
    return response.data;
  },

  async fetchBreachedJobs(params: FetchAllParams): Promise<number> {
    const data = EntitiesService.generateRequestConfig(params);

    const response = await axios.post<number>(
      `${this.endpoint}/count-breached`,
      data.params,
    );
    return response.data;
  },

  async fetchFilterCount(params: FetchAllParams): Promise<number | undefined> {
    const filterParams: FilterParams = {};
    if (params) {
      for (const param of Object.entries(params)) {
        const [field, filter] = param as [string, Filter];

        if (!isBlank(filter.value)) {
          if (Array.isArray(filter.value)) {
            filterParams[field] = filter.value.map(
              (valueItem: string) => `${filter.operation}${valueItem}`,
            );
          } else {
            filterParams[field] = `${filter.operation}${
              filter.value as string
            }`;
          }
        }
      }
    }

    const response = await axios.post<number>(
      `${this.endpoint}/count`,
      filterParams,
    );
    return response.data;
  },

  async delete(id: string): Promise<AxiosResponse> {
    return axios.delete(`${this.endpoint}/${id}`);
  },

  async restore(id: string): Promise<void> {
    return this.get(`${this.endpoint}/restore/${id}`);
  },
};
