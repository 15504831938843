export enum ITransformationOperator {
  AddDays = 'ADD_DAYS',
  AddHours = 'ADD_HOURS',
  AddMinutes = 'ADD_MINUTES',
  SetValue = 'SET_VALUE',
  StringOrNull = 'STRING_OR_NULL',
  StringOrEmpty = 'STRING_OR_EMPTY',
  ValidDateOrNull = 'VALID_DATE_OR_NULL',
  NumberValueOrNull = 'NUMBER_VALUE_OR_NULL',
}
