




































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Report } from '@/services/api/reports/report.class';
import { BModal } from 'bootstrap-vue';
import ReportSelect from './ReportSelect.vue';
import FieldSelect from './ReportSelect.vue';

@Component({
  components: {
    ReportSelect,
    FieldSelect,
  },
})
export default class LinkReportForm extends Vue {
  @Prop({ type: Object, required: true })
  linkedReport!: Report;

  newLinkedReport: {
    value?: string;
    text?: string;
  } = {};

  $refs!: {
    linkReportModal: BModal;
  };

  form: Report = new Report();

  newField = '';

  closeLinkedReportModal(): void {
    this.$refs.linkReportModal.hide();
    this.newField = '';
  }

  linkReport() {
    if (!this.newField) return;

    this.$emit('save', this.newField);
    this.$refs.linkReportModal.hide();
    this.newField = '';
  }
}
