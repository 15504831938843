





















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ShipmentLabel } from '../services/api/shipment-labels/shipment-label.interface';
import api from '../services/api/';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { CarriersWithLabelsSupport } from '@/services/api/shipment-labels/shipment-label-types';
import { ShipmentLabelItem } from '@/services/api/shipment-labels/shipment-label-item.interface';

const usersModule = namespace('users');

@Component({})
export default class ShipmentLabels extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  labels!: ShipmentLabel;

  @Prop({
    type: String,
    required: true,
  })
  shipmentRef!: string;

  @Prop({
    type: String,
    required: false,
  })
  carrier!: CarriersWithLabelsSupport;

  @usersModule.Getter('GET_TIMEZONE')
  timezone!: string;

  @usersModule.Getter('GET_DATE_FORMAT')
  dateFormat!: string;

  async downloadFile(fileUrl: string, filename: string): Promise<void> {
    const file = await api.files.getFile(fileUrl);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  formatTime(date: string): string {
    return moment(date).tz(this.timezone).format(`${this.dateFormat} HH:mm`);
  }

  capitalize(source: string): string {
    if (typeof source !== 'string' || source.length < 1) {
      return source.toString();
    }
    if (source.length === 1) {
      return source.toLocaleUpperCase();
    }
    return `${source[0].toLocaleUpperCase()}${source
      .slice(1)
      .toLocaleLowerCase()}`;
  }

  get isSupportedCarrierForLabels(): boolean {
    return Object.values(CarriersWithLabelsSupport).includes(this.carrier);
  }

  get labelsItemsList(): ShipmentLabelItem[] {
    return this.labels.items || [];
  }
}
