






















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class ShipmentArchiveButton extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  archived!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Emit()
  toggle(archived: boolean): boolean {
    return !archived;
  }
}
