

































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../components/FieldSelect.vue';
import MilestoneSelect from '../components/MilestoneSelect.vue';
import flatPickr from 'vue-flatpickr-component';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { Office } from '../services/api/offices/office.class';
import { Role } from '../services/api/users/role.enum';
import Multiselect from 'vue-multiselect';
import { User as UserClass } from '../services/api/users/user.class';
import { ResetPasswordRequest } from '../services/api/auth/reset-password-request.interface';
import { DateFormat } from '../services/api/users/date-format.enum';
import { UserOfficeRelation } from '@/types/ui/user-office-relation';
import { ErrorBag } from 'vee-validate';

const toastersModule = namespace('toasters');
const usersModule = namespace('users');
const officesModule = namespace('offices');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
    FieldSelect,
    MilestoneSelect,
    Multiselect,
  },
})
export default class User extends Vue {
  get dateFormats(): string[] {
    return Object.values(DateFormat);
  }

  @officesModule.Action('FETCH_ALL_GLOBAL')
  fetchOffices!: () => Promise<void>;

  @officesModule.Getter('ALL_GLOBAL')
  allOffices!: Office[];

  @usersModule.Action('CREATE')
  createUser!: (user: UserClass) => Promise<UserClass>;

  @usersModule.Action('UPDATE')
  updateUser!: (obj: { id: string; user: UserClass }) => Promise<UserClass>;

  @usersModule.Action('DELETE')
  deleteUser!: (id: string) => Promise<UserClass>;

  @usersModule.Action('FETCH_ONE')
  fetchUserById!: (userId: string) => Promise<UserClass>;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @officesModule.Getter('ALL_GLOBAL')
  offices!: Office[];

  @usersModule.Getter('ALL')
  users!: UserClass[];

  @toastersModule.Action('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @usersModule.Action('RESET_AUTH')
  reset!: ({ userId }: ResetPasswordRequest) => Promise<any>;

  Role = Role;
  form: UserClass = new UserClass();
  timezones: string[] = [];

  initRole = Role.TeamMember;
  initOffice = {};

  optionOffices: Office[] = [];

  formErrors!: ErrorBag;

  @Watch('form.offices', {
    deep: true,
  })
  onFormOfficesChanged(): void {
    // reset the options list to all available offices
    this.optionOffices = this.allOffices;

    // remove each office selected in the form from the optionOffice array
    for (const formOffice of this.form.offices) {
      this.optionOffices = this.optionOffices.filter(
        (optionOffice: Office) => formOffice.office._id !== optionOffice._id,
      );
    }
  }

  findMostCommonRole(user: UserClass): Role {
    const rolesArray = [];
    const officesArray = [];
    for (const officeRelation of user.offices) {
      officesArray.push(officeRelation.office);
      rolesArray.push(officeRelation.role);
    }
    let highestHits = 0;
    let mostCommonRole: Role = Role.TeamMember;
    for (const roleString of Object.values(Role)) {
      const hitArray = user.offices.filter(
        (x: UserOfficeRelation) => x.role === roleString,
      );
      if (hitArray.length > highestHits) {
        highestHits = hitArray.length;
        mostCommonRole = roleString;
      }
    }
    return mostCommonRole;
  }

  addOffice(): void {
    this.form.offices.push({
      office: this.optionOffices[0],
      role: this.initRole,
    });
  }

  addRolesToOffices(): void {
    for (const formOffice of this.form.offices) {
      formOffice.role = this.initRole;
    }
  }

  deleteField(index: number): void {
    if (this.form.offices) {
      this.form.offices.splice(index, 1);
    }
  }

  onSubmit(): void {
    this.$validator.validate().then(async (validatedInput: boolean) => {
      if (validatedInput) {
        if (this.form._id) {
          if (this.form.__v) {
            delete this.form.__v;
          }
          this.addRolesToOffices();
          const user = await this.updateUser({
            id: this.form._id,
            user: this.form,
          });
          if (user) {
            this.addToast({
              message: `${user.username} was successfully updated`,
              title: 'User updated',
              type: ToasterTypes.SUCCESS,
            });
            this.$router.push({ name: 'allUsers' });
          }
        } else {
          if (this.form.__v) {
            delete this.form.__v;
          }
          this.addRolesToOffices();
          const user: UserClass = await this.createUser(this.form);
          if (user) {
            this.addToast({
              message: `an invitation email has been sent to ${user.email}`,
              title: `User ${user.username} successfully created`,
              type: ToasterTypes.SUCCESS,
            });
            this.$router.push({ name: 'allUsers' });
          }
        }
        this.$validator.reset();
      }
    });
  }

  async onReset(): Promise<void> {
    await this.reset({ userId: this.$route.params.id });
  }

  async created(): Promise<void> {
    this.timezones = ['Europe/Amsterdam', 'Asia/Jerusalem'];

    await this.fetchOffices();
    this.optionOffices = this.allOffices;
    if (this.$route.params.id) {
      const foundUser = await this.fetchUserById(this.$route.params.id);
      this.form = { ...foundUser };
      this.initRole = this.findMostCommonRole(this.form);
    } else {
      this.form = new UserClass();
      this.form.offices.push({
        office: { ...this.office },
        role: this.initRole,
      });
      this.form.timezone = this.timezones[0];
    }
  }
}
