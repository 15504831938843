



























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { ShipmentImport } from '@/services/api/shipment-imports/shipment-import.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import moment from 'moment-timezone/moment-timezone';
import { PaginatedResponseClass } from '@/services/api/paginated-response';

const shipmentImportsModule = namespace('shipmentImports');
const usersModule = namespace('users');

@Component({
  components: {
    EntityList,
  },
})
export default class AllShipmentImports extends Vue {
  @shipmentImportsModule.Getter('ALL_PAGINATED')
  shipmentImports!: PaginatedResponseClass<ShipmentImport>;

  @usersModule.Getter('GET_TIMEZONE')
  timezone!: string;

  @usersModule.Getter('GET_DATE_FORMAT')
  dateFormat!: string;

  @shipmentImportsModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => Promise<PaginatedResponseClass<ShipmentImport>>;

  @shipmentImportsModule.Action('UPDATE')
  updateById!: any;

  filter: FetchAllParams = {};

  formatTime(item: ShipmentImport): string {
    return moment(item.createdAt)
      .tz(this.timezone)
      .format(`${this.dateFormat} HH:mm`);
  }

  statusLabel(item: ShipmentImport): string {
    const badges: { [key: string]: string } = {
      DONE: 'success',
      ONGOING: 'info',
      FAILED: 'danger',
      REFUSED: 'danger',
      UPLOADED: 'info',
      CANCELLED: 'warning',
    };
    const badge = badges[item.status ? item.status : 'info'];
    return `<p class="badge badge-${badge} badge-pill mt-2">${
      item.status || ''
    }</p>`;
  }

  getDuration(item: ShipmentImport): string {
    if (!item.updatedAt || !item.createdAt) {
      return 'N/A';
    }

    const updatedAt = moment(item.updatedAt);
    const createdAt = moment(item.createdAt);

    return updatedAt.from(createdAt, true);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public columns = [
    {
      field: 'file.name.original',
      label: 'File',
      sortable: true,
      sortDirection: 'asc',
      test: 'hello',
    },
    {
      field: 'user.username',
      label: 'User',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: this.formatTime.bind(this),
      label: 'Started on',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: this.statusLabel.bind(this),
      label: 'Status',
      sortable: true,
      sortDirection: 'asc',
      isHTML: true,
    },
    {
      field: this.getDuration.bind(this),
      label: 'Duration',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'totalRecords',
      label: 'Total lines',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'success',
      label: 'Imported',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'failed',
      label: 'Failed',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  handleFilterChange(
    filter: FetchAllParams,
  ): Promise<PaginatedResponseClass<ShipmentImport>> {
    return this.fetchAll(filter);
  }

  handleDataImport(item: ShipmentImport): void {
    this.$router.push({
      name: 'ShipmentImport',
      params: { id: item._id || '' },
    });
  }

  async created(): Promise<void> {
    this.filter.sort = ['createdAt,desc'];
    this.filter.limit = 25;
    await this.fetchAll(this.filter);
  }
}
