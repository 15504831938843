/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CustomerUploadPdfResponse } from '@/services/api/customers/customer-upload-pdf-response';
import { CustomersState } from './types';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { Customer } from '@/services/api/customers/customer-interface';

export const mutations = {
  SET_SORT(state: CustomersState, payload: string[]) {
    state.apiParams = {
      ...state.apiParams,
      sort: payload,
    };
  },
  SET_ONE(state: CustomersState, payload: Customer): void {
    state.one = payload;
  },
  SET_PAGINATED(
    state: CustomersState,
    payload: PaginatedResponse<Customer>,
  ): void {
    state.customers = payload;
  },
  SET_ALL(state: CustomersState, payload: Customer[]): void {
    state.allCustomers = payload;
  },
  SET_UPLOAD_RESULT(
    state: CustomersState,
    payload: CustomerUploadPdfResponse,
  ): void {
    state.pdfUpload = payload;
  },
};
