type Env = 'development' | 'test' | 'staging';

const env: Env = (process.env.NODE_ENV as Env) || 'development';

type OptionalEnvParamsList = 'OKF_SHIPMENTS_LIMIT' | 'ENV';

type EnvParamsList =
  | 'API_URL'
  | 'APP'
  | 'LOG_URL'
  | 'USE_SEPERATE_LOGS_API'
  | 'DOMAIN_URL';

type EnvParams = {
  [key in EnvParamsList]: string;
} &
  {
    [key in OptionalEnvParamsList]?: string;
  };

type EnvConfig = {
  [key in Env]: EnvParams;
};

import config from './env.variables.json';
const envConfig = ((config as unknown) as EnvConfig)[env];

export default envConfig;
