
















































































































































import {
  Vue,
  Component,
  Prop,
  Model,
  Emit,
  Watch,
} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

import FieldSelect from '../components/FieldSelect.vue';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { TransformationCondition } from '../services/api/transformations/transformation-condition.interface';
import {
  ConditionOperator,
  humanReadableConditionOperator,
} from '../services/api/transformations/condition-operator.enum';
import { Transformation } from '@/services/api/transformations/transformation.class';

@Component({
  components: {
    Multiselect,
    FieldSelect,
  },
})
export default class TransformationConditionRow extends Vue {
  @Model('change', {
    type: Object,
  })
  condition!: TransformationCondition;

  @Prop({
    type: Boolean,
    default: false,
  })
  useFieldSelect!: boolean;

  @Prop({
    type: Number,
  })
  keyNumber!: number;

  @Prop({
    type: Array,
    required: false,
  })
  transformations!: Transformation[];

  importedFields: string[] = [];

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  @Watch('transformations', { immediate: true })
  onOutputFieldChange(): string[] {
    this.transformations.map(trans =>
      trans.operations.map(operation =>
        operation.transformationFields.map(field =>
          this.importedFields.push(field),
        ),
      ),
    );
    return this.importedFields;
  }

  @Watch('condition.operator', { deep: true })
  onConditionChange(
    newVal: ConditionOperator,
    oldVal: ConditionOperator,
  ): void {
    if (newVal === oldVal) {
      return;
    }
    if (!this.isArrayInput) {
      this.condition.parameters = '';
    } else {
      this.condition.parameters = [];
    }
  }

  get operatorOptions(): ConditionOperator[] {
    const {
      In,
      NotIn,
      Contains,
      doesNotContains,
      ...operators
    } = ConditionOperator;
    return Object.values(ConditionOperator);
  }

  get showParams(): boolean | undefined {
    return (
      this.condition.operator &&
      this.condition.operator !== ConditionOperator.Exists
    );
  }

  get isArrayInput(): boolean | undefined {
    return (
      this.condition.operator === ConditionOperator.In ||
      this.condition.operator === ConditionOperator.NotIn ||
      this.condition.operator === ConditionOperator.ContainsIn
    );
  }

  get isNumberInput(): boolean | undefined {
    return (
      this.condition.operator === ConditionOperator.GreaterThan ||
      this.condition.operator === ConditionOperator.LowerThan ||
      this.condition.operator === ConditionOperator.GreaterOrEqual ||
      this.condition.operator === ConditionOperator.LowerOrEqual
    );
  }

  @Emit('delete')
  deleteCondition(): boolean {
    return true;
  }

  operatorCustomLabel(label: ConditionOperator): string {
    return humanReadableConditionOperator(label);
  }
}
