














import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CustomerResponse } from '../services/api/customers/customer-response.interface';
import { PaginatedResponseClass } from '../services/api/paginated-response';

const customersModule = namespace('customers');

@Component
export default class CustomerExcludingSelect extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  value!: CustomerResponse | null;

  @Prop({
    type: Array,
    default: () => [],
  })
  excludes!: string[];

  @customersModule.Getter('ALL')
  customers!: CustomerResponse[];

  options: any[] = [];
  // CustomerResponse[]
  @Watch('customers', {
    immediate: true,
  })
  onCustomersChange(customers: CustomerResponse[]): void {
    this.createOptions(customers, this.excludes);
  }

  @Watch('excludes', {
    immediate: true,
  })
  onExcludesChange(excludes: string[]): void {
    this.createOptions(this.customers, excludes);
  }

  @Emit('input')
  onChange(selectedValue: CustomerResponse): CustomerResponse {
    this.$emit('change', selectedValue);
    return selectedValue;
  }

  private createOptions(customers: CustomerResponse[], excludes: string[]) {
    this.options = customers.map((customer: CustomerResponse) => ({
      value: customer,
      text: customer.parent.name,
      disabled: excludes.includes(customer._id || ''),
    }));
  }
}
