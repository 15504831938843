import { GetterTree } from 'vuex';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { UIState } from './types';
import { RootState } from '../types';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { Ui } from '@/services/api/ui/ui.class';

export const getters: GetterTree<UIState, RootState> = {
  ALL(state): Ui[] {
    return state.all;
  },

  UIS(state): Ui[] {
    return state.uis.docs;
  },
  UIS_PAGINATED(state): PaginatedResponse<Ui> {
    return state.uis;
  },
  LOADING(state): boolean {
    return state.loading;
  },
  ERROR(state): boolean {
    return state.error;
  },
  API_PARAMS(state): FetchAllParams {
    return state.apiParams;
  },
  GET(state): Ui {
    return state.one;
  },
};
