























































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import FieldSelect from '../components/FieldSelect.vue';
import { Toaster } from '../store/modules/toasters/types';
import { Milestone } from '../services/api/milestones/milestone.class';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { FieldFlagsBag, ErrorBag, FieldFlags } from 'vee-validate';

const milestonesModule = namespace('milestones');
const milestoneTypesModule = namespace('milestoneTypes');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');

@Component({
  components: {
    Multiselect,
  },
})
export default class MilestoneDetail extends Vue {
  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Milestone;

  @milestonesModule.Getter('ONE')
  milestone!: Milestone;

  @milestonesModule.Getter('MAX_SEQUENCE')
  maxSequence!: number;

  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @milestonesModule.Action('CREATE')
  createMilestone!: (milestone: any) => Promise<Milestone>;

  @milestonesModule.Action('UPDATE')
  updateMilestone!: (payload: { id: string; milestone: any }) => void;

  @milestonesModule.Action('FETCH_ONE')
  fetchMilestone!: (id: string) => Promise<void>;

  @milestonesModule.Action('FETCH_MAX_SEQUENCE')
  fetchMaxSequence!: () => Promise<number>;

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchMilestoneTypes!: () => any[];

  form: Milestone = new Milestone();
  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  get isFormValid(): boolean {
    return Object.keys(this.formFields).every((field: string) => {
      const fieldFlags: FieldFlags = this.formFields[field];

      return Boolean(fieldFlags && fieldFlags.valid);
    });
  }

  shipmentAvailableFields = ['createdAt', 'updatedAt'];

  @Watch('milestone', { deep: true })
  onTypeChange(): void {
    this.form = this.milestone;
  }

  onSequenceChange(event: Event): void {
    if (!event) {
      return;
    }
    const value = (event.target as HTMLInputElement).valueAsNumber;
    this.form.sequence = value < 0 ? 0 : value;
  }

  async onSubmit(): Promise<void> {
    if (this.form._id) {
      this.updateMilestone({ id: this.form._id, milestone: this.form });
    } else {
      const milestone: Milestone = await this.createMilestone(this.form);
      if (milestone && milestone._id) {
        this.$router.replace({
          name: 'Milestone',
          params: { id: milestone._id },
        });
      }
    }
  }

  async created(): Promise<void> {
    this.fetchMilestoneTypes();
    if (this.$route.params.id) {
      await this.fetchMilestone(this.$route.params.id);
      this.form = this.milestone;
    } else {
      await this.fetchMaxSequence();
      this.form.sequence = this.maxSequence + 1;
      this.form.office = this.office._id;
    }
  }

  handleShipmentFieldCheck(event: boolean): void {
    event
      ? (this.form.shipmentField = this.shipmentAvailableFields[0])
      : (this.form.shipmentField = '');
  }

  addSelectedShipmentField(fieldValue: string): void {
    this.form.shipmentField = fieldValue;
  }
}
