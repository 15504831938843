




































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ShipmentAttachment } from '../services/api/shipment-attachments/shipment-attachment.class';
import { FileResponse } from '../services/api/files/file-response.interface';
import api from '../services/api/';
import { FileType } from '../services/api/attachments/file-type.enum';
import { Attachment } from '../services/api/attachments/attachment.class';
import vue2Dropzone from 'vue2-dropzone';

@Component({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  components: { vue2Dropzone },
})
export default class ShipmentAttachments extends Vue {
  @Prop()
  attachments!: Attachment[];

  @Prop()
  shipmentAttachments!: ShipmentAttachment[];

  @Prop()
  shipmentID!: string;

  $refs!: Vue['$refs'] & HTMLElement[];

  file: File = {} as File;

  getDropzoneOptions(): {
    url: string;
    autoProcessQueue: boolean;
    thumbnailWidth: number;
    maxFilesize: number;
    timeout: null;
    dictDefaultMessage: string;
  } {
    return {
      url: '/',
      autoProcessQueue: false,
      thumbnailWidth: 150,
      maxFilesize: 512,
      timeout: null,
      dictDefaultMessage:
        'Drop files here to upload <br> or <br> click to select files',
    };
  }

  async getDownloadFile(fileUrl: string, filename: string): Promise<void> {
    const file = await api.files.getFile(fileUrl);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async downloadFile(attachment: Attachment): Promise<void> {
    const shipmentAttachment = this.getShipmentAttachmentByAttachment(
      attachment,
    );
    if (!shipmentAttachment) {
      return;
    }
    return this.getDownloadFile(
      (shipmentAttachment.file as FileResponse).url,
      (shipmentAttachment.file as FileResponse).name.original,
    );
  }

  downloadFileByShipmentAttachment(
    shipmentAttachment: ShipmentAttachment,
  ): Promise<void> {
    if (!shipmentAttachment) {
      return Promise.reject();
    }
    return this.getDownloadFile(
      (shipmentAttachment.file as FileResponse).url,
      (shipmentAttachment.file as FileResponse).name.original,
    );
  }

  getShipmentAttachmentByAttachment(
    attachment: Attachment,
  ): ShipmentAttachment | void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dz = this.$refs[`dropzone-${attachment.name || ''}`] as any;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const dropzone =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      dz && Array.isArray(dz) && dz.length ? dz[0].dropzone : null;

    if (dropzone) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      dropzone.removeAllFiles();
    }

    for (const item of this.shipmentAttachments) {
      const id =
        typeof item.attachment === 'string'
          ? item.attachment
          : item.attachment._id;
      if (item.shipment === this.shipmentID) {
        if (attachment._id === id) {
          return item;
        }
      }
    }
    return;
  }

  getShipmentAttachmentByShipmentAttachment(
    shipmentAttachment: ShipmentAttachment,
  ): ShipmentAttachment | void {
    for (const item of this.shipmentAttachments) {
      if (item.shipment === this.shipmentID) {
        if (shipmentAttachment.file === item.file) {
          return item;
        }
      }
    }
    return;
  }

  getShipmentAttachmentsByAttachment(
    attachment: Attachment,
  ): ShipmentAttachment[] {
    const shipmentAttachments = [];
    for (const item of this.shipmentAttachments) {
      const id =
        typeof item.attachment === 'string'
          ? item.attachment
          : item.attachment._id;
      if (item.shipment === this.shipmentID) {
        if (attachment._id === id) {
          shipmentAttachments.push(item);
        }
      }
    }
    return shipmentAttachments;
  }

  unAttachShipment(attachment: Attachment): void {
    if (!attachment) {
      return;
    }

    const shipmentAttachment = this.getShipmentAttachmentByAttachment(
      attachment,
    );
    if (!shipmentAttachment) {
      return;
    }
    const userFeedback = confirm(
      `Are you sure you want to unattach ${attachment.name!}?`,
    );
    if (userFeedback) {
      this.$emit('unAttach', shipmentAttachment);
    }
  }

  unAttachShipmentByShipmentAttachment(
    shipmentAttachment: ShipmentAttachment,
  ): void {
    if (shipmentAttachment == null) {
      return;
    }
    const file = shipmentAttachment.file as FileResponse;
    const userFeedback = confirm(
      `Are you sure you want to unattach ${file.name.original}?`,
    );
    if (userFeedback) {
      this.$emit('unAttach', shipmentAttachment);
    }
  }

  formatTypes(attachment: Attachment): string | void {
    if (!attachment || !attachment.fileTypes) {
      return;
    }

    let allExtensionsArray: string[] = [...attachment.fileTypes];
    if (attachment.fileTypes.includes(FileType.XLS)) {
      allExtensionsArray = allExtensionsArray.concat([
        'xlsx',
        'xlsm',
        'xlr',
        'xlw',
        'xla',
        'xlam',
        'xml',
        'xlt',
        'xltm',
        'xltx',
        'xlsb',
        'csv',
      ]);
    }
    return allExtensionsArray.join(',.');
  }

  startDropzoneUpload(
    file: Dropzone.DropzoneFile,
    attachment: Attachment,
  ): void {
    this.$emit('upload', file, attachment);
  }
}
