import Vue from 'vue';
import VeeValidate, { Configuration } from 'vee-validate';
import { DataObject } from '@/services/api/data-object.interface';
import { Milestone } from '@/services/api/milestones/milestone.class';

const config: Configuration = {
  fieldsBagName: 'formFields',
  errorBagName: 'formErrors',
};

Vue.use(VeeValidate, config);

VeeValidate.Validator.extend('verify_password_score', {
  getMessage: () => 'Please use a stronger password, this password is too weak',
  validate: (value, passwordScore) => {
    if (Array.isArray(passwordScore)) {
      if (parseInt(passwordScore[0], 10) >= 3) {
        return true;
      }
    }
    return false;
  },
});
VeeValidate.Validator.extend('dataObject', {
  getMessage: () => 'The field is required',
  validate: (value: DataObject) => {
    if (!value.text || value.text === 'select a field') {
      return false;
    }
    return true;
  },
});
VeeValidate.Validator.extend('MilestoneNotEmpty', {
  getMessage: () => 'Select a milestone',
  validate: (value: Milestone | Milestone[]) => {
    if (Array.isArray(value)) {
      if (!value[0].name.length) {
        return false;
      }
      return true;
    }
    if (!value.name.length) {
      return false;
    }
    return true;
  },
});

VeeValidate.Validator.extend('firstAliasNotEmpty', {
  getMessage: () => 'Select at least one alias for this customer',
  validate: (value: string, aliases) => {
    if (Array.isArray(aliases)) {
      for (const alias of aliases) {
        if ((alias as string).length >= 1) {
          return true;
        }
        continue;
      }
    }
    return false;
  },
});
