














import { Vue, Component, Model, Emit, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import {
  FilterOperation,
  humanReadableFilterOperation,
} from '../types/filter-operation.enum';
import { FieldSelectDataType } from '@/types/field-select-data-type.enum';

@Component({
  components: {
    Multiselect,
  },
})
export default class FilterOperationComponent extends Vue {
  @Model('input', {
    type: String,
  })
  operation!: FilterOperation;

  @Prop({
    type: Boolean,
  })
  stringValue!: true;

  @Prop({
    type: String,
    required: false,
  })
  dataType?: FieldSelectDataType;

  @Emit('input')
  onChange(value: FilterOperation): FilterOperation {
    return value;
  }

  get operationValue(): FilterOperation {
    if (this.operation === FilterOperation.Equals) {
      return FilterOperation.EqualsWithSign;
    }
    return this.operation;
  }

  set operationValue(value: FilterOperation) {
    if (value === FilterOperation.EqualsWithSign) {
      this.onChange(FilterOperation.Equals);
    } else {
      this.onChange(value);
    }
  }

  get operationOptions(): FilterOperation[] {
    if (this.dataType === FieldSelectDataType.TypeNumber) {
      return [
        FilterOperation.Equals,
        FilterOperation.GreaterThan,
        FilterOperation.GreaterThanOrEqual,
        FilterOperation.LessThan,
        FilterOperation.LessThanOrEqual,
      ];
    } else {
      return [
        FilterOperation.Equals,
        FilterOperation.EqualsWithSign,
        FilterOperation.StartsWith,
        FilterOperation.Contains,
      ];
    }
  }

  operationCustomLabel(item: FilterOperation): string {
    return humanReadableFilterOperation(item);
  }
}
