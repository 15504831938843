import lowerCase from 'lodash.lowercase';

export enum ConditionOperator {
  Exists = 'EXISTS',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  GreaterThan = 'GREATER_THAN',
  LowerThan = 'LOWER_THAN',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  In = 'IN',
  NotIn = 'NIN',
  Contains = 'CONTAINS',
  doesNotContains = 'NOT_CONTAINS',
  ContainsIn = 'CONTAINS_IN',
}

export function humanReadableConditionOperator(
  value: ConditionOperator,
): string {
  switch (value) {
  case ConditionOperator.NotIn:
    return 'not in list';

  case ConditionOperator.In:
    return 'in list';

  default:
    return lowerCase(value);
  }
}
