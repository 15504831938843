





























































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Vue, Component, Provide, Watch } from 'vue-property-decorator';
import { FieldFlagsBag, FieldFlags, ErrorBag } from 'vee-validate';
import appMilestoneSelect from '../components/MilestoneSelect.vue';
import FieldSelect from '../components/FieldSelect.vue';
import AttachmentSelect from '../components/AttachmentSelect.vue';
import DestinationNotifications from '../components/DestinationNotifications.vue';
import { namespace } from 'vuex-class';
import MailForm from '../components/MailForm.vue';
import { VerifiedEmail } from '../services/api/verified-emails/verified-email.class';
import { Customer } from '../services/api/customers/customer-interface';
import { CustomerMilestoneRequest } from '../services/api/customers/customer-milestone-request.interface';
import { Office } from '../services/api/offices/office.class';
import draggable from 'vuedraggable';
import { Attachment } from '../services/api/attachments/attachment.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { MailDetails } from '@/services/api/reports/mail-details.class';
import { CustomerMilestone } from '@/types/customer/customer-milestone.class';
import { Milestone } from '@/services/api/milestones/milestone.class';
import { SavableComponent } from '@/types/savable-component.class';

const customersModule = namespace('customers');
const verifiedEmailsModule = namespace('verifiedEmails');
const usersModule = namespace('users');
const attachmentsModule = namespace('attachments');

@Component({
  components: {
    appMilestoneSelect,
    FieldSelect,
    AttachmentSelect,
    DestinationNotifications,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    draggable,
    MailForm,
  },
})
export default class CustomerMilestoneList extends SavableComponent {
  @Provide('validator') $validatorRef = this.$validator;

  @verifiedEmailsModule.Getter('ALL')
  verifiedEmails!: VerifiedEmail[];

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @verifiedEmailsModule.Action('FETCH_ALL')
  fetchVerifiedEmails!: (filter?: any) => Promise<VerifiedEmail[]>;

  @customersModule.Getter('ONE')
  customer!: Customer;

  @customersModule.Action('FETCH_ONE')
  fetchCustomer!: (id: string) => Promise<Customer>;

  @attachmentsModule.Action('FETCH_ONE')
  fetchAttachment!: (id: string) => Attachment;

  @attachmentsModule.Getter('ALL_PAGINATED')
  allAttachments!: PaginatedResponseClass<Attachment>;

  @attachmentsModule.Action('FETCH_ALL')
  fetchAllAttachments!: (
    filter?: FetchAllParams,
  ) => Promise<PaginatedResponseClass<Attachment>>;

  @customersModule.Action('UPDATE')
  updateCustomer!: (obj: {
    id: string;
    customer: Customer;
  }) => Promise<Customer>;

  customerMilestones: CustomerMilestone[] = [];
  showKeywordModal = false;
  selectedKeyword: {
    text?: string;
    value?: string;
  } = {};

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  useDestinationOfficeEmails: boolean[] = [];

  newField: {
    alias?: string;
    field?: {
      text?: string | undefined;
      value?: string | undefined;
    };
    name?: string;
  } = { field: {}, alias: '' };

  newAttachment: {
    value?: string;
    text?: string;
  } = {};

  newDestinationNotification: {
    office?: {
      value?: string;
      text?: string;
    };
    email?: string;
  } = {};

  dragOptions: any = {
    animation: 200,
    group: 'description',
    ghostClass: 'ghost',
  };

  $refs!: Vue['$refs'] & {
    mailForm: MailForm;
  };

  @Watch('customerMilestones', { deep: true })
  onCustomerMilestonesChange(val: CustomerMilestone[]) {
    this.useDestinationOfficeEmails = val.map(
      milestone => milestone.destinationNotifications.length > 0,
    );
  }

  keywordCallback: (str: string) => string = () => '';

  useDestinationOfficeToggled(val: boolean, index: number): void {
    this.$set(this.useDestinationOfficeEmails, index, val);
  }

  get title(): string {
    if (this.customer && this.customer.parent) {
      return this.customer.parent.name;
    }

    return 'New Customer';
  }

  get customerId(): string | null {
    if (this.customer && this.customer._id) {
      return this.customer._id;
    }
    return null;
  }

  get isFormValid(): boolean {
    return Object.keys(this.formFields).every((field: string) => {
      const fieldFlags: FieldFlags = this.formFields[field];

      return Boolean(fieldFlags && fieldFlags.valid);
    });
  }

  get isFieldValid(): boolean {
    if (
      !this.newField ||
      !this.newField.field ||
      !this.newField.field.text ||
      !this.newField.field.value
    ) {
      return false;
    }
    return true;
  }

  get isAttachmentValid(): boolean {
    if (
      !this.newAttachment ||
      !this.newAttachment.text ||
      !this.newAttachment.value
    ) {
      return false;
    }
    return true;
  }

  checkIsDestinationNotificationValid(): boolean {
    const emailInvalid = this.formErrors.has('input-dest-notification-email');

    if (
      !this.newDestinationNotification ||
      !this.newDestinationNotification.office ||
      !this.newDestinationNotification.office.value ||
      !this.newDestinationNotification.email ||
      emailInvalid
    ) {
      return false;
    }

    return true;
  }

  checkEmailInputValidity(event: KeyboardEvent): boolean {
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    );
    return emailRegex.test(this.newDestinationNotification.email || '');
  }

  validateEventTarget(event: Event | KeyboardEvent): void {
    this.$validator
      .validate('input-dest-notification-email')
      .then((validatedInputTo: boolean) => {
        if (validatedInputTo) {
          this.$validator.reset();
        }
      });
  }

  handleBlur(event: Event): void {
    this.validateEventTarget(event);
  }

  keydownHandler(event: KeyboardEvent): void {
    if (
      event.key === 'Enter' ||
      event.key === ',' ||
      event.key === ';' ||
      event.key === 'Tab'
    ) {
      // The key pressed was the enter key
      if (!event.target) {
        return;
      }
      this.validateEventTarget(event);
    }
  }

  addEmail(target: string, input: string, listIndex: number): void {
    switch (target) {
      case `input-to-${listIndex}`:
        this.customerMilestones[listIndex].mail.to.push(input);
        break;
      case `input-cc-${listIndex}`:
        if (!input.includes('@')) {
          return;
        }
        this.customerMilestones[listIndex].mail.cc.push(input);
        break;
      case `input-bcc-${listIndex}`:
        if (!input.includes('@')) {
          return;
        }
        this.customerMilestones[listIndex].mail.bcc.push(input);
        break;
    }
  }

  removeEmail(field: string, index: number, listIndex: number): void {
    switch (field) {
      case 'to':
        this.customerMilestones[listIndex].mail.to.splice(index, 1);
        break;
      case 'cc':
        this.customerMilestones[listIndex].mail.cc.splice(index, 1);
        break;
      case 'bcc':
        this.customerMilestones[listIndex].mail.bcc.splice(index, 1);
        break;
      default:
        break;
    }
  }

  updateMailFormValues(mailFormInfo: MailDetails, listIndex: number): void {
    this.customerMilestones[listIndex].mail = mailFormInfo;
  }

  async validateChildComponents(): Promise<boolean> {
    const res: CustomerMilestone[] = [];
    let mileStoneValid = false;
    if (!this.customerMilestones.length) {
      mileStoneValid = true;
    }

    await Promise.all(
      this.customerMilestones.map(
        async (item, index): Promise<boolean | undefined> => {
          const hasDestinationOfficeEmails =
            this.customerMilestones[index].destinationNotifications.length > 0;
          const hasValidDestEmailAddresses =
            this.useDestinationOfficeEmails[index] &&
            hasDestinationOfficeEmails;

          if (
            !hasValidDestEmailAddresses &&
            (!item.mail.to.length || !item.mail.to[0].length)
          ) {
            await this.$validator.validate(`inputTo-${index}`);
          } else {
            res.push(item);
          }
          mileStoneValid = await this.$validator.validate(
            `mileStoneSelect-${index}`,
          );
          return undefined;
        },
      ),
    );

    if (res.length === this.customerMilestones.length && mileStoneValid) {
      return true;
    }
    return false;
  }

  async save(): Promise<void> {
    if (!(await this.validateChildComponents())) {
      return;
    }

    const customer = { ...this.customer } as Customer;
    const updateCustomer = { ...this.customer } as Customer;
    if (customer.customerMilestones && updateCustomer.customerMilestones) {
      for (const [index, cms] of customer.customerMilestones.entries()) {
        if (typeof cms.milestone === 'object' && updateCustomer) {
          updateCustomer.customerMilestones[index].milestone =
            cms.milestone._id;
        }
      }
    }
    if (!updateCustomer.office) {
      updateCustomer.office = this.office._id || '';
    }

    if (this.customerId) {
      const customerResponse = await this.updateCustomer({
        id: this.customerId,
        customer: updateCustomer,
      });
      if (customerResponse) {
        this.customerMilestones = customerResponse.customerMilestones as CustomerMilestone[];
      }
    }
  }

  addCustomerMilestone(): void {
    this.customerMilestones.push({
      milestone: new Milestone(),
      fields: [],
      attachments: [],
      destinationNotifications: [],
      mail: new MailDetails(),
      active: true,
    });
  }

  copyCurrentMilestoneNotification(index: number): void {
    const currentMilestone = this.customerMilestones[index];

    this.customerMilestones.push({
      milestone: new Milestone(),
      fields: currentMilestone.fields,
      attachments: currentMilestone.attachments,
      destinationNotifications: currentMilestone.destinationNotifications || [],
      mail: currentMilestone.mail,
      active: true,
    });
  }

  addField(cms: CustomerMilestoneRequest): void {
    if (!cms.fields) {
      return;
    }
    if (this.newField.field) {
      cms.fields.push({
        name: this.newField.field.text,
        alias: this.newField.alias
          ? this.newField.alias
          : this.newField.field.text
          ? (this.$t(this.newField.field.text) as string)
          : 'ERROR',
        field: this.newField.field.value,
      });
    }
    this.newField = {};
  }

  addAttachment(cms: CustomerMilestoneRequest): void {
    if (!cms.attachments) {
      cms.attachments = [];
    }

    const existingAttachment =
      cms.attachments &&
      cms.attachments.length &&
      cms.attachments.find(x => x.attachment === this.newAttachment.value);

    if (this.newAttachment.value && !existingAttachment) {
      cms.attachments.push({
        name: this.newAttachment.text,
        attachment: this.newAttachment.value,
      });
    }
    this.newAttachment = {};
  }

  addDestinationNotification(cms: CustomerMilestoneRequest): void {
    if (!cms.destinationNotifications) {
      cms.destinationNotifications = [];
    }

    const existingDestNotification =
      cms.destinationNotifications &&
      cms.destinationNotifications.length &&
      cms.destinationNotifications.find(
        x => x.office === this.newDestinationNotification.office,
      );

    if (this.newDestinationNotification.office && !existingDestNotification) {
      cms.destinationNotifications.push({
        office: this.newDestinationNotification.office,
        email: this.newDestinationNotification.email,
      });
    }
    this.newDestinationNotification = {};
  }

  moveField(
    cms: CustomerMilestoneRequest,
    oldIndex: number,
    offset: number,
  ): void {
    if (!cms.fields) {
      return;
    }
    const newIndex: number = oldIndex + offset;
    if (newIndex < 0 || newIndex === cms.fields.length) {
      return;
    }
    const obj1 = cms.fields[oldIndex];
    const obj2 = cms.fields[newIndex];
    cms.fields[oldIndex] = obj2;
    cms.fields[newIndex] = obj1;
    cms.fields.push({});
    cms.fields.pop();
  }

  deleteField(cms: CustomerMilestoneRequest, index: number): void {
    if (!cms.fields) {
      return;
    }
    cms.fields.splice(index, 1);
  }

  deleteAttachment(cms: CustomerMilestoneRequest, index: number): void {
    if (!cms.attachments) {
      return;
    }
    cms.attachments.splice(index, 1);
  }

  deleteDestinationNotification(
    cms: CustomerMilestoneRequest,
    index: number,
  ): void {
    if (!cms.destinationNotifications) {
      return;
    }
    this.$set(cms.destinationNotifications, index, null);
    cms.destinationNotifications = cms.destinationNotifications.filter(
      item => item !== null,
    );
  }

  isPrivateNote(field: {
    alias?: string;
    field?: string;
    name?: string;
  }): boolean | undefined {
    if (!field.field) {
      return;
    }
    if (field.field.includes('attachments')) {
      const formattedField = field.field.split('.');
      const attachments = this.allAttachments;
      if (attachments) {
        const attachment = attachments.docs.find(
          (x: Attachment) => x._id === formattedField[1],
        );
        if (attachment) {
          return attachment.isPrivate;
        }
      }
    }
  }

  async created(): Promise<void> {
    await this.fetchAllAttachments();
    await this.fetchCustomer(this.$route.params.customerId);
    await this.fetchVerifiedEmails({ verified: true });
    this.customerMilestones = this.customer
      .customerMilestones as CustomerMilestone[];
  }

  mounted(): void {
    if (this.$route.hash) {
      setTimeout(() => (location.href = this.$route.hash), 500);
    }
  }
}
