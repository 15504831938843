/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import ReportSchedulesService from '@/services/api/reports/report-schedules.service';
import { ReportSchedule } from '@/services/api/reports/report-schedule.class';
import { ToasterTypes } from '@/store/modules/toasters/types';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { RootState } from '@/store/modules/types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Filter } from '@/types/filter.interface';
import { SortOrder } from '@/services/api/sort-order.enum';

const reportSchedulesServices = new ReportSchedulesService();
const namespaced = true;

interface ReportScheduleState {
  one: ReportSchedule;
  all: PaginatedResponse<ReportSchedule>;
  apiParams: FetchAllParams;
}

const initialReportScheduleState: ReportScheduleState = {
  one: new ReportSchedule(),
  all: new PaginatedResponseClass(),
  apiParams: { sort: [`timeToSend,${SortOrder.Asc}`] },
};

const getters: GetterTree<ReportScheduleState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<ReportScheduleState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<ReportSchedule>) {
    // payload.docs = [
    //   ...payload.docs.map(item => {
    //     if (item.recurrenceType === 'DAYS') {
    //       return { ...item, sortOrder: 0 };
    //     }
    //     if (item.recurrenceType === 'WEEKS') {
    //       return { ...item, sortOrder: 1 };
    //     }
    //     return { ...item, sortOrder: 2 };
    //   }),
    // ];
    // payload.docs.sort((a, b) =>
    //   a.sortOrder > b.sortOrder
    //     ? 1
    //     : a.sortOrder === b.sortOrder
    //       ? a.time > b.time
    //         ? 1
    //         : -1
    //       : -1,
    // );
    state.all = payload;
  },
  SET_ONE(state, payload: ReportSchedule) {
    state.one = payload;
  },
};

const actions: ActionTree<ReportScheduleState, RootState> = {
  async CREATE(context, payload: ReportSchedule): Promise<ReportSchedule> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportSchedulesServices.create(payload);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Created report schedule successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string): Promise<ReportSchedule> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportSchedulesServices.fetchOne(id);
      context.commit('SET_ONE', report);
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context,
    filter?: Filter,
  ): Promise<PaginatedResponse<ReportSchedule>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const reports = await reportSchedulesServices.fetch(
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', reports);
      return reports;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async ADD_LINKED_REPORT(
    _context,
    payload: { reportId: string; linkedReportId: string },
  ) {
    await reportSchedulesServices.addLinkedReports(
      payload.reportId,
      payload.linkedReportId,
    );
  },
  async REMOVE_LINKED_REPORT(
    _context,
    payload: { reportId: string; linkedReportId: string },
  ) {
    await reportSchedulesServices.removeLinkedReports(
      payload.reportId,
      payload.linkedReportId,
    );
  },
  async UPDATE(
    context,
    payload: { id: string; reportSchedule: ReportSchedule },
  ): Promise<ReportSchedule> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportSchedulesServices.update(
        payload.id,
        payload.reportSchedule,
      );
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        { title: 'Save successfully', message: '', type: ToasterTypes.SUCCESS },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string): Promise<ReportSchedule> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const report = await reportSchedulesServices.delete(payload);
      context.commit('SET_ONE', report);
      context.dispatch(
        'toasters/ADD_TOASTER',
        {
          title: 'Report schedule deleted successfully',
          message: '',
          type: ToasterTypes.SUCCESS,
        },
        { root: true },
      );
      return report;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced,
  actions,
  getters,
  mutations,
  state: initialReportScheduleState,
};
