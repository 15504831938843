




















































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

const officesModule = namespace('offices');

@Component({
  components: {
    Multiselect,
  },
})
export default class DestinationNotification extends Vue {
  @Prop()
  value!: {};
  @officesModule.Getter('ALL_OPTIONS')
  options!: Option[];

  @officesModule.Action('FETCH_ALL_OPTIONS')
  fetchOptions!: (filter?: FetchAllParams) => Promise<void>;

  searchQuery: string[] = [];

  modelValue: any[] = [];

  $refs!: Vue['$refs'] & {
    customAttachmentSelect: Multiselect;
  };

  @Watch('value', {
    immediate: true,
  })
  onValueChange(value: any): void {
    if (value) {
      if (this.isStringInput) {
        const search = this.options.find(option => option.value === value);
        if (search) {
          this.modelValue = search as any;
        }
      } else {
        this.modelValue = value;
      }
    } else {
      this.modelValue = [];
    }
  }

  @Emit('input')
  onChange(selectedValue: any): unknown {
    if (this.isStringInput) {
      return selectedValue && selectedValue.value
        ? selectedValue.value
        : undefined;
    }
  }

  onSearch(input: string): void {
    if (input.length === 2 || input.length === 0) {
      this.fetchOptions({
        s: input,
      });
    }
  }

  async created(): Promise<void> {
    await this.fetchOptions();
    await this.searchTranslations('');
    this.onValueChange(this.value);
  }

  get isStringInput(): boolean {
    return typeof this.value === 'string';
  }

  async searchTranslations(s: string): Promise<void> {
    await this.fetchOptions({ name: s });
  }
}
