import flatpickrTypes from 'flatpickr';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

export function getFlatPickrConfig(): flatpickrTypes.Options.Options {
  return {
    enableTime: true,
    time_24hr: true,
    altInput: true,
    altFormat: 'd/m/y H:i',
    dateFormat: 'Z',
    defaultHour: 12,
    wrap: true,
    plugins: [
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ShortcutButtonsPlugin({
        button: [
          {
            label: 'NOW',
          },
        ],
        onClick: (index: number, fp: flatpickrTypes.Instance) => {
          fp.setDate(new Date(Date.now()));
          fp.close();
        },
      }),
    ],
  };
}
