


































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appNavTabs from '../components/NavTabs.vue';
import { Office } from '../services/api/offices/office.class';
import { SavableComponent } from '@/types/savable-component.class';
import { NavTabItem } from '@/types/nav-tab-item.interface';

const officesModule = namespace('offices');
const regionsModule = namespace('regions');
const companiesModule = namespace('companies');

@Component({
  components: {
    appNavTabs,
  },
})
export default class OfficeDetail extends Vue {
  @officesModule.Getter('GET')
  office!: Office;

  @officesModule.Action('FETCH_ONE')
  fetchOffice!: (id: string) => Promise<Office>;

  @regionsModule.Action('FETCH_ALL')
  fetchRegions!: () => void;

  @companiesModule.Action('FETCH_ALL')
  fetchCompanies!: () => void;

  $refs!: Vue['$refs'] & {
    child: SavableComponent;
  };

  get isEditing(): boolean {
    return !!this.$route.params.id;
  }

  get navTabs(): NavTabItem[] {
    return this.isEditing
      ? [
        {
          label: 'General',
          routeName: 'OfficeGeneral',
        },
        {
          label: 'Imports',
          routeName: 'OfficeImports',
        },
      ]
      : [
        {
          label: 'General',
          routeName: 'CreateOffice',
        },
      ];
  }

  save(): void {
    const child = this.$refs.child;
    if (child instanceof SavableComponent) {
      child.save();
    }
  }

  created(): void {
    this.fetchRegions();
    this.fetchCompanies();
    if (this.$route.params.id) {
      this.fetchOffice(this.$route.params.id);
    }
  }
}
