/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment-timezone';
import gt from 'lodash.gt';
import eq from 'lodash.eq';
import gte from 'lodash.gte';
import lt from 'lodash.lt';
import lte from 'lodash.lte';
import get from 'lodash.get';
import set from 'lodash.set';

import { Types } from 'mongoose';
import numeral from 'numeral';

import { ITransformation } from './transformation.interface';
import { ITransformationOperator } from './transformation-operator.enum';
import { ConditionOperator } from './condition-operator.enum';
import { ITransformationCondition } from './transformation-condition.interface';
import { ITransformationCombinator } from './transformation-combinator.enum';

export class Transformations {
  static handleTransformation(
    originalInput: any,
    transformation: ITransformation,
    output?: any,
  ) {
    if (!transformation.conditions) {
      return;
    }
    for (const condition of transformation.conditions) {
      if (!this.isConditionValid(originalInput, condition)) {
        return output || originalInput;
      }
    }
    if (!output) {
      output = originalInput;
    }

    if (!transformation.operations) {
      return;
    }
    for (const operation of transformation.operations) {
      const transformedValue = this.processOperation(
        originalInput,
        operation.transformationFields,
        operation.operator,
        operation.combinator,
        operation.parameter,
      );

      output = this.setField(output, operation.outputField, transformedValue);
    }
    return output;
  }

  public static isConditionValid(
    object: any,
    condition: ITransformationCondition,
  ) {
    let val = this.getField(object, condition.field);
    let param = condition.parameters;

    // remove casing if string
    if (typeof val === 'string') {
      val = val.toLowerCase();
    }

    if (val instanceof Types.ObjectId) {
      val = val.toString();
    }

    // remove casing if string
    if (typeof param === 'string') {
      param = param.toLowerCase();
    }

    if (param instanceof Array) {
      param = [];
      for (const p of condition.parameters) {
        param.push(p.toLowerCase());
      }
    }

    switch (condition.operator) {
    case ConditionOperator.Exists:
      return Boolean(val);
    case ConditionOperator.Equal:
      return eq(val, param);
    case ConditionOperator.NotEqual:
      return !eq(val, param);
    case ConditionOperator.In:
      return param.includes(val);
    case ConditionOperator.NotIn:
      return !param.includes(val);
    case ConditionOperator.Contains:
      return val.includes(param);
    case ConditionOperator.NotContains:
      return !val.includes(param);
    case ConditionOperator.GreaterThan:
      return !gt(val, param);
    case ConditionOperator.GreaterOrEqual:
      return !gte(val, param);
    case ConditionOperator.LowerThan:
      return !lt(val, param);
    case ConditionOperator.LowerOrEqual:
      return !lte(val, param);
    default:
      return false;
    }
  }

  static getFields(object: any, fields: string[]) {
    const ret = [];
    for (const field of fields) {
      ret.push(this.getField(object, field));
    }
    return ret;
  }

  static getField(object: any, field: string) {
    let val = get(object, field);
    if (!val && field.startsWith('shipmentMilestones.')) {
      const subFields: string[] = field.split('.');
      if (subFields.length < 2) {
        return;
      }
      const shipmentMilestone = object.shipmentMilestones.get(subFields[1]);
      if (subFields.length < 3) {
        return shipmentMilestone;
      }
      val = get(shipmentMilestone, subFields[2]);
    }
    return val;
  }

  static setField(object: any, field: string, value: any) {
    if (
      field.startsWith('shipmentMilestones.') &&
      object.shipmentMilestones instanceof Map
    ) {
      const fields = field.split('.');
      const shipmentMilestone = object.shipmentMilestones.get(fields[1]);

      object.shipmentMilestones.set(
        fields[1],
        set(shipmentMilestone, fields[2], value),
      );
      return object;
    }
    return set(object, field, value);
  }

  private static processOperation(
    object: any,
    fields: string[],
    operator: ITransformationOperator,
    combinator: ITransformationCombinator = ITransformationCombinator.firstNotEmpty,
    parameter?: any,
  ) {
    const values = this.getFields(object, fields);
    if (!values || !values.length) {
      return;
    }

    const val = this[combinator](values);

    if (!val) {
      return null;
    }
    return this[operator](val, parameter);
  }

  private static FIRST_NOT_EMPTY(values: string[]): string | null {
    for (const val of values) {
      if (val) {
        return val;
      }
    }
    return null;
  }

  private static CONCATENATE(values: string[]): string {
    return values.join('');
  }

  private static ADD_TIME(
    value: string,
    parameter: number,
    unit: moment.unitOfTime.DurationConstructor,
  ): Date {
    return moment(value).add(parameter, unit).toDate();
  }

  private static ADD_DAYS(value?: string, parameter?: number): Date | null {
    if (!value || !parameter) {
      return null;
    }
    return this.ADD_TIME(value, parameter, 'd');
  }

  private static ADD_HOURS(value?: string, parameter?: number): Date | null {
    if (!value || !parameter) {
      return null;
    }
    return this.ADD_TIME(value, parameter, 'h');
  }

  private static ADD_MINUTES(value?: string, parameter?: number): Date | null {
    if (!value || !parameter) {
      return null;
    }
    return this.ADD_TIME(value, parameter, 'm');
  }

  private static SET_VALUE(value?: string, parameter?: any): string {
    return parameter;
  }

  private static translateMonthsShort(dateString: string): string {
    return dateString
      ? dateString
        .replace('mei', 'May')
        .replace('okt', 'Oct')
        .replace('maart', 'Mar')
        .replace('mrt', 'Mar')
      : dateString;
  }

  private static VALID_DATE_OR_NULL(
    value?: string,
    parameter?: string,
  ): Date | null {
    if (!value) {
      return null;
    }
    value = this.translateMonthsShort(value);
    const momentDate: moment.Moment = moment(value, parameter);
    return momentDate.isValid() ? momentDate.toDate() : null;
  }

  private static NUMBER_VALUE_OR_NULL(
    value?: string,
    parameter?: any,
  ): number | null {
    if (value && typeof value === 'string' && value.includes(',')) {
      value = value.replace('.', '');
      value = value.replace(',', '.');
    }
    return value ? numeral(value).value() : null;
  }

  private static STRING_OR_NULL(
    value?: string,
    parameter?: any,
  ): string | null {
    return value || null;
  }
  private static STRING_OR_EMPTY(value?: string, parameter?: any): string {
    return value || ' ';
  }
}
