import { AxiosRequestConfig } from 'axios';
import { isBlank } from '@/utils/is-blank.util';

import axios from '@/axios';
import { PaginatedResponse } from '../paginated-response';
import { FetchAllParams } from '../fetch-all-params.interface';
import { Customer } from './customer-interface';
import { FilterParams } from '@/types/filter-params.interface';

export default {
  get endpoint(): string {
    return (localStorage.getItem('local_base_url') as string) + '/customers';
  },
  async fetch(params?: FetchAllParams): Promise<PaginatedResponse<Customer>> {
    const config: AxiosRequestConfig = {};
    if (params) {
      const { filters, ...rest } = params;
      const filterParams: FilterParams = {};
      if (filters) {
        for (const [field, filter] of Object.entries(filters)) {
          if (!isBlank(filter.value)) {
            if (Array.isArray(filter.value)) {
              filterParams[field] = filter.value.map(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                (valueItem: any) => `${filter.operation}${valueItem}`,
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              filterParams[field] = `${filter.operation}${filter.value}`;
            }
          }
        }
      }
      config.params = {
        ...rest,
        ...filterParams,
      };
    }
    const response = await axios.get<PaginatedResponse<Customer>>(
      this.endpoint,
      config,
    );
    return response.data;
  },

  async fetchAll(params?: FetchAllParams): Promise<Customer[]> {
    const config: AxiosRequestConfig = {};
    if (params) {
      const { filters, ...rest } = params;
      const filterParams: FilterParams = {};
      if (filters) {
        for (const [field, filter] of Object.entries(filters)) {
          if (!isBlank(filter.value)) {
            if (Array.isArray(filter.value)) {
              filterParams[field] = filter.value.map(
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                (valueItem: any) => `${filter.operation}${valueItem}`,
              );
            } else {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              filterParams[field] = `${filter.operation}${filter.value}`;
            }
          }
        }
      }
      config.params = {
        ...rest,
        ...filterParams,
      };
    }
    const response = await axios.get<Customer[]>(
      `${this.endpoint}/all-customers`,
      config,
    );
    return response.data;
  },

  async fetchOne(id: string): Promise<Customer> {
    const response = await axios.get<Customer>(`${this.endpoint}/${id}`);
    return response.data;
  },

  async create(customer: Customer): Promise<Customer> {
    const response = await axios.post<Customer>(this.endpoint, customer);
    return response.data;
  },

  async update(id: string, customer: Customer): Promise<Customer> {
    const response = await axios
      .put<Customer>(`${this.endpoint}/${id}`, customer)
      .catch(error => {
        throw error;
      });
    return response.data;
  },

  async delete(id: string): Promise<Customer> {
    const response = await axios.delete(`${this.endpoint}/${id}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  },
};
