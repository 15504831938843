





















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Toaster } from '../store/modules/toasters/types';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { Office } from '@/services/api/offices/office.class';

const milestoneTypesModule = namespace('milestoneTypes');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');

@Component({
  components: {},
})
export default class MilestoneTypeDetail extends Vue {
  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @milestoneTypesModule.Getter('GET')
  milestoneType!: MilestoneType;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @milestoneTypesModule.Action('CREATE')
  createMilestoneType!: (
    milestoneType: MilestoneType,
  ) => Promise<MilestoneType>;

  @milestoneTypesModule.Action('UPDATE')
  updateMilestoneType!: (payload: {
    id: string;
    milestoneType: MilestoneType;
  }) => Promise<void>;

  @milestoneTypesModule.Action('FETCH_ONE')
  fetchMilestoneType!: (id: string) => Promise<void>;

  form: MilestoneType = new MilestoneType();

  @Watch('shipmentType', { deep: true })
  onTypeChange(): void {
    this.form = this.milestoneType;
  }
  async onSubmit(): Promise<void> {
    if (this.form._id) {
      await this.updateMilestoneType({
        id: this.form._id,
        milestoneType: this.form,
      });
    } else {
      const milestoneType = await this.createMilestoneType(this.form);
      if (milestoneType._id) {
        this.$router.replace({
          name: 'MilestoneType',
          params: { id: milestoneType._id },
        });
      }
    }
  }

  async created(): Promise<void> {
    if (this.$route.params.id) {
      await this.fetchMilestoneType(this.$route.params.id);
      this.form = { ...this.milestoneType };
    }
    this.form.office = this.office._id;
  }
}
