
















import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import appToaster from '@/components/Toaster.vue';
import { namespace } from 'vuex-class';

const toastersModule = namespace('toasters');

@Component({
  components: {
    appToaster,
  },
})
export default class Toasters extends Vue {
  @toastersModule.Getter('ALL')
  toasters!: Toasters[];

  @toastersModule.Action('CLEAR_ALL')
  removeToasters!: () => void;

  destroyed(): void {
    this.removeToasters();
  }
}
