import { StickyColumnType } from '@/services/api/shipment-types/sticky-column-type.enum';
import { ISequence } from '@/types/sequence.interface';
import { UIField } from '@/types/ui/ui-field.class';
import { UIFilter } from '@/types/ui/ui-filter.interface';

export class Ui implements ISequence {
  _id?: string;
  baseFilter: UIFilter[] = [];
  name = '';
  icon = 'globe';
  path = '';
  sequence = 0;
  milestones: string[] = [];
  shipmentType: string | null = '';
  archived: boolean | null = false;
  stickyColumnType: StickyColumnType = StickyColumnType.Ocean;
  forDisplay = false;
  stickyFields: UIField[] = [];
  scrollFields: UIField[] = [];
  office = '';
  milestoneType: string | undefined = '';
}
