




































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone';
import flatPickr from 'vue-flatpickr-component';
import flatpickrTypes from 'flatpickr';
import { getFlatPickrConfig } from '../utils/flatpickr';

import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { ShipmentRequest } from '../services/api/shipments/shipment-request.interface';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { DeepPartial } from '../types/deep-partial.type';
import { DateFormat } from '../services/api/users/date-format.enum';

import { ShipmentForm } from '../types/shipment-form.class';
import { PartialShipmentForm } from '../types/partial-shipment-form.class';

import appShipmentCheckbox from '../components/ShipmentCheckbox.vue';
import appShipmentMilestone from '../components/ShipmentMilestone.vue';
import appShipmentTypeSelect from '../components/ShipmentTypeSelect.vue';
import { Office } from '../services/api/offices/office.class';
import { ShipmentMilestoneDetailInput } from '../types/shipments/shipment-milestone-detail-input.interface';
import { CustomerMilestoneResponse } from '../services/api/customers/customer-milestone-response.interface';
import { ShipmentTypeResponse } from '../services/api/shipment-types/shipment-type-response.interface';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { SavableComponent } from '../types/savable-component.class';
import ShipmentMilestonePlaceholder from '../components/ShipmentMilestonePlaceholder.vue';
import { maerskReasonCodes, asmlReasonCodes } from '../utils/reasonCodes';
import { ReasonCode } from '@/types/reason-code.interface';

const usersModule = namespace('users');
const shipmentsModule = namespace('shipments');
const shipmentTypesModule = namespace('shipmentTypes');

@Component({
  components: {
    appShipmentCheckbox,
    appShipmentMilestone,
    appShipmentTypeSelect,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
    ShipmentMilestonePlaceholder,
  },
})
export default class ShipmentDetail extends SavableComponent {
  @Prop({
    type: Object,
  })
  shipment!: ShipmentResponse;

  // these are from the UI fields, NOT from milestones DB collection!
  @Prop({
    type: Array,
    required: true,
  })
  milestones!: MilestoneResponse[];

  @Prop({
    type: Number,
  })
  updateCounter!: number;

  @usersModule.Getter('GET_TIMEZONE')
  userTimezone!: string;

  @usersModule.Getter('GET_DATE_FORMAT')
  userDateFormat!: DateFormat;

  @shipmentsModule.Getter('IS_LOADING')
  isLoadingDetail!: (key: string) => boolean;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentTypeResponse[];

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchShipmentTypes!: (params?: FetchAllParams) => Promise<void>;

  @shipmentsModule.Action('FETCH_ONE')
  fetchShipment!: (shipmentId: string) => Promise<ShipmentResponse>;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  form: ShipmentForm = this.createForm(this.milestones);

  flatPickrConfig = getFlatPickrConfig();

  $refs!: Vue['$refs'] & {
    flatPickr: flatpickrTypes.Instance;
  };

  @Watch('updateCounter', { immediate: true }) async onUpdateChange(
    updateCounter: number,
  ): Promise<void> {
    if (!updateCounter || updateCounter < 1) {
      return;
    }
    await this.fetchShipment(this.shipment._id);
  }

  @Watch('userDateFormat', {
    immediate: true,
  })
  onUserDateFormatChange(dateFormat?: DateFormat): void {
    let flatPickrConfigAltDateFormat: string;

    switch (dateFormat) {
    case DateFormat.YY_MM_DD:
      flatPickrConfigAltDateFormat = 'y/m/d';
      break;

    case DateFormat.MM_DD_YY:
      flatPickrConfigAltDateFormat = 'm/d/y';
      break;

    case DateFormat.DD_MM_YY:
    default:
      flatPickrConfigAltDateFormat = 'd/m/y';
      break;
    }

    if (!this.flatPickrConfig) {
      this.flatPickrConfig = getFlatPickrConfig();
    }
    this.flatPickrConfig.altFormat = `${flatPickrConfigAltDateFormat} H:i`;
  }

  @Emit('partial-update')
  partialUpdate(
    shipmentRequest: DeepPartial<ShipmentRequest>,
  ): DeepPartial<ShipmentRequest> {
    return shipmentRequest;
  }

  @Watch('shipment', {
    immediate: true,
  })
  onShipmentChanged(shipment: ShipmentResponse): void {
    this.form = this.createForm(shipment);
  }

  @Emit()
  shipmentMilestoneClicked(input: {
    milestoneId: string;
    milestoneCode: string;
  }): ShipmentMilestoneDetailInput {
    const sendNotification = !!(
      this.shipment.customer &&
      this.shipment.customer.customerMilestones &&
      this.shipment.customer.customerMilestones.find(
        (cm: CustomerMilestoneResponse) => {
          if (typeof cm.milestone !== 'string' && '_id' in cm.milestone) {
            return cm.milestone._id === input.milestoneId;
          }

          return cm.milestone === input.milestoneId;
        },
      )
    );

    return {
      shipmentId: this.shipment._id,
      shipmentRef: this.shipment.ref,
      ...input,
      value: this.shipment.shipmentMilestones[input.milestoneId].value,
      priority: this.shipment.shipmentMilestones[input.milestoneId].priority,
      status: this.shipment.shipmentMilestones[input.milestoneId].status,
      sendNotification,
    };
  }

  isLoading(milestoneId: string): boolean {
    const key = `${this.shipment._id}.${milestoneId}`;
    return this.isLoadingDetail(key);
  }

  get latestMilestone(): MilestoneResponse | null {
    const reverseMilestones: MilestoneResponse[] = [
      ...this.milestones,
    ].reverse();
    for (const milestone of reverseMilestones) {
      if (!this.shipment.shipmentMilestones[milestone._id]) {
        const index = reverseMilestones.findIndex(
          (x: MilestoneResponse) => x._id === milestone._id,
        );
        reverseMilestones.splice(index, 1);
      }
    }
    const latestMilestone:
    | undefined
    | MilestoneResponse = reverseMilestones.find(
      (milestone: MilestoneResponse) =>
        Boolean(this.shipment.shipmentMilestones[milestone._id].value),
    );

    return latestMilestone || null;
  }

  get maerskReasonCodes(): ReasonCode[] {
    return maerskReasonCodes;
  }

  get asmlReasonCodes(): ReasonCode[] {
    return asmlReasonCodes;
  }

  get shipmentType(): string | undefined {
    const shipmentType = this.shipmentTypes.find(
      type => type._id === this.shipment.type,
    );
    return shipmentType && shipmentType.name;
  }

  mapToTimezoneAndRemoveTimezone(
    timezone: string,
    date?: string,
    keepLocalTime = false,
  ): string {
    if (!date) {
      return '';
    }

    if (keepLocalTime) {
      return moment(date).tz(timezone, true).format('YYYY-MM-DDTHH:mm:ss');
    }

    return moment.tz(date, timezone).format('YYYY-MM-DDTHH:mm:ss');
  }

  mapToTimezone(
    timezone: string,
    date?: string,
    keepLocalTime = false,
  ): string {
    if (!date) {
      return '';
    }

    if (keepLocalTime) {
      return moment(date).tz(timezone, true).toISOString();
    }

    return moment.tz(date, timezone).toISOString();
  }

  formatDateAccordingToTimezone(
    date: string,
    timezone: string,
    format: string,
  ): string {
    return moment.tz(date, timezone).format(`${format} HH:mm`);
  }

  onChangePartialShipmentDate(
    date: Date[],
    timezone: string,
    index: number,
  ): void {
    const _date: string | null = date[0] ? date[0].toISOString() : null;

    if (_date) {
      this.form.partialShipments[index].date = this.mapToTimezone(
        timezone,
        _date,
        true,
      );
    } else {
      this.form.partialShipments[index].date = '';
    }
  }

  getFormattedDate(value?: string): string {
    if (!value) {
      return '';
    }

    return moment
      .tz(value, this.userTimezone)
      .format(`${this.userDateFormat} HH:mm`);
  }

  toggleFlatPickr(pickr: flatpickrTypes.Instance): void {
    pickr.toggle();
  }

  get totalPartialShipmentAmount(): number {
    const partialShipments: PartialShipmentForm[] = this.form.partialShipments;

    if (partialShipments.length) {
      const partialShipmentAmounts: number[] = partialShipments.map(
        (partialShipmentForm: PartialShipmentForm) =>
          partialShipmentForm.amount,
      );

      return partialShipmentAmounts.reduce(
        (total: number, currentValue: number) => total + currentValue,
      );
    }

    return 0;
  }

  createForm(
    shipmentOrMilestones: ShipmentResponse | MilestoneResponse[],
  ): ShipmentForm {
    if (Array.isArray(shipmentOrMilestones)) {
      const milestones: MilestoneResponse[] = shipmentOrMilestones;

      return new ShipmentForm(milestones);
    }

    const shipment: ShipmentResponse = shipmentOrMilestones;
    return new ShipmentForm(shipment);
  }

  toggleCmr(checked: boolean): void {
    this.form.cmr = checked;
  }

  toggleArchived(archived: boolean): void {
    for (const key of Object.keys(this.form.archived)) {
      this.form.archived[key] = archived;
    }
  }

  toggleDraft(draft: boolean): void {
    this.form.custom.isDraft = draft;
  }

  toggleCompleted(completed: boolean): void {
    this.form.completed = completed;
  }

  manualEditConfirm(externalEDIReference: string): void {
    const isChanged = !!(
      this.shipment.custom.externalEDIReference &&
      externalEDIReference !== this.shipment.custom.externalEDIReference
    );

    this.form.custom.wasManuallyUpdated = isChanged;
  }

  removePartialShipment(index: number): void {
    this.form.partialShipments.splice(index, 1);
  }

  addPartialShipment(): void {
    this.form.partialShipments.push(new PartialShipmentForm());
  }

  getWeekDay(weekday: number): string | undefined {
    switch (weekday) {
    case 1:
      return 'Monday';
      break;
    case 2:
      return 'Tuesday';
      break;
    case 3:
      return 'Wednesday';
      break;
    case 4:
      return 'Thursday';
      break;
    case 5:
      return 'Friday';
      break;
    case 6:
      return 'Saturday';
      break;
    case 7:
      return 'Sunday';
      break;
    }
  }

  save(): void {
    this.partialUpdate(this.mapToPartialShipmentRequest(this.form));
  }

  async created(): Promise<void> {
    if (!this.shipmentTypes.length) {
      await this.fetchShipmentTypes();
    }
    if (!this.flatPickrConfig) {
      this.flatPickrConfig = getFlatPickrConfig();
    }
  }

  private mapToPartialShipmentRequest(
    shipmentForm: ShipmentForm,
  ): DeepPartial<ShipmentRequest> {
    const ret = {
      ref: shipmentForm.ref,
      mawb: shipmentForm.mawb,
      hawb: shipmentForm.hawb,
      flight: {
        origin: shipmentForm.flight.origin,
        originCountry: shipmentForm.flight.originCountry,
        destination: shipmentForm.flight.destination,
        destinationCountry: shipmentForm.flight.destinationCountry,
        destinationRegion: shipmentForm.flight.destinationRegion,
        name: shipmentForm.flight.name,
      },
      partialShipments: shipmentForm.partialShipments,
      remarks: shipmentForm.remarks,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      delayReasons: shipmentForm.delayReasons,
      priorDocument: shipmentForm.priorDocument,
      pieces: shipmentForm.pieces,
      grossWeight: shipmentForm.grossWeight,
      chargeableWeight: shipmentForm.chargeableWeight,
      volume: shipmentForm.volume,
      freightTerm: shipmentForm.freightTerm,
      cmr: shipmentForm.cmr,
      custom: {
        wareHouseInstruction: shipmentForm.custom.wareHouseInstruction,
        shipper: shipmentForm.custom.shipper,
        consignee: shipmentForm.custom.consignee,
        customer: shipmentForm.custom.customer,
        truckerDel: shipmentForm.custom.truckerDel,
        truckerPU: shipmentForm.custom.truckerPU,
        terminal: shipmentForm.custom.terminal,
        time: shipmentForm.custom.time,
        isDraft: shipmentForm.custom.isDraft,
        customRef: shipmentForm.custom.customRef,
        ticketNumber: shipmentForm.custom.ticketNumber,
        serialNumber: shipmentForm.custom.serialNumber,
        loadingRef: shipmentForm.custom.loadingRef,
        loadingLocation: shipmentForm.custom.loadingLocation,
        pic: shipmentForm.custom.pic,
        sellingProduct: shipmentForm.custom.sellingProduct,
        externalEDIReference: shipmentForm.custom.externalEDIReference,
        wasManuallyUpdated: shipmentForm.custom.wasManuallyUpdated,
      },
      service: {
        type: shipmentForm.service.type,
        level: shipmentForm.service.level,
      },
      archived: shipmentForm.archived,
      sortedPallets: shipmentForm.sortedPallets,
      completed: shipmentForm.completed,
      customField1: shipmentForm.customField1,
      customField2: shipmentForm.customField2,
      customField3: shipmentForm.customField3,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      customerServiceReference1: shipmentForm.customerServiceReference1,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      customerServiceReference2: shipmentForm.customerServiceReference2,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      customerServiceReference4: shipmentForm.customerServiceReference4,
    };

    return ret;
  }
}
