










































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Provide } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../../components/FieldSelect.vue';
import MilestoneSelect from '../../components/MilestoneSelect.vue';
import CustomerSelect from '../../components/CustomerExcludingSelect.vue';
import FieldsDaySelect from '../../components/FieldsDaySelect.vue';
import ReportScheduleList from '../../components/ReportScheduleList.vue';
import LinkedReportList from '../../components/LinkedReportList.vue';
import ReportScheduleForm from '../../components/ReportScheduleForm.vue';
import LinkReportForm from '../../components/LinkReportForm.vue';
import ShipmentTypeSelect from '../../components/ShipmentTypeSelect.vue';
import flatPickr from 'vue-flatpickr-component';
import flatpickrTypes from 'flatpickr';
import { Office } from '../../services/api/offices/office.class';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { VerifiedEmail } from '../../services/api/verified-emails/verified-email.class';
import LogTable from '../../components/LogTable.vue';
import MailForm from '../../components/MailForm.vue';
import { FetchAllParams } from '../../services/api/fetch-all-params.interface';
import { FilterOperation } from '../../types/filter-operation.enum';
import { BModal } from 'bootstrap-vue';
import { ReportCondition } from '../../services/api/reports/report-condition.class';
import { ReportField } from '../../services/api/reports/report-field.class';
import { CustomerResponse } from '../../services/api/customers/customer-response.interface';
import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import { Report as ReportClass } from '../../services/api/reports/report.class';
import { MailDetails } from '../../services/api/reports/mail-details.class';
import { FilterParams } from '@/types/filter-params.interface';
import { MilestoneType } from '@/services/api/milestone-types/milestone-type.class';
import {
  ActiveReportType,
  ReportTypeTranslations,
} from '../../types/reports/report-type.enum';
import { ReportSchedule } from '@/services/api/reports/report-schedule.class';
import ReportLogs from '../../components/ReportLogs.vue';
import { TestIds } from '@/types/test-ids.enum';
import formatDate from './helpers/formatDate';
import checkIsFromLessThanTo from './helpers/checkIsFromLessThanTo';
import getDateRangeCondition from './helpers/getDateRangeCondition';
import checkSelectedRangeValues from './helpers/checkSelectedRangeValues';
import { dateRangeFieldType } from './helpers/types';
import availableConditions from './helpers/availableConditions';
import checkSimilarConditionTypes from './helpers/checkSimilarConditionTypes';
import { PaginatedResponse } from '@/services/api/paginated-response';
import ReportSelect from '../../components/ReportSelect.vue';
import { LinkedReportOption } from '@/services/api/reports/linked-report-option.interface';
import { get } from 'lodash';

const reportsModule = namespace('reports');
const usersModule = namespace('users');
const customersModule = namespace('customers');
const verifiedEmailsModule = namespace('verifiedEmails');
const milestoneTypesModule = namespace('milestoneTypes');
const reportSchedulesModule = namespace('reportSchedules');
const shipmentTypesModule = namespace('shipmentTypes');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
    FieldSelect,
    MilestoneSelect,
    CustomerSelect,
    LogTable,
    FieldsDaySelect,
    MailForm,
    ReportScheduleList,
    LinkedReportList,
    ReportSelect,
    ReportScheduleForm,
    LinkReportForm,
    ReportLogs,
    ShipmentTypeSelect,
  },
})
export default class Report extends Vue {
  @Provide('validator') $validatorRef = this.$validator;
  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @customersModule.Action('FETCH_ALL')
  fetchCustomers!: () => Promise<CustomerResponse[]>;

  @reportsModule.Getter('GET')
  report!: ReportClass;

  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @reportsModule.Action('CREATE')
  createReport!: (report: ReportClass) => Promise<ReportClass>;

  @reportsModule.Action('UPDATE')
  updateReport!: (payload: {
    id: string;
    report: ReportClass;
  }) => Promise<ReportClass>;

  @reportsModule.Action('FETCH_ONE')
  fetchReport!: (id: string) => Promise<ReportClass>;

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchAllMilestoneTypes!: (filter?: FilterParams) => Promise<void>;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentTypeResponse[];

  @verifiedEmailsModule.Action('FETCH_ALL')
  fetchVerifiedEmails!: (filter?: FilterParams) => Promise<VerifiedEmail[]>;

  @reportSchedulesModule.Getter('ALL_PAGINATED')
  reportSchedules!: PaginatedResponse<ReportSchedule>;

  @reportSchedulesModule.Getter('API_PARAMS')
  apiParams!: FetchAllParams;

  @reportSchedulesModule.Action('FETCH_ALL')
  fetchReportSchedules!: (params?: FetchAllParams) => Promise<ReportSchedule[]>;

  @reportSchedulesModule.Action('UPDATE')
  updateReportSchedule!: (payload: {
    id: string;
    reportSchedule: ReportSchedule;
  }) => Promise<ReportSchedule>;

  @reportSchedulesModule.Action('ADD_LINKED_REPORT')
  addLinkedReport!: (payload: {
    reportId: string;
    linkedReportId: string;
  }) => Promise<void>;

  @reportSchedulesModule.Action('REMOVE_LINKED_REPORT')
  removeLinkedReport!: (payload: {
    reportId: string;
    linkedReportId: string;
  }) => Promise<void>;

  @reportSchedulesModule.Action('CREATE')
  createReportSchedule!: (
    reportSchedule: ReportSchedule,
  ) => Promise<ReportSchedule>;

  @reportSchedulesModule.Action('DELETE')
  deleteReportSchedule!: (id: string) => Promise<ReportSchedule>;

  get reportTypes(): Option[] {
    return Object.values(ActiveReportType).map(value => ({
      value,
      text: get(ReportTypeTranslations, value),
    }));
  }

  $refs!: Vue['$refs'] & {
    fieldEditModal: BModal;
    mailForm: HTMLElement;
    reportScheduleForm: ReportScheduleForm;
    linkReportForm: LinkReportForm;
  };

  flatPickrConfig: flatpickrTypes.Options.Options = {
    enableTime: true,
    // eslint-disable-next-line camelcase
    time_24hr: false,
    altInput: true,
    noCalendar: true,
    dateFormat: 'Z',
    defaultHour: 12,
    minuteIncrement: 10,
  };
  sortButtonList: boolean[] = [];
  logFilter?: FetchAllParams = {};
  timeLabel = 'Time:';
  timeDiff = '';
  newField: ReportField = new ReportField();
  sortOrder = 'Asc';
  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;
  emailToIndex = 0;
  showLogs = false;
  emailValid = true;
  dayOptions = [
    { text: 'Mon', value: 1 },
    { text: 'Tue', value: 2 },
    { text: 'Wed', value: 3 },
    { text: 'Thu', value: 4 },
    { text: 'Fri', value: 5 },
    { text: 'Sat', value: 6 },
    { text: 'Sun', value: 0 },
  ];
  archivedOptions = [
    { label: 'All' },
    { label: 'Active' },
    { label: 'Archived' },
  ];
  operatorOptions = [
    { text: 'Equals', value: 'EQUALS' },
    { text: 'Between', value: 'BETWEEN' },
  ];
  possibleConditions = availableConditions;

  TestIds: { [key: string]: number | string } = TestIds;

  form: ReportClass = new ReportClass();
  selectedReportSchedule: ReportSchedule = new ReportSchedule();
  selectedLinkedReport: ReportClass = new ReportClass();

  editFieldObject: ReportField = new ReportField();
  editFieldIndex = -1;

  showBothDatesErrorMessage = false;
  showFromLessThanToErrorMessage = false;
  isDateRangeValid = true;

  @Watch('report', { deep: true })
  onTypeChange(): void {
    this.form = this.report;
    if (!this.form.mail) {
      this.form.mail = new MailDetails();
    }
  }

  @Watch('form.mail.to', { deep: true })
  onFormChange() {
    try {
      if (this.form.mail.to.length > 0) {
        this.emailValid = true;
      } else {
        this.emailValid = false;
      }
    } catch (e) {
      this.emailValid = false;
    }
  }

  @Watch('$route.params', { immediate: true })
  async onValueChange(): Promise<void> {
    const reportId = this.$route.params.id;

    if (!reportId) return;

    this.apiParams.filters = {
      report: {
        value: this.$route.params.id,
        operation: FilterOperation.Equals,
      },
    };
    await this.fetchReportSchedules(this.apiParams);
  }

  buttonsDisabled(): boolean {
    try {
      const hasName = Boolean(this.form.name);
      const hasSheetName = Boolean(this.form.sheetName);
      const hasMails = this.form.mail.to.length > 0;

      return !(hasName && hasSheetName && hasMails);
    } catch (e) {
      return false;
    }
  }

  linkedReports(): Array<{}> {
    if (!this.report || !this.report.linkedReports) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const listValues: LinkedReportOption[] = this.report.linkedReports.map(
      (report: ReportClass) => {
        const officeName =
          report.office && report.office ? (report.office as Office).name : '';

        return {
          office: officeName,
          report: report.name,
          _id: report._id,
        } as LinkedReportOption;
      },
    );

    return listValues;
  }

  addEmail(target: string, input: string): void {
    switch (target) {
      case `input-to-${0}`:
        this.form.mail.to.push(input);
        break;
      case `input-cc-${0}`:
        if (!input.includes('@')) {
          return;
        }
        this.form.mail.cc.push(input);
        break;
      case `input-bcc-${0}`:
        if (!input.includes('@')) {
          return;
        }
        this.form.mail.bcc.push(input);
        break;
    }
  }

  handleRemoveMail(field: string, index: number): void {
    this.removeEmail(field, index);
  }

  removeEmail(field: string, index: number): void {
    switch (field) {
      case 'to':
        this.form.mail.to.splice(index, 1);
        break;
      case 'cc':
        this.form.mail.cc.splice(index, 1);
        break;
      case 'bcc':
        this.form.mail.bcc.splice(index, 1);
        break;
      default:
        break;
    }
  }

  updateMailFormValues(mailFormInfo: MailDetails): void {
    this.form.mail = mailFormInfo;
  }

  generateSortButtonList(): void {
    const sortList = [];
    for (const field of this.form.fields) {
      if (!this.form.sortParam) {
        sortList.push(false);
        continue;
      }
      if (this.form.sortParam.sortField === field.field) {
        sortList.push(true);
      } else {
        sortList.push(false);
      }
    }
    this.sortButtonList = sortList;
    if (this.form.sortParam) {
      this.sortOrder = this.form.sortParam.sortOrder;
    }
  }

  toggleSorting(index: number): void {
    if (
      !this.form.sortParam ||
      this.form.sortParam.sortField !== this.form.fields[index].field
    ) {
      this.form.sortParam = {
        sortField: this.form.fields[index].field,
        sortOrder: 'Asc',
      };
    } else {
      if (this.form.sortParam && this.form.sortParam.sortOrder === 'Asc') {
        this.form.sortParam = {
          sortField: this.form.fields[index].field,
          sortOrder: 'Desc',
        };
        this.sortOrder = 'Desc';
      } else {
        delete this.form.sortParam;
      }
    }
    this.generateSortButtonList();
  }

  getLabel(condition: ReportCondition): string {
    const cond: ReportCondition | undefined = this.possibleConditions.find(
      (x: ReportCondition) => x.conditionType === condition.conditionType,
    );

    const label = cond ? cond.label : '';

    return label;
  }

  addCustomerToCondition(
    condition: ReportCondition,
    cust: CustomerResponse,
  ): void {
    if (condition.data.customers) {
      condition.data.customers.push(cust);
    }
  }

  addAllShipmentTypes = (
    condition: ReportCondition,
    shipmentTypes: ShipmentTypeResponse[],
  ): void => {
    condition.data.shipmentTypes = shipmentTypes.filter(
      shipmentType => shipmentType.name !== 'ALL',
    );
  };

  addShipmentTypeToCondition(
    condition: ReportCondition,
    selectedShipmentType: ShipmentTypeResponse,
  ): void {
    if (condition.data.shipmentTypes) {
      const { shipmentTypes } = condition.data;
      const availableShipmentTypes = this.shipmentTypes;
      const isAllSelected = selectedShipmentType.name === 'ALL';

      !isAllSelected && shipmentTypes.push(selectedShipmentType);

      isAllSelected &&
        this.addAllShipmentTypes(condition, availableShipmentTypes);
    }
  }

  removeShipmentTypeFromCondition(
    shipmentType: ShipmentTypeResponse,
    condition: ReportCondition,
  ): void {
    if (condition.data.shipmentTypes) {
      condition.data.shipmentTypes = [
        ...condition.data.shipmentTypes.filter(
          filteredShipmentType => filteredShipmentType._id !== shipmentType._id,
        ),
      ];
    }
  }

  removeCustomerFromCondition(
    cust: CustomerResponse,
    condition: ReportCondition,
  ): void {
    if (condition.data.customers) {
      condition.data.customers = [
        ...condition.data.customers.filter(
          customer => customer._id !== cust._id,
        ),
      ];
    }
  }

  addCondition(condition: ReportCondition): void {
    if (!this.handleSimilarConditions(condition, this.form.conditions)) {
      this.form.conditions.push({
        conditionType: condition.conditionType,
        data: { ...condition.data },
        label: '',
      });
    }
  }

  handleSimilarConditions(
    condition: ReportCondition,
    conditions: ReportCondition[],
  ): boolean {
    return checkSimilarConditionTypes(condition, conditions);
  }

  removeCondition(index: number): void {
    this.form.conditions.splice(index, 1);
  }

  addField(): void {
    this.form.fields.push({
      name: this.newField.field,
      alias: this.newField.alias
        ? this.newField.alias
        : (this.$t(this.newField.field) as string),
      field: this.newField.field,
    });
    this.newField = new ReportField();
    this.generateSortButtonList();
  }

  moveField(oldIndex: number, offset: number): void {
    const newIndex: number = oldIndex + offset;
    if (newIndex < 0 || newIndex === this.form.fields.length) {
      return;
    }
    const obj1 = this.form.fields[oldIndex];
    const obj2 = this.form.fields[newIndex];
    this.form.fields[oldIndex] = obj2;
    this.form.fields[newIndex] = obj1;
    this.form.fields.push(new ReportField());
    this.form.fields.pop();
    this.generateSortButtonList();
  }

  deleteField(index: number): void {
    this.form.fields.splice(index, 1);
    this.generateSortButtonList();
  }

  editField(index: number): void {
    this.form.fields[index].field = this.editFieldObject.field;
    this.form.fields[index].name = this.editFieldObject.field;
    this.form.fields[index].alias = this.editFieldObject.alias
      ? this.editFieldObject.alias
      : (this.$t(this.editFieldObject.field) as string);
  }

  openFieldEditModal(index: number, field: ReportField): void {
    this.editFieldObject = field ? field : new ReportField();
    this.editFieldIndex = index;
    this.$refs.fieldEditModal.show();
  }
  onCloseFieldEditModal(): void {
    this.editFieldObject = new ReportField();
    this.editFieldIndex = -1;
  }

  async onSubmit(): Promise<boolean> {
    const hasMail = this.form.mail.to.length;
    const dateRange = getDateRangeCondition(this.form.conditions);

    !dateRange && (this.isDateRangeValid = true);
    !hasMail && this.validateChildComponents();
    dateRange && this.validateSelectedDateRange(dateRange);

    await this.$validator.validate().then(async (result: boolean) => {
      const allowSubmit = result && this.emailValid && this.isDateRangeValid;

      if (!allowSubmit) {
        return false;
      }

      const noOffice = !this.form.office;
      const noMilestoneType = !this.form.milestoneType;
      const isMilestoneTypeId = this.milestoneTypes[0]._id;
      const isFormId = this.form._id;
      const allowChangeMilestoneType = noMilestoneType && isMilestoneTypeId;

      noOffice && this.assignOffice();

      allowChangeMilestoneType && this.assignMilestoneId(isMilestoneTypeId);

      isFormId
        ? await this.updateReport({ id: isFormId, report: this.form })
        : await this.replaceRoute();
    });

    return true;
  }

  onReportScheduleEdit(schedule: ReportSchedule): void {
    this.selectedReportSchedule = schedule;
    (this.$refs.reportScheduleForm.$refs.reportScheduleModal as BModal).show();
  }

  openScheduleReportModal(): void {
    this.selectedReportSchedule = { ...new ReportSchedule(), repeatEvery: 1 };
    (this.$refs.reportScheduleForm.$refs.reportScheduleModal as BModal).show();
  }

  openLinkReportsModal(): void {
    this.selectedLinkedReport = new ReportClass();
    (this.$refs.linkReportForm.$refs.linkReportModal as BModal).show();
  }

  async handleAddLinkedReport(linkedReportId: string): Promise<void> {
    if (!this.form._id) {
      const submit = await this.onSubmit();
      if (!submit) {
        return;
      }
    }

    const reportId = this.report._id;
    if (!reportId) {
      return;
    }

    await this.addLinkedReport({
      reportId,
      linkedReportId,
    });

    await this.fetchReportSchedules(this.apiParams);
    await this.fetchReport(this.$route.params.id);
  }

  async handleRemoveLinkedReport(
    linkedReportOption: LinkedReportOption,
  ): Promise<void> {
    if (!this.form._id) {
      const submit = await this.onSubmit();
      if (!submit) {
        return;
      }
    }

    const reportId = this.report._id;
    if (!reportId) {
      return;
    }

    await this.removeLinkedReport({
      reportId,
      linkedReportId: linkedReportOption._id,
    });

    await this.fetchReportSchedules(this.apiParams);
    await this.fetchReport(this.$route.params.id);
  }

  async saveReportSchedule(schedule: ReportSchedule): Promise<void> {
    if (!this.form._id) {
      const submit = await this.onSubmit();
      if (!submit) {
        return;
      }
      schedule.report = this.$route.params.id;
    }
    schedule.office = this.office._id as string;
    if (schedule._id) {
      await this.updateReportSchedule({
        reportSchedule: schedule,
        id: schedule._id,
      });
      await this.fetchReportSchedules(this.apiParams);
      return;
    }
    await this.createReportSchedule(schedule);
    await this.fetchReportSchedules(this.apiParams);
    await this.fetchReport(this.$route.params.id);
  }

  async saveLinkedReport(linkedReportId: string): Promise<void> {
    for (const schedule of this.reportSchedules.docs) {
      schedule.reports = schedule.reports
        ? [...schedule.reports, linkedReportId]
        : [linkedReportId];

      await this.handleAddLinkedReport(linkedReportId);
    }
  }

  async removeReportSchedule(reportSchedule: ReportSchedule): Promise<void> {
    await this.deleteReportSchedule(reportSchedule._id as string);
    await this.fetchReportSchedules(this.apiParams);
  }

  async created(): Promise<void> {
    await this.fetchAllMilestoneTypes();
    await this.fetchVerifiedEmails({ verified: true });
    await this.fetchCustomers();
    this.apiParams.filters = {
      report: {
        value: this.$route.params.id,
        operation: FilterOperation.Equals,
      },
    };
    if (this.$route.params.id) {
      await this.fetchReportSchedules(this.apiParams);
      this.form = await this.fetchReport(this.$route.params.id);
      this.logFilter = {
        sort: ['createdAt,desc'],
        filters: {
          report: {
            value: this.$route.params.id,
            operation: FilterOperation.Equals,
          },
        },
      };
    }
    this.generateSortButtonList();
  }

  formatSelectedDate(date: Date): string {
    return formatDate(date);
  }

  handleRangeValueChange(
    dateString: string,
    condition: ReportCondition,
    field: dateRangeFieldType,
  ): void {
    const eventToDate = new Date(`${dateString}`);

    this.showBothDatesErrorMessage = false;
    this.showFromLessThanToErrorMessage = false;

    condition.data[field] = eventToDate;
  }

  private validateChildComponents(): void {
    this.$validator.validate(`inputTo-${0}`);
    this.emailValid = false;
  }

  private validateSelectedDateRange(dateRange: ReportCondition): void {
    const rangeHasBothDates = checkSelectedRangeValues(dateRange);
    this.showBothDatesErrorMessage = !rangeHasBothDates;

    if (rangeHasBothDates) {
      this.showFromLessThanToErrorMessage = !checkIsFromLessThanTo(dateRange);
    }

    const noErrorMessages = !(
      this.showBothDatesErrorMessage || this.showFromLessThanToErrorMessage
    );

    this.isDateRangeValid = noErrorMessages;
  }

  private assignOffice(): void {
    this.form.office = this.office._id;
  }

  private assignMilestoneId(milestoneTypeId: string): void {
    this.form.milestoneType = milestoneTypeId;
  }

  private async replaceRoute(): Promise<void> {
    const report = await this.createReport(this.form);

    if (report && report._id) {
      await this.$router.replace({
        name: 'Report',
        params: { id: report._id },
      });
    }
  }
}
