import { AbilityBuilder, Ability } from '@casl/ability';

import { Role } from './services/api/users/role.enum';
import { ShipmentMilestoneStatus } from './services/api/shipments/shipment-milestone-status.enum';

export function defineAbilitiesFor(
  id: string,
  role: Role,
  milestoneIds: string[],
  isAdmin?: boolean,
) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { rules, can, cannot } = AbilityBuilder.extract();

  can('read', 'IntegrationLog');

  if (role === Role.Manager) {
    can(['read', 'update'], 'User', {
      _id: id,
    });

    can('read', 'Milestone');
    can('read', 'Customer');
    can('read', 'Shipment');
    can('read', 'Statistics');
  }

  if (role === Role.Synchronizer || role === Role.SynchronizerImporter) {
    if (role === Role.SynchronizerImporter) {
      can('manage', 'ShipmentImport');
    }
    can(['read', 'update'], 'User', {
      _id: id,
    });

    can('read', 'Milestone');
    can('read', 'Customer');

    for (const milestoneId of milestoneIds) {
      can('read', 'Shipment', {
        [`shipmentMilestones.${milestoneId}.status`]: {
          $in: [
            ShipmentMilestoneStatus.Changed,
            ShipmentMilestoneStatus.Conflicted,
          ],
        },
      });
    }

    can(
      'update',
      'Shipment',
      milestoneIds.map(
        (milestoneId: string) => `shipmentMilestones.${milestoneId}.status`,
      ),
    );
  }

  if (role === Role.TeamMember) {
    can(['read', 'update'], 'User', {
      _id: id,
    });

    can('read', 'Milestone');
    can('read', 'Log');
    can('read', 'Customer');
    can(['read', 'update'], 'Shipment');
    can('update', 'Shipment', 'value');
    cannot('update', 'Shipment', ['archived', 'priority', 'completed']);
  }

  if (role === Role.TeamLeader) {
    can(['read', 'update'], 'User', {
      _id: id,
    });
    can('read', 'Log');
    can('manage', 'Report');
    can('manage', 'ShipmentImport');
    can('read', 'Milestone');
    can('manage', 'Customer');
    can(['read', 'create', 'update'], 'Shipment');
    can('update', 'Shipment', ['value', 'priority']);
    cannot('update', 'Shipment', ['archived']);
  }

  if (role === Role.SuperUser || role === Role.CompanyAdmin) {
    can('manage', 'Rule');
    can('manage', 'Report');
    can('manage', 'ShipmentImport');
    can('manage', 'UI');
    can('manage', 'Office');
    can('manage', 'User');
    can('read', 'Milestone');
    can('manage', 'Customer');
    can(['read', 'create', 'update'], 'Shipment');
    can('read', 'Log');
    can('read', 'Statistics');
  }

  if (role === Role.CompanyAdmin) {
    can('manage', 'Milestone');
    can('manage', 'MilestoneType');
    can('manage', 'UI');
    can('manage', 'ShipmentType');
    can('manage', 'ShipmentImport');
    can('read', 'Log');
    can('manage', 'Office');
    can('manage', 'Company');
    can('manage', 'User');
    can('manage', 'VerifiedEmail');
  }

  cannot('update', 'User', ['isAdmin']);

  if (isAdmin) {
    can('manage', 'all');
  }
  return new Ability(rules);
}
