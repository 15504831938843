import { ReportSchedule } from './report-schedule.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';

export default class ReportSchedulesService extends EntitiesService<
  ReportSchedule
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) + '/report-schedules'
    );
  }

  async addLinkedReports(
    reportId: string,
    linkedReportId: string,
  ): Promise<void> {
    await axios.post<ReportSchedule>(`${this.endpoint}/link-report`, {
      reportId,
      linkedReportId,
    });

    return;
  }

  async removeLinkedReports(
    reportId: string,
    linkedReportId: string,
  ): Promise<void> {
    await axios.post<ReportSchedule>(`${this.endpoint}/link-report/delete`, {
      reportId,
      linkedReportId,
    });

    return;
  }
}
