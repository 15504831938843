import { OfficeDuplicationStatus } from './office-duplication-status.class';
import { OfficeDuplicationProgress } from './office-duplication-progress.enum';

export class OfficeDuplication {
  public _id?: string;
  office!: string;
  region?: string;
  public newName = '';
  public user?: string = '';
  public createdAt: Date = new Date();
  public updatedAt: Date = new Date();
  statuses?: OfficeDuplicationStatus;
  status: OfficeDuplicationProgress = OfficeDuplicationProgress.NotStarted;
  selectedCopies?: string[];
}
