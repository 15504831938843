export enum DateFormat {
  DD_MM_YY = 'DD/MM/YY',
  MM_DD_YY = 'MM/DD/YY',
  YY_MM_DD = 'YY/MM/DD',
  DD_MM_YYYY = 'DD/MM/YYYY',
  MM_DD_YYYY = 'MM/DD/YYYY',
  YYYY_MM_DD = 'YYYY/MM/DD',
  DD_MM_YYYY_HH_mm_ss = 'DD/MM/YYYY HH:mm:ss',
  MM_DD_YYYY_HH_mm_ss = 'MM/DD/YYYY HH:mm:ss',
  YYYY_MM_DD_HH_mm_ss = 'YYYY/MM/DD HH:mm:ss',
}
