import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import ShipmentTypesService from '@/services/api/shipment-types/shipment-types.service';
import { ShipmentType } from '@/services/api/shipment-types/shipment-type.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import { Filter } from '@/types/filter.interface';

const shipmentTypesService = new ShipmentTypesService();

interface ShipmentTypesState {
  loading: boolean;
  error: boolean;
  shipmentTypes: PaginatedResponse<ShipmentTypeResponse>;
  shipmentTypesNew: ShipmentType[];
  apiParams: FetchAllParams;
  current: ShipmentTypeResponse;
}
const initialShimpentTypestate: ShipmentTypesState = {
  loading: false,
  error: false,
  shipmentTypes: new PaginatedResponseClass(),
  shipmentTypesNew: [],
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
  current: {} as ShipmentTypeResponse,
};
const getters: GetterTree<ShipmentTypesState, RootState> = {
  GET: (state: ShipmentTypesState): ShipmentTypeResponse => state.current,
  ALL: (state: ShipmentTypesState) => state.shipmentTypes.docs,
  ALL_PAGINATED: (state: ShipmentTypesState) => state.shipmentTypes,
  API_PARAMS: (state: ShipmentTypesState) => state.apiParams,
};

const mutations: MutationTree<ShipmentTypesState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<ShipmentTypeResponse>) {
    state.shipmentTypes = payload;
  },
  SET_ONE(state, payload: ShipmentTypeResponse) {
    state.current = payload;
  },
};
const actions: ActionTree<ShipmentTypesState, RootState> = {
  async CREATE(context, payload: ShipmentType) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentType = await shipmentTypesService.create(payload);
      context.commit('SET_ONE', shipmentType);
      return shipmentType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentType = await shipmentTypesService.fetchOne(id);
      context.commit('SET_ONE', shipmentType);
      return shipmentType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const shipmentTypes = await shipmentTypesService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', shipmentTypes);
      return shipmentTypes;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; shipmentType: any }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentType = await shipmentTypesService.update(
        payload.id,
        payload.shipmentType,
      );
      context.commit('SET_ONE', shipmentType);
      return shipmentType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentType = await shipmentTypesService.delete(payload);
      context.commit('SET_ONE', shipmentType);
      return shipmentType;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialShimpentTypestate,
  getters,
  mutations,
  actions,
};
