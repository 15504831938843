import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import ShipmentImportsService from '@/services/api/shipment-imports/shipment-imports.service';
import { ShipmentImport } from '@/services/api/shipment-imports/shipment-import.class';
import { ShipmentImportLog } from '@/services/api/shipment-imports/shipment-import-log.class';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { Filter } from '@/types/filter.interface';
import { RootState } from '@/store/modules/types';
import { AxiosResponse } from 'axios';

const shipmentImportsService = new ShipmentImportsService();

interface ShipmentImportsState {
  all: PaginatedResponse<ShipmentImport>;
  one: ShipmentImport;
  apiParams: FetchAllParams;
  headers: string[];
  logs: PaginatedResponse<ShipmentImportLog>;
}

const initialShipmentImportsState: ShipmentImportsState = {
  one: {},
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
  headers: [],
  logs: new PaginatedResponseClass(),
};

const getters: GetterTree<ShipmentImportsState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
  GET_HEADERS: state => state.headers,
  LOGS: state => state.logs,
};

const mutations: MutationTree<ShipmentImportsState> = {
  SET_API_PARAMS(state, payload: FetchAllParams): void {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<ShipmentImport>): void {
    state.all = payload;
  },
  SET_ONE(state, payload: ShipmentImport): void {
    state.one = payload;
  },
  SET_HEADERS(state, payload: string[]): void {
    state.headers = payload;
  },
  SET_LOGS(state, payload: PaginatedResponse<ShipmentImportLog>) {
    state.logs = payload;
  },
};

const actions: ActionTree<ShipmentImportsState, RootState> = {
  async CREATE(context, payload: ShipmentImport): Promise<ShipmentImport> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentImport = await shipmentImportsService.create(payload);
      context.commit('SET_ONE', shipmentImport);
      return shipmentImport;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string): Promise<ShipmentImport> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentImport = await shipmentImportsService.fetchOne(id);
      context.commit('SET_ONE', shipmentImport);
      return shipmentImport;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context,
    filter?: Filter,
  ): Promise<PaginatedResponse<ShipmentImport>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const shipmentImports = await shipmentImportsService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', shipmentImports);
      return shipmentImports;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(
    context,
    payload: { id: string; shipmentImport: ShipmentImport },
  ): Promise<ShipmentImport> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentImport = await shipmentImportsService.update(
        payload.id,
        payload.shipmentImport,
      );
      context.commit('SET_ONE', shipmentImport);
      return shipmentImport;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string): Promise<ShipmentImport> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const shipmentImport = await shipmentImportsService.delete(payload);
      context.commit('SET_ONE', shipmentImport);
      return shipmentImport;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async GET_FILE_HEADERS(context, file: string): Promise<AxiosResponse> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const headers = await shipmentImportsService.getFileHeaders(file);
      context.commit('SET_HEADERS', headers);
      return headers;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_SHIPMENT_IMPORT_LOGS(
    context,
    payload: { id: string; filter?: Filter },
  ): Promise<PaginatedResponse<ShipmentImportLog>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', payload.filter);

      const logs = await shipmentImportsService.fetchLogsByImportId(
        payload.id,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_LOGS', logs);
      return logs;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialShipmentImportsState,
  getters,
  mutations,
  actions,
};
