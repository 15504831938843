































import { Vue, Component, Prop, Watch, Provide } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class MultiTextInput extends Vue {
  @Provide('validator') $validatorRef = this.$validator;

  @Prop({
    required: true,
  })
  inputValues!: string[];

  textInput = ' ';

  @Watch('textInput')
  InputChanged(): void {
    this.$validator.reset();
  }

  removeValue(field: string, index: number): void {
    this.$emit('removeValue', field, index);
  }

  keyupHandlerInput(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ',' || event.key === ';') {
      // The key pressed was the enter key
      if (!event.target) {
        return;
      }
      this.validateEventTarget(event.key);
    }
  }

  validateEventTarget(checkKey: string): void {
    if (!this.textInput.length && this.inputValues.length) {
      return;
    }
    this.$validator.validate('textInput').then((validatedInput: boolean) => {
      if (validatedInput) {
        if (checkKey === ',' || checkKey === ';') {
          this.textInput = this.textInput.substr(0, this.textInput.length - 1);
        }
        this.$emit('addValue', this.textInput);
        this.$validator.reset();
        this.textInput = '';
      }
    });
  }
}
