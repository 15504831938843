import { DateFormat } from './date-format.enum';
import { UserOfficeRelation } from '@/types/ui/user-office-relation';

export class User {
  _id?: string;
  __v?: any;
  email = '';
  username = '';
  firstName = '';
  lastName?: string;
  timezone = 'Europe/Amsterdam';
  dateFormat: DateFormat = DateFormat.DD_MM_YY;
  isDisplay = false;
  offices: UserOfficeRelation[] = [];
  selectedOffices?: string[];
  companies?: any[];
}
