


































































































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import entityListRow from '@/components/EntityListRow.vue';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { FilterOperation } from '@/types/filter-operation.enum';
import { DragEventOutput } from '@/types/drag-event-output.interface';
import draggable from 'vuedraggable';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { CustomButton } from '@/types/custom-button.interface';

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    draggable,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    entityListRow,
  },
})
export default class EntityList extends Vue {
  @Prop({ required: true })
  entityName!: string;

  @Prop()
  data!: unknown;

  @Prop()
  columns!: Array<EntityListColumn<unknown>>;

  @Prop({ default: true })
  showSearch!: boolean;

  @Prop({ default: 'Search' })
  searchLabel?: string;

  @Prop({ default: true })
  showCreate!: boolean;

  @Prop({ default: true })
  showEdit!: boolean;

  @Prop()
  editLabel?: string;

  @Prop({ default: true })
  showDelete!: boolean;

  @Prop()
  deleteLabel?: string;

  @Prop()
  rowClickable?: boolean;

  @Prop()
  filter?: FetchAllParams;

  @Prop()
  customButtons?: CustomButton[];

  @Prop()
  customActionsTitle?: string;

  @Prop()
  sortDirection?: string;

  @Prop()
  customValidator?: (evalItem: unknown) => boolean;

  @Prop()
  editValidator?: (evalItem: unknown) => boolean;

  @Prop()
  deleteValidator?: (evalItem: unknown) => boolean;

  @Prop({ default: false })
  showCustomActions!: boolean;

  @Prop({ default: 's' })
  searchField!: string;

  @Prop({ default: 'Create new ' })
  createLabel!: string;

  @Prop({ default: false })
  draggable!: boolean;

  @Prop({ default: true })
  isPaginatedData!: boolean;
  paginated!: boolean;

  dragOptions: any = {
    animation: 200,
    ghostClass: 'ghost',
  };

  inputSearch = '';
  get combinedLabel(): string {
    return this.createLabel + this.lowerCaseEntityName;
  }

  get showActionColumn(): boolean {
    return this.showDelete || this.showEdit || this.showCustomActions;
  }

  get showPagination(): boolean | unknown {
    return (
      this.isPaginatedData &&
      this.data &&
      (this.data as PaginatedResponse<unknown>).pages > 1
    );
  }

  get lowerCaseEntityName(): string {
    return this.entityName.charAt(0).toLowerCase() + this.entityName.slice(1);
  }

  get listData(): unknown {
    return this.isPaginatedData
      ? (this.data as PaginatedResponse<unknown>).docs
      : this.data;
  }

  set listData(data: unknown) {
    this.onDataReordered(data);
  }

  // Used when draggable data is not ISequence
  @Emit('data-reordered')
  onDataReordered(data: unknown): unknown {
    return data;
  }

  @Watch('inputSearch', {
    deep: true,
  })
  onFilterChanged(): void {
    this.prepFilterToSend(true);
    this.$emit('filterChanged', this.filter);
  }

  @Watch('data.page')
  onPaginationChanged(): void {
    this.prepFilterToSend(false);
    this.$emit('filterChanged', this.filter);
  }

  @Emit('drag-ended')
  onDragEnded(oldIndex: number, newIndex: number): DragEventOutput {
    return {
      oldIndex,
      newIndex,
    };
  }

  onEdit(item: unknown): void {
    this.$emit('edit', item);
  }

  onDelete(item: unknown): void {
    this.$emit('delete', item);
  }

  onCustomEvent(eventName: string, item: unknown): void {
    this.$emit(eventName, item);
  }

  prepFilterToSend(resetPagination: boolean): void {
    if (!this.filter) {
      this.filter = {};
    }
    if (!this.filter.filters) {
      this.filter.filters = {};
    }

    this.filter.filters[this.searchField] = {
      operation: FilterOperation.Contains,
      value: this.inputSearch,
    };

    this.filter.page = resetPagination
      ? 1
      : (this.data as PaginatedResponse<unknown>).page;
  }

  // Used when draggable data is ISequence
  dragEnded(event: DragEventOutput): void {
    const { oldIndex, newIndex } = event;
    if (oldIndex !== newIndex) {
      this.onDragEnded(oldIndex, newIndex);
    }
  }

  doSort(col: EntityListColumn<unknown>): void {
    this.$emit('sort', {
      column: col.sortField as string,
      sortDirection: this.sortDirection,
    });
  }

  sortIcon(sortDirection: string): string[] {
    if (sortDirection === 'asc') {
      return ['far', 'sort-amount-up'];
    }
    return ['far', 'sort-amount-down'];
  }
}
