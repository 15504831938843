




















































/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { FilterOperation } from '@/types/filter-operation.enum';

const reportsModule = namespace('reports');

@Component({
  components: {
    Multiselect,
  },
})
export default class ReportSelect extends Vue {
  @Prop()
  value!: {};
  @reportsModule.Getter('ALL_OPTIONS')
  options!: Option[];

  @reportsModule.Action('FETCH_ALL_COMPANY_REPORTS')
  fetchCompanyReports!: (filter?: FetchAllParams) => Promise<Option[]>;

  searchQuery: string[] = [];

  modelValue: any[] = [];

  $refs!: Vue['$refs'] & {
    customAttachmentSelect: Multiselect;
  };

  @Watch('value', {
    immediate: true,
  })
  onValueChange(value: any): void {
    if (value) {
      if (this.isStringInput) {
        const search = this.options.find(option => option.value === value);
        if (search) {
          this.modelValue = search as any;
        }
      } else {
        this.modelValue = value;
      }
    }
  }

  @Emit('input')
  onChange(selectedValue: any): any[] {
    if (this.isStringInput) {
      return selectedValue && selectedValue.value
        ? selectedValue.value
        : undefined;
    } else {
      return selectedValue;
    }
  }

  async searchReports(searchTerm: string): Promise<void> {
    const filter: FetchAllParams = {};
    filter.filters = {
      name: {
        operation: FilterOperation.Contains,
        value: searchTerm,
      },
    };

    await this.fetchCompanyReports(filter);
  }

  get isStringInput(): boolean {
    return typeof this.value === 'string';
  }
}
