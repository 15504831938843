




























import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appNavTabs from '../components/NavTabs.vue';
import { NavTabItem } from '@/types/nav-tab-item.interface';
import { SavableComponent } from '@/types/savable-component.class';
import { Customer as Cust } from '@/services/api/customers/customer-interface';

import appCustomerDetail from '@/components/CustomerDetail.vue';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';

const customersModule = namespace('customers');

@Component({
  components: {
    appNavTabs,
  },
})
export default class Customer extends Vue {
  @customersModule.Action('FETCH_ONE')
  fetchCustomer!: (id: string) => Promise<Cust>;

  @customersModule.Getter('ONE')
  customer!: Cust;

  to: any = { name: 'createCustomer' };

  customerTitle = 'New Customer';

  @Watch('$route.path', { immediate: true })
  async onValueChange(value: string): Promise<string> {
    if (value.includes('create')) {
      return this.customerTitle;
    }
    await this.fetchCustomer(this.$route.params.customerId);
    this.customerTitle = this.customer.parent.name;
    return this.customerTitle;
  }

  get isEditing(): boolean {
    return !!this.$route.params.customerId;
  }

  get navTabs(): NavTabItem[] {
    return this.isEditing
      ? [
        {
          label: 'Details',
          routeName: 'customer',
        },
        {
          label: 'Milestone Notifications',
          routeName: 'customerMilestoneNotifications',
        },
      ]
      : [
        {
          label: 'Details',
          routeName: 'createCustomer',
        },
      ];
  }
  @Watch('$route.params.customerId')
  async onCustomerIdChanged(): Promise<void> {
    await this.updateTo();
  }

  save(): void {
    const child = this.$refs.child;
    if (child instanceof SavableComponent) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      child.save();
    }
  }

  async created(): Promise<void> {
    await this.updateTo();
  }

  async updateTo(): Promise<void> {
    if (this.$route.params.customerId) {
      const customer = await this.fetchCustomer(this.$route.params.customerId);
      this.to = { name: 'customer', params: { customerId: customer._id } };
      this.customerTitle = customer.parent.name;
    } else {
      this.to = { name: 'createCustomer' };
    }
  }
}
