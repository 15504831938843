







































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BModal } from 'bootstrap-vue';
import api from '../services/api/';
import Multiselect from 'vue-multiselect';
import { Toaster } from '@/store/modules/toasters/types';

import { ShipmentRequest } from '@/services/api/shipments/shipment-request.interface';
import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { defineAbilitiesFor } from '@/define-abilities-for';
import { Role } from '@/services/api/users/role.enum';
import { SortOrder } from '@/services/api/sort-order.enum';
import { DeepPartial } from '@/types/deep-partial.type';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';

import appShipmentList from '../components/ShipmentList.vue';
import appShipmentDetail from '../components/ShipmentDetail.vue';
import appShipmentMilestoneDetail from '../components/ShipmentMilestoneDetail.vue';
import LogTable from '../components/LogTable.vue';
import appShipmentAttachment from '../components/ShipmentAttachments.vue';
import appShipmentLabels from '../components/ShipmentLabels.vue';
import { Filters } from '@/types/filters.interface';
import { FilterOperation } from '@/types/filter-operation.enum';

import { Sort } from '@/utils/sort.class';
import { ShipmentMilestoneDetailInput } from '@/types/shipments/shipment-milestone-detail-input.interface';
import { ShipmentMilestoneStatus } from '@/services/api/shipments/shipment-milestone-status.enum';
import { Attachment } from '@/services/api/attachments/attachment.class';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { ShipmentAttachment } from '@/services/api/shipment-attachments/shipment-attachment.class';
import { SavableComponent } from '@/types/savable-component.class';
import { UIFilter } from '@/types/ui/ui-filter.interface';
import get from 'lodash.get';
import { Office } from '@/services/api/offices/office.class';
import { transformUiFilter } from '@/utils/transformBaseFilter.util';
import { Ui } from '@/services/api/ui/ui.class';
import { StateChanger } from 'vue-infinite-loading';
import { Milestone } from '@/services/api/milestones/milestone.class';
import { UIField } from '@/types/ui/ui-field.class';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;
import { CarriersWithLabelsSupport } from '../services/api/shipment-labels/shipment-label-types';

const shipmentsModule = namespace('shipments');
const usersModule = namespace('users');
const attachmentsModule = namespace('attachments');
const shipmentAttachmentsModule = namespace('shipmentAttachments');
const toastersModule = namespace('toasters');

type OptionalExceptFor<T, TRequired extends keyof T> = Partial<T> &
Pick<T, TRequired>;
type ShipmentMilestonePayload = OptionalExceptFor<
ShipmentMilestoneDetailInput,
'shipmentId' | 'milestoneId' | 'sendNotification'
> & { overridden?: boolean; query?: Filters };

@Component({
  components: {
    appShipmentList,
    appShipmentDetail,
    appShipmentLabels,
    appShipmentMilestoneDetail,
    appShipmentAttachment,
    Multiselect,
    LogTable,
  },
})
export default class Shipments extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  ui!: Ui;

  CarriersWithLabelsSupport = CarriersWithLabelsSupport;

  @shipmentsModule.Getter('API_PARAMS')
  apiParams!: FetchAllParams;

  @shipmentsModule.Getter('ALL')
  shipments!: ShipmentResponse[];

  @shipmentsModule.Getter('SELECTED')
  selectedShipment!: ShipmentResponse;

  @usersModule.Getter('GET_ID')
  userId!: string;

  @usersModule.Getter('GET_ROLE')
  userRole!: Role;

  @usersModule.Getter('GET_IS_DISPLAY')
  userIsDisplay!: boolean;

  @usersModule.Getter('GET_TIMEZONE')
  userTimezone!: string;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @shipmentsModule.Getter('GET_INFINITE_LOADING_IDENTIFIER')
  infiniteLoadingIdentifier!: number;

  @shipmentsModule.Getter('ACTIVE')
  activeShipmentId!: string;

  @shipmentsModule.Mutation('INCREMENT_INFINITE_LOADING_IDENTIFIER')
  incrementInfiniteLoadingIdentifier!: () => void;

  @shipmentsModule.Mutation('SET_PAGE')
  setPage!: (page: number) => void;

  @shipmentsModule.Mutation('SET')
  setShipments!: (shipments: ShipmentResponse[]) => void;

  @shipmentsModule.Action('FETCH_ALL')
  fetchShipments!: (
    apiParams: FetchAllParams,
  ) => Promise<PaginatedResponse<ShipmentResponse>>;

  @shipmentsModule.Action('FETCH_FILTER_COUNT')
  fetchFilterCount!: (filter?: FetchAllParams) => Promise<number>;

  @shipmentsModule.Action('FETCH_COUNT')
  fetchCount!: (filter?: FetchAllParams) => Promise<number>;

  @shipmentsModule.Action('FETCH_ONE')
  fetchShipment!: (shipmentId: string) => Promise<ShipmentResponse>;

  @shipmentsModule.Action('PARTIAL_UPDATE')
  partialUpdateShipment!: (obj: {
    id: string;
    shipment: DeepPartial<ShipmentRequest>;
  }) => Promise<ShipmentResponse>;

  @shipmentsModule.Action('CLEAR_FILTERS_AND_SORT')
  clearShipmentFiltersAndSort!: () => void;

  @shipmentsModule.Action('SET_FILTERS')
  filterShipments!: (filters: Filters) => Promise<void>;

  @shipmentsModule.Action('RELOAD')
  reloadShipments!: () => Promise<void>;

  @shipmentsModule.Action('SET_SORT')
  sortShipments!: (arr: unknown[]) => void;

  @shipmentsModule.Action('SET_SHIPMENT_MILESTONE')
  setShipmentMilestone!: (
    payload: Partial<ShipmentMilestoneDetailInput>,
  ) => Promise<ShipmentResponse>;

  @shipmentsModule.Action('SET_MULTIPLE_SHIPMENT_MILESTONES')
  setMultipleShipmentMilestones!: (
    payload: ShipmentMilestonePayload,
  ) => Promise<ShipmentResponse[]>;

  @shipmentsModule.Action('SET_ACTIVE')
  setActive!: (shipmentId: string | null) => void;

  @shipmentsModule.Action('SET_SHIPMENT_MILESTONE_STATUS')
  setShipmentMilestoneStatus!: (
    payload: ShipmentMilestonePayload,
  ) => Promise<void>;

  @shipmentsModule.Action('SET_CURRENT_UI_BASE_FILTERS')
  setCurrentUiBaseFilters!: (baseFilters: UIFilter[]) => void;

  @attachmentsModule.Getter('ALL')
  allAttachments!: Attachment[];

  @attachmentsModule.Action('FETCH_UNPAGINATED')
  fetchAllAttachments!: (filter?: FetchAllParams) => Promise<Attachment>;

  @shipmentAttachmentsModule.Action('CREATE')
  createShipmentAttachment!: (
    shipmentAttachment: ShipmentAttachment,
  ) => Promise<ShipmentAttachment>;

  @shipmentAttachmentsModule.Action('DELETE')
  deleteShipmentAttachment!: (id: string) => Promise<ShipmentAttachment>;

  @shipmentAttachmentsModule.Getter('ALL')
  allShipmentAttachments!: ShipmentAttachment[];

  @shipmentAttachmentsModule.Action('FETCH_UNPAGINATED')
  fetchAllShipmentAttachments!: (
    filter?: FetchAllParams,
  ) => Promise<ShipmentAttachment>;

  @shipmentsModule.Action('RESTORE')
  restoreShipmentAction!: (shipmentId: string) => Promise<void>;

  // TODO: update list when deleted
  @shipmentsModule.Action('DELETE')
  deleteShipmentAction!: (shipmentId: string) => Promise<void>;

  @toastersModule.Action('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  interval?: number = 0;

  milestones!: Milestone[];

  shipmentID = '';

  tabIndex = 0;

  milestoneCode = '';

  shipmentsCount = 0;

  confirmationTotal = '';

  confirmation = false;

  updateDisabled = true;

  MAXSHIPMENTCOUNT = 25;

  selectedUpdateOption: Option = {
    text: '',
    value: '',
  };

  logFilter: FetchAllParams = {};

  logContentFilter = {};

  logColumns = 'shipmentMilestones';

  shipmentMilestoneDetailInput: ShipmentMilestoneDetailInput | null = null;
  shipmentMilestoneDetailUnchangedInput: ShipmentMilestoneDetailInput | null = null;

  sort: Sort[] = [];

  updateCounter = 0;

  $refs!: Vue['$refs'] & {
    shipmentDetail: BModal;
    milestoneDetail: BModal;
  };

  @Watch('ui', { immediate: true })
  onUiChange(val: Ui): void {
    this.init(val);
  }

  @Watch('shipmentMilestoneDetailInput', {
    immediate: true,
    deep: true,
  })
  onShipmentMilestoneDetailInputChange(): void {
    this.updateShipmentsCountAndStates();
    this.confirmationTotal = '';
    this.confirmation = false;
  }

  get sortArray(): string[] {
    const refactoredArray: string[] = [];
    this.sort.forEach((item: Sort) => {
      let direction = SortOrder.Asc;
      if (!item.asc) {
        direction = SortOrder.Desc;
      }
      refactoredArray.push(`${item.column},${direction}`);
    });
    return refactoredArray;
  }

  get canUpdateShipmentPriority(): boolean {
    return this.$can('update', 'Shipment', 'priority');
  }

  get canUpdateShipmentValue(): boolean {
    return this.$can('update', 'Shipment', 'value');
  }

  get aggregationFields(): Array<{ text: string; value: string }> {
    const allSearchResult = { text: 'All search results', value: '' };
    const options = this.office.aggregationFields.map(option => ({
      text: option,
      value: option,
    }));
    options.push(allSearchResult);
    return options;
  }

  setSort(column: string): void {
    const currentIndex = this.sort.findIndex(
      (sortLevel: Sort) => sortLevel.column === column,
    );
    const sortItem: { column: string; asc: boolean } = { column, asc: true };
    if (currentIndex !== -1) {
      sortItem.asc = !this.sort[currentIndex].asc;
      this.sort.splice(currentIndex, 1);
    }

    this.sort.unshift(sortItem);
  }

  async combineFilter(filters: Filters): Promise<void> {
    this.setPage(1);

    filters = {
      ...filters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ...(transformUiFilter(this.ui.baseFilter) as Filters),
    };
    this.incrementInfiniteLoadingIdentifier();
    await this.filterShipments(filters);
  }

  updateShipmentModal(): void {
    const ref =
      this.tabIndex === 0 ? 'shipmentDetailComponent' : 'attachmentsComponent';
    const child = this.$refs[ref] as SavableComponent;
    if (child instanceof SavableComponent) {
      child.save();
    }
    this.hideShipmentDetail();
  }

  deleteShipment(id: string): void {
    if (!confirm('Are you sure you want to delete this shipment?')) {
      return;
    }

    this.deleteShipmentAction(id);
    this.hideShipmentDetail();
  }

  restoreShipment(id: string): void {
    this.restoreShipmentAction(id);
    this.hideShipmentDetail();
  }

  doSort(column: string): void {
    if (column === 'customer') {
      column = 'customer.parent.name';
    }
    this.setPage(1);
    this.incrementInfiniteLoadingIdentifier();
    this.setSort(column);
    this.sortShipments([...this.sortArray, ...sortByPriority(this.milestones)]);
    this.reloadShipments();
  }

  tabChanged(e: number): void {
    this.tabIndex = e;
  }

  async showShipmentDetail(shipmentId: string): Promise<void> {
    await this.fetchShipment(shipmentId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    this.$refs.shipmentDetail.show();
    this.shipmentID = shipmentId;
  }

  async onPartialUpdateShipment(
    shipment: DeepPartial<ShipmentRequest>,
  ): Promise<void> {
    await this.partialUpdateShipment({
      id: this.selectedShipment._id,
      shipment,
    });
    this.updateCounter = 0;
  }

  resetInfiniteLoading($state: StateChanger): void {
    $state.reset();
  }
  async loadMoreShipments($state: StateChanger): Promise<void> {
    this.setPage(this.apiParams.page ? this.apiParams.page + 1 : 1);
    const result = await this.fetchShipments({
      ...this.apiParams,
    });
    if (result.page >= result.pages) {
      $state.complete();
    } else {
      $state.loaded();
    }
  }

  hideShipmentDetail(): void {
    this.$refs.shipmentDetail.hide();
  }

  async handleShipmentAttachment(
    file: File,
    attachment: Attachment,
  ): Promise<void> {
    if (!file) {
      return;
    }
    const uploadedFile = await api.files.create(file);
    if (attachment) {
      const shipmentAttachment = new ShipmentAttachment();
      shipmentAttachment.shipment = this.shipmentID;
      shipmentAttachment.attachment = attachment;
      shipmentAttachment.file = uploadedFile[0]._id;
      await this.createShipmentAttachment(shipmentAttachment);
    }
    await this.fetchAllAttachments();
    await this.fetchAllShipmentAttachments();
  }

  async unAttachShipmentById(
    shipmentAttachment: ShipmentAttachment,
  ): Promise<void> {
    if (!shipmentAttachment) {
      return;
    }
    await this.deleteShipmentAttachment(shipmentAttachment._id as string);
    await this.fetchAllAttachments();
    await this.fetchAllShipmentAttachments();
  }

  shipmentMilestoneClicked(input: ShipmentMilestoneDetailInput): void {
    this.setActive(input.shipmentId);
    if (this.canUpdateShipmentValue) {
      if (input.dateToggleClick === true) {
        let payload: ShipmentMilestonePayload = {
          shipmentId: input.shipmentId,
          milestoneId: input.milestoneId,
          sendNotification: input.sendNotification,
        };
        if (input.value === null) {
          payload = {
            ...payload,
            value: Date.now(),
            overridden: true,
          };
          this.setShipmentMilestone(payload);
        } else {
          payload = {
            ...payload,
            value: null,
            overridden: true,
          };
          this.setShipmentMilestone(payload);
        }
      } else {
        this.showEditMilestoneModal(input);
      }
    } else {
      this.doSync(input);
    }
  }

  showEditMilestoneModal(input: ShipmentMilestoneDetailInput): void {
    this.generateMilestoneLogFilter(input.shipmentId, input.milestoneId);
    this.generateLogContentFilter(input.milestoneId);
    this.shipmentMilestoneDetailUnchangedInput = { ...input };
    this.shipmentMilestoneDetailInput = input;
    this.milestoneCode = this.shipmentMilestoneDetailInput.milestoneCode;
    this.selectedUpdateOption = this.aggregationFields[0];
    this.$refs.milestoneDetail.show();
  }

  hideEditMilestoneModal(): void {
    this.$refs.milestoneDetail.hide();
    this.shipmentMilestoneDetailInput = null;
    this.shipmentMilestoneDetailUnchangedInput = null;
    this.selectedUpdateOption = this.aggregationFields[0];
  }

  async updateShipmentsCountAndStates(): Promise<void> {
    this.shipmentsCount = 0;
    this.updateDisabled = true;
    this.shipmentsCount = await this.countAggregatedShipments();
    this.checkEnableUpdate(
      this.shipmentsCount,
      parseInt(this.confirmationTotal, 10),
      this.MAXSHIPMENTCOUNT,
    );
    this.confirmationTotal = '0';
    return;
  }

  async countAggregatedShipments(): Promise<number> {
    if (this.selectedUpdateOption.value === 'ref') {
      return 1;
    } else {
      const shipment = this.shipments.find(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ship => ship._id === this.shipmentMilestoneDetailInput!.shipmentId,
      );
      return await this.shipmentsQueryCount(
        this.selectedUpdateOption.text,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shipment!,
      );
    }
  }

  checkEnableUpdate(
    shipmentsCount: number,
    confirmationTotal: number,
    maxShipmentCount: number,
  ): boolean {
    if (shipmentsCount > maxShipmentCount) {
      return (this.updateDisabled = true);
    }
    if (shipmentsCount === 1) {
      return (this.updateDisabled = false);
    } else {
      if (parseInt(this.confirmationTotal, 10) === this.shipmentsCount) {
        return (this.updateDisabled = false);
      } else {
        return (this.updateDisabled = true);
      }
    }
  }

  async updateShipmentMilestone(): Promise<void> {
    if (
      this.shipmentMilestoneDetailInput &&
      this.shipmentMilestoneDetailUnchangedInput
    ) {
      let payload: ShipmentMilestonePayload = {
        shipmentId: this.shipmentMilestoneDetailInput.shipmentId,
        milestoneId: this.shipmentMilestoneDetailInput.milestoneId,
        sendNotification: this.shipmentMilestoneDetailInput.sendNotification,
      };
      // TODO, get from this.shipments.find;
      const shipment = this.shipments.find(
        ship => ship._id === payload.shipmentId,
      );

      if (shipment) {
        const priorityHasBeenChanged =
          shipment.shipmentMilestones[payload.milestoneId].priority !==
          this.shipmentMilestoneDetailInput.priority;

        if (this.canUpdateShipmentPriority && priorityHasBeenChanged) {
          payload = {
            ...payload,
            priority: this.shipmentMilestoneDetailInput.priority,
          };
        }

        if (
          this.shipmentMilestoneDetailInput.value !==
          this.shipmentMilestoneDetailUnchangedInput.value
        ) {
          payload = {
            ...payload,
            value: this.shipmentMilestoneDetailInput.value,
            overridden: true,
          };
        }

        if (this.shipmentsCount === 1) {
          this.triggerShipmentFormUpdate();
          this.setShipmentMilestone(payload);
          this.$refs.milestoneDetail.hide();
          return;
        } else {
          if (
            this.shipmentsCount > 1 &&
            this.shipmentsCount <= this.MAXSHIPMENTCOUNT
          ) {
            await this.bulkUpdateMilestones(
              shipment,
              this.selectedUpdateOption,
              payload,
            );
            this.$refs.milestoneDetail.hide();
            return;
          }
        }
      }
    }
    this.selectedUpdateOption = this.aggregationFields[0];
    this.hideEditMilestoneModal();
  }

  triggerShipmentFormUpdate(): void {
    // this counter triggers a watcher in ShipmentDetail to save the full form
    ++this.updateCounter;
  }

  shipmentsQueryCount(
    groupOption: string,
    shipment: ShipmentResponse,
  ): Promise<number> {
    let query: FetchAllParams = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    query[groupOption as keyof FetchAllParams] = get(shipment, groupOption);
    if (groupOption === 'All search results') {
      query = { ...this.apiParams.filters };
      return this.fetchFilterCount(query);
    }
    return this.fetchCount(query);
  }

  bulkUpdateMilestones(
    shipment: ShipmentResponse,
    groupOption: { text: string; value: string },
    payload: ShipmentMilestonePayload,
  ): Promise<ShipmentResponse[]> {
    let query: Filters = {};
    if (groupOption.value !== '') {
      query[groupOption.text] = {
        value: get(shipment, groupOption.text) as string,
        operation: '' as FilterOperation,
      };
    } else {
      query = { ...this.apiParams.filters };
    }

    return this.setMultipleShipmentMilestones({ ...payload, query });
  }

  async doSync(input: ShipmentMilestoneDetailInput): Promise<void> {
    if (
      !this.canUpdateShipmentValue &&
      (input.status === ShipmentMilestoneStatus.Changed ||
        input.status === ShipmentMilestoneStatus.Conflicted)
    ) {
      await this.setShipmentMilestoneStatus({
        shipmentId: input.shipmentId,
        milestoneId: input.milestoneId,
        status: ShipmentMilestoneStatus.Registered,
      });
    }
  }

  customLabel(option: Option): string | LocaleMessage {
    return this.$t(option.text);
  }

  init(ui: Ui): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.startInterval();
    this.setPage(1);
    const milestoneFields = ui.scrollFields
      .concat(ui.stickyFields)
      .filter((x: UIField) => x.fieldType === 'ms');

    this.milestones = milestoneFields.map((x: UIField) => {
      if (x.milestone) {
        return x.milestone;
      }
    }) as Milestone[];

    this.$ability.update(
      defineAbilitiesFor(
        this.userId,
        this.userRole,
        this.milestones.map(
          (milestone: Milestone) => milestone && (milestone._id as string),
        ),
      ).rules,
    );

    this.clearShipmentFiltersAndSort();
    this.setShipments([]);
    this.sort = [];

    const { ...params } = this.apiParams;
    params.sort = sortByPriority(this.milestones);
    this.sortShipments(params.sort);
    params.filters = params.filters || {};
    params.filters = {
      ...params.filters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      ...(transformUiFilter(ui.baseFilter) as Filters),
    };
    params.page = this.apiParams.page;
    this.incrementInfiniteLoadingIdentifier();
    this.filterShipments(params.filters ? params.filters : {});
    this.fetchAllAttachments();
    this.fetchAllShipmentAttachments();
    this.setCurrentUiBaseFilters(ui.baseFilter);
    this.selectedUpdateOption = this.aggregationFields[0];
  }

  startInterval(): void {
    if (this.ui.forDisplay && this.userIsDisplay) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setInterval(async () => {
        this.setPage(1);
        await this.reloadShipments();
      }, 60000);
    }
  }

  generateMilestoneLogFilter(shipmentId: string, milestoneId: string): void {
    const milestoneField = `content.shipmentMilestones.${milestoneId}`;

    this.logFilter = {
      sort: ['createdAt,desc'],
      limit: 10,
      filters: {
        object: {
          value: shipmentId,
          operation: FilterOperation.Equals,
        },
        [milestoneField]: {
          value: 'null',
          operation: FilterOperation.NotEquals,
        },
      },
    };
  }

  generateLogContentFilter(milestoneId: string): void {
    this.logContentFilter = { shipmentMilestones: milestoneId };
  }

  destroyed(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

function sortByPriority(milestones: Milestone[]): string[] {
  return [
    `priority,${SortOrder.Desc}`,
    ...[...milestones]
      .reverse()
      .map(
        (milestone: Milestone) =>
          `shipmentMilestones.${
            milestone && (milestone._id as string)
          }.priority,${SortOrder.Desc}`,
      ),
  ];
}
