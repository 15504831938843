





































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { Ui } from '@/services/api/ui/ui.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { DragEventOutput } from '../types/drag-event-output.interface';
import { BModal } from 'bootstrap-vue';
import { CustomButton } from '@/types/custom-button.interface';
import { TestIds } from '@/types/test-ids.enum';
import { FilterParams } from '@/types/filter-params.interface';

const uiModule = namespace('ui');

@Component({
  components: {
    EntityList,
  },
})
export default class AllUis extends Vue {
  @uiModule.Getter('ALL')
  uis!: Ui[];

  @uiModule.Action('UPDATE_LIST')
  updateListSequence!: (dragEvent: DragEventOutput) => Promise<void>;

  @uiModule.Action('FETCH_UNPAGINATED')
  fetchAllUnpaginated!: (filter?: FilterParams) => Promise<void>;

  @uiModule.Action('DELETE')
  deleteById!: (id: string) => Promise<Ui>;

  @uiModule.Action('DUPLICATE')
  duplicateUi!: (payload: { ui: Ui }) => Promise<Ui>;

  @uiModule.Action('SAVE_LIST')
  saveUiList!: (ui: Ui[]) => Promise<void>;

  filter: FetchAllParams = {};

  selectedUi = new Ui();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public columns: any[] = [
    {
      field: 'name',
      label: 'Ui Name',
    },
    {
      field: 'icon',
      label: 'Icon',
    },
  ];

  $refs!: Vue['$refs'] & {
    cloneModal: BModal;
  };

  customButtons: CustomButton[] = [
    {
      variant: 'light',
      icon: 'copy',
      event: 'openCloneModal',
      show: this.$can('create', 'UI'),
      id: TestIds.CLONE_UI,
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAllUnpaginated(filter);
  }

  redirectToDetail(ui: Ui): void {
    this.$router.push({
      name: 'Ui',
      params: { id: ui._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateUi',
    });
  }

  openCloneModal(item: Ui): void {
    this.$refs.cloneModal.show();
    this.selectedUi = { ...item };
  }

  async copyUi(): Promise<void> {
    if (this.selectedUi) {
      this.selectedUi.name = `Copy of ${this.selectedUi.name}`;
      const ui = await this.duplicateUi({ ui: this.selectedUi });
      if (ui && ui._id) {
        await this.$router.replace({
          name: 'Ui',
          params: { id: ui._id },
        });
      }
    }
  }

  async redirectToDelete(ui: Ui): Promise<void> {
    const result = confirm('Are you sure you want to delete ' + ui.name + '?');
    if (!result) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    await this.deleteById(ui._id!);
    await this.fetchAllUnpaginated(this.filter);
  }

  async handleDrag(event: DragEventOutput): Promise<void> {
    await this.updateListSequence(event);
    await this.saveUiList(this.uis);
    await this.fetchAllUnpaginated(this.filter);
  }

  async created(): Promise<void> {
    this.filter.sort = ['sequence,asc'];
    this.filter.limit = 10;
    await this.fetchAllUnpaginated(this.filter);
  }
}
