import { FileType } from './file-type.enum';

export class Attachment {
  _id?: string;
  name?: string;
  description?: string;
  fileTypes?: FileType[];
  isPrivate?: boolean;
  company?: string;
  multiple?: boolean;
}
