



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EntityList from '../components/EntityList.vue';
import { namespace } from 'vuex-class';
import { LogLevel } from '../types/log-level.enum';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { FilterParams } from '../types/filter-params.interface';
import { EntityListColumn } from '../types/entity-list-column.interface';
import { Log, ShipmentMilestoneLog } from '../services/api/logs/log.class';
import { User } from '../services/api/users/user.class';
import { LogType } from '../services/api/logs/log-type.enum';
import { LogAction } from '../services/api/logs/log-action.enum';

import { ShipmentImportStatus } from '@/types/import-status.enum';
import { formatInTimeZone } from 'date-fns-tz';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
const logsModule = namespace('logs');
const usersModule = namespace('users');

@Component({
  components: {
    EntityList,
  },
})
export default class LogTable extends Vue {
  @usersModule.Getter('GET_TIMEZONE')
  timezone!: string;

  @logsModule.Getter('ALL_PAGINATED')
  logs!: PaginatedResponseClass<Log>;

  @logsModule.Mutation('CLEAR')
  clearLogs!: () => void;

  @logsModule.Action('FETCH_ALL')
  fetchLogs!: (params?: FilterParams) => Log[];

  @Prop({
    required: false,
  })
  filter!: FilterParams;

  @Prop({
    required: false,
    default: 5,
  })
  limit!: number;

  @Prop({
    required: false,
  })
  counter!: number;

  @Prop({
    required: false,
  })
  customColumns!: string;

  @Prop({
    required: false,
  })
  contentFilter!: FilterParams;

  @Prop({
    required: false,
  })
  status!: string;

  public defaultLogColumns: Array<EntityListColumn<Log>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatTime,
      label: 'Time',
      sortable: true,
      sortDirection: 'asc',
      isHTML: true,
    },
    {
      isHTML: true,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatLogLevel,
      param: 'level',
      label: 'Type',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatContent,
      label: 'Remarks',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  public shipmentMilestoneLogColumns: Array<EntityListColumn<Log>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatTime,
      label: 'Time',
      sortable: true,
      sortDirection: 'asc',
      isHTML: true,
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatUser,
      label: 'Users',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatShipmentMilestoneValue,
      label: 'Changed Value',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      isHTML: true,
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatShipmentMilestonePriorityRule,
      label: 'Rule',
    },
  ];
  public columns: Array<EntityListColumn<Log>> = [];
  format = 'dd/MM/yyyy HH:mm';
  @Watch('status')
  onStatusChange(): void {
    if (this.status === ShipmentImportStatus.Done) {
      this.fetchLogs(this.filter);
    }
  }

  @Watch('counter')
  onCounterChange(): void {
    this.fetchLogs(this.filter);
  }

  formatTime(log: Log): string {
    if (log.createdAt) {
      return formatInTimeZone(log.createdAt, this.timezone, this.format);
    } else {
      return '';
    }
  }

  formatLogLevel(input: Log): string {
    switch (input) {
    case LogLevel.Log:
      return '<span class="badge badge-info badge-pill">Log</span>';
    case LogLevel.Warn:
      return '<span class="badge badge-warning badge-pill">Warning</span>';
    case LogLevel.Error:
      return '<span class="badge badge-danger badge-pill">Error</span>';
    default:
      return '<span class="badge badge-primary badge-pill">Info</span>';
    }
  }

  formatContent(log: Log): string {
    if (!log.content) {
      if (
        log.action === LogAction.Created &&
        log.objectType === LogType.ShipmentImport
      ) {
        return 'Import started';
      }
      return 'N/A';
    }
    return log.content.message
      ? log.content.message
      : JSON.stringify(log.content);
  }

  formatShipmentMilestoneValue(log: Log): string {
    const shipmentMileLog = this.parseLog(log, this.customColumns);
    if (shipmentMileLog) {
      if (shipmentMileLog.value === null) {
        return 'Date cleared';
      }
      if (shipmentMileLog.value) {
        return formatInTimeZone(
          shipmentMileLog.value,
          this.timezone,
          this.format,
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  formatShipmentMilestonePriorityRule(log: Log): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const shipmentMileLog = this.parseLog(log, this.customColumns);
    if (shipmentMileLog) {
      if (
        shipmentMileLog.priority &&
        typeof shipmentMileLog.priority === 'number'
      ) {
        return `<span class="badge badge-warning badge-pill">${shipmentMileLog.priority}</span>`;
      }
      if (shipmentMileLog.ruleResult) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `<span class="badge badge-danger badge-pill">${shipmentMileLog.ruleResult.status}</span>`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  parseLog(log: Log, parseType: string): ShipmentMilestoneLog | void {
    const contentFilterValue: string = Object.values(
      this.contentFilter,
    )[0] as string;
    if (parseType === 'shipmentMilestones') {
      if (log.content && log.content.shipmentMilestones) {
        const shipmentMileLog = log.content.shipmentMilestones[
          contentFilterValue
        ] as ShipmentMilestoneLog;
        return shipmentMileLog;
      }
    }
  }

  formatUser(log: Log): string {
    if (log.user) {
      if (typeof log.user === 'string') {
        return log.user;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const user: User = (log.user! as unknown) as User;
        return user.username;
      }
    } else {
      return '';
    }
  }

  selectColumns(customFormat: string): void {
    switch (customFormat) {
    case 'shipmentMilestones':
      this.columns = this.shipmentMilestoneLogColumns;
      break;
    default:
      this.columns = this.defaultLogColumns;
    }
  }

  handleFilterChange(): void {
    this.fetchLogs(this.filter);
  }

  created(): void {
    this.clearLogs();
    this.selectColumns(this.customColumns);
    this.handleFilterChange();
  }
}
