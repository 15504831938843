import { ShipmentAttachment } from './shipment-attachment.class';
import EntitiesService from '../entities/entities.service';

export default class ShipmentAttachmentsService extends EntitiesService<
ShipmentAttachment
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) +
      '/shipment-attachments'
    );
  }
}
