















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { ResetPasswordRequest } from '../services/api/auth/reset-password-request.interface';

const usersModule = namespace('users');
const appModule = namespace('application');

@Component({})
export default class PasswordResetRequest extends Vue {
  @appModule.Getter('GET_LOADING')
  loading!: boolean;

  @usersModule.Action('RESET')
  requestResetToken!: (payload: ResetPasswordRequest) => Promise<boolean>;

  form = {
    email: '',
  };

  formFields!: FieldFlagsBag;

  formErrors!: ErrorBag;

  resetSuccess = false;

  sendResetPasswordRequest(): void {
    this.$validator.validate('email').then(async (validatedInput: boolean) => {
      if (validatedInput) {
        this.resetSuccess = await this.requestResetToken({
          email: this.form.email,
        });
        this.form = { email: '' };
        this.$validator.reset();
      }
    });
  }
}
