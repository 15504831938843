/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import RulesService from '@/services/api/rules/rules.service';
import { Rule } from '@/services/api/rules/rule.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { Filter } from '@/types/filter.interface';

const rulesService = new RulesService();

interface RuleState {
  one: Rule;
  all: PaginatedResponse<Rule>;
  apiParams: FetchAllParams;
}

const initialRuleState: RuleState = {
  one: new Rule(),
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};
const getters: GetterTree<RuleState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<RuleState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<Rule>) {
    state.all = payload;
  },
  SET_ONE(state, payload: Rule) {
    state.one = payload;
  },
};

const actions: ActionTree<RuleState, RootState> = {
  async CREATE(context, payload: Rule) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const rule = await rulesService.create(payload);
      context.commit('SET_ONE', rule);
      return rule;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const rule = await rulesService.fetchOne(id);
      context.commit('SET_ONE', rule);
      return rule;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const rules = await rulesService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', rules);
      return rules;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; rule: any }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const rule = await rulesService.update(payload.id, payload.rule);
      context.commit('SET_ONE', rule);
      return rule;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const rule = await rulesService.delete(payload);
      context.commit('SET_ONE', rule);
      return rule;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialRuleState,
  getters,
  mutations,
  actions,
};
