


















































































































































































































































import { MailDetails } from '@/services/api/reports/mail-details.class';
import { VerifiedEmail } from '@/services/api/verified-emails/verified-email.class';
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../components/FieldSelect.vue';
const verifiedEmailsModule = namespace('verifiedEmails');

@Component({
  components: {
    FieldSelect,
  },
})
export default class MailForm extends Vue {
  @Inject('validator') $validatorRef: any;
  @verifiedEmailsModule.Getter('ALL')
  verifiedEmails!: VerifiedEmail[];

  @Prop({
    required: true,
  })
  mailFormInfo!: MailDetails;

  @Prop({
    default: 0,
  })
  msIndex!: number;

  @Prop({
    default: false,
  })
  toOptional!: boolean;

  mailToInput = ' ';
  mailCcInput = ' ';
  mailBccInput = ' ';
  emailValidated: boolean | null = null;

  showKeywordModal = false;
  selectedKeyword: {
    text?: string;
    value?: string;
  } = {};

  @Watch('mailToInput')
  ToInputChanged(): void {
    this.$validator.reset();
  }
  @Watch('toOptional')
  ToOptionalChanged(): void {
    this.$validator.reset();
  }
  @Watch('mailCcInput')
  CcInputChanged(): void {
    this.$validator.reset();
  }
  @Watch('mailBccInput')
  BccInputChanged(): void {
    this.$validator.reset();
  }
  removeEmail(field: string, emailToIndex: number): void {
    this.$emit('removeEmail', field, emailToIndex);
  }

  validateEventTarget(event: Event | KeyboardEvent): void {
    const eventTarget = event.target as HTMLEmbedElement;

    switch (eventTarget.id) {
      case `input-to-${this.msIndex}`:
        if (
          this.toOptional ||
          (!this.mailToInput.length && this.mailFormInfo.to.length)
        ) {
          return;
        }
        this.$validator
          .validate(`inputTo-${this.msIndex}`)
          .then((validatedInputTo: boolean) => {
            if (validatedInputTo) {
              this.$emit('addEmail', eventTarget.id, this.mailToInput);
              this.mailToInput = '';
              this.$validator.reset();
            }
          });
        break;
      case `input-cc-${this.msIndex}`:
        if (!this.mailCcInput.length && this.mailFormInfo.cc.length) {
          return;
        }
        this.$validator
          .validate(`inputCc-${this.msIndex}`)
          .then((validatedInputCc: boolean) => {
            if (validatedInputCc) {
              this.$emit('addEmail', eventTarget.id, this.mailCcInput);
              this.mailCcInput = '';
              this.$validator.reset();
            }
          });
        break;
      case `input-bcc-${this.msIndex}`:
        if (!this.mailBccInput.length && this.mailFormInfo.bcc.length) {
          return;
        }
        this.$validator
          .validate(`inputBcc-${this.msIndex}`)
          .then((validatedInputBcc: boolean) => {
            if (validatedInputBcc) {
              this.$emit('addEmail', eventTarget.id, this.mailBccInput);
              this.mailBccInput = '';
              this.$validator.reset();
            }
          });
        break;
      default:
        break;
    }
  }

  handleBlur(event: Event): void {
    this.validateEventTarget(event);
  }

  keydownHandler(event: KeyboardEvent): void {
    if (
      event.key === 'Enter' ||
      event.key === ',' ||
      event.key === ';' ||
      event.key === 'Tab'
    ) {
      // The key pressed was the enter key
      if (!event.target) {
        return;
      }
      this.validateEventTarget(event);
    }
  }

  handleInput(): void {
    this.$emit('input', this.mailFormInfo);
  }

  openKeywordModal(cb: (str: string) => string): void {
    this.keywordCallback = cb;
    this.showKeywordModal = true;
  }

  keywordCallback: (str: string) => string = () => '';

  attachKeywordAndCloseModal(): void {
    if (this.selectedKeyword.value) {
      this.keywordCallback(`{{ ${this.selectedKeyword.value} }}`);
    }
    this.showKeywordModal = false;
  }
}
