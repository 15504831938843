



























































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { FieldFlagsBag, FieldFlags, ErrorBag } from 'vee-validate';

import { Customer } from '@/services/api/customers/customer-interface';
import { CustomerUploadPdfResponse } from '@/services/api/customers/customer-upload-pdf-response';

import Dropzone from 'dropzone';
import vue2Dropzone from 'vue2-dropzone';
import { namespace } from 'vuex-class';
import { Office } from '@/services/api/offices/office.class';
import api from '../services/api/';
import { FileEvent } from '@/types/file-event.interface';
import { CustomerDelivery as CustDelivery } from '@/types/customer/customer-delivery.class';
import { CustomerParent } from '@/types/customer/customer-parent.class';
import { CustomerInvoice } from '@/types/customer/customer-invoice-class';
import countries from 'i18n-iso-countries';
import CustomerDelivery from '../components/CustomerDelivery.vue';

import { SavableComponent } from '@/types/savable-component.class';

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

const customersModule = namespace('customers');
const usersModule = namespace('users');

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    vue2Dropzone,
    CustomerDelivery,
  },
})
export default class CustomerDetail extends SavableComponent {
  @customersModule.Getter('UPLOAD_RESULT')
  uploadResult!: CustomerUploadPdfResponse;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @customersModule.Action('FETCH_ONE')
  fetchCustomer!: (id: string) => Promise<Customer>;

  @customersModule.Action('UPLOAD_PDF')
  uploadPdf!: (file: File) => Promise<void>;

  @customersModule.Getter('ONE')
  customer!: Customer;

  @customersModule.Action('CREATE')
  createCustomer!: (customer: Customer) => Promise<Customer>;

  @customersModule.Action('UPDATE')
  doUpdateCustomer!: (obj: {
    id: string;
    customer: Customer;
  }) => Promise<Customer>;

  form: Customer = {
    parent: new CustomerParent(),
    deliveries: [] as CustDelivery[],
    customerMilestones: [],
    aliases: [] as string[],
    remark: '',
    invoice: new CustomerInvoice(),
    office: '',
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  options = countries.getNames('en', { select: 'official' });
  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  showPdfUploadResult = false;

  $refs!: Vue['$refs'] & {
    dropzone: Dropzone;
    customerDelivery: CustomerDelivery;
  };

  get title(): string {
    if (this.customer) {
      return this.customer.parent.name;
    }
    return 'New Customer';
  }

  async startPdfUpload(e: FileEvent): Promise<void> {
    await this.uploadPdf(e.target.files[0]);
    this.form.customerInfoFile = this.uploadResult;
  }

  removeAlias(index: number): null | void {
    if (!this.form || !this.form.aliases) {
      return null;
    }
    this.form.aliases.splice(index, 1);
  }
  removeCustomerInfoFile(): void {
    this.form.customerInfoFile = undefined;
  }

  addAlias(): null | void {
    if (!this.form || !this.form.aliases) {
      return null;
    }
    this.form.aliases.push('');
  }

  get customerId(): string | null {
    if (this.customer && this.customer._id) {
      return this.customer._id;
    }

    return null;
  }

  get isFormValid(): boolean {
    return Object.keys(this.formFields).every((field: string) => {
      const fieldFlags: FieldFlags = this.formFields[field];

      return Boolean(fieldFlags && fieldFlags.valid);
    });
  }

  wait(ms: number): Promise<unknown> {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  async save(): Promise<void> {
    if (this.form.aliases && !this.form.aliases.length) {
      this.addAlias();
    }
    // This function is used because the validation was triggered before the dom was rerendered
    // when an alias is added to the array
    await this.wait(50);
    await this.$validator.validate();

    if (!this.isFormValid) {
      return;
    }
    if (!this.form.office) {
      this.form.office = this.office._id || '';
    }
    if (this.form.customerMilestones) {
      for (const [index, cms] of this.form.customerMilestones.entries()) {
        if (typeof cms.milestone === 'object') {
          this.form.customerMilestones[index].milestone = cms.milestone._id;
        }
      }
    }
    if (this.$route.params.customerId) {
      const id = this.form._id as string;
      this.updateCustomer({ id, customer: this.form });
    } else {
      await this.createCust(this.form);
    }
  }

  async created(): Promise<void> {
    if (this.$route.params.customerId) {
      await this.fetchCustomer(this.$route.params.customerId);
      this.form = this.customer;
    }
  }

  async updateCustomer(obj: { id: string; customer: Customer }): Promise<void> {
    if (obj.customer.customerInfoFile) {
      obj.customer.customerInfoFile = (obj.customer
        .customerInfoFile as CustomerUploadPdfResponse)._id;
    }
    await this.doUpdateCustomer(obj);
  }

  async createCust(customer: Customer): Promise<void> {
    if (customer.customerInfoFile) {
      customer.customerInfoFile = (customer.customerInfoFile as CustomerUploadPdfResponse)._id;
    }
    await this.createCustomer(customer);

    this.$router.replace({
      name: 'customer',
      params: {
        customerId: this.customer._id as string,
      },
    });
    this.form = this.customer;
  }

  downloadFile(url: string, filename: string): Promise<void> {
    return this.getDownloadFile(url, filename);
  }
  async getDownloadFile(fileUrl: string, filename: string): Promise<void> {
    const file = await api.files.getFile(fileUrl);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  async openDeliveryModal(): Promise<void> {
    const res = await this.$validator.validate();
    if (!res) {
      return;
    }
    const delivery = new CustDelivery();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.$refs.customerDelivery.openCustomerDeliveryModal(delivery, false);
  }

  addDelivery(delivery: CustDelivery): void {
    this.form.deliveries.push(delivery);
  }

  removeDelivery(index: number): void {
    this.form.deliveries.splice(index, 1);
  }
}
