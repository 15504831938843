import { TransformationCondition } from './transformation-condition.interface';
import { TransformationOperation } from './transformation-operation.interface';
import { ISequence } from '@/types/sequence.interface';

export class Transformation implements ISequence {
  _id?: string;
  sequence = 0;
  name = '';
  office = '';
  operations: TransformationOperation[] = [];
  conditions: TransformationCondition[] = [];
  __v?: any;

  constructor(sequence = 0, office = '') {
    this.sequence = sequence;
    this.office = office;
  }
}
