import { MilestoneType } from './milestone-type.class';
import EntitiesService from '../entities/entities.service';

export default class MilestoneTypesService extends EntitiesService<
MilestoneType
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) + '/milestone-types'
    );
  }
}
