




























































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace, Getter, Action } from 'vuex-class';
import { ShipmentMilestoneStatus } from '../services/api/shipments/shipment-milestone-status.enum';
import { RuleResult } from '../services/api/shipments/rule-result.interface';
import { RuleResultStatus } from '../types/rule-result-status.enum';
import { Role } from '../services/api/users/role.enum';

const usersModule = namespace('users');

@Component
export default class ShipmentMilestoneCheckbox extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  checked!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isLoading!: boolean;

  @Prop({
    type: String,
    default: ShipmentMilestoneStatus.Confirmed,
  })
  status?: ShipmentMilestoneStatus;

  @Prop({
    type: Number,
    default: 0,
  })
  priority!: number;

  @Prop({
    type: Object,
  })
  ruleResult!: RuleResult;

  @Prop({
    type: String,
    required: true,
  })
  shipmentId!: string;

  @Prop({
    type: String,
    required: true,
  })
  milestoneId!: string;

  @Prop({
    type: String,
    default: null,
  })
  value!: string;

  @usersModule.Getter('GET_ROLE')
  userRole!: Role;

  get validationClass(): object {
    const classList = {
      new: false,
      changed: false,
      registered: false,
      conflicted: false,
      confirmed: false,
      priority1: false,
      priority2: false,
      ruleWarning: false,
      ruleCritical: false,
    };

    switch (this.status) {
    case ShipmentMilestoneStatus.Changed:
      classList.changed = true;
      break;
    case ShipmentMilestoneStatus.Registered:
      classList.registered = true;
      break;
    case ShipmentMilestoneStatus.Conflicted:
      classList.conflicted = true;
      break;
    case ShipmentMilestoneStatus.Confirmed:
      classList.confirmed = true;
      break;
    }

    switch (this.priority) {
    case 1:
      classList.priority1 = true;
      break;
    case 2:
      classList.priority2 = true;
      break;
    }

    if (!this.priority && this.ruleResult) {
      switch (this.ruleResult.status) {
      case RuleResultStatus.Warning:
        classList.ruleWarning = true;
        break;
      case RuleResultStatus.Critical:
        classList.ruleCritical = true;
        break;
      }
    }

    return classList;
  }

  @Emit()
  toggle(checked: boolean): string {
    return this.milestoneId;
  }
}
