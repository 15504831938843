export enum ReportType {
  Summary = 'SUMMARY_REPORT',
  Update = 'UPDATE_REPORT',
  LatestMilestone = 'LATEST_MILESTONE_REPORT',
  onChange = 'ON_MILESTONE_REPORT',
  Timely = 'TIMELY_REPORT',
  UpdateOverview = 'UPDATE_OVERVIEW',
}

export enum ActiveReportType {
  Timely = 'TIMELY_REPORT',
  UpdateOverview = 'UPDATE_OVERVIEW',
}

export const ReportTypeTranslations = {
  TIMELY_REPORT: 'Timely report',
  UPDATE_OVERVIEW: 'Milestone update overview',
};
