import { ToastersState, Toaster } from './types';

export const mutations = {
  ADD_TOASTER(state: ToastersState, toaster: Toaster): void {
    state.toasters = [toaster, ...state.toasters];
  },

  REMOVE_TOASTER(state: ToastersState, toaster: Toaster): void {
    state.toasters = state.toasters.filter(el => el !== toaster);
  },

  CLEAR_ALL(state: ToastersState): void {
    state.toasters = [];
  },
};
