


































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment-timezone/moment-timezone';
import LogTable from '@/components/LogTable.vue';
import importDetail from '@/views/ImportDetail.vue';
import importData from '@/views/ImportData.vue';
import { ShipmentImport } from '@/services/api/shipment-imports/shipment-import.class';
import api from '@/services/api';
import { FileResponse } from '@/services/api/files/file-response.interface';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { ImportShipmentsResponse } from '@/services/api/shipments/import-shipments-response';
import EntityList from '../components/EntityList.vue';
import { EntityListColumn } from '@/types/entity-list-column.interface';
import { ShipmentImportLog } from '../services/api/shipment-imports/shipment-import-log.class';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { SortOrder } from '@/services/api/sort-order.enum';

const shipmentsImportModule = namespace('shipmentImports');
const usersModule = namespace('users');

@Component({ components: { LogTable, importDetail, importData, EntityList } })
export default class ShipmentImportView extends Vue {
  @usersModule.Getter('GET_DATE_FORMAT')
  dateFormat!: string;

  @shipmentsImportModule.Action('FETCH_ONE')
  getShipmentImportById!: (id: string) => Promise<ShipmentImport>;

  @shipmentsImportModule.Getter('LOGS')
  importLogs!: PaginatedResponseClass<ShipmentImportLog>;

  @shipmentsImportModule.Action('FETCH_SHIPMENT_IMPORT_LOGS')
  fetchImportLogs!: (props: {
    id: string;
    filter?: FetchAllParams;
  }) => Promise<PaginatedResponseClass<ShipmentImportLog>>;

  shipmentImport: ShipmentImport = new ShipmentImport();

  filter: FetchAllParams = {};

  counter = 0;

  columns: Array<EntityListColumn<ShipmentImportLog>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: 'status',
      label: 'Status',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: 'success',
      label: 'Success',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: 'failed',
      label: 'Failed',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: 'totalRecords',
      label: 'Total Records',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: 'statusMessage',
      label: 'Message',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  formatDate(date: Date): string {
    return moment(date).format(`${this.dateFormat} hh:mm`);
  }

  getStatusLabel(item: string): string {
    const statusClass = this.$t(`status.${item}.class`) as string;
    const statusText = this.$t(`status.${item}`) as string;

    return `<p class="badge badge-${statusClass} badge-pill ml-1 mt-2">${statusText}</p>`;
  }

  async getDownloadFile(fileUrl: string, filename: string): Promise<void> {
    const file = await api.files.getFile(fileUrl);
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  }

  downloadFile(item: ShipmentImport): Promise<void> | undefined {
    if (!item.file) {
      return;
    }
    return this.getDownloadFile(item.file.url, item.file.name.original);
  }

  downloadAttachment(file: FileResponse): Promise<void> {
    return this.getDownloadFile(file.url, file.name.original);
  }

  refreshLogs(importResult: ImportShipmentsResponse): void {
    this.counter = importResult.failed;
  }

  handleFinishedImport(importResult: ImportShipmentsResponse): void {
    this.shipmentImport.status = importResult.status;
  }

  handleFilterChange(): void {
    this.fetchImportLogs({ id: this.$route.params.id, filter: this.filter });
  }

  generateLogFilter(): void {
    if (this.$route.params.id) {
      this.filter = {
        sort: [`createdAt,${SortOrder.Desc}`],
        limit: 20,
      };
    }
  }

  async created(): Promise<void> {
    this.generateLogFilter();
    if (this.$route.params.id) {
      this.shipmentImport = await this.getShipmentImportById(
        this.$route.params.id,
      );
      await this.fetchImportLogs({
        id: this.$route.params.id,
        filter: this.filter,
      });
    }
  }
}
