































































































































































































































/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import FieldSelect from '../components/FieldSelect.vue';
import MilestoneSelect from '../components/MilestoneSelect.vue';
import ShipmentTypeSelect from '../components/ShipmentTypeSelect.vue';
import LogTable from '../components/LogTable.vue';
import flatPickr from 'vue-flatpickr-component';
import { Rule } from '../services/api/rules/rule.class';
import { CompareTwoMilestonesOperation } from '../types/compare-two-milestones-operation.enum';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { Milestone } from '../services/api/milestones/milestone.class';
import { Office } from '../services/api/offices/office.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { FilterOperation } from '../types/filter-operation.enum';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import { RuleType } from '@/services/api/rules/rule-type.enum';
import { ShipmentType } from '@/services/api/shipment-types/shipment-type.class';

const rulesModule = namespace('rules');
const milestonesModule = namespace('milestones');
const usersModule = namespace('users');

@Component({
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    flatPickr,
    FieldSelect,
    MilestoneSelect,
    ShipmentTypeSelect,
    LogTable,
  },
})
export default class RuleDetail extends Vue {
  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @milestonesModule.Getter('ALL_BY_SEQUENCE')
  milestones!: MilestoneResponse[];

  @rulesModule.Getter('GET')
  rule!: Rule;

  @milestonesModule.Action('FETCH_ALL')
  fetchMilestones!: () => Promise<void>;

  @rulesModule.Action('CREATE')
  createRule!: (rule: any) => any;

  @rulesModule.Action('UPDATE')
  updateRule!: (payload: { id: string; rule: any }) => Promise<void>;

  @rulesModule.Action('FETCH_ONE')
  fetchRule!: (id: string) => Promise<void>;

  resultStatusOptions = [
    {
      value: 1,
      text: 'Warning',
    },
    {
      value: 2,
      text: 'Critical',
    },
  ];

  operatorOptions: any = [];
  typeOptions = [
    {
      value: 'CHRONOLOGIC_OPERATIONAL_MILESTONES',
      text: 'Chronologic Operational Milestones',
      params: { shipmentType: { ...new ShipmentType(), _id: '' } },
    },
    {
      value: 'COMPARE_TWO_MILESTONES',
      text: 'Compare Two Milestones',
      params: {
        milestone1: null,
        operation: 'BEFORE',
        milestone2: null,
        ifMilestone1IsEmpty: false,
        params: { shipmentType: { ...new ShipmentType(), _id: '' } },
      },
    },
    {
      value: 'MILESTONE_REQUIRED',
      text: 'Milestone Required',
      params: {
        milestone: null,
        milestones: [],
        shipmentType: { ...new ShipmentType(), _id: '' },
      },
    },
    {
      value: 'TIME_BASED_COMPARE',
      text: 'Time Based Compare',
      params: {
        milestone1: null,
        milestone2: null,
        operation: 'BEFORE',
        duration: { day: 1, hour: 0 },
        shipmentType: { ...new ShipmentType(), _id: '' },
      },
    },
    {
      value: 'COMPARE_NOW',
      text: 'Compare Now',
      params: {
        milestone1: null,
        milestone2: null,
        shipmentType: { ...new ShipmentType(), _id: '' },
      },
    },
  ];

  form: Rule = {
    ...new Rule(),
    params: { shipmentType: { ...new ShipmentType(), _id: '' } },
  };

  logFilter?: FetchAllParams = {};
  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  @Watch('form.type')
  onFormTypeChange(newType: RuleType): void {
    if (this.rule.params === undefined) {
      return;
    }
    if (this.form.type === this.rule.type) {
      this.form.params = this.rule.params;
    } else {
      const findResult = this.typeOptions.find((x: any) => x.value === newType);
      if (findResult) {
        this.form.params = findResult.params;
      }
    }
  }

  transformChronologic(): Rule {
    return {
      ...this.form,
      params: {
        ...this.form.params,
        shipmentType: this.form.params
          ? this.form.params.shipmentType
          : undefined,
      },
    };
  }

  transformCompareTwo(): Rule {
    return {
      ...this.form,
      params: {
        ...this.form.params,
        milestone1: this.form.params
          ? this.form.params.milestone1._id
          : undefined,
        operation: this.form.params ? this.form.params.operation : undefined,
        milestone2: this.form.params
          ? this.form.params.milestone2._id
          : undefined,
        ifMilestone1IsEmpty: false,
      },
    };
  }
  transformMileStoneRequired(): Rule {
    return {
      ...this.form,
      params: {
        ...this.form.params,
        milestone: this.form.params
          ? this.form.params.milestone._id
          : undefined,
        milestones: [
          ...(this.form.params
            ? this.form.params.milestones.map((item: Milestone) => item._id)
            : undefined),
        ],
      },
    };
  }

  transformTimeBasedCompare(): Rule {
    return {
      ...this.form,
      params: {
        ...this.form.params,
        milestone1: this.form.params
          ? this.form.params.milestone1._id
          : undefined,
        milestone2: this.form.params
          ? this.form.params.milestone2._id
          : undefined,
        operation: this.form.params ? this.form.params.operation : undefined,
        duration: {
          day: this.form.params ? this.form.params.duration.day : undefined,
          hour: this.form.params ? this.form.params.duration.hour : undefined,
        },
        mustHavePartialShipments: this.form.params
          ? this.form.params.mustHavePartialShipments
          : undefined,
      },
    };
  }

  transformCompareNow(): Rule {
    return {
      ...this.form,
      params: {
        ...this.form.params,
        milestone1: this.form.params
          ? this.form.params.milestone1._id
          : undefined,
        milestone2: this.form.params
          ? this.form.params.milestone2._id
          : undefined,
      },
    };
  }

  fillMilestone(): void {
    if (this.form.params) {
      this.form.params.milestone =
        this.findMilestone(this.form.params.milestone) || undefined;
      this.form.params.milestones = this.form.params.milestones.map(
        (x: string) => this.findMilestone(x) || {},
      );
    }
  }

  fillTwoMilestones(): void {
    if (this.form.params) {
      this.form.params.milestone1 =
        this.findMilestone(this.form.params.milestone1) || {};
      this.form.params.milestone2 =
        this.findMilestone(this.form.params.milestone2) || {};
    }
  }

  prepForm(): void {
    switch (this.form.type) {
    case 'CHRONOLOGIC_OPERATIONAL_MILESTONES':
      break;
    case 'COMPARE_TWO_MILESTONES':
      this.fillTwoMilestones();
      break;
    case 'MILESTONE_REQUIRED':
      this.fillMilestone();
      break;
    case 'TIME_BASED_COMPARE':
      this.fillTwoMilestones();
      break;
    case 'COMPARE_NOW':
      this.fillTwoMilestones();
      break;
    }
  }

  submitTransform(): Rule | undefined {
    switch (this.form.type) {
    case 'CHRONOLOGIC_OPERATIONAL_MILESTONES':
      return this.transformChronologic();
      break;
    case 'COMPARE_TWO_MILESTONES':
      return this.transformCompareTwo();
      break;
    case 'MILESTONE_REQUIRED':
      return this.transformMileStoneRequired();
      break;
    case 'TIME_BASED_COMPARE':
      return this.transformTimeBasedCompare();
      break;
    case 'COMPARE_NOW':
      return this.transformCompareNow();
      break;
    }
  }

  onSubmit(): void {
    this.$validator.validate().then((result: boolean) => {
      if (result) {
        this.save();
      }
    });
  }

  async save(): Promise<Rule | void> {
    let rule: any;
    switch (this.form.type) {
    case 'CHRONOLOGIC_OPERATIONAL_MILESTONES':
      rule = this.transformChronologic();
      break;
    case 'COMPARE_TWO_MILESTONES':
      rule = this.transformCompareTwo();
      break;
    case 'MILESTONE_REQUIRED':
      rule = this.transformMileStoneRequired();
      break;
    case 'TIME_BASED_COMPARE':
      rule = this.transformTimeBasedCompare();
      break;
    case 'COMPARE_NOW':
      rule = this.transformCompareNow();
      break;
    }

    if (!this.form.office || !this.rule.office) {
      rule.office = this.office._id;
      this.form.office = this.office._id;
    }
    if (this.form._id) {
      await this.updateRule({ id: this.form._id, rule });
      this.generateLogFilter(this.form._id);
    } else {
      const ruleRes = await this.createRule(rule);
      if (ruleRes && ruleRes._id) {
        this.generateLogFilter(ruleRes._id);
        await this.$router.replace({
          name: 'Rule',
          params: { id: ruleRes._id },
        });
      }
    }
  }

  generateOperationOptions(): void {
    this.operatorOptions = Object.values(CompareTwoMilestonesOperation);
  }

  generateLogFilter(id: string): void {
    this.logFilter = {
      sort: ['createdAt,desc'],
      filters: {
        object: {
          value: id,
          operation: FilterOperation.Equals,
        },
      },
    };
  }

  findMilestone(id?: string): Milestone | void {
    return this.milestones.find((ms: Milestone) => id === ms._id);
  }

  async created(): Promise<void> {
    this.generateLogFilter(this.$route.params.id);
    await this.fetchMilestones();
    this.generateOperationOptions();
    if (this.$route.params.id) {
      await this.fetchRule(this.$route.params.id);
      this.form = { ...this.rule };
      this.prepForm();
    } else {
      this.form = {
        ...new Rule(),
        params: { shipmentType: { ...new ShipmentType(), _id: '' } },
      };
    }
  }
}
