import Vue from 'vue';
import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { ImportShipmentsResponse } from '@/services/api/shipments/import-shipments-response';
import { Filters } from '@/types/filters.interface';
import { ShipmentsState } from './types';
import { UIFilter } from '@/types/ui/ui-filter.interface';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<ShipmentsState> = {
  SET(state: ShipmentsState, payload: ShipmentResponse[]) {
    state.shipments = payload;
  },
  ADD_SHIPMENTS(state, payload: ShipmentResponse[]) {
    state.shipments = state.shipments.concat(payload);
  },
  SET_SELECTED(state, payload: string) {
    state.selected = payload;
  },
  SET_ACTIVE(state, shipmentId: string | null) {
    state.activeShipment = shipmentId;
  },
  REMOVE_SHIPMENT(state, shipmentIndex: number) {
    state.shipments.splice(shipmentIndex, 1);
  },
  REMOVE_SHIPMENT_BY_ID(state, shipmentId: string) {
    const shipmentIndex: number = state.shipments.findIndex(
      (shipment: ShipmentResponse) => shipment._id === shipmentId,
    );

    if (shipmentIndex > -1) {
      mutations.REMOVE_SHIPMENT(state, shipmentIndex);
    }
  },
  INCREMENT_INFINITE_LOADING_IDENTIFIER(state) {
    ++state.infiniteLoadingIdentifier;
    state.shipments = [];
  },
  UPDATE_SHIPMENT(
    state,
    payload: {
      shipmentIndex: number;
      shipment: ShipmentResponse;
    },
  ): void {
    state.shipments.splice(payload.shipmentIndex, 1, payload.shipment);
  },
  SET_FILTERS(state, payload: Filters) {
    state.apiParams = {
      ...state.apiParams,
      filters: {
        ...payload,
      },
    };
  },
  SET_PAGE(state, page: number) {
    state.apiParams.page = page;
  },
  SET_SORT(state, payload: string[]) {
    state.apiParams = {
      ...state.apiParams,
      sort: payload,
    };
  },
  SET_IMPORT(state, payload: ImportShipmentsResponse) {
    state.import.result = payload;
  },
  SET_IMPORT_RESULT(state, payload: ImportShipmentsResponse) {
    state.import.result = payload;
  },
  INCREASE_NEW_SHIPMENTS(state) {
    state.newShipments++;
  },
  RESET_NEW_SHIPMENTS(state) {
    state.newShipments = 0;
  },
  SET_ONE(state, payload: ShipmentResponse) {
    const shipmentIndex: number = state.shipments.findIndex(
      (shipment: ShipmentResponse) => shipment._id === payload._id,
    );
    if (shipmentIndex > -1) {
      state.shipments.splice(shipmentIndex, 1, payload);
    } else {
      state.shipments = [...state.shipments, payload];
    }
  },
  SET_LOADSTATE(state, key: string) {
    Vue.set(state.loadingDetail, key, true);
  },
  UNSET_LOADSTATE(state, key: string) {
    Vue.set(state.loadingDetail, key, false);
  },
  SET_PER_MILESTONE(state, payload: number[]) {
    state.perMilestone = payload;
  },
  SET_COUNT(state, payload: number) {
    state.count = payload;
  },
  SET_BREACHED_JOBS(state, payload: number) {
    state.breachedJobs = payload;
  },
  SET_CURRENT_UI_BASE_FILTERS(state, baseFilters: UIFilter[]) {
    state.currentUiBaseFilters = baseFilters;
  },
};
