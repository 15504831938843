import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';
import { Attachment } from '@/services/api/attachments/attachment.class';
import store from '@/store';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/modules/types';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { FieldSelectDataType } from '../../types/field-select-data-type.enum';

interface UtilityState {
  fieldSelectOptions: Option[];
  initialFieldSelectOptions: Option[];
}

const namespaced = true;

const globalState: UtilityState = {
  fieldSelectOptions: [],
  initialFieldSelectOptions: [
    {
      value: 'archived',
      text: 'archived',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'chargeableWeight',
      text: 'chargeableWeight',
      required: false,
      dataType: FieldSelectDataType.TypeNumber,
    },
    {
      value: 'cmr',
      text: 'cmr',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'containerNumber',
      text: 'containerNumber',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'containerType',
      text: 'containerType',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.sono',
      text: 'custom.sono',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'officeObj.name',
      text: 'custom.officeCode',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.vesselId',
      text: 'custom.vesselId',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.loadTerm',
      text: 'custom.loadTerm',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.carrierCode',
      text: 'custom.carrierCode',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.bkca',
      text: 'custom.bkca',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'americanManifestSystem',
      text: 'americanManifestSystem',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'dangerousGoods',
      text: 'dangerousGoods',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'toBeExported',
      text: 'toBeExported',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.bkcb',
      text: 'custom.bkcb',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.consignee',
      text: 'custom.consignee',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.customer',
      text: 'custom.customer',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.realConsignee',
      text: 'custom.realConsignee',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.dfts',
      text: 'custom.dfts',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.doc',
      text: 'custom.doc',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.docs',
      text: 'custom.docs',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.cs',
      text: 'custom.cs',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.ecs',
      text: 'custom.ecs',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.firstVesselName',
      text: 'custom.firstVesselName',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.firstVesselVoyage',
      text: 'custom.firstVesselVoyage',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.fwb',
      text: 'custom.fwb',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.isDraft',
      text: 'custom.isDraft',
      required: true,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.loadingLocation',
      text: 'custom.loadingLocation',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.loadingRef',
      text: 'custom.loadingRef',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.description',
      text: 'custom.description',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.pickupHours',
      text: 'custom.pickupHours',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.operatorid',
      text: 'custom.operatorid',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.ownerId',
      text: 'custom.ownerId',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.pic',
      text: 'custom.pic',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.poa',
      text: 'custom.poa',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.preAlert',
      text: 'custom.preAlert',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.secondVesselName',
      text: 'custom.secondVesselName',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.secondVesselVoyage',
      text: 'custom.secondVesselVoyage',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.sellingProduct',
      text: 'custom.sellingProduct',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.terminal',
      text: 'custom.terminal',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.shipper',
      text: 'custom.shipper',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.shipperRef',
      text: 'custom.shipperRef',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.shipperCity',
      text: 'custom.shipperCity',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.time',
      text: 'custom.time',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.customRef',
      text: 'custom.customRef',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.verifiedGrossMass1',
      text: 'custom.verifiedGrossMass1',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.customsDocuments',
      text: 'custom.customsDocuments',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.verifiedGrossMass2',
      text: 'custom.verifiedGrossMass2',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.truckerDel',
      text: 'custom.truckerDel',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.truckerPU',
      text: 'custom.truckerPU',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.thirdVesselName',
      text: 'custom.thirdVesselName',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.cost',
      text: 'custom.cost',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.revenue',
      text: 'custom.revenue',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.dutiesAndVAT',
      text: 'custom.dutiesAndVAT',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.docChecked',
      text: 'custom.docChecked',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.blConfirmed',
      text: 'custom.blConfirmed',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'custom.ticketNumber',
      text: 'custom.ticketNumber',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.serialNumber',
      text: 'custom.serialNumber',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.thirdVesselVoyage',
      text: 'custom.thirdVesselVoyage',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.unid',
      text: 'custom.unid',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.wareHouseInstruction',
      text: 'custom.wareHouseInstruction',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.wm',
      text: 'custom.wm',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'customer',
      text: 'customer',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'trackingNumbers',
      text: 'trackingNumbers',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.address.street',
      text: 'customer.delivery.address.street',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.address.city',
      text: 'customer.delivery.address.city',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.address.postalCode',
      text: 'customer.delivery.address.postalCode',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.address.country',
      text: 'customer.delivery.address.country',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.contact.name',
      text: 'customer.delivery.contact.name',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.contact.email',
      text: 'customer.delivery.contact.email',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.contact.phone.office',
      text: 'customer.delivery.contact.phone.office',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.contact.phone.mobile',
      text: 'customer.delivery.contact.phone.mobile',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.opening.days',
      text: 'customer.delivery.opening.days',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.opening.hours',
      text: 'customer.delivery.opening.hours',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.appointment',
      text: 'customer.delivery.appointment',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.customsHandling',
      text: 'customer.delivery.customsHandling',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.delivery.sortInstruction',
      text: 'customer.delivery.sortInstruction',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customer.parent.name',
      text: 'customer.parent.name',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'completed',
      text: 'completed',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'deleted',
      text: 'Deleted',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'flight.destination',
      text: 'flight.destination',
      required: true,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.destinationCountry',
      text: 'flight.destinationCountry',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.destinationRegion',
      text: 'flight.destinationRegion',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.voyage',
      text: 'flight.voyage',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.name',
      text: 'flight.name',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.origin',
      text: 'flight.origin',
      required: true,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'flight.originCountry',
      text: 'flight.originCountry',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'freightTerm',
      text: 'freightTerm',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'grossWeight',
      text: 'grossWeight',
      required: false,
      dataType: FieldSelectDataType.TypeNumber,
    },
    {
      value: 'hawb',
      text: 'hawb',
      required: true,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'mawb',
      text: 'mawb',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'partialShipments',
      text: 'partialShipments',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'pieces',
      text: 'pieces',
      required: false,
      dataType: FieldSelectDataType.TypeNumber,
    },
    {
      value: 'priorDocument',
      text: 'priorDocument',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'invoiceNumber',
      text: 'invoiceNumber',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'ref',
      text: 'ref',
      required: true,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'remarks',
      text: 'remarks',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'service.level',
      text: 'service.level',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'typeName',
      text: 'typeName',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'service.type',
      text: 'service.type',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'sku',
      text: 'sku',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'sortedPallets',
      text: 'sortedPallets',
      required: false,
      dataType: FieldSelectDataType.TypeNumber,
    },
    {
      value: 'type',
      text: 'Shipment type',
      type: 'shipmentType',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'updatedAt',
      text: 'updatedAt',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'voyage',
      text: 'voyage',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'volume',
      text: 'volume',
      required: false,
      dataType: FieldSelectDataType.TypeNumber,
    },
    {
      value: 'partialShipmentsPending',
      text: 'partialShipmentsPending',
      required: false,
      dataType: FieldSelectDataType.TypeBoolean,
    },
    {
      value: 'customField1',
      text: 'customField1',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customField2',
      text: 'customField2',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customField3',
      text: 'customField3',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customerServiceReference1',
      text: 'customerServiceReference1',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customerServiceReference2',
      text: 'customerServiceReference2',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customerServiceReference3',
      text: 'customerServiceReference3',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'customerServiceReference4',
      text: 'customerServiceReference4',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.workSite',
      text: 'custom.workSite',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.supplierCountry',
      text: 'custom.supplierCountry',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.consumerCountry',
      text: 'custom.consumerCountry',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'custom.externalEDIReference',
      text: 'custom.externalEDIReference',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'trackingError',
      text: 'trackingError',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
    {
      value: 'delayReasons',
      text: 'delayReasons',
      required: false,
      dataType: FieldSelectDataType.TypeString,
    },
  ],
};
const getters: GetterTree<UtilityState, RootState> = {
  GET_FIELD_SELECT_OPTIONS: state => state.fieldSelectOptions,
  GET_INITIAL_FIELD_SELECT_OPTIONS: state => state.initialFieldSelectOptions,
};
const mutations: MutationTree<UtilityState> = {
  SET_FIELD_SELECT_OPTIONS(state, payload: Option[]) {
    state.fieldSelectOptions = payload;
  },
};
const actions: ActionTree<UtilityState, RootState> = {
  async FETCH_FIELD_SELECT_OPTIONS(
    context,
    fetchOptions: {
      includeAttachments: boolean;
      includeMilestones: boolean;
      includeFilterOptions: boolean;
    },
  ) {
    let options = [
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ...(context.getters.GET_INITIAL_FIELD_SELECT_OPTIONS as Option[]),
    ];
    if (fetchOptions.includeMilestones) {
      await context.dispatch(
        'milestones/FETCH_ALL',
        { limit: 999 },
        { root: true },
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const milestones = context.rootGetters[
        'milestones/ALL_BY_SEQUENCE'
      ] as MilestoneResponse[];

      options = options.concat(
        milestones.map((x: MilestoneResponse) => ({
          value: `shipmentMilestones.${x._id}.value`,
          text: 'Milestone: ' + x.code,
          type: 'milestone',
          dataType: FieldSelectDataType.TypeString,
          required: false,
        })),
      );
    }

    if (fetchOptions.includeAttachments) {
      await store.dispatch('attachments/FETCH_ALL');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const Attachments = store.getters[
        'attachments/ALL_PAGINATED'
      ] as PaginatedResponse<Attachment>;

      options = options.concat(
        Attachments.docs.map((x: Attachment) => ({
          value: `attachments.${x._id || ''}`,
          text: `Attachment: ${x.name || ''}`,
        })),
      );
    }
    if (fetchOptions.includeFilterOptions) {
      options = options.concat({
        value: 'ruleOrPriority',
        text: 'Priority or RuleBreach',
        dataType: FieldSelectDataType.TypeBoolean,
      });
    }
    context.commit('SET_FIELD_SELECT_OPTIONS', options);
  },
};
export default {
  namespaced,
  state: globalState,
  getters,
  mutations,
  actions,
};
