import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import LogsService from '@/services/api/logs/logs.service';
import { Log } from '@/services/api/logs/log.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { Filter } from '@/types/filter.interface';
import env from '../../../env.config';

const logsService = new LogsService();
const namespaced = true;

interface StoreState {
  one: Log;
  all: PaginatedResponse<Log>;
  apiParams: FetchAllParams;
}

const initialLogsState: StoreState = {
  one: new Log(),
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<StoreState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<StoreState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<Log>) {
    state.all = payload;
  },
  SET_ONE(state, payload: Log) {
    state.one = payload;
  },
  CLEAR(state) {
    state.all = new PaginatedResponseClass();
  },
};
const actions: ActionTree<StoreState, RootState> = {
  async CREATE(context, payload: Log) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const log = (await logsService.create(payload)) as Log;
      context.commit('SET_ONE', log);
      return log;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const log = (await logsService.fetchOne(id)) as Log;
      context.commit('SET_ONE', log);
      return log;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const logs =
        env.ENV !== 'TEST'
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          await logsService.fetch(context.getters.API_PARAMS)
          : [];
      context.commit('SET_ALL', logs);
      return logs;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; log: Log }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const log = (await logsService.update(payload.id, payload.log)) as Log;
      context.commit('SET_ONE', log);
      return log;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string): Promise<Log> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const log = (await logsService.delete(payload)) as Log;
      context.commit('SET_ONE', log);
      return log;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced,
  state: initialLogsState,
  actions,
  getters,
  mutations,
};
