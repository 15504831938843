import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store/';
import '@/plugins/bootstrap-vue';
import '@/plugins/font-awesome';
import '@/plugins/casl';
import '@/plugins/truncate';
import '@/plugins/vee-validate';
import '@/plugins/vue-inline-svg';
import VueI18n from 'vue-i18n';
import VueClipboard from 'vue-clipboard2';
import InfiniteLoading from 'vue-infinite-loading';

import { defaultLocale } from './i18n/index';
import { languages } from './i18n/index';
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const messages = Object.assign(languages);

Vue.config.productionTip = false;
VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);
Vue.use(VueI18n);

Vue.use(InfiniteLoading, {
  system: {
    throttleLimit: 200,
  },
});

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  messages,
  silentTranslationWarn: true,
});

new Vue({
  i18n,
  router,
  store,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  render: (h: any) => h(App),
}).$mount('#app');
