






























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import appNavBarUserMenu from '@/components/TheNavBarUserMenu.vue';
import appNavBarViewPicker from '@/components/TheNavBarViewPicker.vue';
import { defineAbilitiesFor } from '../define-abilities-for';
import env from '../../env.config';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { Role } from '../services/api/users/role.enum';
import { Office } from '../services/api/offices/office.class';
import { FileResponse } from '../services/api/files/file-response.interface';
import { Ui } from '@/services/api/ui/ui.class';
import { Milestone } from '@/services/api/milestones/milestone.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { IList } from '../services/api/ui/ui-list.interface';
import { TestIds } from '@/types/test-ids.enum';
import adminListConditionDataSets from '../utils/adminListConditionDataSets';

const usersModule = namespace('users');
const shipmentsModule = namespace('shipments');
const uiModule = namespace('ui');
const milestonesModule = namespace('milestones');

@Component({
  components: {
    appNavBarUserMenu,
    appNavBarViewPicker,
  },
})
export default class NavBar extends Vue {
  @uiModule.Getter('ALL')
  uis!: Ui[];

  @uiModule.Action('FETCH_UNPAGINATED')
  fetchUis!: (filter?: FetchAllParams) => Promise<Ui[]>;

  @milestonesModule.Getter('ALL_BY_SEQUENCE')
  milestones!: MilestoneResponse[];

  @usersModule.Getter('GET_ID')
  userId!: string;

  @usersModule.Getter('GET_ROLE')
  userRole!: Role;

  @usersModule.Getter('GET_LOGGED_IN')
  loggedIn!: boolean;

  @usersModule.Getter('GET_FIRST_NAME')
  firstName!: string;

  @usersModule.Getter('GET_LAST_NAME')
  lastName!: string;

  @usersModule.Getter('GET_IS_ADMIN')
  adminStatus!: boolean;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @shipmentsModule.Getter('NEW_SHIPMENTS')
  newShipments!: number;

  @shipmentsModule.Action('RELOAD')
  reloadShipments!: any;

  @usersModule.Action('LOGOUT')
  logout!: any;

  @milestonesModule.Action('FETCH_ALL')
  fetchMilestones!: (filter?: FetchAllParams) => Promise<Milestone[]>;

  @shipmentsModule.Action('SET_ACTIVE')
  setActiveShipment!: (shipmentId: string | null) => void;

  application: string = env.APP;

  TestIds: { [key: string]: number | string } = TestIds;

  @Watch('userRole')
  async updateAbilities(): Promise<void> {
    if (this.userRole) {
      await this.fetchUis();
      this.$ability.update(
        defineAbilitiesFor(
          this.userId,
          this.userRole,
          this.milestones.map((milestone: MilestoneResponse) => milestone._id),
          this.adminStatus,
        ).rules,
      );
    }
  }

  get uiList(): any[] {
    const list: any[] = [...this.uis];

    if (this.$can('read', 'Customer')) {
      list.push({
        name: 'Customers',
        icon: 'user',
        path: '/customers',
      });
    }

    if (this.$can('read', 'Report')) {
      list.push({
        name: 'Reports',
        icon: 'file-excel',
        path: '/reports',
        testId: `${TestIds.VIEWPICKER_REPORT_ITEM}`,
      });
    }

    if (this.$can('read', 'Statistics')) {
      list.push({
        name: 'Statistics',
        icon: 'chart-bar',
        path: '/statistics',
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return list;
  }

  get adminList(): IList[] {
    const list: IList[] = [];

    adminListConditionDataSets.forEach(conditionSet => {
      const { action, subject, data } = conditionSet;
      if (this.$can(action, subject)) {
        list.push(data);
      }
    });

    return list;
  }

  get canImport(): boolean {
    return (
      this.$can('create', 'Shipment') || this.$can('create', 'ShipmentImport')
    );
  }

  get styling(): string {
    return 'oakfields';
  }

  get logo(): string {
    if (this.office.company.logo) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      return (this.office.company.logo as FileResponse).url;
    }
    return '/assets/img/mileviewer.svg';
  }

  switchEnvironment(): void {
    this.$router.push({ name: 'environments' });
  }

  async created(): Promise<void> {
    if (this.userRole) {
      await this.fetchMilestones({ limit: 50 });
      this.$ability.update(
        defineAbilitiesFor(
          this.userId,
          this.userRole,
          this.milestones.map((milestone: MilestoneResponse) => milestone._id),
          this.adminStatus,
        ).rules,
      );
    }
  }
}
