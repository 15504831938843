import { TokenUserInfo } from '@/types/token-user-info.interface';
import axios from '@/axios';
import { LoginResponse } from './login-response.interface';
import { ResetPassword } from './reset-password.interface';
import { ResetPasswordRequest } from './reset-password-request.interface';

export default {
  async requestResetTokenUserName(
    resetPasswordToken: string,
  ): Promise<TokenUserInfo> {
    const response = await axios.get<TokenUserInfo>(
      `/auth/reset-password/${resetPasswordToken}`,
    );
    return response.data;
  },
  async login(username: string, password: string): Promise<LoginResponse> {
    const response = await axios.post<LoginResponse>('/auth/login', {
      username,
      password,
    });
    return response.data;
  },
  async selectOffice(id: string): Promise<LoginResponse> {
    const response = await axios.post<LoginResponse>('/auth/select-office', {
      id,
    });
    return response.data;
  },

  async requestResetPassword(
    resetRequest: ResetPasswordRequest,
  ): Promise<boolean> {
    const response = await axios.post<boolean>('/auth/reset-password', {
      ...resetRequest,
    });
    return response.data;
  },

  async requestResetPasswordAuthenticated(
    resetRequest: ResetPasswordRequest,
  ): Promise<boolean> {
    const response = await axios.post<boolean>('/auth/reset-password-auth', {
      ...resetRequest,
    });
    return response.data;
  },

  async resetPassword(resetPassword: ResetPassword): Promise<boolean> {
    const response = await axios.post<boolean>(
      `/auth/reset-password/${resetPassword.token}`,
      {
        password: resetPassword.password,
        confirmPassword: resetPassword.confirmPassword,
      },
    );
    return response.data;
  },
};
