import { render, staticRenderFns } from "./NavTabs.vue?vue&type=template&id=3d178bdf&scoped=true&"
import script from "./NavTabs.vue?vue&type=script&lang=ts&"
export * from "./NavTabs.vue?vue&type=script&lang=ts&"
import style0 from "./NavTabs.vue?vue&type=style&index=0&id=3d178bdf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d178bdf",
  null
  
)

export default component.exports