import { ReportCondition } from '../../../services/api/reports/report-condition.class';

const checkSelectedRangeValues = (dateRange: ReportCondition): boolean => {
  const hasFromDateValue = dateRange.data.dateStart;
  const hasToDateValue = dateRange.data.dateEnd;

  const isBothSelected = !!(hasFromDateValue && hasToDateValue);

  return isBothSelected;
};

export default checkSelectedRangeValues;
