// disabling because of the access to the store getters
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import Vue from 'vue';
import Router, { Route, RouteRecord } from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';
import AllCustomers from '@/views/AllCustomers.vue';
import Customer from '@/views/Customer.vue';
import CustomerDetail from '@/components/CustomerDetail.vue';
import CustomerMilestoneList from '@/components/CustomerMilestoneList.vue';
import ImportData from '@/views/ImportData.vue';
import ImportDetail from '@/views/ImportDetail.vue';
import AllUsers from '@/views/AllUsers.vue';
import User from '@/views/User.vue';
import Report from '@/views/Reports/Report.vue';
import AllReports from '@/views/AllReports.vue';
import ShipmentType from '@/views/ShipmentType.vue';
import AllShipmentTypes from '@/views/AllShipmentTypes.vue';
import Milestone from '@/views/Milestone.vue';
import AllMilestones from '@/views/AllMilestones.vue';
import AllLogs from '@/views/AllLogs.vue';
import Ui from '@/views/Ui.vue';
import AllUis from '@/views/AllUis.vue';
import { Role } from './services/api/users/role.enum';
import AllShipmentImports from '@/views/AllShipmentImports.vue';
import AllOffices from '@/views/AllOffices.vue';
import AllCompanies from '@/views/AllCompanies.vue';
import Company from '@/views/Company.vue';
import CompanyGeneral from '@/views/CompanyGeneral.vue';
import CompanyTranslations from '@/views/CompanyTranslations.vue';
import Office from '@/views/Office.vue';
import OfficeGeneral from '@/views/OfficeGeneral.vue';
import OfficeImports from '@/views/OfficeImports.vue';
import OfficeDuplicationDetail from '@/views/OfficeDuplicationDetail.vue';
import Environments from '@/views/Environments.vue';
import MilestoneType from '@/views/MilestoneType.vue';
import AllMilestoneTypes from '@/views/AllMilestoneTypes.vue';
import Rule from '@/views/Rule.vue';
import AllRules from '@/views/AllRules.vue';
import VerifiedEmailDetail from '@/views/VerifiedEmailDetail.vue';
import AllVerifiedEmails from '@/views/AllVerifiedEmails.vue';
import VerificationLanding from '@/views/VerificationLanding.vue';
import Statistics from '@/views/Statistics.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import Attachments from '@/views/Attachments.vue';
import ShipmentImport from '@/views/ShipmentImport.vue';
import AllOfficeDuplications from '@/views/AllOfficeDuplications.vue';
import AllIntegrationLogs from '@/views/AllIntegrationLogs.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import { ToasterTypes } from './store/modules/toasters/types';
import { VueRouter } from 'vue-router/types/router';
import { UserResponse } from './services/api/users/user-response.interface';
import PasswordResetRequest from '@/views/PasswordResetRequest.vue';

Vue.use(Router);

interface Meta {
  requiredAdmin?: boolean;
  requiresAuth: boolean;
  requiresRoles: Role[];
  savable?: boolean;
  errorMessage?: string;
  fallBack: string;
}

interface RouteRecordWithMeta extends RouteRecord {
  meta: Meta;
}

interface RouteWithMeta extends Route {
  meta: Meta;
}

export const createRouter = (): VueRouter => {
  const createdRouter = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        name: 'index',
        path: '/',
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem('local_base_url')) {
            next({ name: 'environments' });
          } else if (!store.getters['ui/ALL'].length) {
            store
              .dispatch('ui/FETCH_UNPAGINATED', store.getters['ui/API_PARAMS'])
              .then(() => next({ path: '/shipments' }));
          } else {
            next({ path: '/shipments' });
          }
        },
      },
      {
        name: 'login',
        path: '/login',
        component: Login,
      },
      {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset,
        children: [
          {
            path: ':token',
            component: PasswordReset,
          },
        ],
      },
      {
        path: '/password-reset-request',
        name: 'PasswordResetRequest',
        component: PasswordResetRequest,
      },
      {
        name: 'environments',
        path: '/environments',
        component: Environments,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'importData',
        path: '/import-data',
        component: ImportData,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'importDetail',
        path: '/import/:id',
        component: ImportDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/customers',
        name: 'Customers',
        component: AllCustomers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/customers/create',
        component: Customer,
        meta: {
          requiresAuth: true,
          savable: true,
        },
        children: [
          {
            path: '',
            name: 'createCustomer',
            component: CustomerDetail,
            meta: { savable: true },
          },
        ],
      },
      {
        path: '/customers/:customerId',
        component: Customer,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'customer',
            component: CustomerDetail,
            meta: { savable: true },
          },
          {
            path: 'milestone-notifications',
            component: CustomerMilestoneList,
            name: 'customerMilestoneNotifications',
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
              savable: true,
            },
          },
        ],
      },
      {
        path: '/users',
        name: 'allUsers',
        component: AllUsers,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/users/create',
        name: 'CreateUser',
        component: User,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/users/:id',
        name: 'User',
        component: User,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/shipment-types/create',
        name: 'CreateShipmentType',
        component: ShipmentType,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/offices',
        name: 'allOffices',
        component: AllOffices,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/offices/create',
        component: Office,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
        children: [
          {
            path: '',
            name: 'CreateOffice',
            component: OfficeGeneral,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin],
              errorMessage: 'no access rights to this page',
              fallBack: 'allOffices',
              savable: true,
            },
          },
        ],
      },
      {
        path: '/offices/:id',
        name: 'Office',
        component: Office,
        redirect: '/offices/:id/general',
        meta: {
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            redirect: 'general',
          },
          {
            path: 'general',
            name: 'OfficeGeneral',
            component: OfficeGeneral,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
              savable: true,
            },
          },
          {
            path: 'imports',
            name: 'OfficeImports',
            component: OfficeImports,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin, , Role.SuperUser],
              savable: true,
            },
          },
        ],
      },
      {
        path: '/office-duplication/:id',
        name: 'OfficeDuplicationDetail',
        component: OfficeDuplicationDetail,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/office-duplications',
        name: 'allOfficeDuplications',
        component: AllOfficeDuplications,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/companies/create',
        component: Company,
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          savable: true,
        },
        children: [
          {
            path: '',
            name: 'CreateCompany',
            component: CompanyGeneral,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin],
              savable: true,
            },
          },
        ],
      },
      {
        path: '/companies/:id',
        name: 'Company',
        component: Company,
        redirect: '/companies/:id/general',
        meta: {
          requiresRoles: [Role.CompanyAdmin],
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            redirect: 'general',
          },
          {
            path: 'general',
            name: 'CompanyGeneral',
            component: CompanyGeneral,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin],
              savable: true,
            },
          },
          {
            path: 'translations',
            name: 'CompanyTranslations',
            component: CompanyTranslations,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin],
              savable: true,
            },
          },
          {
            path: 'attachments',
            name: 'Attachments',
            component: Attachments,
            meta: {
              requiresAuth: true,
              requiresRoles: [Role.CompanyAdmin],
              savable: false,
            },
          },
        ],
      },
      {
        path: '/companies',
        name: 'allCompanies',
        component: AllCompanies,
        meta: {
          requiresRoles: [Role.CompanyAdmin],
          requiresAuth: true,
        },
      },
      {
        path: '/shipment-types/:id',
        name: 'ShipmentType',
        component: ShipmentType,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/shipment-types',
        name: 'ShipmentTypes',
        component: AllShipmentTypes,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/milestones/create',
        name: 'CreateMilestone',
        component: Milestone,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/milestones/:id',
        name: 'Milestone',
        component: Milestone,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/milestones',
        name: 'Milestones',
        component: AllMilestones,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/reports/create',
        name: 'CreateReport',
        component: Report,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser, Role.TeamLeader],
        },
      },
      {
        path: '/reports/:id',
        name: 'Report',
        component: Report,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/reports',
        name: 'Reports',
        component: AllReports,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/screens/create',
        name: 'CreateUi',
        component: Ui,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/screens/:id',
        name: 'Ui',
        component: Ui,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/screens',
        name: 'Uis',
        component: AllUis,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/rules/create',
        name: 'CreateRule',
        component: Rule,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/rules/:id',
        name: 'Rule',
        component: Rule,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/rules',
        name: 'Rules',
        component: AllRules,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/logs',
        name: 'Logs',
        component: AllIntegrationLogs,
        meta: {
          requiresAuth: true,
          requiresRoles: [
            Role.CompanyAdmin,
            Role.SuperUser,
            Role.TeamLeader,
            Role.TeamMember,
          ],
        },
      },
      {
        path: '/shipment-imports',
        name: 'ShipmentImports',
        component: AllShipmentImports,
        meta: {
          requiresAuth: true,
          requiresRoles: [
            Role.CompanyAdmin,
            Role.SuperUser,
            Role.TeamLeader,
            Role.SynchronizerImporter,
          ],
        },
      },
      {
        path: '/shipment-imports/:id',
        name: 'ShipmentImport',
        component: ShipmentImport,
        meta: {
          requiresAuth: true,
          requiresRoles: [],
        },
      },
      {
        path: '/milestone-types',
        name: 'MilestoneTypes',
        component: AllMilestoneTypes,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/milestone-types/create',
        name: 'CreateMilestoneType',
        component: MilestoneType,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/milestone-types/:id',
        name: 'MilestoneType',
        component: MilestoneType,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin],
        },
      },
      {
        path: '/verified-emails/create',
        name: 'CreateVerifiedEmail',
        component: VerifiedEmailDetail,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/verified-emails/:id',
        name: 'VerifiedEmailDetail',
        component: VerifiedEmailDetail,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/verified-emails',
        name: 'VerifiedEmails',
        component: AllVerifiedEmails,
        meta: {
          requiresAuth: true,
          requiresRoles: [Role.CompanyAdmin, Role.SuperUser],
        },
      },
      {
        path: '/verification-landing/:token',
        name: 'VerificationLanding',
        component: VerificationLanding,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
          requiresRoles: [Role.CompanyAdmin, Role.Manager, Role.SuperUser],
          requiresAuth: true,
        },
      },
      {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem('local_base_url')) {
            next({ name: 'environments' });
          } else if (!store.getters['ui/ALL'].length) {
            store
              .dispatch('ui/FETCH_UNPAGINATED', store.getters['ui/API_PARAMS'])
              .then(() => next(to.path));
          } else {
            next();
          }
        },
      },
    ],
  });

  createdRouter.beforeEach(async (toRoute, from, next) => {
    const to = toRoute as RouteWithMeta;
    // check if page needs auth
    if (
      to.matched.some((record: RouteRecordWithMeta) => record.meta.requiresAuth)
    ) {
      // check if user has token
      if (localStorage.getItem('token')) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const loginResult: UserResponse = await store.dispatch(
          'users/AUTO_LOGIN',
        );
        if (!loginResult) {
          next({ name: 'login' });
        } else {
          if (to.meta.requiredAdmin && store.getters['users/GET_IS_ADMIN']) {
            next({ name: 'index' });
          } else if (
            !to.meta.requiresRoles ||
            to.meta.requiresRoles.length === 0
          ) {
            next();
          } else if (to.meta.requiresRoles) {
            const hasRequiredRole = to.meta.requiresRoles.find(
              (role: string) => role === store.getters['users/GET_ROLE'],
            );
            if (hasRequiredRole) {
              next();
            } else {
              store
                .dispatch('toasters/ADD_TOASTER', {
                  title:
                    to.meta.errorMessage || 'Page forbidden or Login Expired', // TODO make case specific
                  message: 'Redirected to available page',
                  type: ToasterTypes.ERROR,
                })
                .then(() => next({ name: to.meta.fallBack || 'index' }));

              next({ name: to.meta.fallBack || 'index' });
            }
          }
        }
      } else {
        next({ name: 'login' });
      }
    } else {
      next();
    }
  });

  return createdRouter;
};

const router = createRouter();

export function resetRouter(): void {
  const newRouter = createRouter();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  router.matcher = newRouter.matcher;
}

export default router;
