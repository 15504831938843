















































































































import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appShipmentArchiveButton from '../components/ShipmentArchiveButton.vue';
import appShipmentMilestone from '../components/ShipmentMilestone.vue';
import appShipmentCheckbox from '../components/ShipmentCheckbox.vue';
import appShipmentCustomLink from '../components/ShipmentCustomLink.vue';
import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { UIField } from '../types/ui/ui-field.class';
import { Office } from '@/services/api/offices/office.class';
import ShipmentItemChecksMixin from '@/mixins/shipment-item-checks.mixin';
import ShipmentItemFieldFunctionsMixin from '@/mixins/shipment-item-field-functions.mixin';

const shipmentsModule = namespace('shipments');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');

@Component({
  components: {
    appShipmentArchiveButton,
    appShipmentCheckbox,
    appShipmentMilestone,
    appShipmentCustomLink,
  },
})
export default class ShipmentItemStickyColumn extends Mixins(
  ShipmentItemChecksMixin,
  ShipmentItemFieldFunctionsMixin,
) {
  @Prop({
    type: Object,
    required: true,
  })
  shipment!: ShipmentResponse;

  @Prop({
    type: String,
    required: true,
  })
  milestoneType!: string;

  @Prop({
    type: Array,
    required: false,
  })
  stickyFields!: UIField[];

  @Prop({
    type: Boolean,
    required: true,
    default: false,
  })
  isActive!: boolean;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Office;

  @usersModule.Getter('GET_IS_DISPLAY')
  isDisplay!: boolean;

  @shipmentsModule.Getter('IS_LOADING')
  isLoadingDetail!: (key: string) => boolean;

  @Emit()
  archive(archive: boolean): boolean {
    return archive;
  }

  @Emit()
  showDetail(showDetail: boolean): boolean {
    return showDetail;
  }

  @Emit()
  shipmentMilestoneClicked(
    field: UIField,
    milestoneId: string,
  ): { milestoneId: string; milestoneCode: string } | undefined {
    if (field.milestone && typeof field.milestone !== 'string') {
      return {
        milestoneId,
        milestoneCode: field.milestone.code,
      };
    }
  }

  checkIfFieldDisabled(field: UIField): boolean {
    if (field.isDisabled === true || field.isDisabled === 'true') {
      return true;
    }

    return false;
  }

  isLoading(field: UIField): boolean {
    let key = `${this.shipment._id}.${field.field}`;
    if (
      field.fieldType === 'ms' &&
      field.milestone &&
      typeof field.milestone !== 'string'
    ) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      key = `${this.shipment._id}.${field.milestone._id}`;
    }
    return this.isLoadingDetail(key);
  }

  get isLoadingArchived(): boolean {
    const key = `${this.shipment._id}.archived`;
    return this.isLoadingDetail(key);
  }

  get priorityStar(): string[] {
    if (this.shipment.priority === 1) {
      return ['fas', 'star-half-alt'];
    } else if (this.shipment.priority === 2) {
      return ['fas', 'star'];
    }

    return ['far', 'star'];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  emitActions(field: UIField, shipment: any): void {
    if (field.openShipment) {
      this.$emit('openShipment', shipment);
    }
    if (field.openCustomer) {
      this.$emit('openCustomer', shipment);
    }
  }

  prepClasses(field: UIField): string {
    let classString = '';
    if (field.bold) {
      classString += 'font-weight-bold ';
    }
    if (field.truncate) {
      classString += 'text-truncate ';
    }
    return classString;
  }

  copyToClipboard(value: string): void {
    this.$copyText(value);
    this.addToast({
      title: 'Content copied!',
      message: `'${value}' has been copied to clipboard`,
      type: ToasterTypes.SUCCESS,
    });
  }

  printCustomer(shipment: ShipmentResponse): string {
    if (shipment.customer) {
      return shipment.customer.parent.name;
    }
    return shipment.custom.customer;
  }

  checkIfActionIsDisabled(field: UIField) {
    if (field.openCustomer && this.isCustomerLinked(this.shipment)) {
      return false;
    }
    return true;
  }
}
