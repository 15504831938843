import { SortOrder } from '@/services/api/sort-order.enum';
import { CustomersState } from './types';
import { Customer } from '@/services/api/customers/customer-interface';

export const state: CustomersState = {
  customers: {
    docs: [] as Customer[],
    total: 0,
    pages: 1,
    page: 1,
    limit: 999,
  },
  allCustomers: [] as Customer[],
  one: {} as Customer,
  apiParams: {
    limit: 999,
    sort: [`parent.name,${SortOrder.Asc}`],
  },
  pdfUpload: null,
};
