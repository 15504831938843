import { Office } from '../offices/office.class';
import { TransformationCondition } from '../transformations/transformation-condition.interface';
import { RequiredTime } from './shipment-type-required-time.class';

export class ShipmentType {
  _id?: string;
  name = '';
  milestoneType = '';
  requiredMilestones: { [key: string]: string[] } = {};
  office?: Office | string;
  conditions: TransformationCondition[] = [];
  requiredTime = new RequiredTime();
}
