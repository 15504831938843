import { Module } from 'vuex';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UIState } from './types';
import { RootState } from '../types';
import { SortOrder } from '@/services/api/sort-order.enum';
import { Ui } from '@/services/api/ui/ui.class';

export const state: UIState = {
  one: {} as Ui,
  loading: false,
  error: false,
  all: [] as Ui[],
  uis: {
    total: 0,
    page: 0,
    pages: 0,
    limit: 0,
    docs: [] as Ui[],
  },
  apiParams: {
    limit: 999,
    populate: {
      path: 'milestones',
      options: {
        sort: {
          sequence: SortOrder.Asc,
        },
      },
    },
  },
};

const namespaced = true;
const ui: Module<UIState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default ui;
