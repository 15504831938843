import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowToRight,
  faAngleDoubleRight,
  faChartBar,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCircle,
  faDownload,
  faExchange,
  faGlobe,
  faInfo,
  faSave,
  faSearch,
  faShareSquare,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faStar,
  faSyncAlt,
  faTimes,
  faTv,
  faUser,
  faUserCircle,
  faUpload,
  faPlus,
  faMinus,
  faCalendar,
  faEdit,
  faClipboard,
  faClipboardCheck,
  faHistory,
  faMapMarkerAlt,
  faTrashAlt,
  faBarcodeRead,
  faSlidersH,
  faFileExcel,
  faLayerGroup,
  faWarehouse,
  faBalanceScale,
  faAt,
  faUsers,
  faCalendarAlt,
  faQuestionCircle,
  faInfoCircle,
  faSync,
  faEmptySet,
  faExclamationTriangle,
  faLocation,
  faMapMarkedAlt,
  faEllipsisV,
  faCheck,
  faEnvelope,
  faCopy,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArchive as fasArchive,
  faBars,
  faCheckCircle,
  faStar as fasStar,
  faStarHalfAlt,
  faAngleDown,
  faSignOutAlt,
  faPlus as fasPlus,
  faCalendar as fasCalendar,
  faSlidersH as fasSlidersH,
  faBan as fasBan,
  faRandom,
  faArrowsV,
  faPencil,
  faHeartRate,
  faCircle as fasCircle,
  faUpload as fasUpload,
  faRedo,
  faShip,
  faMinus as fasMinus,
  faBuilding,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArchive,
  fasArchive,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowToRight,
  faBars,
  faCircle,
  faChartBar,
  faCheckCircle,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faClipboard,
  faClipboardCheck,
  faDownload,
  faExchange,
  faGlobe,
  faInfo,
  faSave,
  faSearch,
  faShareSquare,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faStarHalfAlt,
  faStar,
  fasStar,
  faSyncAlt,
  faTimes,
  faHistory,
  faTv,
  faUser,
  faUpload,
  faAngleDown,
  faUserCircle,
  faSignOutAlt,
  faPlus,
  fasPlus,
  faCalendar,
  fasCalendar,
  faEdit,
  faMapMarkerAlt,
  faTrashAlt,
  faBarcodeRead,
  faSlidersH,
  fasSlidersH,
  faFileExcel,
  faCopy,
  fasBan,
  faRandom,
  faWarehouse,
  faBuilding,
  faLayerGroup,
  faArrowsV,
  faPencil,
  faBalanceScale,
  faAt,
  faHeartRate,
  faUsers,
  fasUpload,
  faCalendarAlt,
  faQuestionCircle,
  fasCircle,
  faRedo,
  faShip,
  faSync,
  faEmptySet,
  faInfoCircle,
  faMinus,
  fasMinus,
  faExclamationTriangle,
  faAngleDoubleRight,
  faLocation,
  faMapMarkedAlt,
  faEllipsisV,
  faCheck,
  faEnvelope,
  faExclamationCircle,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
