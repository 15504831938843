import { UIFilter } from '@/types/ui/ui-filter.interface';
import { isBlank } from '@/utils/is-blank.util';

export function transformUiFilter(
  uiFilter: UIFilter[],
): Record<string, unknown> | undefined {
  const newFilter: Record<string, unknown> = {};

  if (!uiFilter) {
    return newFilter;
  }

  const groups = new Map<string, UIFilter[]>();

  uiFilter.forEach(filter => {
    if (!filter.isActive) {
      return;
    }

    groups.set(filter.field, [...(groups.get(filter.field) || []), filter]);
  });

  groups.forEach((filters, key) => {
    if (filters.length === 1) {
      const filter = filters[0];
      if (!isBlank(filter.values[0])) {
        const field: string = filter.field;
        if (filter.field.includes('shipmentMilestones')) {
          const key = filter.field.split('.')[1];
          newFilter.shipmentMilestones = {
            operation: '',
            value: `${filter.values[0]}.${key}`,
          };
        } else {
          newFilter[field] = {
            operation: filter.operation,
            value: filter.values[0],
          };
        }
      }
    } else {
      newFilter[key] = {
        operation: '',
        value: filters.map(f => f.values),
      };
    }
  });

  return newFilter;
}
