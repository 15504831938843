export class Milestone {
  _id?: string;
  name = '';
  code = '';
  shipmentField?: string;
  sequence = 0;
  type = '';
  showOnDisplay = false;
  isDisabled = false;
  useShipmentField = false;
  office?: string;
}
