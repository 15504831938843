












































































































































































import fieldSelect from '../components/FieldSelect.vue';
import Multiselect from 'vue-multiselect';
import { ErrorBag, FieldFlagsBag } from 'vee-validate';
import { Vue, Component, Model, Prop } from 'vue-property-decorator';
import { UiFilterForm } from '../types/ui/ui-filter-form.class';
import { isBlank } from '@/utils/is-blank.util';
import { FieldSelectResponse } from '../types/fieldSelectResponse.interface';
import { UIFilter } from '@/types/ui/ui-filter.interface';
import { FilterOperation } from '@/types/filter-operation.enum';
import { Booleans } from '@/types/ui/boolean.enum';
import { FieldSelectDataType } from '@/types/field-select-data-type.enum';
import FilterOperationSelect from '../components/FilterOperationSelect.vue';
import ShipmentTypeSelect from '../components/ShipmentTypeSelect.vue';
import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import CustomerSelect from '@/components/CustomerSelect.vue';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { Milestone } from '@/services/api/milestones/milestone.class';
import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';
import { namespace } from 'vuex-class';
import MultiTextInput from '@/components/MultiTextInput.vue';

const utilityModule = namespace('utility');

@Component({
  components: {
    fieldSelect,
    Multiselect,
    FilterOperationSelect,
    ShipmentTypeSelect,
    CustomerSelect,
    MultiTextInput,
  },
})
export default class UiFilterInput extends Vue {
  @Model('change', {
    required: true,
  })
  uiFilter!: UIFilter;

  @Prop({
    type: Array,
  })
  milestones!: MilestoneResponse[];

  @Prop({
    type: String,
  })
  milestoneType!: string;

  @Prop({
    type: Array,
    required: true,
  })
  customers!: CustomerResponse[];

  @utilityModule.Getter('GET_INITIAL_FIELD_SELECT_OPTIONS')
  options!: Option[];

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  uiFilterForm = new UiFilterForm();

  uiFilterTextInput = '';

  milestoneDayOffset = 0;

  get booleanOptions(): Option[] {
    const booleanOptions = Object.values(Booleans).map(item =>
      this.inputFormBoolPropAlignment(item),
    );
    return booleanOptions;
  }

  inputFormBoolPropAlignment(boolinput: string): Option {
    return ({ value: boolinput, text: boolinput } as unknown) as Option;
  }

  inputFormTypePropAlignment(dataType: FieldSelectDataType): string {
    if (dataType === FieldSelectDataType.TypeString) {
      return 'text';
    } else {
      return dataType;
    }
  }

  valueCustomerLabel(item: CustomerResponse): string {
    return item.parent ? item.parent.name : '';
  }

  setFilterTitle(title: string): void {
    this.uiFilter.title = title;
  }

  returnOptionText(item: Option): string {
    return item.text;
  }

  setUiFilterValueTextAdd(input: string): void {
    this.uiFilterForm.values.push(input);
    this.uiFilter.values = this.uiFilterForm.values;
    this.$emit('updateValues', this.uiFilter.values);
  }

  setUiFilterValueTextRemove(values: string[], valueIndex: number): void {
    values.splice(valueIndex, 1);
    this.uiFilterForm.values = values;
    this.uiFilter.values = values;
    this.$emit('updateValues', this.uiFilter.values);
  }

  setUiFilterValuesCustomers(): void {
    this.uiFilter.values = this.uiFilterForm.customersValues.map(
      value => value._id,
    );
    this.$emit('updateValues', this.uiFilter.values);
  }

  setUiFilterValueBoolean(selection: Option): void {
    this.uiFilter.values = [];
    this.uiFilter.values[0] = selection.value;
    this.uiFilterForm.value = this.uiFilter.values[0];
    this.$emit('updateValues', this.uiFilter.values);
    return;
  }

  setUiFilterValue(selection: string): void {
    this.uiFilter.values = [];
    this.uiFilter.values[0] = selection;
    this.$emit('updateValues', this.uiFilter.values);
  }

  setUiFilterValueShipmentType(selection: ShipmentTypeResponse): void {
    this.uiFilter.values = [];
    this.uiFilterForm.value = selection._id;
    this.uiFilter.values[0] = selection._id;
    this.$emit('updateValues', this.uiFilter.values);
    return;
  }

  setUiFilterValueMilestone(milestoneDayOffset: number): void {
    this.milestoneDayOffset = milestoneDayOffset;
    this.uiFilter.values[0] = this.milestoneDayOffset.toString();
    this.$emit('updateValues', this.uiFilter.values);
    return;
  }

  setUiFilterOperation(operation: FilterOperation): void {
    this.uiFilter.operation = isBlank(operation)
      ? ('' as FilterOperation)
      : operation;
    this.uiFilterForm.values = [];
  }

  determineFilterOnFieldType(field: FieldSelectResponse): void {
    if (field.text) {
      if (field.text.includes('archived')) {
        this.uiFilter.field = `${field.value}.${this.milestoneType}`;
        this.uiFilter.operation = FilterOperation.Equals;
        this.uiFilterForm.values = ['true'];
      } else if (field.type === 'shipmentType') {
        this.uiFilter.field = field.value;
        this.uiFilter.operation = FilterOperation.Equals;
      } else if (field.text.includes('Milestone')) {
        const shipmentMilestone = field.value.split('.value')[0];
        this.uiFilter.field = `${shipmentMilestone}.value`;
        this.uiFilter.operation = FilterOperation.IsToday;
      } else {
        this.uiFilter.field = field.value;
        this.uiFilter.operation = FilterOperation.Equals;
        this.uiFilterForm.field.dataType = field.dataType;
      }
    }
  }

  parseUiFilterToFilterForm(
    filterForm: UiFilterForm,
    filterTitle: string,
    filterField: string,
    filterValues: string[],
    filterOperation: FilterOperation,
    allMilestones: Milestone[],
    allCustomers: CustomerResponse[],
  ): UiFilterForm {
    filterForm.title = filterTitle;
    if (filterField && filterField.includes('archived')) {
      const textField = filterField.split('.')[0];
      filterForm.field = {
        value: filterField,
        text: textField,
        type: 'boolean',
      };
      filterForm.value = filterValues[0];
      return filterForm;
    } else if (filterField && filterField.includes('Milestone')) {
      const shipmentMilestone = filterField.split('.')[1];
      filterForm.field = {
        value: filterField,
        text: this.getMilestoneName(shipmentMilestone, allMilestones),
        type: 'milestone',
      };
      filterForm.values = filterValues;
      return filterForm;
    } else if (filterField && filterField.includes('type')) {
      filterForm.field = {
        value: filterField,
        text: filterField,
        type: 'shipmentType',
      };
      filterForm.value = filterValues[0];
      return filterForm;
    } else if (filterField === 'customer') {
      filterForm.field = {
        value: filterField,
        text: filterField,
        type: 'customer',
      };
      filterForm.customersValues = this.getCustomer(filterValues, allCustomers);
      return filterForm;
    } else {
      filterForm.field = {
        value: filterField,
        text: filterField,
      };
      const uiFilterMatch = this.options.find(
        option => option.value === filterField,
      );

      if (uiFilterMatch) {
        filterForm.field = uiFilterMatch;
      }
      filterForm.operation = filterOperation;
      filterForm.values = filterValues;
      filterForm.value = filterValues ? filterValues[0] : '';
      return filterForm;
    }
  }

  getMilestoneName(id: string, allMilestones: Milestone[]): string {
    const milestone = allMilestones.filter(m => m._id === id);
    return milestone[0].code;
  }

  getCustomer(
    idsList: string[],
    allCustomers: CustomerResponse[],
  ): CustomerResponse[] {
    const fullCustomers = idsList.map(id =>
      allCustomers.find(customer => customer._id === id),
    );
    return fullCustomers as CustomerResponse[];
  }

  created(): void {
    this.uiFilterForm = this.parseUiFilterToFilterForm(
      this.uiFilterForm,
      this.uiFilter.title,
      this.uiFilter.field,
      this.uiFilter.values,
      this.uiFilter.operation,
      this.milestones,
      this.customers,
    );
    if (this.uiFilterForm.field.type === 'milestone') {
      this.milestoneDayOffset = parseInt(this.uiFilterForm.values[0], 10);
    }
  }
}
