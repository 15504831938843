














































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Toaster } from '../store/modules/toasters/types';
import { Company } from '../services/api/companies/company.class';
import { SavableComponent } from '../types/savable-component.class';
import api from '../services/api/';
import env from '../../env.config';

const companiesModule = namespace('companies');
const toastersModule = namespace('toasters');

@Component({
  components: {},
})
export default class CompanyDetail extends SavableComponent {
  @companiesModule.Getter('GET')
  company!: Company;

  @toastersModule.Mutation('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @companiesModule.Action('CREATE')
  createCompany!: (company: any) => Promise<Company>;

  @companiesModule.Action('UPDATE')
  updateCompany!: (payload: {
    id: string;
    company: Company;
  }) => Promise<Company>;

  @companiesModule.Action('FETCH_ONE')
  fetchCompany!: (id: string) => void;

  form: Company = new Company();
  logoFile: any = null;

  @Watch('company', { deep: true })
  onTypeChange(): void {
    this.form = { ...this.company };
  }

  get isEditing(): boolean {
    return !!this.$route.params.id;
  }

  async save(): Promise<void> {
    if (this.logoFile) {
      const files = await api.files.create(
        this.logoFile,
        env.DOMAIN_URL + '/files',
      );
      if (files.length > 0) {
        this.form.logo = files[0]._id;
        const pathId = files[0].path.split('/');
        this.form.logoPath = pathId[1];
      }
    }
    let id = '';
    if (this.form._id) {
      id = this.form._id;
      this.updateCompany({ id: this.form._id, company: this.form });
    } else {
      const company = await this.createCompany(this.form);
      id = company._id || '';
    }

    if (!this.isEditing) {
      this.$router.replace({ name: 'Company', params: { id } });
    }
  }

  created(): void {
    if (this.isEditing) {
      this.fetchCompany(this.$route.params.id);
    }
  }
}
