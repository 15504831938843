












































import { Component } from 'vue-property-decorator';
import debounce from 'lodash.debounce';
import { namespace } from 'vuex-class';
import { LocaleMessageObject } from 'vue-i18n';
import { SavableComponent } from '../types/savable-component.class';
import { TranslationTableItem } from '../services/api/translations/translation-table-item.interface';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';

const translationsModule = namespace('translations');

@Component({})
export default class CompanyTranslations extends SavableComponent {
  @translationsModule.Getter('GET_TABLE_ITEMS')
  translations!: TranslationTableItem[];

  @translationsModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => void;

  @translationsModule.Action('FETCH_GENERAL')
  fetchGeneral!: (filter?: any) => void;

  @translationsModule.Action('SET_PARAMS')
  setParams!: (filter: FetchAllParams) => void;

  @translationsModule.Action('CREATE')
  createTranslations!: (payload: {
    companyId: string;
    translations: TranslationTableItem[];
  }) => Promise<LocaleMessageObject>;

  search = '';

  tableFields = [
    {
      key: 'original',
      label: 'Original',
    },
    {
      key: 'translated',
      label: 'Translated',
    },
  ];

  get companyId(): string {
    return this.$route.params.id;
  }

  onSearch(): void {
    debounce(() => {
      this.setParams({ name: this.search.trim() });
    }, 300).bind(this)();
  }

  async save(): Promise<void> {
    const translations = await this.createTranslations({
      companyId: this.companyId,
      translations: this.translations,
    });
    this.$i18n.setLocaleMessage(this.$i18n.locale, translations);
  }

  created(): void {
    this.fetchAll();
    this.fetchGeneral();
  }
}
