




























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { EntityListColumn } from '../types/entity-list-column.interface';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { OfficeDuplication } from '@/services/api/office-duplications/office-duplication.class';
import { Office } from '@/services/api/offices/office.class';
import moment from 'moment-timezone/moment-timezone';

const officeDuplicationsModule = namespace('officeDuplications');
const officesModule = namespace('offices');
const usersModule = namespace('users');

@Component({
  components: { EntityList },
})
export default class AllOfficeDuplications extends Vue {
  @officesModule.Getter('ALL')
  allOffices!: Office[];

  @officesModule.Action('FETCH_ALL')
  fetchAllOffices!: (filter?: FetchAllParams) => Promise<Office[]>;

  @officeDuplicationsModule.Getter('ALL_PAGINATED')
  allOfficeDuplications!: OfficeDuplication[];

  @officeDuplicationsModule.Action('FETCH_ALL_PAGINATED')
  fetchCloneOffices!: (params?: FetchAllParams) => Promise<OfficeDuplication[]>;

  @usersModule.Getter('GET_TIMEZONE')
  timezone!: any;

  get officeDuplicationsList(): OfficeDuplication[] {
    return this.allOfficeDuplications;
  }

  get entityColumns(): void {
    return;
  }

  public columns: Array<EntityListColumn<OfficeDuplication>> = [
    { field: 'newName', label: 'New Office', sortable: true },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { field: this.getOriginalOffice, label: 'Original Office', sortable: true },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.formatDate,
      label: 'Started on',
      sortable: true,
    },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { field: this.statusLabel, label: 'Status', sortable: true, isHTML: true },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { field: this.getDuration, label: 'Duration', sortable: true },
  ];

  filter: FetchAllParams = { sort: ['createdAt,desc'] };

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchCloneOffices(filter);
  }

  getOriginalOffice(item: OfficeDuplication): string {
    const office: Office =
      this.allOffices.find(off => off._id === item.office) || new Office();
    return office.name;
  }

  formatDate(item: OfficeDuplication): string {
    return moment(item.createdAt)
      .tz(this.timezone)
      .format('YYYY/MM/DD - HH:mm');
  }

  statusLabel(item: OfficeDuplication): string {
    const statusClass = this.$t(`status.${item.status}.class`) as string;
    const statusText = this.$t(`status.${item.status}`) as string;
    return `<p class="badge badge-${statusClass} badge-pill ml-1 mt-2">${statusText}</p>`;
  }

  getDuration(item: OfficeDuplication): string {
    if (!item.updatedAt || !item.createdAt) {
      return 'N/A';
    }
    const update = new Date(item.updatedAt).getTime();
    const created = new Date(item.createdAt).getTime();
    const duration = moment.duration(update - created);
    const hours = Math.floor(duration.asHours());
    const min = Math.floor(duration.asMinutes() % 60);
    return `${hours}h${min}`;
  }

  redirectToDetail(item: OfficeDuplication): void {
    this.$router.push({
      name: 'OfficeDuplicationDetail',
      params: { id: item._id as string },
    });
  }

  async created(): Promise<void> {
    await this.fetchCloneOffices(this.filter);
    await this.fetchAllOffices();
  }
}
