import { render, staticRenderFns } from "./OfficeGeneral.vue?vue&type=template&id=6175845d&scoped=true&"
import script from "./OfficeGeneral.vue?vue&type=script&lang=ts&"
export * from "./OfficeGeneral.vue?vue&type=script&lang=ts&"
import style0 from "./OfficeGeneral.vue?vue&type=style&index=0&id=6175845d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6175845d",
  null
  
)

export default component.exports