












































import { Component, Prop, Mixins } from 'vue-property-decorator';
import SortMixin from '../mixins/sort.mixin';
import { Filters } from '../types/filters.interface';

@Component({
  components: {},
})
export default class ShipmentListScrollColumn extends Mixins(SortMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  field!: any;

  @Prop({
    type: Object,
    required: true,
  })
  filters!: Filters;
}
