







































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VerifiedEmail } from '../services/api/verified-emails/verified-email.class';

const verifiedEmailsModule = namespace('verifiedEmails');

@Component({
  components: {},
})

export default class VerifiedEmailDetail extends Vue {
  @verifiedEmailsModule.Getter('GET')
  verifiedEmail!: VerifiedEmail;

  @verifiedEmailsModule.Action('UPDATE')
  updateVerifiedEmail!: (payload: {
    id: string;
    verifiedEmail: VerifiedEmail;
  }) => Promise<VerifiedEmail>;

  @verifiedEmailsModule.Action('FETCH_ONE')
  fetchVerifiedEmail!: (id: string) => Promise<void>;

  @verifiedEmailsModule.Action('CREATE')
  createVerifiedEmail!: (verifiedEmail: VerifiedEmail) => Promise<VerifiedEmail>;

  @verifiedEmailsModule.Action('SEND_VERIFICATION')
  sendVerificationEmailById!: (id: string) => Promise<VerifiedEmail>;

  form: VerifiedEmail = new VerifiedEmail();

  async onSubmit(): Promise<void>  {
    if (this.form._id) {
      await this.updateVerifiedEmail({ id: this.form._id, verifiedEmail: this.form });
    } else {
      const verifiedEmail: VerifiedEmail = await this.createVerifiedEmail(
        this.form,
      );
      this.form = verifiedEmail;
      if (verifiedEmail && verifiedEmail._id) {
        this.$router.replace({
          name: 'VerifiedEmailDetail',
          params: { id: verifiedEmail._id },
        });
      }
    }
  }

  async created(): Promise<void> {
    this.form = new VerifiedEmail();
    if (this.$route.params.id) {
      await this.fetchVerifiedEmail(this.$route.params.id);
      this.form = this.verifiedEmail;
    }
  }
}
