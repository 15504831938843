// mixin.js
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

@Component
export default class SortMixin extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  public sort!: any[];

  @Emit('sort')
  private doSort(field: string): string {
    return field;
  }

  private sortIcon(ascending: boolean) {
    if (ascending) {
      return ['far', 'sort-amount-up'];
    }

    return ['far', 'sort-amount-down'];
  }

  private getSortLevel(column: string) {
    if (column === 'customer') {
      column = 'customer.parent.name';
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return this.sort.findIndex((sortLevel: any) => sortLevel.column === column);
  }
}
