import { ReportCondition } from '../../../services/api/reports/report-condition.class';

const availableConditions: ReportCondition[] = [
  {
    conditionType: 'ONLY_CHANGED',
    label: 'Only changed shipments',
    data: {},
  },
  {
    conditionType: 'LATEST_MILESTONE',
    label: 'Latest milestone is',
    data: { milestones: [] },
  },
  {
    conditionType: 'CUSTOMER_IN',
    label: 'Customer is',
    data: { customers: [] },
  },
  {
    conditionType: 'SHIPMENT_TYPE_IS',
    label: 'Shipment type',
    data: { shipmentTypes: [] },
  },
  {
    conditionType: 'CUSTOMER_NIN',
    label: 'Customer is not',
    data: { customers: [] },
  },
  {
    conditionType: 'MILESTONE_COMPARE',
    label: 'Milestone between or equal',
    data: {
      milestone: undefined,
      operator: 'EQUALS',
      offset: 0,
      offsetAfter: -1,
    },
  },
  {
    conditionType: 'DATE_RANGE',
    label: 'Created at',
    data: { dateStart: undefined, dateEnd: undefined },
  },
  {
    conditionType: 'ORIGIN_IS',
    label: 'Origin is',
    data: { origin: undefined },
  },
  {
    conditionType: 'DESTINATION_IS',
    label: 'Destination is',
    data: { destination: undefined },
  },
];

export default availableConditions;
