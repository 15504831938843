import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import AttachmentsService from '@/services/api/attachments/attachments.service';
import { Attachment } from '@/services/api/attachments/attachment.class';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '../types';
import { Filter } from '@/types/filter.interface';

const attachmentsService = new AttachmentsService();
const namespaced = true;

export interface AttachmentsState {
  allUnpaginated: Attachment[];
  all: PaginatedResponse<Attachment>;
  allOptions: Option[];
  one: Attachment;
  apiParams: FetchAllParams;
}

const initialAttachmentsState: AttachmentsState = {
  allUnpaginated: [],
  all: new PaginatedResponseClass(),
  allOptions: [],
  one: new Attachment(),
  apiParams: {},
};

const getters: GetterTree<AttachmentsState, RootState> = {
  GET: (state: AttachmentsState): Attachment => state.one,
  ALL: (state: AttachmentsState): Attachment[] => state.allUnpaginated,
  ALL_PAGINATED: (state: AttachmentsState) => state.all,
  GET_ALL_OPTIONS: (state: AttachmentsState) => state.allOptions,
  API_PARAMS: (state: AttachmentsState): FetchAllParams => state.apiParams,
};

const mutations: MutationTree<AttachmentsState> = {
  SET_API_PARAMS(state: AttachmentsState, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state: AttachmentsState, payload: PaginatedResponse<Attachment>) {
    state.all = payload;
  },
  SET_ALL_OPTIONS(
    state: AttachmentsState,
    payload: PaginatedResponse<Attachment>,
  ) {
    const allNewOptions = payload.docs.map(
      (attachment: Attachment) =>
        ({
          value: attachment._id,
          text: attachment.name,
        } as Option),
    );

    state.allOptions = allNewOptions;
  },
  SET_UNPAGINATED(state: AttachmentsState, payload: Attachment[]) {
    state.allUnpaginated = payload;
  },
  SET_ONE(state: AttachmentsState, payload: Attachment) {
    state.one = payload;
  },
};

const actions: ActionTree<AttachmentsState, RootState> = {
  async CREATE(context, payload: Attachment) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const attachment = await attachmentsService.create(payload);
      context.commit('SET_ONE', attachment);
      return attachment;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const attachment = await attachmentsService.fetchOne(id);
      context.commit('SET_ONE', attachment);
      return attachment;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL_OPTIONS(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const attachments = await attachmentsService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL_OPTIONS', attachments);
      return attachments;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: Filter) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const attachments = await attachmentsService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', attachments);
      return attachments;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_UNPAGINATED(context, filter?: any) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const attachments = await attachmentsService.fetchAll(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_UNPAGINATED', attachments);
      return attachments;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { _id: string; attachment: Attachment }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const attachment = await attachmentsService.update(payload._id, payload);
      context.commit('SET_ONE', attachment);
      return attachment;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const attachment = await attachmentsService.delete(payload);
      context.commit('SET_ONE', attachment);
      return attachment;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced,
  state: initialAttachmentsState,
  actions,
  mutations,
  getters,
};
