import { ShipmentImport } from './shipment-import.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { PaginatedResponse } from '../paginated-response';
import { ShipmentImportLog } from './shipment-import-log.class';
import { FetchAllParams } from '../fetch-all-params.interface';

export default class ShipmentImportsService extends EntitiesService<
ShipmentImport
> {
  get endpoint(): string {
    return (
      (localStorage.getItem('local_base_url') as string) + '/shipment-imports'
    );
  }

  async getFileHeaders(file: string): Promise<AxiosResponse<void>> {
    const response = await axios.get(`${this.endpoint}/file-headers/${file}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data;
  }

  async fetchLogsByImportId(
    id: string,
    params?: FetchAllParams,
  ): Promise<PaginatedResponse<ShipmentImportLog>> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );
    const response = await axios.get<PaginatedResponse<ShipmentImportLog>>(
      `${
        localStorage.getItem('local_base_url') as string
      }/shipment-import-logs/${id}`,
      config,
    );

    return response.data;
  }
}
