export enum FilterOperation {
  Equals = '',
  EqualsWithSign = '=',
  NotEquals = '!',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  StartsWith = '^',
  EndsWith = '$',
  Contains = '~',
  IsToday = 'today.',
}

export function humanReadableFilterOperation(
  operation: FilterOperation,
): string {
  switch (operation) {
  case FilterOperation.EqualsWithSign:
  case FilterOperation.Equals:
    return 'Equals';
  case FilterOperation.NotEquals:
    return 'Not Equals';
  case FilterOperation.GreaterThan:
    return 'Greater Than';
  case FilterOperation.LessThan:
    return 'Less Than';
  case FilterOperation.GreaterThanOrEqual:
    return 'Greater Than Or Equal';
  case FilterOperation.LessThanOrEqual:
    return 'Less Than Or Equal';
  case FilterOperation.StartsWith:
    return 'Starts With';
  case FilterOperation.EndsWith:
    return 'Ends With';
  case FilterOperation.Contains:
    return 'Contains';
  case FilterOperation.IsToday:
    return 'Is Today';
  }
}
