





















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { VerifiedEmail } from '../services/api/verified-emails/verified-email.class';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';

const verifiedEmailsModule = namespace('verifiedEmails');

@Component({
  components: {
    EntityList,
  },
})
export default class AllVerifiedEmails extends Vue {
  @verifiedEmailsModule.Getter('ALL_PAGINATED')
  verifiedEmails!: any;

  @verifiedEmailsModule.Action('FETCH_ALL')
  fetchAll!: (filter?: any) => Promise<void>;

  @verifiedEmailsModule.Action('DELETE')
  deleteById!: (filter?: any) => Promise<void>;

  filter: FetchAllParams = {};

  public columns: any[] = [
    {
      field: 'address',
      label: 'Verified email address',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'verified',
      label: 'Verified',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  redirectToDetail(verifiedEmail: VerifiedEmail): void {
    this.$router.push({
      name: 'VerifiedEmailDetail',
      params: { id: verifiedEmail._id as string },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'CreateVerifiedEmail',
    });
  }

  async redirectToDelete(verifiedEmail: VerifiedEmail): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + verifiedEmail.address + '?',
    );
    if (!result) {
      return;
    }
    await this.deleteById(verifiedEmail._id);
    this.fetchAll();
  }

  async created(): Promise<void> {
    await this.fetchAll();
  }
}
