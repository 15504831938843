import { TranslationMode } from '@/services/api/translations/translation-mode.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { TranslationResponse } from '@/services/api/translations/translation-response.interface';
import { TranslationTableItem } from '@/services/api/translations/translation-table-item.interface';
import { TranslationType } from '@/services/api/translations/translation-type.enum';
import TranslationsService from '@/services/api/translations/translations.service';
import { FilterOperation } from '@/types/filter-operation.enum';
import { ToasterTypes } from './toasters/types';
import { Module } from 'vuex';
import { RootState } from './types';
import { MilestoneResponse } from '@/services/api/milestones/milestone-response.interface';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

const translationsService = new TranslationsService();

export interface TranslationState {
  all: TranslationResponse[];
  general: TranslationResponse[];
  apiParams: FetchAllParams;
}

const translationsModule: Module<TranslationState, RootState> = {
  namespaced: true,
  state: {
    all: [],
    general: [],
    apiParams: {},
  },
  getters: {
    ALL: (state: TranslationState) => state.all,
    DEFAULT: (state: TranslationState) => state.general,
    API_PARAMS: (state: TranslationState) => state.apiParams,
    GET_TABLE_ITEMS: (state: TranslationState): TranslationTableItem[] => {
      let filteredItems = [...state.general];

      if (state.apiParams && state.apiParams.name) {
        filteredItems = [
          ...filteredItems.filter((item: TranslationResponse) => {
            const searchParam = state.apiParams.name!.toLowerCase();

            const specificTranslationIncludesSearchValue = state.all.find(
              (t: TranslationResponse) =>
                t.key === item.key &&
                t.value.toLowerCase().includes(searchParam),
            );

            const generalTranslationIncludesSearchValue = item.value
              .toLowerCase()
              .includes(searchParam);

            return (
              generalTranslationIncludesSearchValue ||
              specificTranslationIncludesSearchValue
            );
          }),
        ];
      }

      const tableItems: TranslationTableItem[] = filteredItems.map(
        (item: TranslationResponse) => {
          const specificTranslation = state.all.find(
            (t: TranslationResponse) =>
              t.key === item.key && t.type === TranslationType.Specific,
          );

          return {
            key: item.key,
            original: item.value,
            translated:
              (specificTranslation && specificTranslation.value) || '',
          };
        },
      );
      return tableItems;
    },
  },
  mutations: {
    SET_API_PARAMS(state: TranslationState, payload: FetchAllParams) {
      state.apiParams = payload;
    },
    SET_ALL(state: TranslationState, payload: TranslationResponse[]) {
      state.all = payload;
    },
    SET_GENERAL(state: TranslationState, payload: TranslationResponse[]) {
      state.general = payload;
    },
  },
  actions: {
    async CREATE(
      context: any,
      payload: { companyId: string; translations: TranslationTableItem[] },
    ) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const createdTranslations = payload.translations.reduce(
          (items: { [key: string]: string }, item: TranslationTableItem) => {
            items[item.key] = item.translated;
            return items;
          },
          {},
        );

        const translations = await translationsService.createByCompanyId(
          payload.companyId,
          createdTranslations,
        );
        context.dispatch(
          'toasters/ADD_TOASTER',
          { title: 'Save successful', message: '', type: ToasterTypes.SUCCESS },
          { root: true },
        );
        context.dispatch('FETCH_ALL');
        return translations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async FETCH_ALL(context: any, filter?: any) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        context.commit('SET_API_PARAMS', filter);
        const translations = await translationsService.fetchAll({
          filters: {
            mode: {
              value: TranslationMode.Edit,
              operation: FilterOperation.Equals,
            },
            value: {
              value: filter as string,
              operation: FilterOperation.Contains,
            },
          },
        });

        await context.dispatch(
          'milestones/FETCH_ALL',
          { limit: 999 },
          { root: true },
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const milestones = context.rootGetters[
          'milestones/ALL_BY_SEQUENCE'
        ] as MilestoneResponse[];

        // add each milestone code as a translated value, and add the expected shipmentMilestones._id.value as the key
        milestones.forEach(milestone =>
          translations.push({
            key: `shipmentMilestones.${milestone._id}.value`,
            mode: TranslationMode.Read,
            type: TranslationType.Specific,
            value: `Milestone: ${milestone.code}`,
          }),
        );
        if (filter) {
          const filteredTranslations = translations.filter(trans =>
            trans.value.toLowerCase().includes(filter.toLowerCase()),
          );
          context.commit('SET_ALL', filteredTranslations);
          return filteredTranslations;
        }
        context.commit('SET_ALL', translations);
        return translations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async FETCH_GENERAL(context: any, filter?: any) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const translations = await translationsService.fetchAll({
          filters: {
            type: {
              value: TranslationType.General,
              operation: FilterOperation.Equals,
            },
            mode: {
              value: TranslationMode.Edit,
              operation: FilterOperation.Equals,
            },
          },
        });
        context.commit('SET_GENERAL', translations);
        return translations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    SET_PARAMS(context: any, filter: FetchAllParams) {
      context.commit('SET_API_PARAMS', filter);
    },
    async LOAD_DEFAULT(context: any) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const translations = await translationsService.loadDefaultTranslations();
        return translations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
    async LOAD_SPECIFIC(context: any) {
      try {
        context.dispatch('application/SET_LOADING', null, { root: true });
        const translations = await translationsService.loadSpecificTranslations();

        await context.dispatch(
          'milestones/FETCH_ALL',
          { limit: 999 },
          { root: true },
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const milestones = context.rootGetters[
          'milestones/ALL_BY_SEQUENCE'
        ] as MilestoneResponse[];

        // add each milestone code as a translated value, and add the expected shipmentMilestones._id.value as the key
        milestones.forEach(milestone =>
          Object.assign(translations, {
            [`shipmentMilestones.${milestone._id}.value`]: `Milestone: ${milestone.code}`,
          }),
        );
        return translations;
      } finally {
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    },
  },
};

export default translationsModule;
