// mixin.js
import { ShipmentResponse } from '@/services/api/shipments/shipment-response.interface';
import { UIField } from '@/types/ui/ui-field.class';
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment-timezone';
import { Office } from '@/services/api/offices/office.class';

@Component
export default class ShipmentItemFieldFunctionsMixin extends Vue {

  checkForActions(field: UIField): boolean {
    return Boolean(field.openShipment || field.openCustomer);
  }

  getField(column: UIField, shipment: ShipmentResponse, office: Office): string | void | ShipmentResponse {
    if (column.func) {
      // eslint-disable-next-line
      return (this as any)[column.func](shipment);
    }

    if (!column.field) {
      return '';
    }

    if (column.formatDatetime) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return moment.tz((shipment as any)[column.field], office.timezone).format(office.dateFormat);
    }

    const fields = column.field.split('.');
    let currentItem = shipment;
    for (const field of fields) {
      if (!currentItem) {
        return currentItem;
      }
      // eslint-disable-next-line
      currentItem = (currentItem as any)[field];
    }
    return currentItem;
  }

  runFromString(fn: string, shipment: ShipmentResponse): any {
    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    return (this as any)[fn](shipment);
  }
}
