






























import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';

const customersModule = namespace('customers');
const shipmentsModule = namespace('shipments');

interface Option {
  groupLabel: string;
  groupValues: GroupValue[];
}

interface GroupValue {
  label: string;
  value: CustomerResponse | null;
  trackBy: string | null;
}

@Component({
  components: {
    Multiselect,
  },
})
export default class CustomerSelect extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  })
  values!: string[] | null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @customersModule.Getter('ALL')
  customers!: CustomerResponse[];

  selected: GroupValue[] = [];

  @Watch('values')
  onValueChange(values: string[] | null): void {
    // If the ShipmentType changes it clears the incoming values (filter).
    // We need to clear our selection when that happens
    if (values && values.length === 0) {
      this.selected = [];
    }
  }

  @Emit('close')
  onClose(groupValues: GroupValue[]): Array<CustomerResponse | null> {
    return groupValues.map((groupValue: GroupValue) => groupValue.value);
  }

  private createOptions(customers: CustomerResponse[]): Option[] {
    return [
      {
        groupLabel: 'Select all',
        groupValues: [
          ...customers.map((customer: CustomerResponse) =>
            this.toGroupValue(customer),
          ),
          this.toGroupValue(null),
        ],
      },
    ];
  }

  private toGroupValue(customer: CustomerResponse | null): GroupValue {
    if (customer) {
      return {
        label: customer.parent.name,
        value: customer,
        trackBy: customer._id || '',
      };
    } else {
      return {
        label: 'No customer',
        value: null,
        trackBy: null,
      };
    }
  }
}
