import { render, staticRenderFns } from "./LinkedReportList.vue?vue&type=template&id=489d41d2&scoped=true&"
import script from "./LinkedReportList.vue?vue&type=script&lang=ts&"
export * from "./LinkedReportList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489d41d2",
  null
  
)

export default component.exports