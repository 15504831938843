


























































import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import ShipmentItemChecksMixin from '../mixins/shipment-item-checks.mixin';
import Multiselect from 'vue-multiselect';
import SortMixin from '../mixins/sort.mixin';
import appShipmentItem from '../components/ShipmentItem.vue';
import appShipmentListStickyColumn from '../components/ShipmentListStickyColumn.vue';
import appShipmentListScrollColumn from '../components/ShipmentListScrollColumn.vue';
import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { Filters } from '../types/filters.interface';
import { namespace } from 'vuex-class';
import { ShipmentMilestoneDetailInput } from '../types/shipments/shipment-milestone-detail-input.interface';
import { Ui } from '@/services/api/ui/ui.class';
import { UIField } from '@/types/ui/ui-field.class';

const usersModule = namespace('users');

@Component({
  components: {
    appShipmentItem,
    appShipmentListStickyColumn,
    appShipmentListScrollColumn,
    Multiselect,
  },
})
export default class ShipmentList extends Mixins(SortMixin, ShipmentItemChecksMixin) {
  @Prop({
    type: Array,
    required: true,
  })
  shipments!: ShipmentResponse[];

  @Prop({
    type: Object,
    required: true,
  })
  filters!: Filters;

  @Prop({
    type: Object,
    required: true,
  })
  ui!: Ui;

  @usersModule.Getter('GET_IS_DISPLAY')
  isDisplay!: boolean;

  get displayClass(): string {
    return this.isDisplay ? 'display' : 'no-display';
  }

  @Emit('filter')
  doFilter(filters: Filters): Filters {
    return filters;
  }

  @Emit()
  showDetail(shipmentId: string): string {
    return shipmentId;
  }

  @Emit()
  shipmentMilestoneClicked(
    input: ShipmentMilestoneDetailInput,
  ): ShipmentMilestoneDetailInput {
    return input;
  }

  showCustomer(custId: string): void {
    this.$router.push({ name: 'customer', params: { customerId: custId } });
  }

  public getScrollFieldWidth(field: UIField, index: number): string {
    if (field.fieldType === 'ms') {
      return index === 0 ? '120px' : '105px';
    }
    if (field.width === undefined) {
      field.width = 15;
    }
    return index === 0
      ? (field.width + 15).toString() + 'px'
      : field.width.toString() + 'px';
  }

  public getCustomer(shipment: ShipmentResponse): string {
    if (shipment.customer) {
      return shipment.customer.parent.name;
    }
    return shipment.custom.customer;
  }

  public getStickyWidth(): number {
    let ret = 0;
    for (const field of this.ui.stickyFields) {
      if (field.width) {
        ret += field.width;
      }
    }
    return ret + 25;
  }
}
