





































import { TestIds } from '../types/test-ids.enum';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { UIResponse } from '@/services/api/ui/ui-response.interface';

@Component
export default class NavBarViewPicker extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  uiList!: UIResponse[];

  @Prop({
    type: String,
    required: true,
  })
  currentRoute!: string;

  TestIds: { [key: string]: number | string } = TestIds;

  get currentUI(): UIResponse {
    const thisUI = this.uiList.find(
      (ui: UIResponse) => ui.path === this.currentRoute,
    );
    if (thisUI) {
      return thisUI;
    }
    return this.uiList[0];
  }

  icon(icon: string): [string, string] {
    return ['far', icon];
  }
}
