import { Report } from './report.class';
import EntitiesService from '../entities/entities.service';
import { FetchAllParams } from '../fetch-all-params.interface';
import { PaginatedResponse } from '../paginated-response';
import { ReportLog } from './report-log.class';
import axios from '@/axios';
import { AxiosRequestConfig } from 'axios';

export default class ReportsService extends EntitiesService<Report> {
  get endpoint(): string {
    return (localStorage.getItem('local_base_url') as string) + '/reports';
  }

  async fetchAllCompanyReports(params?: FetchAllParams): Promise<Report[]> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );
    const response = await axios.get<Report[]>(
      (localStorage.getItem('local_base_url') as string) + '/reports/company',
      config,
    );

    return response.data;
  }

  async fetchLogs(
    params?: FetchAllParams,
  ): Promise<PaginatedResponse<ReportLog>> {
    const config: AxiosRequestConfig = EntitiesService.generateRequestConfig(
      params,
    );
    const response = await axios.get<PaginatedResponse<ReportLog>>(
      (localStorage.getItem('local_base_url') as string) + '/report-logs',
      config,
    );

    return response.data;
  }

  async duplicateReport(report: Report): Promise<Report> {
    const response = await axios.post<Report>(
      (localStorage.getItem('local_base_url') as string) + '/reports/duplicate',
      report,
    );
    return response.data;
  }
}
