import { ISequence } from '@/types/sequence.interface';
import { DragEventOutput } from '@/types/drag-event-output.interface';

// If removeItem is set to true, newIndex property of
// dragEvent won`t matter, so it can be the same as the oldIndex
export function changeItemsSequence(
  items: ISequence[],
  dragEvent: DragEventOutput,
  removeItem = false,
): ISequence[] {
  const movedItem = items[dragEvent.oldIndex];
  const remainingItems = items.filter(
    (item, index) => index !== dragEvent.oldIndex,
  );
  let reorderedItems = [...remainingItems];
  if (!removeItem) {
    reorderedItems = [
      ...remainingItems.slice(0, dragEvent.newIndex),
      movedItem,
      ...remainingItems.slice(dragEvent.newIndex),
    ];
  }
  reorderedItems = [
    ...reorderedItems.map((item, index) => {
      item.sequence = index + 1;
      return item;
    }),
  ];
  return reorderedItems;
}
