import { SortOrder } from '@/services/api/sort-order.enum';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import CompaniesService from '@/services/api/companies/companies.service';
import { Company } from '@/services/api/companies/company.class';
import { RootState } from '@/store/modules/types';
import {
  PaginatedResponse,
  PaginatedResponseClass,
} from '@/services/api/paginated-response';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Filter } from '@/types/filter.interface';

const companiesService = new CompaniesService();

interface CompanyState {
  one: Company;
  all: PaginatedResponse<Company>;
  apiParams: FetchAllParams;
}

const initialCompanyState: CompanyState = {
  one: {
    name: '',
  },
  all: new PaginatedResponseClass(),
  apiParams: {
    limit: 999,
    sort: [`sequence,${SortOrder.Asc}`],
  },
};

const getters: GetterTree<CompanyState, RootState> = {
  GET: state => state.one,
  ALL: state => state.all.docs,
  ALL_PAGINATED: state => state.all,
  API_PARAMS: state => state.apiParams,
};

const mutations: MutationTree<CompanyState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<Company>) {
    state.all = payload;
  },
  SET_ONE(state, payload: Company) {
    state.one = payload;
  },
};

const actions: ActionTree<CompanyState, RootState> = {
  async CREATE(context, payload: Company): Promise<Company> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const company: Company = await companiesService.create(payload);
      context.commit('SET_ONE', company);
      return company;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string): Promise<Company> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const company: Company = await companiesService.fetchOne(id);
      context.commit('SET_ONE', company);
      return company;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ALL(
    context,
    filter?: Filter,
  ): Promise<PaginatedResponse<Company>> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const companies = await companiesService.fetch(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.getters.API_PARAMS,
      );
      context.commit('SET_ALL', companies);
      return companies;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(
    context,
    payload: { id: string; company: Company },
  ): Promise<Company> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const company = await companiesService.update(
        payload.id,
        payload.company,
      );
      context.commit('SET_ONE', company);
      return company;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string): Promise<Company> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const company = await companiesService.delete(payload);
      return company;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialCompanyState,
  getters,
  mutations,
  actions,
};
