import { render, staticRenderFns } from "./CompanyGeneral.vue?vue&type=template&id=5fc27c6c&"
import script from "./CompanyGeneral.vue?vue&type=script&lang=ts&"
export * from "./CompanyGeneral.vue?vue&type=script&lang=ts&"
import style0 from "./CompanyGeneral.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports