import { FileResponse } from '../files/file-response.interface';
import { ShipmentImportStatus } from '@/types/import-status.enum';

export class ShipmentImport {
  _id?: string;
  status?: ShipmentImportStatus;
  file?: FileResponse;
  user?: string;
  totalRecords?: number;
  failed?: number;
  success?: number;
  createdAt?: Date;
  updatedAt?: Date;
}
