









































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Multiselect from 'vue-multiselect';
import flatPickr from 'vue-flatpickr-component';
import { Toaster, ToasterTypes } from '../store/modules/toasters/types';
import { Ui } from '../services/api/ui/ui.class';
import draggable from 'vuedraggable';
import { BModal } from 'bootstrap-vue';
import fieldForm from '../components/FieldForm.vue';
import iconSelector from '../components/IconSelector.vue';
import EntityList from '../components/EntityList.vue';
import { UIField } from '../types/ui/ui-field.class';
import { EntityListColumn } from '../types/entity-list-column.interface';
import { Icon } from '../types/ui/icon.enum';
import appUiFilter from '../components/UIFilter.vue';
import { UIFilter } from '../types/ui/ui-filter.interface';
import { MilestoneResponse } from '../services/api/milestones/milestone-response.interface';
import { Milestone } from '@/services/api/milestones/milestone.class';
import fieldSelect from '../components/FieldSelect.vue';
import { ErrorBag, FieldFlagsBag } from 'vee-validate';
import FilterOperationSelect from '../components/FilterOperationSelect.vue';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponseClass } from '@/services/api/paginated-response';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import ShipmentTypeSelect from '../components/ShipmentTypeSelect.vue';
import { ShipmentTypeResponse } from '@/services/api/shipment-types/shipment-type-response.interface';
import { MilestoneType } from '../services/api/milestone-types/milestone-type.class';
import { UIFieldPosition } from '../types/ui/ui-field-position.enum';
import UiFilterInput from '../components/UiFilterInput.vue';
import { first } from 'lodash';
import { isBlank } from '@/utils/is-blank.util';

const uiModule = namespace('ui');
const toastersModule = namespace('toasters');
const usersModule = namespace('users');
const milestoneTypesModule = namespace('milestoneTypes');
const milestonesModule = namespace('milestones');
const customersModule = namespace('customers');
const shipmentTypesModule = namespace('shipmentTypes');

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

@Component({
  components: {
    flatPickr,
    draggable,
    fieldForm,
    iconSelector,
    EntityList,
    appUiFilter,
    fieldSelect,
    Multiselect,
    FilterOperationSelect,
    ShipmentTypeSelect,
    UiFilterInput,
  },
})
export default class UiView extends Vue {
  @milestonesModule.Getter('ALL')
  milestones!: MilestoneResponse[];

  @usersModule.Getter('GET_WORKING_OFFICE')
  office!: Ui;

  @uiModule.Getter('GET')
  ui!: Ui;

  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @customersModule.Getter('PAGINATED')
  customers!: PaginatedResponseClass<CustomerResponse>;

  @toastersModule.Action('ADD_TOASTER')
  addToast!: (toast: Toaster) => void;

  @uiModule.Action('CREATE')
  createUi!: (ui: Ui) => Promise<Ui>;

  @uiModule.Action('UPDATE')
  updateUi!: (payload: { id: string; ui: Ui }) => Promise<Ui>;

  @uiModule.Action('FETCH_ONE')
  fetchUi!: (id: string) => Promise<void>;

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchAllMilestoneTypes!: (filter?: FetchAllParams) => Promise<void>;

  @milestonesModule.Action('FETCH_ALL')
  fetchAllMilestones!: (
    filter?: FetchAllParams,
  ) => Promise<MilestoneResponse[]>;

  @customersModule.Action('FETCH')
  fetchAllCustomers!: (filter?: FetchAllParams) => Promise<CustomerResponse[]>;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentTypeResponse[];

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchShipmentTypes!: (params?: FetchAllParams) => Promise<void>;

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  $refs!: {
    filterCreateModal: BModal;
    objectModal: BModal;
    iconSelectorModal: BModal;
    copyUiModal: BModal;
  };

  uiForm: Ui = new Ui();
  selectedField: UIField = new UIField();
  selectedFieldType = '';
  uiFilter = {} as UIFilter;
  disableUpdateCreate = true;
  filterInputValues = [];
  selectedFilter = {} as UIFilter;
  isUpdating = false;
  dragOptions = {
    animation: 200,
    group: 'description',
    ghostClass: 'ghost',
  };
  iconList: string[] = [];

  get icon(): string[] {
    for (const item of Object.values(Icon)) {
      this.iconList.push(item);
    }
    return this.iconList;
  }
  public columns: Array<EntityListColumn<UIField>> = [
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.displayTranslatedField,
      label: 'Field',
    },
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      field: this.displayLabel,
      label: 'Label',
    },
    {
      field: (item: UIField): string => {
        if (item.fieldType === 'cb') {
          return 'Checkbox';
        }
        if (item.fieldType === 'ms') {
          return 'Milestone';
        }
        if (item.fieldType === 'icon') {
          return 'Icon';
        }
        return 'Field';
      },
      label: 'Field Type',
    },
    {
      field: (item: UIField): string => (item.bold ? 'Yes' : 'No'),
      label: 'Bold',
    },
  ];

  @Watch('ui', { deep: true })
  onTypeChange(): void {
    this.uiForm = { ...this.ui };
    if (!this.uiForm.milestoneType && this.milestoneTypes.length) {
      this.uiForm.milestoneType = this.milestoneTypes[0]._id
        ? this.milestoneTypes[0]._id
        : undefined;
    }
  }

  populateScreenFields(
    uiFieldsData: UIField[],
    fieldType: UIFieldPosition,
  ): void {
    if (fieldType === UIFieldPosition.Sticky) {
      this.uiForm.stickyFields = uiFieldsData;
    } else {
      this.uiForm.scrollFields = uiFieldsData;
    }
  }

  updateUiFilterValues(filterValues: string[]): boolean {
    if (filterValues && filterValues.length && !isBlank(first(filterValues))) {
      this.disableUpdateCreate = false;
    } else {
      this.disableUpdateCreate = true;
    }
    return this.disableUpdateCreate;
  }

  async addEditUI(uiForm: Ui): Promise<Ui> {
    uiForm.path = `/shipments/${uiForm.name.toLowerCase()}`;
    if (uiForm._id) {
      const updated = await this.updateUi({
        id: uiForm._id,
        ui: uiForm,
      });
      return updated;
    } else {
      const ui = await this.createUi(uiForm);
      if (ui._id) {
        this.$router.replace({
          name: 'Ui',
          params: { id: ui._id },
        });
      }
      return ui;
    }
  }

  async onSubmit(): Promise<void> {
    await this.$validator.validateAll().then(async result => {
      if (result) {
        await this.addEditUI(this.uiForm);
      }
    });
  }

  async updateField(form: UIField): Promise<void> {
    let fields = [];

    if (this.selectedFieldType === 'sticky') {
      fields = this.uiForm.stickyFields || [];
    } else {
      fields = this.uiForm.scrollFields || [];
    }
    const index = fields.findIndex(
      (field: UIField) => form._id && field._id === form._id,
    );
    if (index > -1) {
      fields[index] = { ...form };
    } else {
      fields.push(form);
    }
    this.$refs.objectModal.hide();
    await this.onSubmit();
    this.selectedField = new UIField();
    this.selectedFieldType = '';
  }
  async created(): Promise<void> {
    await this.fetchAllMilestones();
    await this.fetchAllMilestoneTypes();
    await this.fetchAllCustomers();
    await this.fetchShipmentTypes();
    if (!this.uiForm.milestoneType && this.milestoneTypes.length) {
      this.uiForm.milestoneType = this.milestoneTypes[0]._id
        ? this.milestoneTypes[0]._id
        : undefined;
    }
    if (this.$route.params.id) {
      await this.fetchUi(this.$route.params.id);
      this.uiForm = this.ui;
    }
    this.uiForm.office = this.office._id || '';
  }

  resetModal(): void {
    this.$nextTick(() => {
      this.$refs.objectModal.hide();
    });
    this.selectedField = new UIField();
  }
  openIconSelectorModal(): void {
    this.$refs.iconSelectorModal.show();
  }
  handleIconSelected(icon: string): void {
    this.uiForm.icon = icon;
    this.$refs.iconSelectorModal.hide();
  }
  milestoneCodeByField(milestone?: string | Milestone): string {
    if (!milestone) {
      return '';
    }
    if (typeof milestone === 'string') {
      const id = milestone
        .replace('shipmentMilestones.', '')
        .replace('.value', '');
      const result = this.milestones.find(
        (x: MilestoneResponse) => x._id === id,
      );
      return result ? result.code : '';
    }
    return milestone.code || '';
  }
  displayLabel(field: UIField): string {
    if (field.field && field.field.startsWith('shipmentMilestones')) {
      return this.milestoneCodeByField(field.milestone);
    } else if (field.icon && field.labelIcon) {
      return field.labelIcon;
    }
    return field.label || '';
  }
  displayTranslatedField(field: UIField): string {
    if (
      field.fieldType === 'ms' ||
      (field.field && field.field.startsWith('shipmentMilestones'))
    ) {
      return this.milestoneCodeByField(field.milestone);
    } else if (field.icon && field.fieldIcon) {
      return field.fieldIcon;
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return (field.field && `${this.$t(field.field)}`) || '';
  }
  redirectToCreate(fieldType: UIFieldPosition): void {
    this.selectedFieldType = fieldType;
    this.selectedField = new UIField();
    this.$refs.objectModal.show();
  }
  redirectToDelete(item: UIField, fieldType?: UIFieldPosition): void {
    const result = confirm(
      'Are you sure you want to delete ' + item.field + '?',
    );
    if (!result) {
      return;
    }
    if (fieldType === UIFieldPosition.Sticky) {
      this.uiForm.stickyFields = [
        ...this.uiForm.stickyFields.filter(
          (field: UIField) => field._id !== item._id,
        ),
      ];
    } else {
      this.uiForm.scrollFields = [
        ...this.uiForm.scrollFields.filter(
          (field: UIField) => field._id !== item._id,
        ),
      ];
    }
    this.onSubmit();
    this.addToast({
      title: 'Success',
      message: 'Your field has been removed',
      type: ToasterTypes.SUCCESS,
    });
  }

  redirectToDetail(item: UIField, fieldType: string): void {
    this.selectedField = { ...item };
    this.selectedFieldType = fieldType;
    this.$refs.objectModal.show();
  }

  async createUiFilter(uiFilter: UIFilter, isUpdating: boolean): Promise<void> {
    if (isUpdating) {
      this.uiForm.baseFilter.splice(
        this.uiForm.baseFilter.indexOf(this.selectedFilter),
        1,
        uiFilter,
      );
    } else {
      this.uiForm.baseFilter.push(uiFilter);
    }
    this.isUpdating = false;
    this.$refs.filterCreateModal.hide();
    this.uiForm = await this.addEditUI(this.uiForm);
  }

  redirectToEditUiFilter(allUiFilters: UIFilter[], key: number): void {
    this.isUpdating = true;
    this.selectedFilter = allUiFilters[key];
    this.uiFilter = allUiFilters[key];
    this.disableUpdateCreate = true;
    this.$refs.filterCreateModal.show();
  }

  addUiFilter(): void {
    this.uiFilter = {} as UIFilter;
    this.selectedFilter = {} as UIFilter;
    this.uiFilter.isActive = true;
    this.isUpdating = false;
    this.disableUpdateCreate = true;
    this.$refs.filterCreateModal.show();
  }

  async removeUiFilter(index: number, uiForm: Ui): Promise<void> {
    uiForm.baseFilter.splice(index, 1);
    this.uiForm = await this.addEditUI(uiForm);
  }

  async changeActiveStatusFilter(
    state: boolean,
    key: number,
    uiForm: Ui,
  ): Promise<void> {
    uiForm.baseFilter[key].isActive = state;
    this.uiForm = await this.addEditUI(this.uiForm);
  }

  closeFilterCreateModal(): void {
    this.$refs.filterCreateModal.hide();
    return;
  }

  openCopyModal(): void {
    this.$refs.copyUiModal.show();
  }
}
