const getOfficeLocalTimeToSend = (
  dateString: string,
  officeTimezone: string,
): string => {
  const localUserTimeToSend = new Date(dateString);
  const millisecondsInOneMinute = 60000;
  const userTimeToSend = localUserTimeToSend.getTime();

  // Truncate to minute precision:
  const truncatedTime =
    userTimeToSend - (userTimeToSend % millisecondsInOneMinute);
  const officeLocaleTime = new Date(truncatedTime).toLocaleString('en-US', {
    timeZone: officeTimezone,
  });
  const officeDateObject = new Date(officeLocaleTime);

  return officeDateObject.toISOString();
};

export default getOfficeLocalTimeToSend;
