






































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TimeSlot } from '@/types/customer/timeslot.class';

@Component({
  components: {},
})
export default class TimeSlotSelect extends Vue {
  @Prop({ type: Array, required: true })
  timeSlots!: TimeSlot[];

  @Prop({ type: Number })
  weekday!: number;

  @Prop({ type: Boolean })
  openAllDay!: boolean;

  get open(): boolean {
    return this.openAllDay;
  }

  set open(value: boolean) {
    this.setOpenAllDay(value);
  }

  get SelectedWeekday(): string | undefined {
    switch (this.weekday) {
    case 7:
      return 'Sunday:';
      break;
    case 1:
      return 'Monday:';
      break;
    case 2:
      return 'Tuesday:';
      break;
    case 3:
      return 'Wednesday:';
      break;
    case 4:
      return 'Thursday:';
      break;
    case 5:
      return 'Friday:';
      break;
    case 6:
      return 'Saturday:';
      break;
    }
  }

  addTimeSlot(): void {
    if (this.openAllDay) {
      return;
    }
    this.timeSlots.push({
      ...new TimeSlot(),
      startTime: '00:00',
      endTime: '00:00',
    });
  }

  removeTimeSlot(slot: TimeSlot): void {
    if (this.timeSlots.length <= 1) {
      this.$emit('removeSchedule', this.weekday);
      return;
    }
    this.timeSlots.splice(this.timeSlots.indexOf(slot), 1);
  }
  setOpenAllDay(open: boolean): void {
    this.$emit('setOpenAllDay', open);
  }
}
