

















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EntityList from '../components/EntityList.vue';
import { IntegrationLog } from '../services/api/integration-logs/integration-log.class';
import { FetchAllParams } from '@/services/api/fetch-all-params.interface';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { EntityListColumn } from '@/types/entity-list-column.interface';

const integrationLogsModule = namespace('integrationLogs');

@Component({
  components: {
    EntityList,
  },
})
export default class AllIntegrationLogs extends Vue {
  @integrationLogsModule.Getter('ALL_PAGINATED')
  integrationLogs!: PaginatedResponse<IntegrationLog>;

  @integrationLogsModule.Action('FETCH_ALL_PAGINATED')
  fetchAllPaginated!: (
    filter?: FetchAllParams,
  ) => Promise<PaginatedResponse<IntegrationLog>>;

  filter: FetchAllParams = {};

  columns: Array<EntityListColumn<IntegrationLog>> = [
    {
      field: 'createdAt',
      label: 'Created At',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'workSite',
      label: 'Work Site',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'shipmentReference',
      label: 'Job Number',
      sortable: true,
      sortDirection: 'asc',
    },
    {
      field: 'message',
      label: 'Error',
      sortable: true,
      sortDirection: 'asc',
    },
  ];

  async created(): Promise<void> {
    await this.fetchAllPaginated();
  }
}
