var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first(("inputTo-" + _vm.msIndex))
        ? 'Please fill in a valid email address'
        : '',"label":"To:","label-for":"input-to"}},[_c('div',{staticStyle:{"display":"inline","max-width":"100%","overflow-wrap":"anywhere"}},_vm._l((_vm.mailFormInfo.to),function(mailTo,emailToIndex){return _c('b-badge',{key:emailToIndex,staticClass:"emailbadge mb-2 pt-1",attrs:{"pill":""},on:{"click":function($event){return _vm.removeEmail('to', emailToIndex)}}},[_vm._v(" "+_vm._s(mailTo)+" "),_c('font-awesome-icon',{staticClass:"ml-1",staticStyle:{"vertical-align":"middle"},attrs:{"icon":['far', 'times']}})],1)}),1),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:('email|required'),expression:"'email|required'",modifiers:{"disable":true}}],ref:"inputTo",attrs:{"id":("input-to-" + _vm.msIndex),"type":"email","name":("inputTo-" + _vm.msIndex),"placeholder":"example@mileviewer.com","state":!_vm.formErrors.has(("inputTo-" + _vm.msIndex)) ? null : false},on:{"blur":_vm.handleBlur},nativeOn:{"keydown":function($event){return _vm.keydownHandler($event)}},model:{value:(_vm.mailToInput),callback:function ($$v) {_vm.mailToInput=$$v},expression:"mailToInput"}})],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('inputCc'),"label":"Reply to:","label-for":"input-reply"}},[_c('b-form-select',{attrs:{"id":"input-reply","options":_vm.verifiedEmails,"value-field":"_id","text-field":"address"},model:{value:(_vm.mailFormInfo.replyTo),callback:function ($$v) {_vm.$set(_vm.mailFormInfo, "replyTo", $$v)},expression:"mailFormInfo.replyTo"}})],1),_c('b-form-group',{attrs:{"label":"CC:","label-for":"input-cc","invalid-feedback":_vm.formErrors.first(("inputCc-" + _vm.msIndex))
        ? 'Please fill in a valid email address'
        : ''}},[_c('div',{staticStyle:{"display":"inline","max-width":"100%","overflow-wrap":"anywhere"}},_vm._l((_vm.mailFormInfo.cc),function(mailCc,emailCcIndex){return _c('b-badge',{key:emailCcIndex,staticClass:"emailbadge mb-2 pt-1",attrs:{"pill":""},on:{"click":function($event){return _vm.removeEmail('cc', emailCcIndex)}}},[_vm._v(" "+_vm._s(mailCc)+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}})],1)}),1),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:('email'),expression:"'email'",modifiers:{"disable":true}}],ref:"inputCc",attrs:{"id":("input-cc-" + _vm.msIndex),"type":"email","name":("inputCc-" + _vm.msIndex),"placeholder":"Enter CC","state":!_vm.formErrors.has(("inputCc-" + _vm.msIndex)) ? null : false},on:{"blur":_vm.handleBlur},nativeOn:{"keydown":function($event){return _vm.keydownHandler($event)}},model:{value:(_vm.mailCcInput),callback:function ($$v) {_vm.mailCcInput=$$v},expression:"mailCcInput"}})],1),_c('b-form-group',{attrs:{"label":"BCC:","label-for":"input-bcc","invalid-feedback":_vm.formErrors.first(("inputBcc-" + _vm.msIndex))
        ? 'Please fill in a valid email address'
        : ''}},[_c('div',{staticStyle:{"display":"inline","max-width":"100%","overflow-wrap":"anywhere"}},_vm._l((_vm.mailFormInfo.bcc),function(mailBcc,emailBccIndex){return _c('b-badge',{key:emailBccIndex,staticClass:"emailbadge mb-2 pt-1",attrs:{"pill":""},on:{"click":function($event){return _vm.removeEmail('bcc', emailBccIndex)}}},[_vm._v(" "+_vm._s(mailBcc)+" "),_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}})],1)}),1),_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.disable",value:('email'),expression:"'email'",modifiers:{"disable":true}}],ref:"inputBcc",attrs:{"id":("input-bcc-" + _vm.msIndex),"type":"email","name":("inputBcc-" + _vm.msIndex),"placeholder":"Enter BCC","state":!_vm.formErrors.has(("inputBcc-" + _vm.msIndex)) ? null : false},on:{"blur":_vm.handleBlur},nativeOn:{"keydown":function($event){return _vm.keydownHandler($event)}},model:{value:(_vm.mailBccInput),callback:function ($$v) {_vm.mailBccInput=$$v},expression:"mailBccInput"}})],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('email subject'),"state":!_vm.formErrors.has('email subject') ? null : false,"label":"Subject:","label-for":"input-subject"}},[_c('b-input-group',{staticClass:"mt-3"},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"input-subject","state":!_vm.formErrors.has('email subject') ? null : false,"name":"email subject","type":"text","required":"","placeholder":"Enter subject"},on:{"input":_vm.handleInput},model:{value:(_vm.mailFormInfo.subject),callback:function ($$v) {_vm.$set(_vm.mailFormInfo, "subject", $$v)},expression:"mailFormInfo.subject"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.openKeywordModal(function (str) {
              _vm.mailFormInfo.subject
                ? (_vm.mailFormInfo.subject += str)
                : (_vm.mailFormInfo.subject = str);
            })}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'plus'],"size":"lg"}})],1)],1)],1)],1),_c('b-form-group',{attrs:{"invalid-feedback":_vm.formErrors.first('email body'),"state":!_vm.formErrors.has('email body') ? null : false,"label":"Body","label-for":"input-body"}},[_c('b-input-group',[_c('b-form-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"state":!_vm.formErrors.has('email body') ? null : false,"name":"email body","placeholder":"","rows":"6"},on:{"input":_vm.handleInput},model:{value:(_vm.mailFormInfo.body),callback:function ($$v) {_vm.$set(_vm.mailFormInfo, "body", $$v)},expression:"mailFormInfo.body"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.openKeywordModal(function (str) {
              _vm.mailFormInfo.body
                ? (_vm.mailFormInfo.body += str)
                : (_vm.mailFormInfo.body = str);
            })}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'plus'],"size":"lg"}})],1)],1)],1)],1),_c('b-form-group',{attrs:{"label":"Body ending:","label-for":"input-bodyending"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"placeholder":"","rows":"6"},on:{"input":_vm.handleInput},model:{value:(_vm.mailFormInfo.bodyending),callback:function ($$v) {_vm.$set(_vm.mailFormInfo, "bodyending", $$v)},expression:"mailFormInfo.bodyending"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.openKeywordModal(function (str) {
              _vm.mailFormInfo.bodyending
                ? (_vm.mailFormInfo.bodyending += str)
                : (_vm.mailFormInfo.bodyending = str);
            })}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['far', 'plus'],"size":"lg"}})],1)],1)],1)],1),_c('b-modal',{attrs:{"title":"Add dynamic variable to your text","size":"sm","ok-title":"Insert field"},on:{"ok":function($event){_vm.attachKeywordAndCloseModal(function () {
        _vm.mailFormInfo.subject += "{{ " + (_vm.selectedKeyword.value) + " }}";
      })}},model:{value:(_vm.showKeywordModal),callback:function ($$v) {_vm.showKeywordModal=$$v},expression:"showKeywordModal"}},[_c('field-select',{model:{value:(_vm.selectedKeyword),callback:function ($$v) {_vm.selectedKeyword=$$v},expression:"selectedKeyword"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }