import api from '@/services/api/';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from './types';
import {
  TokenUserInfo,
  TokenUserInfoState,
} from '@/types/token-user-info.interface.ts';

const namespaced = true;

const initialTokenUserState: TokenUserInfoState = {
  tokenUserInfo: {
    email: '',
    firstname: '',
    lastname: '',
  },
};

const getters: GetterTree<TokenUserInfoState, RootState> = {
  GET_ONE: state => state.tokenUserInfo,
};

const mutations: MutationTree<TokenUserInfoState> = {
  SET_ONE(state, payload: TokenUserInfo) {
    state.tokenUserInfo = payload;
  },
};

const actions: ActionTree<TokenUserInfoState, RootState> = {
  async FETCH_ONE(context, token: string): Promise<TokenUserInfo> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const tokenUserInfo: TokenUserInfo = await api.auth.requestResetTokenUserName(
        token,
      );
      context.commit('SET_ONE', tokenUserInfo);
      return tokenUserInfo;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

const tokenUserInfoModule: Module<TokenUserInfoState, RootState> = {
  namespaced,
  state: initialTokenUserState,
  getters,
  mutations,
  actions,
};

export default tokenUserInfoModule;
