/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import env from '../env.config';
import store from '@/store';
import { ToasterTypes, Toaster } from './store/modules/toasters/types';

const instance = axios.create({
  baseURL: env.API_URL,
});

const createToasterForResponse = (response: AxiosResponse) => {
  const toaster = {} as Toaster;
  toaster.type = ToasterTypes.ERROR;

  if (!response) {
    toaster.title = 'ERROR';
    toaster.message = 'Network error';
    return toaster;
  }

  switch (response && response.status) {
    case 400:
    case 422:
      toaster.title = 'Bad request';
      toaster.message = response.data.message
        ? response.data.message
        : 'The server could not process the data you sent';
      break;
    case 401:
    case 403:
    case 404:
    case 409:
      toaster.title = response.data.error;
      toaster.message = response.data.message;
    case 500:
      toaster.title = response.data.error;
      toaster.message = response.data.message;
      break;
    default:
      toaster.title = 'ERROR';
      toaster.message = 'Unknown error occured';
      break;
  }
  return toaster;
};

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers.Authorization) {
      return config;
    }
    const token: string = store.getters['users/GET_TOKEN'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  err => Promise.reject(err),
);

instance.interceptors.response.use(
  (success: AxiosResponse) => success,
  (error: AxiosError) => {
    store.dispatch(
      'toasters/ADD_TOASTER',
      createToasterForResponse(error.response as AxiosResponse),
    );
    throw error;
  },
);

export default instance;
