import { initialFilters } from './filters';
import env from '../../../../env.config';
import { ShipmentsState } from './types';

export const state: ShipmentsState = {
  shipments: [],
  infiniteLoadingIdentifier: 0,
  newShipments: 0,
  activeShipment: null,
  selected: null,
  apiParams: {
    limit: env.OKF_SHIPMENTS_LIMIT ? +env.OKF_SHIPMENTS_LIMIT : 25, // temporarily reduced for performance
    page: 1,
    sort: [],
    filters: initialFilters,
  },
  import: {
    result: {
      success: 0,
      failed: 0,
      status: '',
    },
  },
  perMilestone: [],
  count: 0,
  breachedJobs: 0,
  loadingDetail: {},
  currentUiBaseFilters: [],
};
