





















import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CustomerResponse } from '../services/api/customers/customer-response.interface';
import EntityList from '../components/EntityList.vue';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';
import { PaginatedResponseClass } from '../services/api/paginated-response';
import { EntityListColumn } from '@/types/entity-list-column.interface';

const customersModule = namespace('customers');

@Component({
  components: {
    EntityList,
  },
})
export default class CustomerList extends Vue {
  @customersModule.Getter('PAGINATED')
  customers!: PaginatedResponseClass<CustomerResponse>;

  @customersModule.Action('FETCH')
  fetchAll!: (filter?: FetchAllParams) => Promise<void>;

  @customersModule.Action('DELETE')
  deleteById!: (id: string) => Promise<void>;

  public columns: Array<EntityListColumn<CustomerResponse>> = [
    {
      field: 'parent.name',
      label: 'Customer Name',
      sortable: true,
      sortDirection: 'asc',
    },
    { field: 'aliases', label: 'Aliases', isHTML: true },
  ];

  filter: FetchAllParams = {};

  async handleFilterChange(filter: FetchAllParams): Promise<void> {
    await this.fetchAll(filter);
  }

  redirectToDetail(customer: CustomerResponse): void {
    this.$router.push({
      name: 'customer',
      params: { customerId: customer._id },
    });
  }

  redirectToCreate(): void {
    this.$router.push({
      name: 'createCustomer',
    });
  }

  async redirectToDelete(customer: CustomerResponse): Promise<void> {
    const result = confirm(
      'Are you sure you want to delete ' + customer.parent.name + '?',
    );
    if (!result) {
      return;
    }
    if (!customer._id) {
      return;
    }
    await this.deleteById(customer._id);
    await this.fetchAll();
  }

  async created(): Promise<void> {
    await this.fetchAll();
  }
}
