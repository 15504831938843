import { Attachment } from './attachment.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import { FetchAllParams } from '../fetch-all-params.interface';

export interface AttachmentsResponse {
  all: { docs: Attachment[] };
  one: Attachment;
  apiParams: FetchAllParams;
}
export default class AttachmentsService extends EntitiesService<Attachment> {
  get endpoint() {
    return '/attachments';
  }
}
