















import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { UIField } from '../types/ui/ui-field.class';
import { ShipmentResponse } from '../services/api/shipments/shipment-response.interface';
import { Transformations } from '../types/transformations';
import get from 'lodash.get';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

@Component
export default class ShipmentCustomLink extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  shipment!: ShipmentResponse;

  @Prop({
    type: Object,
    required: true,
  })
  field!: UIField;

  result: any = {};

  get fieldText() {
    return get(this.shipment, this.field.field);
  }

  created() {
    this.renderTransformation();
  }

  private renderTransformation() {
    this.field.customLinkTransformations.map(transformation => {
      Transformations.handleTransformation(
        this.shipment,
        transformation,
        this.result,
      );
    });

    if (this.result.url) {
      const tags = this.extractMustacheTags(this.result.url);

      tags.map(key => {
        this.result.url = this.replaceMustacheTagsWithValue(
          this.result.url,
          key,
        );
      });
    }
  }

  private extractMustacheTags(text: any): string[] {
    const tags: string[] = [];
    text.replace(/\{\{(.*?)\}\}/g, (key: string) => tags.push(key));
    return tags;
  }

  private replaceMustacheTagsWithValue(text: string, mustacheTag: string) {
    const propPath = mustacheTag.replace('{{', '').replace('}}', '').trim();

    return text.replace(mustacheTag, get(this.shipment, propPath));
  }
}
