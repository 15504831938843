










































































































import { Vue, Component, Model, Emit } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import lowerCase from 'lodash.lowercase';

import FieldSelect from '../components/FieldSelect.vue';
import { FieldFlagsBag, ErrorBag } from 'vee-validate';
import {
  ITransformation,
  ITransformationCondition,
  ConditionOperator,
} from '../types/transformations';

@Component({
  components: {
    Multiselect,
    FieldSelect,
  },
})
export default class FieldCustomLinkForm extends Vue {
  @Model('change', {
    type: Object,
  })
  transformation!: ITransformation;

  showKeywordModal = false;
  keywordCallback: any = null;
  selectedKeyword = '';

  formFields!: FieldFlagsBag;
  formErrors!: ErrorBag;

  get operatorOptions() {
    const { In, NotIn, ...operators } = ConditionOperator;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.values(operators);
  }

  get customLinkPlaceholder() {
    return 'https://dhl.com/tr={{ ref }}';
  }

  @Emit('remove-link')
  removeLink(): boolean {
    return true;
  }

  showParams(condition: ITransformationCondition): string | boolean {
    return (
      condition.operator && condition.operator !== ConditionOperator.Exists
    );
  }

  operatorCustomLabel(label: string): string {
    return lowerCase(label);
  }

  addCondition(): void {
    const newCondition: ITransformationCondition = {
      field: '',
      operator: ConditionOperator.Equal,
      parameters: '',
    };
    this.transformation.conditions
      ? this.transformation.conditions.push(newCondition)
      : (this.transformation.conditions = [newCondition]);
  }

  deleteCondition(conditionIndex: number): void {
    if (this.transformation.conditions) {
      this.transformation.conditions.splice(conditionIndex, 1);
    }
  }

  openKeywordModal(): void {
    this.showKeywordModal = true;
  }

  attachKeywordAndCloseModal(): void {
    if (this.transformation.operations && this.transformation.operations[0]) {
      this.transformation.operations[0].parameter += `{{ ${this.selectedKeyword} }}`;
      this.showKeywordModal = false;
      this.selectedKeyword = '';
    }
  }
}
