import Vue from 'vue';
import Vuex from 'vuex';

import application from '@/store/modules/application';
import utility from '@/store/modules/utility';
import customers from '@/store/modules/customers';
import milestones from '@/store/modules/milestones';
import milestoneTypes from '@/store/modules/milestone-types.module';
import shipments from '@/store/modules/shipments';
import shipmentTypes from '@/store/modules/shipment-types.module';
import users from '@/store/modules/users';
import ui from '@/store/modules/ui';
import toasters from '@/store/modules/toasters';
import reports from '@/store/modules/reports';
import shipmentImports from '@/store/modules/shipment-imports.module';
import offices from '@/store/modules/offices';
import companies from '@/store/modules/companies';
import rules from '@/store/modules/rules.module';
import verifiedEmails from '@/store/modules/verified-emails.module';
import translations from '@/store/modules/translations';
import statistics from '@/store/modules/statistics';
import logs from '@/store/modules/logs.module';
import transformations from '@/store/modules/transformations';
import attachments from '@/store/modules/attachments/attachments';
import shipmentAttachments from '@/store/modules/shipment-attachments';
import officeDuplications from '@/store/modules/office-duplications';
import tokenUserInfo from '@/store/modules/tokenUserInfo';
import reportSchedules from '@/store/modules/report-schedules';
import integrationLogs from '@/store/modules/integration-logs.module';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export default new Vuex.Store({
  modules: {
    application,
    utility,
    customers,
    milestones,
    milestoneTypes,
    shipments,
    shipmentTypes,
    users,
    ui,
    toasters,
    reports,
    shipmentImports,
    offices,
    companies,
    rules,
    verifiedEmails,
    translations,
    statistics,
    logs,
    transformations,
    attachments,
    shipmentAttachments,
    officeDuplications,
    tokenUserInfo,
    reportSchedules,
    integrationLogs,
  },
});
