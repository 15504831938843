























































import { Vue, Component} from 'vue-property-decorator';
import { namespace} from 'vuex-class';
import { VerifiedEmail } from '../services/api/verified-emails/verified-email.class';

const verifiedEmailsModule = namespace('verifiedEmails');

@Component({
  components: {},
})
export default class VerificationLanding extends Vue {
  @verifiedEmailsModule.Getter('GET')
  verifiedEmail!: VerifiedEmail;

  @verifiedEmailsModule.Action('FETCH_BY_TOKEN')
  fetchByToken!: (request: {
    token: string;
    region: string;
  }) => Promise<VerifiedEmail>;

  @verifiedEmailsModule.Action('VERIFY')
  verify!: (request: {
    token: string;
    region?: string;
  }) => Promise<VerifiedEmail>;

  @verifiedEmailsModule.Action('UPDATE')
  updateVerifiedEmail!: (mail: VerifiedEmail) => Promise<VerifiedEmail>;

  cardText = {
    title: '',
    message: '',
  };

  foundEmail: VerifiedEmail = {
    address: '',
    verified: false,
  };


  async checkForToken(): Promise<void>  {
    await this.fetchByToken({
      token: this.$route.params.token,
      region: (this.$route.query.t as string),
    });
    this.foundEmail = { ...this.verifiedEmail };

    if (this.foundEmail && this.foundEmail.verified === false) {
      this.foundEmail.verified = true;
      this.verify({
        token: this.$route.params.token,
        region: (this.$route.query.t as string),
      });
    }
  }
  async created(): Promise<void>  {
    this.foundEmail = {
      address: '',
      verified: false,
    };
    await this.checkForToken();
  }
}
