






















































































import {
  Vue,
  Component,
  Model,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import { Transformation } from '../services/api/transformations/transformation.class';
import Multiselect from 'vue-multiselect';
import { DataObject } from '@/services/api/data-object.interface';
// eslint-disable-next-line max-len
import { TransformationApplicationSelector } from '@/types/transformations/transformation-application-selector.interface';

@Component({
  components: {
    Multiselect,
  },
})
export default class TransformationRow extends Vue {
  @Model('change', {
    type: Object,
  })
  transformation!: Transformation;
  @Prop() value!: string;
  @Prop({
    type: Array,
    required: false,
  })
  headers!: string[];

  @Prop({
    type: Array,
    required: false,
  })
  options!: DataObject[];

  modelValue = { value: '', text: '' } as DataObject;
  loading = false;

  @Watch('options', { immediate: true })
  onOptionsChange(): void {
    if (this.value) {
      if (!this.options.length) {
        this.loading = true;
      } else {
        const selected = this.options.find(
          option => option.value === this.value,
        );
        if (selected) {
          this.modelValue = selected;
        }
        this.loading = false;
      }
    }
  }

  @Emit('input')
  onChange(selectedValue: DataObject): TransformationApplicationSelector {
    const value = selectedValue.value;
    const type = selectedValue.dataType as string;
    const required = selectedValue.required as boolean;
    const transformation = this.transformation;
    return { value, type, required, transformation };
  }

  getStringValues(list: []): string {
    let values = list.toString();
    values = values.split(',').join('<div class="my-1 border-top"><div/>');
    return values;
  }
}
