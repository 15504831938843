import { render, staticRenderFns } from "./AllShipmentImports.vue?vue&type=template&id=184a4d9a&scoped=true&"
import script from "./AllShipmentImports.vue?vue&type=script&lang=ts&"
export * from "./AllShipmentImports.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184a4d9a",
  null
  
)

export default component.exports