
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { mixins } from 'vue-class-component';

@Component({
  extends: Bar,
})
export default class ShipmentsChart extends mixins(Bar) {
  @Prop() data!: [];
  @Prop() labels!: [];

  @Watch('data')
  onDataChanged(data: any): void | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!data || !data.length) {
      return null;
    }
    this.renderBarChart();
  }

  @Watch('labels')
  onLabelsChanged(labels: string[]): void | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!labels || !labels.length) {
      return null;
    }
    this.renderBarChart();
  }

  renderBarChart(): void | null {
    if (
      !this.data ||
      !this.labels ||
      !this.data.length ||
      !this.labels.length
    ) {
      return null;
    }

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: 'Shipments',
            backgroundColor: '#398fd1',
            data: this.data,
          },
        ],
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: undefined,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                labelString: 'Total',
                display: true,
              },
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
              },
            },
          ],
        },
      },
    );
  }

  mounted(): void {
    this.renderBarChart();
  }
}
