import { render, staticRenderFns } from "./AllOfficeDuplications.vue?vue&type=template&id=64fbee42&scoped=true&"
import script from "./AllOfficeDuplications.vue?vue&type=script&lang=ts&"
export * from "./AllOfficeDuplications.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fbee42",
  null
  
)

export default component.exports