import { ActionContext } from 'vuex';
import { RootState } from '../types';
import { ToastersState, Toaster } from './types';

type AC = ActionContext<ToastersState, RootState>;

export const actions = {
  ADD_TOASTER(context: AC, payload: Toaster): void {
    payload.show = payload.show || 5;
    context.commit('ADD_TOASTER', payload);
  },

  REMOVE_TOASTER(context: AC, payload: Toaster): void {
    context.commit('REMOVE_TOASTER', payload);
  },

  CLEAR_ALL(context: AC): void {
    context.commit('CLEAR_ALL');
  },
};
