





























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ShipmentsChart from '@/components/ShipmentsChart.vue';
import { MilestoneType } from '@/services/api/milestone-types/milestone-type.class';
import { ShipmentType } from '@/services/api/shipment-types/shipment-type.class';
import { Milestone } from '@/services/api/milestones/milestone.class';
import { SortOrder } from '../services/api/sort-order.enum';
import { FilterOperation } from '@/types/filter-operation.enum';
import { FetchAllParams } from '../services/api/fetch-all-params.interface';

const milestoneTypesModule = namespace('milestoneTypes');
const shipmentTypesModule = namespace('shipmentTypes');
const shipmentsModule = namespace('shipments');
const milestonesModule = namespace('milestones');

@Component({
  components: {
    ShipmentsChart,
  },
})
export default class Statistics extends Vue {
  @milestoneTypesModule.Getter('ALL')
  milestoneTypes!: MilestoneType[];

  @milestoneTypesModule.Action('FETCH_ALL')
  fetchMilestoneTypes!: (filter?: any) => Promise<void>;

  @milestonesModule.Getter('ALL')
  milestones!: Milestone[];

  @milestonesModule.Action('FETCH_ALL')
  fetchMilestones!: (filter?: FetchAllParams) => Promise<Milestone[]>;

  @shipmentTypesModule.Getter('ALL')
  shipmentTypes!: ShipmentType[];

  @shipmentTypesModule.Action('FETCH_ALL')
  fetchShipmentTypes!: (filter?: any) => Promise<void>;

  @shipmentsModule.Getter('PER_MILESTONE')
  perMilestone!: any;

  @shipmentsModule.Action('FETCH_PER_MILESTONE')
  doFetchPerMilestone!: (filter?: any) => Promise<void>;

  @shipmentsModule.Getter('COUNT')
  shipmentsCount!: number;

  @shipmentsModule.Action('FETCH_COUNT')
  fetchShipmentsCount!: (filter?: any) => Promise<number>;

  @shipmentsModule.Getter('BREACHED_JOBS')
  breachedJobs!: any;

  @shipmentsModule.Action('FETCH_BREACHED_JOBS')
  doFetchBreachedJobs!: (filter?: any) => Promise<number>;

  activeMilestoneType = '';
  activeShipmentTypes: string[] = [];
  milestoneIds: string[] = [];
  shipments: number[] = [];
  labels: string[] = [];

  interval?: number;

  chartDataLoaded = false;

  jobs: {
    active: number;
    prioritized: number;
    breached: number;
  } = {
    active: 0,
    prioritized: 0,
    breached: 0,
  };

  @Watch('activeMilestoneType')
  async onActiveMilestoneTypeChanged(): Promise<void> {
    await this.refreshData();
  }

  @Watch('activeShipmentTypes')
  async onActiveShipmentTypeChanged(): Promise<void> {
    await this.refreshData();
  }

  async refreshData(): Promise<void> {
    const milestoneFilter: FetchAllParams = {
      filters: {
        type: {
          value: this.activeMilestoneType,
          operation: FilterOperation.Equals,
        },
      },
      sort: [`sequence,${SortOrder.Asc}`],
    };

    const statsFilter: FetchAllParams = {};
    statsFilter.filters = {
      [`archived.${this.activeMilestoneType}`]: {
        value: false,
        operation: FilterOperation.Equals,
      },
    };

    if (this.activeShipmentTypes.length > 0) {
      statsFilter.filters.type = {
        value: this.activeShipmentTypes,
        operation: FilterOperation.Equals,
      };
    }

    await this.fetchMilestones(milestoneFilter);
    this.labels = this.milestones.map((milestone: Milestone) => milestone.code);
    this.milestoneIds = this.milestones.map(
      (milestone: Milestone) => milestone._id as string,
    );

    this.fetchActiveShipments(statsFilter);
    this.fetchPrioritizedJobs(statsFilter);
    this.fetchBreachedJobs(statsFilter);
    this.fetchPerMilestone(statsFilter);
  }

  async fetchPerMilestone(statsFilter: FetchAllParams): Promise<void> {
    this.chartDataLoaded = false;

    // Shipments per milestone
    this.shipments = [];

    const filter: FetchAllParams = {
      filters: {},
    };

    if (statsFilter.filters) {
      const { ...rest } = statsFilter.filters;
      filter.filters = { ...rest };
    }

    if (filter.filters) {
      filter.filters.milestones = {
        value: this.milestoneIds,
        operation: FilterOperation.Equals,
      };
    }

    await this.doFetchPerMilestone(filter);

    this.chartDataLoaded = true;
  }

  async fetchActiveShipments(statsFilter: FetchAllParams): Promise<void> {
    // Active shipments
    this.jobs.active = await this.fetchShipmentsCount(statsFilter);
  }

  async fetchPrioritizedJobs(statsFilter: FetchAllParams): Promise<void> {
    // Prioritized jobs
    const prioritizedJobsFilter: FetchAllParams = {
      filters: {},
    };

    if (statsFilter.filters) {
      const { ...rest } = statsFilter.filters;
      prioritizedJobsFilter.filters = { ...rest };
    }

    if (prioritizedJobsFilter.filters) {
      prioritizedJobsFilter.filters.priority = {
        value: 1,
        operation: FilterOperation.GreaterThanOrEqual,
      };
    }

    this.jobs.prioritized = await this.fetchShipmentsCount(
      prioritizedJobsFilter,
    );
  }

  async fetchBreachedJobs(statsFilter: FetchAllParams): Promise<void> {
    // Jobs with breached rules
    const breachedRules: FetchAllParams = {
      filters: {},
    };

    if (statsFilter.filters) {
      const { ...rest } = statsFilter.filters;
      breachedRules.filters = { ...rest };
    }

    if (breachedRules.filters) {
      breachedRules.filters.milestones = {
        value: this.milestoneIds,
        operation: FilterOperation.Equals,
      };
    }
    this.jobs.breached = await this.doFetchBreachedJobs(breachedRules);
  }

  startInterval(): void {
    this.interval = (setInterval(() => {
      this.refreshData();
    }, 60000) as unknown) as number;
  }

  async created(): Promise<void> {
    await this.fetchMilestoneTypes();

    await this.fetchShipmentTypes();
    this.activeMilestoneType = this.milestoneTypes[0]._id;
    this.startInterval();
  }

  destroyed(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
