var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.attachments),function(attachment,attachmentIndex){return _c('b-card',{key:attachmentIndex,staticClass:"mb-2"},[_c('b-row',[_c('b-col',{attrs:{"md":attachment.multiple ? '12' : '9'}},[_c('div',{staticClass:"font-weight-bold"},[(
              _vm.getShipmentAttachmentByAttachment(attachment) &&
                !attachment.multiple
            )?_c('p',{staticClass:"pointer",class:{ active: _vm.getShipmentAttachmentByAttachment(attachment) },attrs:{"target":"_blank"},on:{"click":function($event){return _vm.downloadFile(attachment)}}},[_vm._v(" "+_vm._s(attachment.name)+" "),(attachment.isPrivate)?_c('b-badge',{staticClass:"bg-warning ml-2 pl-1 pr-1 text-white"},[_vm._v(" internal use ")]):_vm._e()],1):_c('p',[_vm._v(" "+_vm._s(attachment.name)),(attachment.isPrivate)?_c('b-badge',{staticClass:"bg-warning ml-2 pl-1 pr-1 text-white"},[_vm._v(" internal use ")]):_vm._e()],1)]),_c('p',[_vm._v(" "+_vm._s(attachment.description)+" ")])]),(!attachment.multiple)?_c('b-col',{staticClass:"text-right",attrs:{"md":"3"}},[(_vm.getShipmentAttachmentByAttachment(attachment))?_c('b-button',{staticClass:"p-2",attrs:{"id":("attach" + attachmentIndex),"variant":"outline-danger"},on:{"click":function($event){return _vm.unAttachShipment(attachment)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"lg"}}),_c('b-tooltip',{attrs:{"target":("attach" + attachmentIndex),"placement":"bottom"}},[_vm._v(" Unattach from shipment ")])],1):_vm._e()],1):_vm._e(),(attachment.multiple)?_vm._l((_vm.getShipmentAttachmentsByAttachment(
                    attachment
                  )),function(shipmentAttachment,shipmentAttachmentIndex){return _c('b-col',{key:shipmentAttachmentIndex,attrs:{"md":"1"}},[_c('b-button',{staticClass:"active p-2 m-1",attrs:{"id":("download" + (shipmentAttachment._id)),"target":"_blank","variant":"outline-secondary"},on:{"click":function($event){return _vm.downloadFileByShipmentAttachment(shipmentAttachment)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'download'],"fixed-width":"","size":"lg"}}),_c('b-tooltip',{attrs:{"target":("download" + (shipmentAttachment._id)),"placement":"bottom"}},[_vm._v(" "+_vm._s(shipmentAttachment.file.name.original)+" ")])],1),_c('b-button',{staticClass:"p-2 m-1",attrs:{"id":("attach" + (shipmentAttachment._id)),"variant":"outline-danger"},on:{"click":function($event){return _vm.unAttachShipmentByShipmentAttachment(shipmentAttachment)}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'trash-alt'],"fixed-width":"","size":"lg"}}),_c('b-tooltip',{attrs:{"target":("attach" + (shipmentAttachment._id)),"placement":"bottom"}},[_vm._v(" Unattach from shipment ")])],1)],1)}):_vm._e()],2),(
        attachment.multiple || !_vm.getShipmentAttachmentByAttachment(attachment)
      )?_c('vue2-dropzone',{ref:("dropzone-" + (attachment.name)),refInFor:true,staticClass:"shipment-dropzone",attrs:{"id":"dropzone","options":_vm.getDropzoneOptions()},on:{"vdropzone-file-added":function($event){return _vm.startDropzoneUpload($event, attachment)}}}):_vm._e()],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }