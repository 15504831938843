







































































































































import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import appCustomerSelect from '@/components/CustomerSelect.vue';
import SortMixin from '@/mixins/sort.mixin';
import { CustomerResponse } from '@/services/api/customers/customer-response.interface';
import { FilterOperation } from '@/types/filter-operation.enum';
import { Filters } from '@/types/filters.interface';
import { StickyColumnType } from '@/services/api/shipment-types/sticky-column-type.enum';
import { PaginatedResponseClass } from '../services/api/paginated-response';

const customersModule = namespace('customers');

@Component({
  components: {
    appCustomerSelect,
  },
})
export default class ShipmentListStickyColumn extends Mixins(SortMixin) {
  @Prop({
    type: Array,
    required: true,
  })
  stickyFields!: any[];

  @Prop({
    type: Object,
    required: true,
  })
  filters!: Filters;

  @Prop({
    type: String,
    required: false,
  })
  stickyColumnType!: StickyColumnType;

  @customersModule.Action('FETCH_ALL')
  fetchCustomers!: () => Promise<void>;

  showFilters = false;
  headerTexts: string[] = ['MAWB', 'HAWB'];

  @Emit('filter')
  filter(
    key: string,
    value: string | string[],
    operation: FilterOperation,
  ): Filters {
    return {
      ...this.filters,
      [key]: {
        value,
        operation,
      },
    };
  }
  checkIfFilterExists(field: string): any {
    if (!this.filters[field]) {
      return ' ';
    }
    return this.filters[field].value;
  }

  async created(): Promise<void> {
    await this.fetchCustomers();

    // Overwrite default column header text
    if (
      this.stickyColumnType === 'OCEAN' ||
      this.stickyColumnType === 'OCEAN_EXPANDED'
    ) {
      this.headerTexts[0] = 'M B/L';
      this.headerTexts[1] = 'H B/L';
    }
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }
}
