export enum ConditionOperator {
  Exists = 'EXISTS',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  GreaterThan = 'GREATER_THAN',
  LowerThan = 'LOWER_THAN',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  In = 'IN',
  NotIn = 'NIN',
  Contains = 'CONTAINS',
  NotContains = 'NOT_CONTAINS',
}
