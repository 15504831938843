import api from '@/services/api/';
import router from '@/router';
import { LoginRequest } from '@/services/api/auth/login-request.interface';
import { UserResponse } from '@/services/api/users/user-response.interface';
import { DateFormat } from '@/services/api/users/date-format.enum';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from './types';
import { UserRequest } from '@/services/api/users/user-request.interface';
import { UsersApiParams } from './userTypes';
import { ResetPasswordRequest } from '@/services/api/auth/reset-password-request.interface';
import { ResetPassword } from '@/services/api/auth/reset-password.interface';
import { Role } from '@/services/api/users/role.enum';
import { PaginatedResponse } from '@/services/api/paginated-response';
import { Office } from '@/services/api/offices/office.class';
import { AxiosResponse } from 'axios';
import env from '../../../env.config';

export interface OfficeEntity {
  _id: string;
  office: Office;
  role: Role;
}

export interface UsersState {
  loggedIn: boolean;
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  role: Role | '';
  timezone: string;
  dateFormat: DateFormat | '';
  isDisplay: boolean;
  isAdmin: boolean;
  token: string;
  users: PaginatedResponse<UserResponse>;
  offices: OfficeEntity[];
  selectedOffices: OfficeEntity[];
  companies: unknown[];
}

const getters: GetterTree<UsersState, RootState> = {
  GET_LOGGED_IN: state => state.loggedIn,
  GET_ID: state => state.id,
  GET_USERNAME: state => state.username,
  GET_FIRST_NAME: state => state.firstName,
  GET_LAST_NAME: state => state.lastName,
  GET_EMAIL: state => state.email,
  GET_ROLE: state => state.role,
  GET_TOKEN: state => state.token,
  GET_TIMEZONE: state => state.timezone,
  GET_DATE_FORMAT: state => state.dateFormat,
  GET_IS_DISPLAY: state => state.isDisplay,
  ALL: state => state.users.docs,
  ALL_PAGINATED: state => state.users,
  GET_IS_ADMIN: state => state.isAdmin,
  GET_OFFICE_ROLES: state => state.offices,
  GET_OFFICES: state => state.offices.map(officeRole => officeRole.office),
  GET_WORKING_OFFICE: state => {
    if (state.token !== '' && state.selectedOffices.length > 0) {
      return state.selectedOffices[0].office;
    }
    return null;
  },
  GET_COMPANIES: state => state.companies,
};

const mutations: MutationTree<UsersState> = {
  SET_LOGGED_IN: (state, payload: boolean) => {
    state.loggedIn = payload;
  },
  SET_ID: (state, payload: string) => {
    state.id = payload;
  },
  SET_USERNAME: (state, payload: string) => {
    state.username = payload;
  },
  SET_FIRST_NAME: (state, payload: string) => {
    state.firstName = payload;
  },
  SET_LAST_NAME: (state, payload: string) => {
    state.lastName = payload;
  },
  SET_EMAIL: (state, payload: string) => {
    state.email = payload;
  },
  SET_ROLE: (state, payload: Role) => {
    state.role = payload;
  },
  SET_TIMEZONE: (state, payload: string) => {
    state.timezone = payload;
  },
  SET_DATE_FORMAT: (state, payload: DateFormat) => {
    state.dateFormat = payload;
  },
  SET_IS_DISPLAY: (state, payload: boolean) => {
    state.isDisplay = payload;
  },
  SET_IS_ADMIN: (state, payload: boolean) => {
    state.isAdmin = payload;
  },
  SET_TOKEN: (state, payload: string) => {
    state.token = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<UserResponse>) {
    state.users = payload;
  },
  SET_ONE(state, payload: UserResponse) {
    const userIndex: number = state.users.docs.findIndex(
      (user: UserResponse) => user._id === payload._id,
    );

    if (userIndex > -1) {
      state.users.docs.splice(userIndex, 1, payload);
    } else {
      state.users.docs = [...state.users.docs, payload];
    }
  },
  DELETE_ONE(state, payload: UserResponse) {
    const userIndex: number = state.users.docs.findIndex(
      (user: UserResponse) => user._id === payload._id,
    );

    if (userIndex > -1) {
      state.users.docs.splice(userIndex, 1);
    }
  },
  SET_WORKING_OFFICES(state, offices: OfficeEntity[]) {
    state.selectedOffices = offices;
  },
  SET_OFFICES(state, payload: OfficeEntity[]) {
    payload.sort((a, b) => (a.office.name > b.office.name ? 1 : -1));
    state.offices = payload;
  },
  SET_COMPANIES(state, payload: unknown[]) {
    state.companies = payload;
  },
};

const actions: ActionTree<UsersState, RootState> = {
  SET_USER_DATA(context, payload: UserResponse) {
    context.commit('SET_ID', payload._id);
    context.commit('SET_USERNAME', payload.username);
    context.commit('SET_FIRST_NAME', payload.firstName);
    context.commit('SET_LAST_NAME', payload.lastName);
    context.commit('SET_EMAIL', payload.email);
    context.commit('SET_ROLE', payload.role);
    context.commit('SET_TIMEZONE', payload.timezone);
    context.commit('SET_DATE_FORMAT', payload.dateFormat);
    context.commit('SET_IS_DISPLAY', payload.isDisplay);
    context.commit('SET_IS_ADMIN', payload.isAdmin);
    context.commit('SET_OFFICES', payload.offices);
    context.commit('SET_COMPANIES', payload.companies);
    if (payload.offices && payload.selectedOffices) {
      context.commit(
        'SET_WORKING_OFFICES',
        payload.offices.filter((office: OfficeEntity) =>
          payload.selectedOffices.includes(office.office._id || ''),
        ),
      );
    }
  },
  UNSET_USER_DATA(context) {
    context.commit('SET_ID', '');
    context.commit('SET_USERNAME', '');
    context.commit('SET_FIRST_NAME', '');
    context.commit('SET_LAST_NAME', '');
    context.commit('SET_EMAIL', '');
    context.commit('SET_ROLE', '');
    context.commit('SET_TIMEZONE', '');
    context.commit('SET_DATE_FORMAT', '');
    context.commit('SET_IS_DISPLAY', false);
    context.commit('SET_IS_ADMIN', false);
  },
  async LOGIN(context, payload: LoginRequest) {
    try {
      const loginResult = await api.auth.login(
        payload.username,
        payload.password,
      );
      context.commit('SET_LOGGED_IN', true);
      context.dispatch('SET_USER_DATA', loginResult.user);
      context.commit('SET_TOKEN', loginResult.token);
      localStorage.setItem('token', loginResult.token);
      if (loginResult.user.offices && loginResult.user.offices.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        context.dispatch('SELECT_OFFICE', loginResult.user.offices[0].office);
        return loginResult;
      }
      router.push({
        name: 'environments',
      });
      return loginResult;
    } catch (error) {
      // TODO error message on form if login fails
      context.commit('SET_LOGGED_IN', false);
      context.dispatch('UNSET_USER_DATA');
      context.commit('SET_TOKEN', '');
      localStorage.removeItem('token');
      localStorage.removeItem('local_base_url');
    }
  },
  async AUTO_LOGIN(context): Promise<void | UserResponse> {
    try {
      context.commit('SET_TOKEN', localStorage.getItem('token'));
      const loginResult = await api.users.fetchMe();
      if (loginResult) {
        context.commit('SET_LOGGED_IN', true);
        context.dispatch('SET_USER_DATA', loginResult);
      }
      return loginResult;
    } catch (error) {
      // eslint-disable-next-line
      if (
        (error as AxiosResponse).data &&
        (error as AxiosResponse).status === 401
      ) {
        context.commit('SET_LOGGED_IN', false);
        context.dispatch('UNSET_USER_DATA');
        context.commit('SET_TOKEN', '');
        localStorage.removeItem('token');
        context.dispatch('application/UNSET_LOADING', null, { root: true });
      }
    }
  },
  async FETCH_ALL(context, payload: Partial<UsersApiParams>) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      // TODO: should not API_PARAMS come from rootGetters instead?
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      const params = Object.assign({}, context.getters.API_PARAMS, payload);
      const users = await api.users.fetchAll(params);
      context.commit('SET_ALL', users);
      return users;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const user = await api.users.fetchOne(id);
      context.commit('SET_ONE', user);
      return user;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async SELECT_OFFICE(context, office: Office) {
    context.dispatch('application/SET_LOADING', null, { root: true });
    const resp = await api.auth.selectOffice(office._id as string);
    context.commit('SET_TOKEN', resp.token);
    await context.dispatch('regions/FETCH_ALL', null, { root: true });
    localStorage.setItem('token', resp.token);
    localStorage.setItem('local_base_url', env.API_URL);
    await context.dispatch('ui/FETCH_ALL', null, { root: true });
    context.dispatch('application/UNSET_LOADING', null, { root: true });
    window.location.href = '/';
  },
  LOGOUT(context): void {
    context.commit('REMOVE_TOKENS');
    router.push('/login');
  },
  REMOVE_TOKENS(context): void {
    context.commit('SET_LOGGED_IN', false);
    context.dispatch('UNSET_USER_DATA');
    context.commit('SET_TOKEN', '');
    localStorage.removeItem('token');
    localStorage.removeItem('local_base_url');
    context.dispatch('application/UNSET_LOADING', null, { root: true });
  },
  async CREATE(context, payload: UserRequest) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const user = await api.users.create(payload);
      context.commit('SET_ONE', user);
      return user;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async UPDATE(context, payload: { id: string; user: UserRequest }) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const user = await api.users.update(payload.id, payload.user);
      context.commit('SET_ONE', user);
      return user;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
  async DELETE(context, payload: string) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      const user = await api.users.delete(payload);
      context.commit('DELETE_ONE', user);
      return user;
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async RESET(context, payload: ResetPasswordRequest): Promise<boolean> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      return api.auth.requestResetPassword(payload);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async RESET_AUTH(context, payload: ResetPasswordRequest) {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      return api.auth.requestResetPasswordAuthenticated(payload);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },

  async RESET_PASSWORD(context, payload: ResetPassword): Promise<boolean> {
    try {
      context.dispatch('application/SET_LOADING', null, { root: true });
      return api.auth.resetPassword(payload);
    } finally {
      context.dispatch('application/UNSET_LOADING', null, { root: true });
    }
  },
};

const initialState: UsersState = {
  loggedIn: false,
  id: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  timezone: '',
  dateFormat: '',
  isDisplay: false,
  isAdmin: false,
  token: '',
  users: {
    docs: [],
    total: 0,
    limit: 0,
    page: 0,
    pages: 0,
  },
  offices: [],
  selectedOffices: [],
  companies: [],
};

const usersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default usersModule;
