import { Milestone } from './milestone.class';
import EntitiesService from '../entities/entities.service';
import axios from '@/axios';
import { AxiosResponse } from 'axios';

export default class MilestonesService extends EntitiesService<Milestone> {
  get endpoint(): string {
    return (localStorage.getItem('local_base_url') as string) + '/milestones';
  }

  async bulk(milestoneList: Milestone[]): Promise<AxiosResponse> {
    const response = await axios.put(`${this.endpoint}/bulk`, milestoneList);
    return response;
  }
}
